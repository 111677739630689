import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import TpPopup from 'components/common/TpPopup';

class RiskFactorsInfoButton extends React.Component {
    static propTypes = {
        popupContent: PropTypes.string.isRequired,
        popupTitle: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            showConfirmPopup: false
        };

        this.confirmPopupConfig = {
            headerInfo: {
                webStringKey: props.popupTitle,
                type: 'info',
                showCloseButton: true
            },
            size: 'small'
        };
    }

    closeConfirmPopup = () => {
        this.setState({
            showConfirmPopup: false
        });
    };

    openConfirmPopup = () => {
        this.setState({
            showConfirmPopup: true
        });
    };

    render() {
        const {showConfirmPopup} = this.state;
        const {popupContent, popupTitle} = this.props;

        return (
            <div className="risk-matrix-info-container">
                <button
                    id={`risk-matrix-info-button-${popupTitle}`}
                    className="tp-risk-matrix--info-button"
                    onClick={this.openConfirmPopup}
                />
                {showConfirmPopup ? (
                    <TpPopup
                        onClose={this.closeConfirmPopup}
                        {...this.confirmPopupConfig}>
                        <WebString webStringKey={popupContent} />
                    </TpPopup>
                ) : null}
            </div>
        );
    }
}

export default RiskFactorsInfoButton;
