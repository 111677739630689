import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import {translateLabelsInEnumOptions} from '../widgetUtils';
import TextOnlyBaseInputWidget from './TextOnlyBaseInputWidget';

/**
 * Text only display of radio button widget.
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */
export const TextOnlyRadioWidget = (props) => {
    const propsCopy = cloneDeep(props);
    propsCopy.options = translateLabelsInEnumOptions(
        props.formContext.stringResources,
        props.options
    );

    const selectedValue = propsCopy.options.enumOptions.find(
        (option) => option.value === props.value
    );
    const displayLabel = selectedValue ? selectedValue.label : null;

    return <TextOnlyBaseInputWidget id={props.id} value={displayLabel} />;
};

TextOnlyRadioWidget.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string
            })
        )
    }).isRequired,
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired
};

export default TextOnlyRadioWidget;
