import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import EmailList from './EmailList';
import WebString from 'components/webString/WebString';
import CircularLoader from 'components/loader/CircularLoader';
import HTTPClient from 'lib/HTTPClient';
import * as snackActions from 'store/actions/snackActions';
import getErrorSnackObject from './errorSnackUtils';

const ExternalQuestionnaireAssignmentFieldHistorySection = (props) => {
    const [listOfSentEmails, setListOfSentEmails] = useState([]);
    const [isEmailListLoading, setIsEmailListLoading] = useState(false);

    const [showFetchListError, setShowFetchListError] = useState(false);

    const dispatch = useDispatch();

    const {proposalId, stringResources} = useSelector(
        ({process, settings}) => ({
            proposalId: process.proposal.id,
            stringResources: settings.i18n.stringResources
        })
    );

    useEffect(() => {
        fetchEmailList();
    }, [props.fetchHistoryCounter]);

    const fetchEmailList = async () => {
        setIsEmailListLoading(true);

        try {
            const response = await HTTPClient.get(
                `externalquestionnaires/${proposalId}`
            );
            setListOfSentEmails(response);
            setIsEmailListLoading(false);
            props.onSuccess(response[0].email); // TODO #28453 format check
        } catch (error) {
            setIsEmailListLoading(false);
            setShowFetchListError(true);

            const snackProperties = getErrorSnackObject(
                proposalId,
                'tp.externalquestionnaireassignment.notification.message.fetchemaillist.error',
                stringResources
            );
            dispatch(snackActions.createSnack(snackProperties));
        }
    };

    return (
        <>
            <label className="tp-externalquestionnaire-assignment__header">
                <WebString
                    className="tp-externalquestionnaire-assignment__header-history"
                    webStringKey="tp.externalquestionnaireassignment.history.header.text"
                />
            </label>
            {isEmailListLoading ? (
                <CircularLoader />
            ) : showFetchListError ? (
                <WebString
                    className="tp-externalquestionnaire-assignment__fetch-error"
                    webStringKey="tp.errormsg.externalquestionnaireassignment.fetchemaillist"
                />
            ) : (
                <EmailList emailList={listOfSentEmails} />
            )}
        </>
    );
};

ExternalQuestionnaireAssignmentFieldHistorySection.propTypes = {
    fetchHistoryCounter: PropTypes.number,
    onSuccess: PropTypes.func
};

ExternalQuestionnaireAssignmentFieldHistorySection.defaultProps = {
    fetchHistoryCounter: 0,
    onSuccess: () => undefined
};

export default ExternalQuestionnaireAssignmentFieldHistorySection;
