import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    getRoleFromObject,
    getShowMore,
    MIN_NUM_OF_ROLES_TO_SHOW
} from '../../rolesUtils';
import DimensionValues from './DimensionValues';

/**
 * Process dimension values to be rendered depending on the type of object passed from BE
 * @param props
 * @returns {*}
 */
export const RenderDimensions = (props) => {
    if (!props || !props.value || props.value.length === 0) {
        return null;
    }

    const {value: roleAndScope} = props;

    const getNumberOfRolesToShow = (id) => {
        const isShowingMore = getShowMore(props.roleInfoDisplayStateArray, id);
        if (isShowingMore) {
            return roleAndScope.length;
        } else {
            return MIN_NUM_OF_ROLES_TO_SHOW;
        }
    };

    const rolesToShow = getNumberOfRolesToShow(props.row.userId);
    return (
        <div className="tp-all-users-table__dimensions tp-users__column">
            {roleAndScope.slice(0, rolesToShow).map((role) => {
                const roleName = getRoleFromObject(role);
                return (
                    <DimensionValues
                        key={`${roleName}-${props.index}`}
                        roleObject={role}
                        dimensionName={props.column.dimensionName}
                    />
                );
            })}
        </div>
    );
};

const mapStateToProps = (state) => ({
    roleInfoDisplayStateArray:
        state.userManagement.table.roleInfoDisplayStateArray
});

RenderDimensions.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    roleInfoDisplayStateArray: PropTypes.array
};
RenderDimensions.defaultProps = {
    roleInfoDisplayStateArray: []
};

export default connect(mapStateToProps)(RenderDimensions);
