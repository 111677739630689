import {WebStorageStateStore} from 'oidc-client';
import urls from 'routes/urls';

/**
 * Sets up a user manager for making the OpenID Connect requests to
 * the central login.
 *
 * The UserManager (package oidc-client) for this application is created
 * inside the main.js, after retrieving the tp-fe-settings.json content.
 *
 * More information about the userManagerConfig object can be found
 * here: https://github.com/IdentityModel/oidc-client-js/wiki.
 *
 * @param {string} AppContextPath
 * @param {string} AuthAuthorityURI
 * @param {string} oidcClientId
 * @returns {object} identity configurations
 */
const getIdentityConfig = (AppContextPath, AuthAuthorityURI, oidcClientId) => {
    const absoluteBasePath = `${window.location.origin}${AppContextPath}`;
    const redirect_uri = `${absoluteBasePath}${urls.LOGIN_CALLBACK_URL.substring(
        1
    )}`;
    const silent_redirect_uri = `${absoluteBasePath}${urls.SILENT_RENEW_URL.substring(
        1
    )}`;
    const post_logout_redirect_uri = `${absoluteBasePath}${urls.LOGOUT_CALLBACK_URL.substring(
        1
    )}`;

    const IDENTITY_CONFIG = {
        /* REQUIRED */
        authority: AuthAuthorityURI, // The URL of the OIDC provider.
        client_id: oidcClientId, // Your client application's identifier as registered with the OIDC provider.
        redirect_uri, //The URI of your client application to receive a response from the OIDC provider.
        response_type: 'token id_token', //The type of response desired from the OIDC provider.
        scope: 'openid profile email', // The scope being requested from the OIDC provider.

        /* OPTIONAL */
        post_logout_redirect_uri, //  The OIDC post-logout redirect URI.
        automaticSilentRenew: false, //  Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
        silent_redirect_uri, // The URI for the page containing the code handling the silent renew.
        filterProtocolClaims: true,
        loadUserInfo: true, //Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
        monitorSession: true,
        checkSessionInterval: 5000,
        includeIdTokenInSilentRenew: false,
        userStore: new WebStorageStateStore({store: window.localStorage}) //  using localStorage allows many tabs in browser access the same information received from central login
    };

    return IDENTITY_CONFIG;
};

export default getIdentityConfig;
