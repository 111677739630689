/* CONSTANTS */

const defaultAppContextPath = '/tp/client/';

const defaultLocale = 'de';
const defaultTableCellPlaceHolder = '...';

const fileUploadMaxSizeInMB = 10;
const fileUploadExtensions = ['png', 'jpeg', 'pdf'];
const fileUploadMaxFilesCount = 10;

/* AXIOS */
// time axios will wait before failing TODO #9541
const defaultTimeout = 120 * 1000;
// -1 forever, 0 no time, 1 <= retries
const defaultMaxRetries = 2;
const defaultRetryInterval = 5000;
const defaultRetryCondition = (error) => {
    const {response} = error;

    if (response) {
        const {status} = response;
        return ![400, 409, 403, 422, 404, 401, 500].includes(status);
    }

    return true;
};
const defaultRetryCallback = () => {
    /* use to do something just before retrying the request */
};
const defaultRetrySuccessCallback = () => {
    /* use to clear f.e main errors */
};
/* Timers */
const defaultAutoSaveInterval = 60 * 1000;

export default {
    defaultAppContextPath,

    fileUploadMaxSizeInMB,
    fileUploadExtensions,
    fileUploadMaxFilesCount,

    defaultLocale,
    defaultTableCellPlaceHolder,

    defaultTimeout,
    defaultMaxRetries,
    defaultRetryInterval,
    defaultRetryCondition,
    defaultRetryCallback,
    defaultRetrySuccessCallback,

    defaultAutoSaveInterval
};
