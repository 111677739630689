import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import TpButton from 'components/common/buttons/TpButton';
import HTTPClient from 'lib/HTTPClient';

const ChatInputBlock = (props) => {
    const dispatch = useDispatch();
    const [textareaValue, setTextareaValue] = useState('');

    const sendMessage = async (text) => {
        try {
            await HTTPClient.post(
                `proposals/${props.proposalId}/chat-messages`,
                {text}
            );
            props.onSend();
        } catch (error) {
            dispatch({type: 'SEND_FEEDBACK_FAILED', error});
        }
    };

    const handleSent = async () => {
        if (textareaValue.trim()) {
            await sendMessage(textareaValue);
            setTextareaValue('');
        }
    };

    const handleInputChange = (event) => {
        setTextareaValue(event.target.value);
    };

    return (
        <div className="row bk-tp-chatfield__inputblock">
            <div className="col-sm-9">
                <textarea
                    id="txt_chatfield_textarea"
                    className="bk-tp-chatfield__textarea"
                    onChange={handleInputChange}
                    value={textareaValue}
                />
            </div>
            <div className="col-sm-3">
                <TpButton
                    id="btn_feedback_send_message"
                    onClick={handleSent}
                    webStringKey="tp.button.addcomment"
                    className="bk-tp-chatfield__submitbutton"
                />
            </div>
        </div>
    );
};

ChatInputBlock.propTypes = {
    onSend: PropTypes.func.isRequired,
    proposalId: PropTypes.number.isRequired
};

export default ChatInputBlock;
