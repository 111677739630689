import numeral from 'numeral';

/**
 * @class
 * @classdesc  Class representing a placeholder for {} placeholder in a string.
 */
export default class StringPlaceholder {
    /**
     * can be extended with e.g. DATE, WEBSTRING,...
     */
    static ArgumentTypes = {
        CURRENCY: 'CURRENCY',
        SIMPLE: 'SIMPLE'
    };

    static defaultArgument = '___';
    static placeholderRegex = /{[^}]+}/g;

    /**
     *
     * @param {String} variableToReplace - variable name to be replaced, marked in string within {}
     * @param {String|Number} argument - the value to be replaced in place of {variableToReplace}
     * @param {StringPlaceholder.ArgumentTypes} argumentType
     */
    constructor(
        variableToReplace,
        argument,
        argumentType = StringPlaceholder.ArgumentTypes.SIMPLE
    ) {
        this.variableToReplace = variableToReplace;
        this.argument = argument;
        this.argumentType = argumentType;
    }

    /**
     * Replace the value in {} with argument value in accordance with the argument type.
     * If argument is not defined use defaultArgument.
     * Can be extended using another ArgumentTypes.
     *
     * @param {String} message
     *
     * @returns {String}
     */
    replaceIn(message) {
        let argument = this.argument;

        if (argument === undefined || argument === null) {
            argument = StringPlaceholder.defaultArgument;
        }

        if (
            this.argumentType === StringPlaceholder.ArgumentTypes.CURRENCY &&
            !isNaN(argument)
        ) {
            argument = numeral(argument).format('0,0.00');
        }

        return message.replace(`{${this.variableToReplace}}`, argument);
    }

    /**
     * Replace all values in {} with default value.
     *
     * @param {string} message
     * @param defaultValue
     * @returns {string}
     */
    static replaceAllWithDefaultIn(
        message,
        defaultValue = StringPlaceholder.defaultArgument
    ) {
        return message.replace(
            StringPlaceholder.placeholderRegex,
            defaultValue
        );
    }
}
