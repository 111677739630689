import React, {useContext} from 'react';
import {AuthContext} from 'components/authorization/AuthProvider';
import CircularLoader from 'components/loader/CircularLoader';

//  Used by the event addAccessTokenExpiring configured in the AuthService.
const SilentRenew = () => {
    const auth = useContext(AuthContext);
    auth.signinSilentCallback();
    return <CircularLoader />;
};

export default SilentRenew;
