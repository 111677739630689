import React from 'react';
import PropTypes from 'prop-types';
import TpDetailsInfo from 'components/tpForm/fields/TpDetailsInfo';
import * as fieldsUtils from './fieldsUtils';
import CollapsibleContainer from './CollapsibleContainer';
import WebString from '../../webString/WebString';

const isCollapsible = (schema, uiSchema) =>
    fieldsUtils.isType(schema, 'object') &&
    !fieldsUtils.isHeaderFoldingDisabled(uiSchema) &&
    !fieldsUtils.isHeaderHidden(uiSchema);

/**
 * Custom field template used to render every field in a form.
 * This extends the default template by adding classes for required fields, and where the field is a header.
 * By setting 'hideHeader' to true in the UI schema, the header label will be hidden.
 * By setting 'disableFoldingHeader' to true in the UI schema, the header label will be not foldable.
 *
 * @param {Object} props
 * @returns {ReactElement} markup
 */
const TpFieldTemplate = (props) => {
    const {id, classNames, label, required, children, schema, uiSchema} = props;
    // allow field to be skipped completely.
    // this allows complex custom fields which include read-only fields, e.g. in Risk Alerts.
    if (uiSchema['ui:widget'] === 'ommitted') {
        return null;
    }

    const headerClass = fieldsUtils.isType(schema, 'object')
        ? ' bk-cs-form-header'
        : '';
    const borderClass = uiSchema.addBorder ? ' bk-cs-box--bordered' : '';

    if (isCollapsible(schema, uiSchema)) {
        return (
            <div
                id={`${id}_field`}
                className={`${classNames}${headerClass}${borderClass}`}>
                <CollapsibleContainer
                    id={id}
                    titleKey={label}
                    className="bk-cs-form-field">
                    {children}
                </CollapsibleContainer>
            </div>
        );
    }

    const requiredFieldClass = required ? ' bk-cs-form-label--required' : '';
    let detailsInfo;
    if (schema.detailsInfo) {
        detailsInfo = {
            infoText: schema.detailsInfo,
            isOpenByDefault: uiSchema['ui:isDetailsInfoOpenByDefault']
        };
    } else if (uiSchema['ui:detailsInfo']) {
        detailsInfo = uiSchema['ui:detailsInfo'];
    }

    const detailsInfoElement = detailsInfo ? (
        <TpDetailsInfo detailsInfo={detailsInfo} />
    ) : null;

    const labelElement =
        fieldsUtils.isSimpleBooleanField(schema) ||
        fieldsUtils.isHeaderHidden(uiSchema) ? null : (
            <label
                id={`form__section-header::${label}`}
                className={`bk-cs-form-label bk-cs-field-label${requiredFieldClass}`}>
                <WebString webStringKey={label} />
            </label>
        );
    return (
        <div
            id={`${id}_field`}
            className={`${classNames}${headerClass}${borderClass}`}>
            {labelElement}
            <div className="bk-cs-form-field">{children}</div>
            {detailsInfoElement}
        </div>
    );
};

TpFieldTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    classNames: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    schema: PropTypes.object,
    uiSchema: PropTypes.object
};

TpFieldTemplate.defaultProps = {
    classNames: '',
    label: '',
    required: false,
    schema: {},
    uiSchema: {}
};

export default TpFieldTemplate;
