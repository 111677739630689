import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import TpBaseInput from './TpBaseInput';

/**
 * Field for displaying dynamic url based on the input(replaces the parameter).
 * url passed should have the field defined as *PARAMETER* to get it replaced dynamically when defined in ui-schema
 */

export const TpDynamicUrl = (props) => {
    const renderLink = (inputFieldValue) => {
        const {url, urlWebStringKey} = props.options;

        const dynamicUrl = url.replace('*PARAMETER*', inputFieldValue.trim());

        return dynamicUrl && inputFieldValue ? (
            <a
                rel="external"
                id={`dynamic_external_link_${props.id}`}
                className="bk-cs-form-dynamicUrl"
                target="_blank"
                href={dynamicUrl}>
                <WebString webStringKey={urlWebStringKey} />
            </a>
        ) : null;
    };

    let showLink = true;
    const disabled = props.disable || props.readonly;
    if (!disabled && props.schema.pattern) {
        const reg = new RegExp(props.schema.pattern);
        showLink = reg.test(props.value) ? true : false;
    }
    return (
        <React.Fragment>
            <TpBaseInput {...props} />
            {showLink ? renderLink(props.value) : null}
        </React.Fragment>
    );
};

TpDynamicUrl.propTypes = {
    options: PropTypes.shape({
        url: PropTypes.string.isRequired,
        urlWebStringKey: PropTypes.string.isRequired
    }).isRequired,
    value: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    type: PropTypes.string,
    schema: PropTypes.object,
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired
};

TpDynamicUrl.defaultProps = {
    value: '',
    disabled: false,
    readonly: false,
    options: {},
    schema: {},
    type: 'text'
};

export default TpDynamicUrl;
