import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import UserFeatureTypes from 'types/UserFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import AdHocCheckPanel from 'components/adHocCheck/AdHocCheckPanel';
import NotFound from 'components/common/errorHandling/NotFound';

const AdHocCheckRoute = () => {
    const {path} = useRouteMatch();
    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[UserFeatureTypes.AD_HOC_CHECK]}
            defaultChildren={<NotFound />}>
            <Route exact={true} path={path} component={AdHocCheckPanel} />
        </UserFeatureToggle>
    );
};

export default AdHocCheckRoute;
