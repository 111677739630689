import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import WebString from 'components/webString/WebString';
import EditInputEmailAddress from './EditInputEmailAddress';
import ExternalQuestionnaireAssignmentFieldHistorySection from './ExternalQuestionnaireAssignmentFieldHistorySection';
import SendExternalQuestionnaireButton from './SendExternalQuestionnaireButton';
import SkipExternalQuestionnaireButton from './SkipExternalQuestionnaireButton';
import StatusTypes from 'types/StatusTypes';

const ExternalQuestionnaireAssignmentField = () => {
    const {proposalId, view, taskStatus, stringResources} = useSelector(
        ({process, settings}) => ({
            proposalId: process.proposal.id,
            view: process.view,
            taskStatus: process.currentTask.status,
            stringResources: settings.i18n.stringResources
        })
    );

    const [enteredEmailAddress, setEnteredEmailAddress] = useState('');
    const [historyCounter, setHistoryCounter] = useState(0);

    const handleEmailInput = (email) => {
        setEnteredEmailAddress(email);
    };

    const handleOnSuccesHistorySection = (email) => {
        if (!enteredEmailAddress) {
            setEnteredEmailAddress(email);
        }
    };

    const handleOnSendSuccess = () => {
        setHistoryCounter(historyCounter + 1);
    };

    return (
        <div>
            <ExternalQuestionnaireAssignmentFieldHistorySection
                fetchHistoryCounter={historyCounter}
                onSuccess={handleOnSuccesHistorySection}
            />
            {taskStatus !== StatusTypes.Task.WAITING ? null : (
                <>
                    <label
                        className="tp-externalquestionnaire-assignment__header"
                        key="send-email-label">
                        <WebString
                            className="tp-externalquestionnaire-assignment__header-sendquestionnaire"
                            webStringKey="tp.externalquestionnaireassignment.sendquestionnaire.header.text"
                        />
                    </label>
                    <div key="send-edit-toggle">
                        <EditInputEmailAddress
                            onEmailChange={handleEmailInput}
                            enteredEmailAddress={enteredEmailAddress}
                        />
                    </div>
                    <div
                        className="tp-externalquestionnaire-assignment__buttondiv"
                        key="send-questionnaire-buttons">
                        <SendExternalQuestionnaireButton
                            proposalId={proposalId}
                            stringResources={stringResources}
                            email={enteredEmailAddress}
                            onSuccess={handleOnSendSuccess}
                        />
                        <SkipExternalQuestionnaireButton
                            proposalId={proposalId}
                            stringResources={stringResources}
                            view={view}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ExternalQuestionnaireAssignmentField;
