const ADD_USER = 'ADD_USER';
const REMOVE_USER = 'REMOVE_USER';

const defaultState = {
    user: null
};

export const ACTION_TYPES = {
    ADD_USER,
    REMOVE_USER
};

const oidcReducer = (state = defaultState, action) => {
    const {type, user} = action;
    switch (type) {
        case 'ADD_USER':
            return {
                user
            };
        case 'REMOVE_USER':
            return {
                user: null
            };
        default:
            return state;
    }
};

export default oidcReducer;
