import React from 'react';
import UploadFileField from '../UploadFileField';

/**
 * Custom text-only version field to show file upload component.
 * Lists currently uploaded files, with options to download.
 */
const TextOnlyUploadFileField = (props) => (
    <UploadFileField
        {...props}
        textOnly={true}
        disabled={true}
        readonly={true}
    />
);

export default TextOnlyUploadFileField;
