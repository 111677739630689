import React from 'react';
import ReactDOM from 'react-dom';
import Initial from './Initial';
import initialise from './initialise';
import Modernizer from 'modernizr';
require('./styles/scss/bkag.scss');

if (!Modernizer.details) {
    require('details-polyfill');
}

initialise().then((ini) => {
    ReactDOM.render(
        <Initial settings={ini.settings} />,
        document.getElementById('main-entry')
    );
});
