import React from 'react';
import PropTypes from 'prop-types';
import RiskAlertsTableEntry from './RiskAlertsTableEntry';
import RiskAlertsTableHeader from './RiskAlertsTableHeader';

const RiskAlertsTable = (props) => {
    if (!props.alerts.length) {
        return null;
    }
    const headers = ['flags', 'name', 'country', 'match', 'status', 'critical'];

    return (
        <table id="risk-alert-table" className="tp-risk-alerts__table">
            <RiskAlertsTableHeader headers={headers} />
            {props.alerts.map((alert) => (
                <RiskAlertsTableEntry
                    key={alert.peId}
                    alert={alert}
                    onRiskProfileClick={props.onRiskProfileClick}
                />
            ))}
        </table>
    );
};

RiskAlertsTable.propTypes = {
    alerts: PropTypes.arrayOf(
        PropTypes.shape({
            countryCode: PropTypes.string,
            status: PropTypes.string,
            critical: PropTypes.bool,
            comment: PropTypes.string,
            peId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            riskFlags: PropTypes.string.isRequired,
            matchPct: PropTypes.string.isRequired
        })
    ),
    onRiskProfileClick: PropTypes.func.isRequired
};

RiskAlertsTable.defaultProps = {
    alerts: []
};

export default RiskAlertsTable;
