import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import defaultAppValues from 'lib/defaultAppValues';
import HTTPClient from 'lib/HTTPClient';
import Loader from 'components/loader/CircularLoader';
import UploadProcess from './fileUpload/UploadProcess';
import UploadedFileDataList from './fileUpload/UploadedFileDataList';
import fileMimeTypes from './fileUpload/fileMimeTypes';
import LabelForUploadFileField from './fileUpload/LabelForUploadFileField';

const uiOptionsBase = {
    keyForUpload: PropTypes.string.isRequired,
    maxFilesCount: PropTypes.number,
    maxFileSizeInMB: PropTypes.number,
    preventFromDeleting: PropTypes.bool
};

const uiDefaultOptions = {
    maxFilesCount: defaultAppValues.fileUploadMaxFilesCount,
    maxFileSizeInMB: defaultAppValues.fileUploadMaxSizeInMB,
    allowedFileExtensions: defaultAppValues.fileUploadExtensions,
    preventFromDeleting: false
};

/**
 * Custom field to show file upload component.
 * Gives intro text with instructions: number of files, allowed types and size limit.
 * Button to select file for upload.
 * Uploading file progress bar, with success and error states, and
 * retry and cancel buttons.
 * Lists currently uploaded files, with options to download and
 * delete (only if it is not indicated in the ui:options with preventFromDeleting property).
 */
const UploadFileField = (props) => {
    const {
        formContext: {formId},
        uiSchema,
        schema: {title, type}
    } = props;

    const dispatch = useDispatch();
    const proposalId = useSelector((state) => state.process.proposal.id);
    const [uploadedFiles, setUploadedFiles] = useState(null);

    const externalId = `${formId}::${uiSchema['ui:options'].keyForUpload}`;
    const filesEndpointUrlPath = `proposals/${proposalId}/files`;

    useEffect(() => {
        const getAllUploadedFilesAsync = async () => {
            try {
                const files = await HTTPClient.get(
                    `${filesEndpointUrlPath}?externalId=${externalId}`
                );
                setUploadedFiles(files);
            } catch (error) {
                dispatch({type: 'GET_ALL_FILES_FAILED', error});
            }
        };
        getAllUploadedFilesAsync();
    }, []);

    useEffect(() => {
        if (type === 'integer' && Array.isArray(uploadedFiles)) {
            // old upload fields used "type" "object", we need to stay compatible with the old proposals
            props.onChange(uploadedFiles.length);
        }
    }, [uploadedFiles]);

    const handleUploadedFileData = (newFileData) => {
        setUploadedFiles([...uploadedFiles, newFileData]);
    };

    const handleRemoveUploadedFile = (fileId) => {
        const remainingFiles = uploadedFiles.filter(
            (item) => item.fileId !== fileId
        );
        setUploadedFiles(remainingFiles);
    };

    const getFileExtensionsFromMimeTypes = (allowedFileTypes) =>
        allowedFileTypes.map((type) => fileMimeTypes.get(type));

    if (!uploadedFiles) {
        return <Loader />;
    }

    if (props.textOnly) {
        return (
            <div className="file-upload file-upload--textonly">
                <UploadedFileDataList
                    files={uploadedFiles}
                    disabled={true}
                    filesEndpointUrlPath={filesEndpointUrlPath}
                />
            </div>
        );
    }

    const uiOptions = {
        ...uiDefaultOptions,
        ...uiSchema['ui:options']
    };

    const {
        maxFileSizeInMB,
        allowedFileExtensions,
        allowedFileTypes, // old implementation, can be removed in the future
        maxFilesCount,
        preventFromDeleting,
        keyForUpload
    } = uiOptions;

    const {disabled, readonly, required} = props;

    const fileSettings = {
        maxFileSizeInMB,
        maxFilesCount,
        allowedFileExtensions: allowedFileTypes
            ? getFileExtensionsFromMimeTypes(allowedFileTypes)
            : allowedFileExtensions
    };

    const isDisabled = disabled || readonly;

    return (
        <div className="file-upload">
            <LabelForUploadFileField title={title} required={required} />
            {isDisabled ? null : (
                <div className="bk-cs-padding-horiz--sm">
                    <UploadProcess
                        uploadButtonId={`fileupload__upload-button::${keyForUpload}`}
                        filesEndpointUrlPath={filesEndpointUrlPath}
                        onItemSuccess={handleUploadedFileData}
                        uploadedFilesCount={uploadedFiles.length}
                        fileUploadExternalId={externalId}
                        {...fileSettings}
                    />
                </div>
            )}
            <UploadedFileDataList
                files={uploadedFiles}
                disabled={isDisabled}
                onRemoveFile={
                    preventFromDeleting ? null : handleRemoveUploadedFile
                }
                filesEndpointUrlPath={filesEndpointUrlPath}
            />
        </div>
    );
};

UploadFileField.propTypes = {
    schema: PropTypes.shape({
        title: PropTypes.string.isRequired
    }).isRequired,
    formContext: PropTypes.shape({
        formId: PropTypes.string.isRequired
    }).isRequired,
    uiSchema: PropTypes.shape({
        'ui:options': PropTypes.oneOfType([
            PropTypes.shape({
                ...uiOptionsBase,
                allowedFileExtensions: PropTypes.arrayOf(PropTypes.string)
            }),
            PropTypes.shape({
                ...uiOptionsBase,
                allowedFileTypes: PropTypes.arrayOf(PropTypes.string) // we need to stay compatible with old proposals, can be removed in the future
            })
        ]).isRequired
    }).isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    textOnly: PropTypes.bool
};

UploadFileField.defaultProps = {
    disabled: false,
    readonly: false,
    textOnly: false
};

export default UploadFileField;
