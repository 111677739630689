import React from 'react';
import TpLink from 'components/common/TpLink';
import urls from 'routes/urls';

const CreateProposalButton = () => (
    <TpLink
        to={urls.PROPOSAL_INFO_URL}
        webStringKey="tp.button.newproposal"
        className="bk-cs-element-right"
        asButton={true}
        id="bkms-tp-startProposal-btn"
    />
);

export default CreateProposalButton;
