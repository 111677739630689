/**
 * Store measurements to be applied to inline styles for element position manipulation.
 * @type {Object}
 */
const globalDomMeasurements = {
    headerHeight: 150,
    footerHeight: 21,
    spacerHeight: 15
};

export default globalDomMeasurements;
