import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import CircularLoader from 'components/loader/CircularLoader';
import {createSnack} from 'store/actions/snackActions';
import UserSchemaNameTypes from '../userSchemaNameTypes';
import TabIndexContext from './TabIndexContext';
import UserPanelView from './UserPanelView';
import useUserData from './useUserData';
import useSaveState from './useSaveState';
import urls from 'routes/urls';

export const UserPanel = (props) => {
    const history = useHistory();

    const {
        stringResources,
        userId,
        callSnack,
        onError,
        userSchemaNameType
    } = props;

    const [
        userData,
        setGeneralFormData,
        setRolesFormData,
        setSingleRightsData
    ] = useUserData(userId, userSchemaNameType, onError);

    const [saveState, setSaveState] = useSaveState(
        userSchemaNameType,
        userData,
        callSnack,
        onError,
        stringResources
    );
    const [tabIndexState, setTabIndexState] = useState(() => ({
        selectedTabIndex: 0,
        tabIndexesHasError: []
    }));
    const generalFormButtonRef = useRef();
    const rolesFormButtonRefs = useRef({});

    /**
     * Simulate hidden button clicks for all forms so that we can check error and
     * trigger handleOnSubmit function
     */
    const onSaveClicked = () => {
        generalFormButtonRef.current.click();
        Object.keys(rolesFormButtonRefs.current).forEach((key) => {
            rolesFormButtonRefs.current[key].click();
        });
    };

    /**
     * Set saveState hook for preparing to call userData
     */
    const handleOnSubmit = () => {
        setTimeout(() => {
            setSaveState((prevState) => ({
                ...prevState,
                isSaveClicked: true
            }));
        }, 500);
    };

    const handleOnGeneralFormDataChange = ({formData}) => {
        setGeneralFormData((prevState) => ({
            ...prevState,
            ...formData
        }));
        setSaveState((prevState) => ({
            ...prevState,
            isSent: false,
            isSaveClicked: false,
            errors: {
                ...prevState.errors,
                generalFormError: false
            }
        }));
    };

    const handleOnRolesFormDataChange = (roleType, formData, index) => {
        setSingleRightsData((prevState) => ({
            ...prevState,
            ...formData.singleRights
        }));
        setRolesFormData((prevState) => ({
            ...prevState,
            [roleType]: formData
        }));
        setSaveState((prevState) => ({
            ...prevState,
            isSent: false,
            isSaveClicked: false,
            errors: {
                ...prevState.errors,
                [roleType]: false
            }
        }));
        setTabIndexState((prevState) => ({
            ...prevState,
            tabIndexesHasError: prevState.tabIndexesHasError.filter(
                (tabIndex) => tabIndex !== index
            )
        }));
    };

    const handleGeneralFormError = () => {
        setSaveState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                generalFormError: true
            }
        }));
    };

    const handleRoleFormError = (roleType, index) => {
        setSaveState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                [roleType]: true
            }
        }));
        setTabIndexState((prevState) => ({
            ...prevState,
            tabIndexesHasError: prevState.tabIndexesHasError.includes(index)
                ? prevState.tabIndexesHasError
                : [...prevState.tabIndexesHasError, index],
            selectedTabIndex: Math.min(index, ...prevState.tabIndexesHasError)
        }));
    };

    const goBack = () => {
        history.push(urls.USERS_URL);
    };

    const generateRefs = (ref, roleType) => {
        if (!rolesFormButtonRefs.current[roleType])
            rolesFormButtonRefs.current[roleType] = ref;
    };

    if (
        userSchemaNameType === UserSchemaNameTypes.UPDATE &&
        !userData.generalFormData
    ) {
        return <CircularLoader />;
    }

    return (
        <TabIndexContext.Provider
            value={{
                tabIndexState,
                setTabIndexState
            }}>
            <UserPanelView
                userData={userData}
                saveButtonDisabled={saveState.isSent}
                userSchemaNameType={userSchemaNameType}
                stringResources={stringResources}
                generalFormButtonRef={generalFormButtonRef}
                generateRefs={generateRefs}
                onSaveClicked={onSaveClicked}
                handleOnSubmit={handleOnSubmit}
                handleOnGeneralFormDataChange={handleOnGeneralFormDataChange}
                handleOnRolesFormDataChange={handleOnRolesFormDataChange}
                handleGeneralFormError={handleGeneralFormError}
                handleRoleFormError={handleRoleFormError}
                goBack={goBack}
            />
        </TabIndexContext.Provider>
    );
};

UserPanel.propTypes = {
    callSnack: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired,
    userId: PropTypes.string,
    userSchemaNameType: PropTypes.oneOf(Object.values(UserSchemaNameTypes))
};

UserPanel.defaultProps = {
    userId: null,
    userSchemaNameType: UserSchemaNameTypes.CREATE
};

const mapStateToProps = (state) => {
    const {
        i18n: {stringResources}
    } = state.settings;

    return {
        stringResources
    };
};

const mapDispatchToProps = (dispatch) => ({
    onError: (error, type) => dispatch({type, error}),
    callSnack: (snack) => dispatch(createSnack(snack))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
