import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18nUtils from 'lib/i18nUtils';
import * as processActions from 'store/actions/processActions';
import * as snackActions from 'store/actions/snackActions';
import WebString from 'components/webString/WebString';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import TpPopupButton from 'components/common/buttons/TpPopupButton';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserFeatureTypes from 'types/UserFeatureTypes';
import StatusTypes from 'types/StatusTypes';
import AccountFeatureTypes from 'types/AccountFeatureTypes';

export const SetInactiveButton = (props) => {
    const getButton = () => {
        const confirmPopupConfig = {
            headerInfo: {
                webStringKey: 'cs.text.alert.warning',
                type: 'warning',
                showCloseButton: true
            },
            buttons: [
                {
                    id: `btn-decline-set-inactive`,
                    webStringKey: 'cs.text.answer.no'
                },
                {
                    id: `btn-approve-set-inactive`,
                    webStringKey: 'cs.text.answer.yes',
                    action: () =>
                        props.setProposalInactive(
                            props.proposal.id,
                            props.stringResources
                        )
                }
            ]
        };

        return (
            <TpPopupButton
                asLink={true}
                isDefaultClass={false}
                id={`set-inactive-button`}
                iconClassName="tp-meta-info-bar__set-inactive-button-icon"
                className={props.className}
                popupConf={confirmPopupConfig}
                webStringClassName="tp-meta-info-bar__set-inactive-button-webstring"
                webStringKey="tp.setinactive.button">
                <WebString webStringKey="tp.setinactive.confirm.description" />
            </TpPopupButton>
        );
    };

    /**
     * Returns a button for setting a proposal as inactive if the proposal meets the criteria.
     * @returns {*}
     */
    const getInactiveElement = () => {
        const activeProposalStates = [
            StatusTypes.Proposal.APPROVED,
            StatusTypes.Proposal.APPROVED_RESTRICTIONS,
            StatusTypes.Proposal.AUTO_APPROVED
        ];

        return activeProposalStates.includes(props.proposal.status) ? (
            <UserFeatureToggle
                requiredFeatureOneOf={[
                    UserFeatureTypes.PROPOSAL,
                    UserFeatureTypes.ASSESSMENT
                ]}>
                {getButton()}
            </UserFeatureToggle>
        ) : (
            <></>
        );
    };

    return (
        <AccountFeatureToggle
            requiredAccountFeature={AccountFeatureTypes.SET_INACTIVE}>
            {getInactiveElement()}
        </AccountFeatureToggle>
    );
};

SetInactiveButton.propTypes = {
    setProposalInactive: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired,
    proposal: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.oneOf(Object.values(StatusTypes.Proposal)).isRequired
    }).isRequired,
    className: PropTypes.string
};

SetInactiveButton.defaultProps = {
    className: ''
};

const getSuccessSnackProperties = (proposalId, stringResources) => {
    return {
        id: `snack-success-set-inactive-proposal-${proposalId}`,
        snackType: TpSnackbarTypes.SUCCESS,
        autoHideDuration: 4000,
        message: `${i18nUtils.getTranslatedWebStringKey(
            stringResources,
            'tp.setinactive.notification.message.success'
        )}`
    };
};

const mapDispatchToProps = (dispatch) => ({
    setProposalInactive: (proposalId, stringResources) =>
        dispatch(
            processActions.setProposalInactive(proposalId, {
                onSuccess() {
                    dispatch(
                        snackActions.createSnack(
                            getSuccessSnackProperties(
                                proposalId,
                                stringResources
                            )
                        )
                    );
                }
            })
        )
});

const mapStateToProps = ({
    settings: {
        i18n: {stringResources}
    },
    process: {proposal}
}) => ({
    stringResources,
    proposal
});

export default connect(mapStateToProps, mapDispatchToProps)(SetInactiveButton);
