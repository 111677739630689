import React, {useContext} from 'react';

const defaultValue = {
    tabIndexState: {
        selectedTabIndex: 0,
        tabIndexesHasError: []
    },
    setTabIndexState: null
};
const TabIndexContext = React.createContext(defaultValue);

export const useTabIndexContext = () => useContext(TabIndexContext);

export default TabIndexContext;
