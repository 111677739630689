import React from 'react';
import PropTypes from 'prop-types';
import {Tab} from 'react-tabs';
import WebString from 'components/webString/WebString';
import {getWebStringForRole} from '../../rolesUtils';
import UserRoleTypes from 'types/UserRoleTypes';

const RoleFormCustomTab = (props) => {
    const {roleType, isRoleActive, hasError, selected} = props;

    const errorSuffix = () => (hasError ? '-error' : '');

    return (
        <Tab
            selected={selected}
            selectedClassName={`react-tabs__tab--selected tp-user-panel__tab--selected${errorSuffix()}`}>
            <div className={`tp-user-panel__tab${errorSuffix()}`}>
                {isRoleActive ? <i /> : null}
                <WebString webStringKey={getWebStringForRole(roleType)} />
            </div>
        </Tab>
    );
};

RoleFormCustomTab.propTypes = {
    roleType: PropTypes.oneOf(Object.values(UserRoleTypes)).isRequired,
    isRoleActive: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired
};

RoleFormCustomTab.tabsRole = 'Tab';

export default RoleFormCustomTab;
