import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {useDispatch, useSelector} from 'react-redux';
import i18nUtils from 'lib/i18nUtils';
import {loadGlobalStrings} from 'store/actions/settingsActions';
import AccessUnavailablePanel, {
    AccessUnavailablePanelTypes
} from 'components/landingPage/AccessUnavailablePanel';
import CircularLoader from 'components/loader/CircularLoader';

const GlobalStringsLoader = ({children}) => {
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(true);
    const [isAppInitialised, setIsAppInitialised] = useState(false);

    const {mainLocale, locales} = useSelector(
        (state) => state.sessionContext.account
    );

    const loadStrings = () => {
        const storedLocale = i18nUtils.getStoredLocale();
        const locale = locales.includes(storedLocale)
            ? storedLocale
            : mainLocale;

        dispatch(
            loadGlobalStrings(locale, {
                onFailure: () => {
                    setShowLoader(false);
                },
                onSuccess: (response) => {
                    setIsAppInitialised(!isEmpty(response.stringMap));
                    setShowLoader(false);
                }
            })
        );
    };

    useEffect(() => {
        loadStrings();
    }, []);

    if (showLoader) {
        return <CircularLoader overlay={true} />;
    }

    if (!isAppInitialised) {
        return (
            <div className={'bk-cs-content container'}>
                <AccessUnavailablePanel
                    typeOfError={
                        AccessUnavailablePanelTypes.APP_NOT_INITIALIZED
                    }
                />
            </div>
        );
    }

    return children;
};

GlobalStringsLoader.propTypes = {
    children: PropTypes.any.isRequired
};
export default GlobalStringsLoader;
