import NotificationMessage from 'domain/NotificationMessage';
import StringPlaceholder from 'lib/StringPlaceholder';
/**
 * for the form fields with pattern like email, url, ... (required or optional) we must use the pattern that allows an empty string.
 * if it is a required field it will be covered in the json schema by adding the prop  "minLength": 1,
 * so it helps us to avoid two error notifications for one case.
 */
const emptyDunsNumberRegex = '^([0-9]{9}|)$';
const emptyEmailRegex =
    '^([^\\s@]{1,64}@([^_+*~:;,#\'?!\\"§$%&/()=?`´²³{\\[\\]}\\s]+\\.)+([a-zA-z]+)$|)$';
const emptyPhoneNumberRegex = '^([+-]?\\d+|)$';
const emptyUrlRegex =
    '^((https?:\\/\\/)?(www\\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\\.)+[\\w]{2,}(\\/\\S*)?|)$';
const phoneNumberRegex = '^[+-]?\\d+$'; // please use emptyPhoneNumberRegex, can be removed in the future

const trueStringRegex = '^true$';
const onlyNumericRegex = '^\\d*$';
const onlyAlphabetRegex = '^[a-zA-Z]*$';
const noNumbersRegex = '^(\\D|)+$';

// account specific
const c4cNumberRegex = '^\\d{9}$'; // 1080 GEA
const hcpCodeRegex = '^([0-9]{5,11}|)$'; // 1109 Danone

const validationRequiredKey = 'tp.errormsg.validation.required';

const errorPattern = {
    type: {
        number: 'tp.errormsg.validation.typenumber',
        boolean: 'tp.errormsg.validation.nothingselected',
        integer: 'tp.errormsg.validation.typeinteger'
    },
    pattern: {
        dunsNumber: 'tp.errormsg.validation.duns',
        email: 'tp.errormsg.validation.email',
        url: 'tp.errormsg.validation.url',
        mustBeConfirmed: 'tp.errormsg.validation.mustbeconfirmed',
        onlyNumeric: 'tp.errormsg.validation.typenumber',
        phoneNumber: 'tp.errormsg.validation.phonenumber',
        onlyAlphabet: 'tp.errormsg.validation.onlyalphabets',
        noNumbers: 'tp.errormsg.validation.nonumbers',
        // account specific
        c4cNumber: 'tp.errormsg.validation.c4cnumber', // 1080 GEA
        hcpCode: 'tp.errormsg.validation.hcpcode' // 1109 Danone
    },
    minLength: {
        tooFewCharacters: 'tp.errormsg.validation.toofewcharacters',
        required: validationRequiredKey
    },
    maxLength: {
        tooManyCharacters: 'tp.errormsg.validation.toomanycharacters'
    },
    minItems: {
        tooFewItems: 'tp.errormsg.validation.toofewitems',
        tooFewItemsPlural: 'tp.errormsg.validation.toofewitemsplural'
    },
    enum: {
        notSelected: 'tp.errormsg.validation.nothingselected'
    },
    uniqueItems: 'tp.errormsg.validation.uniqueitems',
    minimum: validationRequiredKey,
    wrongEmailFormat: 'tp.errormsg.validation.email',
    multipleOf: 'tp.errormsg.validation.multipleof',

    // back-end validation errors
    noData: 'tp.errormsg.validation.nodata',
    required: validationRequiredKey,
    invalidInput: 'tp.errormsg.validation.invalidinput',
    bpIsEmployee:
        'tp.errormsg.validation.bpisemployee' /* if business partner is employee of the company */,
    order:
        'tp.errormsg.validation.ordernotdescending' /* if order for the array items is not descending */
};

const handlePattern = (pattern, jsonSchemaErrorParams) => {
    switch (jsonSchemaErrorParams.pattern) {
        case emptyDunsNumberRegex:
            return pattern.dunsNumber;
        case c4cNumberRegex:
            return pattern.c4cNumber;
        case emptyEmailRegex:
            return pattern.email;
        case emptyUrlRegex:
            return pattern.url;
        case trueStringRegex:
            return pattern.mustBeConfirmed;
        case onlyNumericRegex:
            return pattern.onlyNumeric;
        case emptyPhoneNumberRegex:
        case phoneNumberRegex:
            return pattern.phoneNumber;
        case onlyAlphabetRegex:
            return pattern.onlyAlphabet;
        case hcpCodeRegex:
            return pattern.hcpCode;
        case noNumbersRegex:
            return pattern.noNumbers;
        default:
            return null;
    }
};

const handleType = (type, jsonSchemaErrorParams) =>
    type[jsonSchemaErrorParams.type] || null;

const handleMinLength = (minLength, jsonSchemaErrorParams) =>
    jsonSchemaErrorParams.limit === 1
        ? minLength.required
        : minLength.tooFewCharacters;

const handleMaxLength = (maxLength) => maxLength.tooManyCharacters;

const handleMinItems = (minItems, {limit}) =>
    limit > 1 ? minItems.tooFewItemsPlural : minItems.tooFewItems;

const handleEnum = (enumMsgs) => enumMsgs.notSelected;

const simpleErrorHandler = (error) => error;

const errorNameHandler = {
    type: handleType,
    pattern: handlePattern,
    minLength: handleMinLength,
    maxLength: handleMaxLength,
    minItems: handleMinItems,
    minimum: simpleErrorHandler,
    enum: handleEnum,
    noData: simpleErrorHandler,
    required: simpleErrorHandler,
    invalidInput: simpleErrorHandler,
    bpIsEmployee: simpleErrorHandler,
    wrongEmailFormat: simpleErrorHandler,
    multipleOf: simpleErrorHandler,
    order: simpleErrorHandler,
    uniqueItems: simpleErrorHandler
};

/**
 * Find webStringKey from pattern. If not found returns default message.
 *
 * @param {Object} jSchemaFormError
 * @returns {String} webStringKey or message
 */
const getWebStringKeyFromPattern = (jSchemaFormError) => {
    const {name, params, message} = jSchemaFormError;
    try {
        // Call the errorNameHandler for error.name and pass the arguments as well as the errorPattern
        return errorNameHandler[name](errorPattern[name], params) || message;
    } catch (err) {
        return message;
    }
};

/**
 * Transform back-end validation error into messages
 *
 * @param {Object} error - from back-end original object
 * @returns {Object} object - consist of name, params and title
 */
const normalizeBackendError = (error) => {
    let argument = '';
    let title = '';

    if (error.meta && error.meta.violatedSchema) {
        const schema = JSON.parse(error.meta.violatedSchema);
        argument = schema[error.name];
        title = schema.title;
    }

    return {
        name: error.name, // example: 'minLength'
        params: {
            limit: argument // example: '2'
        },
        title // example: 'first name'
    };
};

/**
 * react-jsonschema-form uses one form of IDs for form content, another for errors.
 * translate from one to the other, to allow scrolling from error notification panel to error.
 *
 * replace the value of array '[1]' by '_1'
 * replace 'instance' by 'root'
 * replace '.' by '_'
 * add  '_field' at the end
 *
 *
 * @param {string} errorProperty field from JRSF
 * @returns {string} modified errorProperty
 */
const buildFEErrorFormId = (errorProperty) =>
    `root${errorProperty
        .replace(/\[/g, '_')
        .replace(/\]/g, '')
        .replace('instance', '')
        .replace(/\./g, '_')}_field`;

const createNotificationMessageFromError = (error) => {
    const message = getWebStringKeyFromPattern(error);
    const messageArgument = error.params.limit;
    const placeholder = messageArgument
        ? new StringPlaceholder('0', messageArgument)
        : null;
    const title = error.schema ? error.schema.title : '';
    const anchor = error.property ? buildFEErrorFormId(error.property) : null;
    return new NotificationMessage(message, placeholder, title, anchor);
};

/**
 * Iterates over all clientValidationErrors to generate {NotificationMessage} entities.
 *
 * @param {Object[]} clientValidationErrors - jSchemaFormError
 * @returns {NotificationMessage[]}
 */
const transformFrontEndErrors = (clientValidationErrors) => {
    return clientValidationErrors.map(createNotificationMessageFromError);
};

/**
 * Iterates over all serverValidationErrors to generate {NotificationMessage} entities.
 *
 * @param {Object[]} serverValidationErrors - errorInfo from back-end original objects
 * @returns {NotificationMessage[]}
 */
const transformBackEndErrors = (serverValidationErrors) => {
    return serverValidationErrors.map((error) => {
        error = normalizeBackendError(error);
        const {params, title} = error;
        const messageFromPattern = getWebStringKeyFromPattern(error);
        const messageArgument =
            params && !Array.isArray(params.limit) ? params.limit : null;
        const placeholder = messageArgument
            ? new StringPlaceholder('0', messageArgument)
            : null;

        return new NotificationMessage(messageFromPattern, placeholder, title);
    });
};

/**
 * Returns the given web string in stringResources.
 * If the web string is not declared, the web string key
 * will be returned.
 * @param {Object} stringResources
 * @param {String} webStringKey
 * @returns {String}  translated string
 */
const getTranslatedWebStringKey = (stringResources, webStringKey) => {
    const webString = stringResources[webStringKey];
    if (typeof webString === 'string') {
        return webString;
    }
    return webStringKey;
};

/**
 * Translate Umlauts to non-umlauted vowel, e.g. ö -> o
 * Works on lower case only, as this translation has already been done.
 *
 * @param {String} value
 * @returns {String} translated value
 */
const normaliseAccentedCharacters = (value) =>
    value
        .replace(/ä/g, 'a')
        .replace(/å/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/ô/g, 'o')
        .replace(/ü/g, 'u');

/**
 * Put locale into session storage.
 * @param {string} locale - the locale to store
 */
const storeLocale = (locale) => {
    sessionStorage.setItem('locale', locale);
};

/**
 * Retrieve locale from session storage.
 * @returns {string | null} - the stored locale.
 */
const getStoredLocale = () => sessionStorage.getItem('locale');

export default {
    emptyDunsNumberRegex,
    c4cNumberRegex,
    emptyEmailRegex,
    emptyUrlRegex,
    trueStringRegex,
    onlyNumericRegex,
    onlyAlphabetRegex,
    phoneNumberRegex,
    hcpCodeRegex,
    noNumbersRegex,
    emptyPhoneNumberRegex,
    getWebStringKeyFromPattern,
    normalizeBackendError,
    createNotificationMessageFromError,
    transformFrontEndErrors,
    transformBackEndErrors,
    buildFEErrorFormId,
    getTranslatedWebStringKey,
    normaliseAccentedCharacters,
    storeLocale,
    getStoredLocale
};
