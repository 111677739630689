import {SystemSettings} from 'lib/SystemSettings';
import HTTPClient from './lib/HTTPClient';

export default async () => {
    const AppContextPath = process.env.APP_CONTEXT_PATH;

    const settings = await SystemSettings.fetch(AppContextPath);

    HTTPClient.setApiBasePath(settings.ApiBasePath);
    HTTPClient.setApiVersionConfig(settings.ApiVersionConfig);
    HTTPClient.mergeIntoDefaultHeaders(settings.customHeaders);

    return {
        settings: {
            ...settings,
            AppContextPath
        }
    };
};
