import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import MessagesBlock from './MessagesBlock';
import ChatInputBlock from './ChatInputBlock';
import HTTPClient from 'lib/HTTPClient';
import CircularLoader from 'components/loader/CircularLoader';
import ViewTypes from 'types/ViewTypes';
import {proposalClosedTypes} from 'types/StatusTypes';

const ChatField = (props) => {
    const dispatch = useDispatch();

    const [messages, setMessages] = useState(null);
    const [hasUpdateRight, setHasUpdateRight] = useState(false);

    let fetchInterval = null;

    const {proposalId, currentUserEmail, view, status} = useSelector(
        ({process, oidc}) => ({
            proposalId: process.proposal.id,
            currentUserEmail: oidc.user.email,
            view: process.view,
            status: process.proposal.status
        })
    );

    const isProposalClosed = proposalClosedTypes.includes(status);

    useEffect(() => {
        fetchAllMessages();
        if (!isProposalClosed) {
            fetchInterval = setInterval(
                fetchAllMessages,
                props.refetchIntervalTime
            );
        }
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, []);

    const fetchAllMessages = async () => {
        try {
            const {proposalChatMessages, hasUpdateRight} = await HTTPClient.get(
                `proposals/${proposalId}/chat-messages`
            );
            setMessages(proposalChatMessages);
            setHasUpdateRight(hasUpdateRight);
        } catch (error) {
            dispatch({type: 'LOAD_ALL_MESSAGES_FAILED', error});
        }
    };

    if (!messages) {
        return <CircularLoader />;
    }

    const hideChatInputBlock = () =>
        isProposalClosed ||
        (view === ViewTypes.APPLICANT && !messages.length) || // the applicant cannot initiate the conversation
        !hasUpdateRight;

    return (
        <div className="bk-tp-chatfield">
            <MessagesBlock
                currentUserEmail={currentUserEmail}
                messages={messages}
            />
            {hideChatInputBlock() ? null : (
                <ChatInputBlock
                    proposalId={proposalId}
                    onSend={fetchAllMessages}
                />
            )}
        </div>
    );
};

ChatField.propTypes = {
    refetchIntervalTime: PropTypes.number
};

ChatField.defaultProps = {
    refetchIntervalTime: 120 * 1000
};

export default ChatField;
