import React from 'react';
import {addMainError} from 'store/actions/errorActions';
import {showTaskErrors} from 'store/actions/processActions';
import ErrorCode from 'domain/ErrorCode';
import i18nUtils from 'lib/i18nUtils';
import ErrorTypes from 'types/ErrorTypes';

const hideErrorForNotRelevantLocation = (action) =>
    action.meta &&
    action.meta.locationForErrorDisplay &&
    action.meta.locationForErrorDisplay !== window.location.pathname;

/**
 * Recognizes an action with a type containing 'FAILED'.
 *
 * Depending on the returned values from ErrorCode.getHTTPErrorInfo function will do the following:
 * - if validationErrors is present calls action showTaskErrors in order to show the errors in the NotificationPanel
 * - otherwise calls addMainError action in order to show the error in the mainErrorBar
 *
 * @returns {Function}
 */
export default () => (next) => (action) => {
    const {error} = action;

    if (!!error && (!!error.response || !!error.request || action.payload)) {
        const dataForGettingErrorInfo = action.type.includes('FAILED')
            ? error
            : action.payload;
        const errorInfo = ErrorCode.getHTTPErrorInfo(dataForGettingErrorInfo);

        if (errorInfo.errorType === ErrorTypes.VALIDATION) {
            next(
                showTaskErrors(
                    i18nUtils.transformBackEndErrors(errorInfo.errors)
                )
            );
        } else {
            if (!hideErrorForNotRelevantLocation(action)) {
                next(
                    addMainError(
                        errorInfo.titleMessage,
                        errorInfo.webStringKey,
                        errorInfo.placeholders,
                        errorInfo.defaultMessage
                    )
                );
            }
        }
        next(action);
    } else {
        next(action);
    }
};
