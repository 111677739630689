// import and registration locale for react-datepicker
import {registerLocale} from 'react-datepicker';

import {
    enGB,
    ptBR,
    es,
    de,
    fr,
    pl,
    it,
    nl,
    hr,
    sr,
    id,
    lt,
    ja,
    ru,
    th,
    sl,
    zhCN
} from 'date-fns/locale';

// locales should be registered under the same as the values in the redux state for locale
registerLocale('en-GB', enGB);
registerLocale('es', es);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('pl', pl);
registerLocale('pt-BR', ptBR);
registerLocale('it', it);
registerLocale('nl', nl);
registerLocale('hr', hr);
registerLocale('sr', sr);
registerLocale('id', id);
registerLocale('lt', lt);
registerLocale('ja', ja);
registerLocale('ru', ru);
registerLocale('sl', sl);
registerLocale('th', th);
registerLocale('zh', zhCN);
