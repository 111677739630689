import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {autosaveCurrentTask} from 'store/actions/processActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import defaultAppValues from 'lib/defaultAppValues';
import AutoSave from '../common/AutoSave';
import ViewType from 'types/ViewTypes';

/**
 * AutoSave for proposal tasks
 */
export class ProposalAutoSave extends React.Component {
    static propTypes = {
        onAutosave: PropTypes.func.isRequired,
        currentTask: PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            properties: PropTypes.shape({
                actions: PropTypes.array
            }).isRequired,
            lastModificationTimestamp: PropTypes.number.isRequired
        }),
        view: PropTypes.oneOf(Object.values(ViewType)),
        proposalId: PropTypes.number,
        isSavingLocked: PropTypes.bool,
        autosaveInterval: PropTypes.number,
        locale: PropTypes.string
    };

    static defaultProps = {
        currentTask: null,
        proposalId: null,
        isSavingLocked: false,
        autosaveInterval: defaultAppValues.defaultAutoSaveInterval,
        locale: defaultAppValues.defaultLocale
    };

    _handleOnAutosave = () => {
        this.props.onAutosave(
            this.props.proposalId,
            this.props.currentTask,
            this.props.view
        );
    };

    _isTimerVisible = () => {
        const {currentTask} = this.props;

        if (
            !currentTask ||
            !currentTask.properties ||
            !currentTask.properties.actions
        ) {
            return false;
        }
        const {actions} = currentTask.properties;

        return actions.includes('update');
    };

    render() {
        const {
            currentTask,
            isSavingLocked,
            locale,
            autosaveInterval
        } = this.props;

        let contentToSave = null;
        let lastSavedDate = null;
        if (currentTask) {
            contentToSave = currentTask.properties
                ? currentTask.properties.formData
                : null;
            lastSavedDate = currentTask.lastModificationTimestamp;
        }

        return (
            <AutoSave
                onAutosave={this._handleOnAutosave}
                isVisible={this._isTimerVisible}
                isSavingLocked={isSavingLocked}
                locale={locale}
                autosaveInterval={autosaveInterval}
                contentToSave={contentToSave}
                lastSavedDate={lastSavedDate}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const {
        proposal = {},
        currentTask,
        savingCurrentTask,
        loadingTask,
        view
    } = state.process;
    return {
        currentTask,
        view,
        proposalId: proposal.id,
        locale: state.settings.i18n.locale,
        isSavingLocked: savingCurrentTask || loadingTask
    };
};

const mapDispatchToProps = (dispatch) => ({
    onAutosave: wrapInDispatch(autosaveCurrentTask, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProposalAutoSave);
