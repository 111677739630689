import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18nUtils from 'lib/i18nUtils';
import * as snackActions from 'store/actions/snackActions';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import TpPopupButton from 'components/common/buttons/TpPopupButton';
import {recheckProposal} from 'store/actions/processActions';
import StatusTypes from 'types/StatusTypes';
import WebString from 'components/webString/WebString';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import AccountFeatureTypes from 'types/AccountFeatureTypes';

/**
 *  Recheck Now Component to be used in meta bar.
 *  On click, a popup window will be shown with warning message and 'yes' and 'no' buttons
 *  to confirm or cancel the action.
 *  To Be shown only if the proposal is approved or approved with restrictions.
 *
 */
const recheckProposalPopupConfig = (props) => {
    return {
        headerInfo: {
            webStringKey: 'cs.text.alert.warning',
            type: 'warning',
            showCloseButton: false
        },
        buttons: [
            {
                webStringKey: 'cs.text.answer.no',
                id: 'btn-recheck-proposal-no'
            },
            {
                webStringKey: 'cs.text.answer.yes',
                id: 'btn-recheck-proposal-yes',
                action: () =>
                    props.recheckProposal(
                        props.proposal.id,
                        props.stringResources
                    )
            }
        ]
    };
};
export const RecheckProposalButton = (props) => {
    /**
     * Creates a popup config object
     * @return {object} - configuration object for TpPopUp component in TpPopupButton
     * @private
     */

    const getButton = () => {
        const {
            proposal: {id, status}
        } = props;

        if (
            !(
                id &&
                status &&
                (status === StatusTypes.Proposal.APPROVED ||
                    status === StatusTypes.Proposal.APPROVED_RESTRICTIONS)
            )
        ) {
            return <></>;
        }
        return (
            <TpPopupButton
                asLink={true}
                className={props.className}
                iconClassName="tp-meta-info-bar__recheck-proposal-button-icon"
                id="recheck-proposal-btn"
                webStringKey="tp.button.recheckproposal"
                webStringClassName="tp-meta-info-bar__recheck-proposal-button-webstring"
                popupConf={recheckProposalPopupConfig(props)}>
                <WebString webStringKey="tp.text.process.recheck.confirm" />
            </TpPopupButton>
        );
    };

    return (
        <AccountFeatureToggle
            requiredAccountFeature={
                AccountFeatureTypes.PROPOSAL_MANUAL_RECHECK
            }>
            {getButton()}
        </AccountFeatureToggle>
    );
};
RecheckProposalButton.propTypes = {
    proposal: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.oneOf(Object.values(StatusTypes.Proposal))
    }).isRequired,
    recheckProposal: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired
};
const mapStateToProps = (state) => {
    const {
        settings: {
            i18n: {stringResources}
        },
        process: {proposal}
    } = state;
    return {
        proposal,
        stringResources
    };
};

const mapDispatchToProps = (dispatch) => ({
    recheckProposal(proposalId, stringResources) {
        dispatch(
            recheckProposal(proposalId, {
                onSuccess() {
                    dispatch(
                        snackActions.createSnack({
                            id: `snack-success-recheck-proposal-${proposalId}`,
                            snackType: TpSnackbarTypes.SUCCESS,
                            autoHideDuration: 4000,
                            message: `${i18nUtils.getTranslatedWebStringKey(
                                stringResources,
                                'tp.recheck.notification.message.success'
                            )}`
                        })
                    );
                },
                onFailure() {
                    dispatch(
                        snackActions.createSnack({
                            id: `snack-error-recheck-proposal-${proposalId}`,
                            snackType: TpSnackbarTypes.ERROR,
                            autoHideDuration: 4000,
                            message: `${i18nUtils.getTranslatedWebStringKey(
                                stringResources,
                                'tp.recheck.notification.message.error'
                            )} `
                        })
                    );
                }
            })
        );
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecheckProposalButton);
