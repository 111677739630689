import React from 'react';
import PropTypes from 'prop-types';
import RiskAlertsBPName from './RiskAlertsBPName';
import RiskAlertsBPAddress from './RiskAlertsBPAddress';
import RiskAlertsBPDunsNumber from './RiskAlertsBPDunsNumber';

const RiskAlertsBPInfo = ({businessPartner: {name, address, dunsNumber}}) => (
    <div className="bk-cs-margin-bottom--md">
        <RiskAlertsBPName name={name} />
        <RiskAlertsBPAddress address={address} />
        {dunsNumber && dunsNumber.trim() ? (
            <RiskAlertsBPDunsNumber dunsNumber={dunsNumber} />
        ) : null}
    </div>
);

RiskAlertsBPInfo.propTypes = {
    businessPartner: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        dunsNumber: PropTypes.string
    })
};
RiskAlertsBPInfo.defaultProps = {
    businessPartner: {}
};

export default RiskAlertsBPInfo;
