import React from 'react';
import PropTypes from 'prop-types';
import timeUtils from 'lib/timeUtils';
import DisabledTextBlock from './DisabledTextBlock';

/**
 * Converts a string to a formatted date and returns it in a disabled text block.
 */
export default class TpDisplayStringAsDate extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    };

    render() {
        const valueAsNumber = Number(this.props.value);
        const disabledValue = valueAsNumber
            ? timeUtils.getFormattedUnixTimestring(valueAsNumber, 'L')
            : '';
        return <DisabledTextBlock id={this.props.id} value={disabledValue} />;
    }
}
