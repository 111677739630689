import React from 'react';

const tableColFallback = (undefinedColumnName) => ({
    Header: <div>undefined col: {undefinedColumnName}</div>
});

const isArrayNullOrEmpty = (arr) =>
    !arr || !Array.isArray(arr) || arr.length === 0;

export {tableColFallback, isArrayNullOrEmpty};
