import {ACTION_TYPES as ALL_BUSINESSPARTNERS_ACTION_TYPES} from 'store/reducers/businessPartnerSectionReducer';
import HTTPClient from 'lib/HTTPClient';

const getAllBusinessPartners = (searchParams) => ({
    type: ALL_BUSINESSPARTNERS_ACTION_TYPES.LOAD_ALL_BUSINESSPARTNERS,
    promise: HTTPClient.get('business-partners', {
        ...searchParams
    })
});

const getBusinessPartnerById = (businessPartnerId) => ({
    type: ALL_BUSINESSPARTNERS_ACTION_TYPES.LOAD_BUSINESSPARTNER,
    promise: HTTPClient.get(`business-partners/${businessPartnerId}`)
});

const recheckBusinessPartner = (businessPartners, meta) => ({
    type: ALL_BUSINESSPARTNERS_ACTION_TYPES.RECHECK_BUSINESSPARTNER,
    promise: HTTPClient.post(`business-partners/recheck`, businessPartners),
    meta
});

const updateCoreData = (businessPartnerId, editedData) => ({
    type: ALL_BUSINESSPARTNERS_ACTION_TYPES.UPDATE_CORE_DATA,
    promise: HTTPClient.patch(
        `business-partners/${businessPartnerId}`,
        editedData
    )
});

export {
    getAllBusinessPartners,
    getBusinessPartnerById,
    recheckBusinessPartner,
    updateCoreData
};
