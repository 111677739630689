import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import TpTable from '../tables/TpTable';
import * as tpProposalActions from 'store/actions/processActions';
import {getProposalsTableColumns} from './utilities/tableColumns';
import TableTypes from 'types/TableTypes';
import UserRoleTypes from 'types/UserRoleTypes';
import WebString from 'components/webString/WebString';
import urls from 'routes/urls';
import getTableDataFromStateAndDispatchHelper from 'components/dashboard/utilities/tablesStateWithDispatchHelper';

/**
 * It returns the route to navigate
 * If it is an imported proposal, it returns the route for proposal details page
 * If the proposal has a manager view, it returns the route for assessment page
 */
const getRouteToNavigate = (row) => {
    return row.imported
        ? `${urls.PROPOSAL_URL}/${row.id}/details`
        : `${row.hasManagerView ? urls.ASSESSMENT_URL : urls.PROPOSAL_URL}/${
              row.id
          }`;
};

/**
 * Dashboard table for 'manager level 1' user feature.
 * Provides table configuration and API call action to TpTable component.
 */
const ManagerAssessedProposalsTable = ({userRole}) => {
    const history = useHistory();

    const dataFromState = getTableDataFromStateAndDispatchHelper(
        userRole,
        TableTypes.ASSESSED,
        tpProposalActions.getAllAssessedManagerProposals
    );

    const tableColumnsConfigured = getProposalsTableColumns(
        dataFromState.columns,
        dataFromState.filterOptions
    );

    const tableColumns = tableColumnsConfigured.map((column) => {
        let columnToBeModified = Object.assign({}, column);
        if (columnToBeModified.id === 'bpName') {
            columnToBeModified.Header = (
                <WebString webStringKey="cs.text.bpname" />
            );
        }
        return columnToBeModified;
    });

    const onTableRowClick = (row) => {
        history.push(`${getRouteToNavigate(row)}`);
    };

    const mappedStateAndProps = {
        data: dataFromState.data,
        isFetchingData: dataFromState.isFetchingData,
        searchParams: dataFromState.searchParams,
        totalPages: dataFromState.totalPages,
        locale: dataFromState.locale,
        defaultPageSize: dataFromState.defaultPageSize,
        fetchData: dataFromState.fetchData,
        tableColumns,
        onTableRowClick,
        titleWebstringKey: `${
            userRole === UserRoleTypes.AUDITOR
                ? 'tp.table.auditproposals.title'
                : 'tp.table.assessedproposals.title'
        }`,
        descriptionWebstringKey: `${
            userRole === UserRoleTypes.AUDITOR
                ? 'tp.table.auditproposals.text'
                : 'tp.table.assessedproposals.text'
        }`,
        defaultSorted: [
            {
                id: 'proposalId',
                desc: true
            }
        ]
    };

    return <TpTable {...mappedStateAndProps} />;
};

ManagerAssessedProposalsTable.propTypes = {
    userRole: PropTypes.oneOf(Object.values(UserRoleTypes)).isRequired
};

export default ManagerAssessedProposalsTable;
