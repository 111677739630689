import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import urls from 'routes/urls';

/**
 * Section in the header containing logged in user name, and a log out link.
 */
const AccountBox = () => {
    const user = useSelector((state) => state.oidc.user);

    if (!user) {
        return null;
    }

    return (
        <div className="bk-cs-header__accountbox">
            <span className="bk-cs-header__username">{user.login}</span>
            <Link className="bk-cs-header__logout" to={urls.LOGOUT_URL}>
                logout
            </Link>
        </div>
    );
};

export default AccountBox;
