import React from 'react';
import PropTypes from 'prop-types';
import TpLink from 'components/common/TpLink';
import WebString from 'components/webString/WebString';

/**
 * 404 error page is used in routing
 */

const NotFound = ({to, linkTextWebStringKey}) => (
    <div className={'bk-cs-notfound-page'}>
        <div className={'bk-cs-notfound-page__text'}>
            <WebString webStringKey="cs.text.notfound" />
        </div>
        <TpLink
            className={'bk-cs-button-secondary'}
            id={'toFromNotFound'}
            to={to}
            webStringKey={linkTextWebStringKey}
        />
    </div>
);

NotFound.propTypes = {
    to: PropTypes.string,
    linkTextWebStringKey: PropTypes.string
};

NotFound.defaultProps = {
    to: '/',
    linkTextWebStringKey: 'tp.link.tpdashboard'
};

export default NotFound;
