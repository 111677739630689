import React from 'react';
import AccountBox from './AccountBox';
import HeaderLogo from './HeaderLogo';
import HeaderNaviList from './HeaderNaviList';

/**
 * Region at the very top of the page with account name, 'Third Party' link,
 * and other BK products in future, on the left,
 * and the AccountBox content on the right.
 */
const Header = () => (
    <header className="bk-cs-header container-fluid">
        <div className="row bk-cs-header__content">
            <div className="col-xs-6">
                <div className="bk-cs-header__logo-placeholder">
                    <HeaderLogo />
                </div>
                <HeaderNaviList />
            </div>
            <div className="col-xs-6">
                <AccountBox />
            </div>
        </div>
    </header>
);

export default Header;
