import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import TpImage from '../common/TpImage';

const noActiveUser = () => (
    <>
        You are not authorized to access this page because there is no active
        user account matching your email address.
        <br />
        Please contact the responsible department of your organisation.
    </>
);

const appIsNotInitialised = () => (
    <>
        Application is unavailable for the moment.
        <br />
        Please contact the responsible department of your organisation.
    </>
);

export const AccessUnavailablePanelTypes = {
    NO_ACTIVE_USER: noActiveUser,
    APP_NOT_INITIALIZED: appIsNotInitialised
};
export const AccessUnavailablePanel = (props) => {
    const dashboardURL = useSelector((state) => state.settings.DashboardURL);

    return (
        <div className="app-unavailable-panel">
            <h1 className="app-unavailable-panel__title">
                Application unavailable
            </h1>
            {props.typeOfError()}
            <div className="app-unavailable-panel__link-spacer">
                {dashboardURL ? (
                    <a
                        href={dashboardURL}
                        className="app-unavailable-panel__link">
                        back to dashboard
                    </a>
                ) : null}
            </div>
            <TpImage
                src="icons/error-disconnected.svg"
                alt="unplugged_image"
                className="app-unavailable-panel__image"
            />
        </div>
    );
};

AccessUnavailablePanel.propTypes = {
    typeOfError: PropTypes.oneOf([
        AccessUnavailablePanelTypes.NO_ACTIVE_USER,
        AccessUnavailablePanelTypes.APP_NOT_INITIALIZED
    ]).isRequired
};

export default AccessUnavailablePanel;
