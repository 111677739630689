import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Scroll from 'react-scroll';
import WebString from 'components/webString/WebString';
import NotificationMessage from 'domain/NotificationMessage';
import {openParentContainer} from 'store/actions/formActions';

/**
 * This component displays an error title and error message. It also provides
 * the functionality to smoothly scroll to a anchor element in the page.
 * The "anchor" is the html id of the element to scroll to.
 * The title field is limited to 80 characters, to stop very long names spanning multiple lines.
 */
export class NotificationPanelError extends React.Component {
    static propTypes = {
        message: PropTypes.instanceOf(NotificationMessage).isRequired,
        openParentContainer: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.titleCharacterLimit = 80;
    }

    /**
     * Scrolls the window smoothly to the element with html id "anchor".
     * Calls _openErrorSection function to unfold the parent section if it is folded in order to make error element inside visible.
     *
     * @param {object} event The html onClick event.
     * @param {string} anchor The html id of the element to scroll to.
     * @private
     * @returns {undefined}
     */
    _scrollToError(event, anchor) {
        event.preventDefault();
        this.props.openParentContainer(anchor);

        const element = document.getElementById(anchor);
        if (element) {
            const rectElem = element.getBoundingClientRect();
            const posElem = rectElem.top;

            Scroll.animateScroll.scrollMore(posElem - 120, {
                duration: 1500,
                delay: 100,
                smooth: true
            });
        }
    }

    _handleLinkOnClick = (event) => {
        this._scrollToError(event, this.props.message.anchor);
    };

    /**
     * Renders the error message.
     * @returns {ReactElement} markup -  the <li />
     */
    render() {
        const {message, placeholder, title, anchor} = this.props.message;

        if (!message) {
            return null;
        }

        const messageString = (
            <WebString webStringKey={message} placeholder={placeholder} />
        );

        if (!title) {
            return (
                <li className="bk-cs-error bk-cs-margin-bottom--sm">
                    {messageString}
                </li>
            );
        }

        const titleElement = anchor ? (
            <a
                className="bk-cs-error-link"
                href={'#'}
                onClick={this._handleLinkOnClick}>
                <WebString
                    webStringKey={title}
                    characterLimit={this.titleCharacterLimit}
                />
            </a>
        ) : (
            <WebString
                webStringKey={title}
                characterLimit={this.titleCharacterLimit}
            />
        );

        return (
            <li className="bk-cs-error bk-cs-margin-bottom--sm">
                {titleElement}&nbsp;{messageString}
            </li>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    openParentContainer(id) {
        dispatch(openParentContainer(id));
    }
});

export default connect(null, mapDispatchToProps)(NotificationPanelError);
