import clonedeep from 'lodash.clonedeep';
import set from 'lodash.set';

const getRiskAlertDefault = (alert) => ({
    peId: alert.peId,
    riskFlags: alert.flags.sort().join(', '),
    name: alert.name,
    countryCode: alert.countryCode,
    matchPct: alert.matchPct,
    status: '',
    critical: null,
    comment: ''
});

/**
 * For the Dow Jones section, copy and risk alert from fieldData
 * into formData, so they can be rendered into the form.
 * Do not overwrite existing
 * @param {object} formData
 * @param {object} uiSchema
 * @param {object} fieldData
 * @returns {object} the formData with risk alert added
 */
const processRiskAlerts = (formData, uiSchema, fieldData) => {
    // don't need to do anything if there are already riskAlerts in the formData
    if (
        formData.riskAlerts &&
        formData.riskAlerts.rows &&
        fieldData.riskAlerts.matches.every((ra) =>
            formData.riskAlerts.rows.find((fdra) => fdra.peId === ra.peId)
        )
    ) {
        return formData;
    }
    let newFormData = formData;

    if (
        JSON.stringify(uiSchema).includes('"ui:field":"riskAlerts"') &&
        fieldData.riskAlerts &&
        Array.isArray(fieldData.riskAlerts.matches)
    ) {
        newFormData = clonedeep(formData);
        const fieldDataRiskAlerts = fieldData.riskAlerts.matches;
        const formDataRiskAlertsRows = newFormData.riskAlerts
            ? newFormData.riskAlerts.rows
            : [];
        const rows = fieldDataRiskAlerts.map((riskAlert) => {
            const formDataForRiskAlert = formDataRiskAlertsRows.find(
                (fieldDataRiskAlert) =>
                    fieldDataRiskAlert.peId === riskAlert.peId
            );
            if (formDataForRiskAlert) {
                return formDataForRiskAlert;
            }
            return getRiskAlertDefault(riskAlert);
        });

        set(newFormData, 'riskAlerts.rows', rows);
    }

    return newFormData;
};

export {processRiskAlerts};
