import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import TpLink from 'components/common/TpLink';
import WebString from '../webString/WebString';
import urls from 'routes/urls';

const dashboardLink = (to) => ({
    id: 'dashboard-link',
    webStringKey: 'tp.link.dashboard',
    external: true,
    to
});

const productLink = {
    id: 'thirdparty-link',
    webStringKey: 'tp.link.productdashboard',
    to: '/'
};

const alertsLink = {
    id: 'alerts-link',
    webStringKey: 'tp.link.alerts',
    to: urls.ALERTS_URL
};

const alertLink = {
    id: 'alert-link',
    webStringKey: 'tp.link.alert'
};

const businessPartnersLink = {
    id: 'businesspartners-link',
    webStringKey: 'tp.link.businesspartners',
    to: urls.BUSINESS_PARTNERS_URL
};

const businessPartnerLink = {
    id: 'businesspartners-link',
    webStringKey: 'tp.link.businesspartnerdetails'
};

const preCheckLink = {
    id: 'preCheck-link',
    webStringKey: 'tp.link.precheck',
    to: urls.PRE_CHECK_URL
};

const adHocCheckLink = {
    id: 'adhoccheck-link',
    webStringKey: 'tp.link.adhoccheck',
    to: urls.AD_HOC_CHECK_URL
};

const reportsLink = {
    id: 'reports-link',
    webStringKey: 'tp.link.reports',
    to: urls.REPORTS_URL
};

const proposalsLink = {
    id: 'proposals-link',
    webStringKey: 'tp.link.proposals',
    to: urls.PROPOSALS_URL
};

const proposalInfoLink = {
    id: 'proposalinfo-link',
    webStringKey: 'tp.link.proposalinfo',
    to: urls.PROPOSAL_INFO_URL
};

const proposalLink = {
    id: 'process-link',
    webStringKey: 'tp.link.proposal'
};

const assessmentLink = {
    id: 'process-link',
    webStringKey: 'tp.link.assessment',
    to: urls.ASSESSMENT_URL
};

const detailsLink = {
    id: 'details-link',
    webStringKey: 'tp.link.details'
};

const externalQuestionnaireLink = {
    id: 'process-link',
    webStringKey: 'tp.link.externalquestionnaire'
};

const userManagementLink = {
    id: 'userManagement-link',
    webStringKey: 'tp.link.usermanagement',
    to: urls.USERS_URL
};

const userDetailsLink = {
    id: 'userDetails-link',
    webStringKey: 'tp.link.userdetails'
};

const createUserLink = {
    id: 'createUser-link',
    webStringKey: 'tp.link.createuser'
};

const addIDtoBreadcrumb = (
    currentRelativeURL,
    targetPath,
    linkData,
    breadcrumbItems
) => {
    if (currentRelativeURL.match(`${targetPath}/[0-9]+$`)) {
        linkData.appendage = ` ID:${currentRelativeURL
            .match(/[0-9]+$/g)
            .pop()}`;
        breadcrumbItems.push(linkData);
    }
};

/**
 * Builds and displays breadcrumb trail based on current URL
 */
const Breadcrumb = () => {
    const buildBreadcrumbEntries = () => {
        const currentRelativeURL = useLocation().pathname;

        const DashboardURL = useSelector(
            (state) => state.settings.DashboardURL
        );

        const breadcrumbItems = [];

        if (DashboardURL) {
            breadcrumbItems.push(dashboardLink(DashboardURL));
        }

        breadcrumbItems.push(productLink);

        if (currentRelativeURL.includes(urls.PRE_CHECK_URL)) {
            breadcrumbItems.push(preCheckLink);
        }

        if (currentRelativeURL.includes(urls.AD_HOC_CHECK_URL)) {
            breadcrumbItems.push(adHocCheckLink);
        }

        if (currentRelativeURL.includes(urls.REPORTS_URL)) {
            breadcrumbItems.push(reportsLink);
        }

        addBusinessPartnerRelatedCrumbs(breadcrumbItems, currentRelativeURL);

        addProposalRelatedCrumbs(breadcrumbItems, currentRelativeURL);

        addAlertRelatedCrumbs(breadcrumbItems, currentRelativeURL);

        addUserRelatedCrumbs(breadcrumbItems, currentRelativeURL);

        return breadcrumbItems;
    };

    const addBusinessPartnerRelatedCrumbs = (
        breadcrumbItems,
        currentRelativeURL
    ) => {
        if (currentRelativeURL.includes(urls.BUSINESS_PARTNERS_URL)) {
            breadcrumbItems.push(businessPartnersLink);
        }

        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.BUSINESS_PARTNERS_URL,
            businessPartnerLink,
            breadcrumbItems
        );
    };

    const addProposalRelatedCrumbs = (breadcrumbItems, currentRelativeURL) => {
        if (currentRelativeURL.includes(urls.PROPOSALS_URL)) {
            breadcrumbItems.push(proposalsLink);
        }

        if (currentRelativeURL.includes(urls.PROPOSAL_INFO_URL)) {
            breadcrumbItems.push(proposalInfoLink);
        }

        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.PROPOSAL_URL,
            proposalLink,
            breadcrumbItems
        );

        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.ASSESSMENT_URL,
            assessmentLink,
            breadcrumbItems
        );

        if (currentRelativeURL.match(/proposal\/[0-9]+\/details$/)) {
            breadcrumbItems.push(detailsLink);
        }

        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.EXTERNAL_QUESTIONNAIRES_URL,
            externalQuestionnaireLink,
            breadcrumbItems
        );
    };

    const addAlertRelatedCrumbs = (breadcrumbItems, currentRelativeURL) => {
        if (currentRelativeURL.includes(urls.ALERTS_URL)) {
            breadcrumbItems.push(alertsLink);
        }
        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.ALERTS_URL,
            alertLink,
            breadcrumbItems
        );
    };

    const addUserRelatedCrumbs = (breadcrumbItems, currentRelativeURL) => {
        if (currentRelativeURL.includes(urls.USERS_URL)) {
            breadcrumbItems.push(userManagementLink);
        }

        if (currentRelativeURL.includes('users/create')) {
            breadcrumbItems.push(createUserLink);
        }
        addIDtoBreadcrumb(
            currentRelativeURL,
            urls.USERS_URL,
            userDetailsLink,
            breadcrumbItems
        );
    };

    const entries = buildBreadcrumbEntries();

    return (
        <nav className="cs-breadcrumb">
            {entries.map((entry, key) => {
                return (
                    <div key={key} className="cs-breadcrumb__entry">
                        {entry.appendage ? (
                            <>
                                <WebString
                                    key="ws"
                                    webStringKey={entry.webStringKey}
                                />
                                <span key="appendage">{entry.appendage}</span>
                            </>
                        ) : (
                            <TpLink
                                {...entry}
                                id={`bkms-tp-breadcrumb-${entry.id}`}
                                className="cs-breadcrumb__link"
                                key={key}
                            />
                        )}
                    </div>
                );
            })}
        </nav>
    );
};

export default Breadcrumb;
