export function addMainError(
    title,
    message,
    placeholders,
    messageDefault = ''
) {
    return {
        type: 'SHOW_ERROR',
        title,
        message,
        messageDefault,
        placeholders
    };
}

export function removeMainError(key) {
    return {
        type: 'HIDE_ERROR',
        key
    };
}
