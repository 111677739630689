import React from 'react';
import PropTypes from 'prop-types';
import * as fieldsUtils from '../fieldsUtils';
import WebString from '../../../webString/WebString';

/**
 * Text only version of TpFieldTemplate.
 * Does not add the header classes, or folding functionality.
 * Will understand HTML tags in the labelElement
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */
const TextOnlyFieldTemplate = (props) => {
    const {
        id,
        classNames,
        label,
        children,
        schema,
        uiSchema,
        formContext: {redFlags}
    } = props;

    const isHeader = fieldsUtils.isType(schema, 'object');
    const headerClass = isHeader ? ' bk-cs-form-header--text' : '';
    const labelClass = isHeader ? '' : ' bk-cs-form-label--text';

    let dividerElement = null;
    let labelElement = null;

    if (
        !fieldsUtils.isHeaderHidden(uiSchema) &&
        !fieldsUtils.isSimpleBooleanField(schema)
    ) {
        if (isHeader) {
            dividerElement = <div className="section-divider" />;
        }

        let redFlagClass = '';

        if (
            redFlags &&
            id.includes('root_') &&
            redFlags.includes(id.split('root_')[1])
        ) {
            redFlagClass = ' bk-cs-form-label__red-flag';
        }

        labelElement = (
            <label
                className={`bk-cs-form-label${labelClass}${headerClass} bk-cs-field-label${redFlagClass}`}>
                <WebString webStringKey={label} />
            </label>
        );
    }
    return (
        <div id={`${id}_field`} className={classNames}>
            {dividerElement}
            {labelElement}
            <div className="bk-cs-form-field">{children}</div>
        </div>
    );
};

TextOnlyFieldTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    formContext: PropTypes.shape({
        redFlags: PropTypes.array
    }),
    classNames: PropTypes.string,
    label: PropTypes.string,
    schema: PropTypes.object,
    uiSchema: PropTypes.object
};

TextOnlyFieldTemplate.defaultProps = {
    classNames: '',
    label: '',
    formContext: {
        redFlags: []
    },
    schema: {},
    uiSchema: {}
};

export default TextOnlyFieldTemplate;
