import {ACTION_TYPES as ALL_REPORTGENERATION_ACTION_TYPES} from '../reducers/reportGenerationReducer';
import HTTPClient from 'lib/HTTPClient';

const generateReport = (reportParams, meta) => ({
    type: ALL_REPORTGENERATION_ACTION_TYPES.GENERATE_REPORT,
    promise: HTTPClient.post(`reports`, reportParams),
    meta
});

const getReports = (meta) => ({
    type: ALL_REPORTGENERATION_ACTION_TYPES.GET_REPORTS,
    promise: HTTPClient.get(`reports`),
    meta
});

export {generateReport, getReports};
