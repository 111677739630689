import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {autosaveAlerts} from 'store/actions/alertActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import defaultAppValues from 'lib/defaultAppValues';
import AutoSave from 'components/common/AutoSave';
import StatusTypes from 'types/StatusTypes';
import {isAlertPage} from 'components/common/checkRelativeURL';

const isAlertClosed = (dailyAlerts) => {
    return (
        dailyAlerts &&
        dailyAlerts.assessmentStatus === StatusTypes.Alerts.Assessment.CLOSED
    );
};

/**
 * AutoSave for alerts
 */
export const AlertAutoSave = (props) => {
    const currentRelativeURL = useLocation().pathname;
    if (!isAlertPage(currentRelativeURL) || props.autosaveDisabled) {
        return null;
    }

    const handleAutosave = () => {
        props.onAutosave(props.dailyAlerts);
    };

    return (
        <AutoSave
            onAutosave={handleAutosave}
            isSavingLocked={props.isSavingLocked}
            autosaveInterval={props.autosaveInterval}
            locale={props.locale}
            contentToSave={props.dailyAlerts}
        />
    );
};

AlertAutoSave.propTypes = {
    onAutosave: PropTypes.func.isRequired,
    isSavingLocked: PropTypes.bool,
    autosaveInterval: PropTypes.number,
    locale: PropTypes.string,
    dailyAlerts: PropTypes.object,
    autosaveDisabled: PropTypes.bool
};

AlertAutoSave.defaultProps = {
    isSavingLocked: false,
    autosaveInterval: defaultAppValues.defaultAutoSaveInterval,
    locale: defaultAppValues.defaultLocale,
    dailyAlerts: {},
    autosaveDisabled: false
};

const mapStateToProps = (state) => {
    const {dailyAlerts, savingAlerts, isLoading} = state.riskAlerts;

    return {
        dailyAlerts,
        locale: state.settings.i18n.locale,
        isSavingLocked: savingAlerts || isLoading,
        autosaveDisabled: isAlertClosed(dailyAlerts)
    };
};

const mapDispatchToProps = (dispatch) => ({
    onAutosave: wrapInDispatch(autosaveAlerts, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertAutoSave);
