import {ACTION_TYPES} from '../reducers/formReducer';

/**
 * Open a collapsible container, if not already open
 * @param id
 * @returns {{type: *, id: *}}
 */
const openContainer = (id) => ({
    type: ACTION_TYPES.OPEN_CONTAINER,
    id
});

/**
 * Open a collapsible container, if not already open
 * @param id
 * @returns {{type: *, id: *}}
 */
const openParentContainer = (id) => ({
    type: ACTION_TYPES.OPEN_PARENT_CONTAINER,
    id
});

/**
 * Opens a collection of collapsible containers, if not already open.
 * @param containers
 * @returns {{ids: *, type: string}}
 */
const batchOpenContainers = (containers) => ({
    type: ACTION_TYPES.BATCH_OPEN_CONTAINERS,
    containers
});

/**
 * Closes a collection of collapsible containers, if not already closed.
 * @param containers
 * @returns {{ids: *, type: string}}
 */
const batchCloseContainers = (containers) => ({
    type: ACTION_TYPES.BATCH_CLOSE_CONTAINERS,
    containers
});

/**
 * Toggle the open state of a collapsible container
 * @param id
 * @returns {{type: *, id: *}}
 */
const toggleContainer = (id) => ({
    type: ACTION_TYPES.TOGGLE_CONTAINER,
    id
});

/**
 * Add a container, open or closed to the store
 * @type {{type: *, id: *}}
 */
const registerContainer = (id, isOpen) => ({
    type: ACTION_TYPES.REGISTER_CONTAINER,
    id,
    isOpen
});

/**
 * Remove the container from the store
 * @param id
 * @returns {{type: *, id: *}}
 */
const unregisterContainer = (id) => ({
    type: ACTION_TYPES.UNREGISTER_CONTAINER,
    id
});

export {
    openContainer,
    openParentContainer,
    batchOpenContainers,
    batchCloseContainers,
    toggleContainer,
    registerContainer,
    unregisterContainer
};
