import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import UserFeatureTypes from '../../types/UserFeatureTypes';

/**
 * Renders the `child` property if the array of
 * `userFeatures` contains the `requiredFeature` string prop.
 * Otherwise render defaultChildren (null by default).
 * This component is used to simplify the conditional rendering
 * of another component.
 *
 * Note: It can only wrap one react component. If you want to render
 * multiple components based on one feature, please wrap them in
 * another element.
 *
 * @example
 * <UserFeatureToggle
 *      requiredFeatureOneOf=[UserFeatureTypes.PROPOSAL]>
 *      <button> DELETE everything >:D </button>
 * </UserFeatureToggle>
 *
 */
const UserFeatureToggle = ({
    requiredFeatureOneOf,
    children,
    defaultChildren = null
}) => {
    const userFeatures = useSelector(
        (state) => state.sessionContext.user.features
    );

    const isFeatureIncluded = requiredFeatureOneOf.some((requiredFeature) =>
        userFeatures.includes(requiredFeature)
    );

    return isFeatureIncluded ? children : defaultChildren;
};

UserFeatureToggle.propTypes = {
    requiredFeatureOneOf: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(UserFeatureTypes))
    ).isRequired,
    children: PropTypes.object.isRequired,
    defaultChildren: PropTypes.object
};

UserFeatureToggle.defaultProps = {
    defaultChildren: null
};

export default UserFeatureToggle;
