import {useState, useEffect} from 'react';
import HTTPClient from 'lib/HTTPClient';
import userUtils from '../../userUtils';
import UserSchemaNameTypes from '../userSchemaNameTypes';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import i18nUtils from 'lib/i18nUtils';

/**
 * Check if saveState has any validation error and if it is ready to save user data
 */
const isReadyToSave = (saveState) =>
    !saveState.isSent &&
    saveState.isSaveClicked &&
    Object.keys(saveState.errors).every((key) => !saveState.errors[key]);

const createUser = (userDTO) => HTTPClient.post('users', userDTO);

const updateUser = (userDTO, userId) =>
    HTTPClient.put(`users/${userId}`, userDTO);

const userSchemaNameTypeMap = {
    [UserSchemaNameTypes.CREATE]: {
        apiCall: (userData) => createUser(userData),
        errorType: 'CREATE_USER_FAILED'
    },
    [UserSchemaNameTypes.UPDATE]: {
        apiCall: (userData, userId) => updateUser(userData, userId),
        errorType: 'UPDATE_USER_FAILED'
    }
};

/**
 * Custom hook for saveState
 * It checks if the validation is done and calls the related api (create or update user)
 *
 * example for saveState obj:
 * saveState: {
 *      isSent: false,
 *      isSaveClicked: true,
 *      errors: {
 *          generalFormError: false,
 *          APPLICANT: true,
 *          MANAGER_L1: false
 *      }
 * }
 */
const useSaveState = (
    userSchemaNameType,
    userData,
    callSnack,
    onError,
    stringResources
) => {
    const [saveState, setSaveState] = useState(() => ({
        isSent: true,
        isSaveClicked: false,
        errors: {}
    }));

    const getSuccessSnackProperties = () => ({
        id: `snack-success-${userSchemaNameType.toLowerCase()}-user-${
            userData.generalFormData.emailAddress
        }`,
        snackType: TpSnackbarTypes.SUCCESS,
        autoHideDuration: 2000,
        message: `${
            userData.generalFormData.emailAddress
        } ${i18nUtils.getTranslatedWebStringKey(
            stringResources,
            `tp.admin.${userSchemaNameType.toLowerCase()}.notification.message.success`
        )}`
    });

    const getNoRoleErrorSnackProperties = () => ({
        id: 'snack-error-no-role-for-user',
        snackType: TpSnackbarTypes.ERROR,
        autoHideDuration: 5000,
        message: `${i18nUtils.getTranslatedWebStringKey(
            stringResources,
            'tp.errormsg.usermodule.atleastonerolerequired'
        )}`
    });

    async function saveUser(userDTO, userId) {
        try {
            setSaveState((prevState) => ({
                ...prevState,
                isSent: true
            }));
            await userSchemaNameTypeMap[userSchemaNameType].apiCall(
                userDTO,
                userId
            );
            callSnack(getSuccessSnackProperties());
        } catch (error) {
            onError(error, userSchemaNameTypeMap[userSchemaNameType].errorType);
        }
    }

    useEffect(() => {
        if (userData && isReadyToSave(saveState)) {
            const userDTO = userUtils.convertUserDataToUserDTOWithMultipleRoles(
                userData
            );
            if (userDTO && userDTO.roles.length === 0) {
                callSnack(getNoRoleErrorSnackProperties());
                return;
            }
            saveUser(userDTO, userData.generalFormData.id);
        }
    }, [saveState]);

    return [saveState, setSaveState];
};

export default useSaveState;
