import React from 'react';
import PropTypes from 'prop-types';
import RedFlagEntry from './RedFlagEntry';
import WebString from 'components/webString/WebString';

/**
 * Render the red flags in a section. Or in case of no red flags it will
 * show a message saying to proceed.
 * @param props
 * @returns {*}
 * @constructor
 */

export class RedFlagSectionTemplate extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                children: PropTypes.object.isRequired
            })
        )
    };

    static defaultProps = {
        items: []
    };

    render() {
        return (
            <section id="red-flags-section">
                {this.props.items.length >= 1 ? (
                    this.props.items.map((item, index) => {
                        const entryProps = {
                            id: index.toString(),
                            ...item.children.props
                        };
                        return <RedFlagEntry key={index} {...entryProps} />;
                    })
                ) : (
                    <WebString webStringKey="tp.form.recoactionplan.noredflags.title" />
                )}
            </section>
        );
    }
}

export default RedFlagSectionTemplate;
