import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const ArrayFieldTemplate = (props) => {
    return (
        <fieldset className="field field-array field-array-of-object">
            <legend>
                <WebString
                    id={`${props.idSchema.$id}__title`}
                    webStringKey={props.title}
                />
            </legend>
            {props.items.map((element, i) => (
                <div key={`${i}-${props.title}`}>{element.children}</div>
            ))}
        </fieldset>
    );
};
ArrayFieldTemplate.propTypes = {
    idSchema: PropTypes.shape({
        $id: PropTypes.string.isRequired
    }).isRequired,
    items: PropTypes.array.isRequired,
    title: PropTypes.string
};
ArrayFieldTemplate.defaultProps = {
    title: ''
};

export default ArrayFieldTemplate;
