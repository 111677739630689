import {handle} from 'redux-pack';
import TableTypes from 'types/TableTypes';
import defaultAppValues from 'lib/defaultAppValues';

const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
const SAVE_TENANT_SETTINGS = 'SAVE_TENANT_SETTINGS';

export const ACTION_TYPES = {
    UPDATE_USER_INFO,
    SAVE_TENANT_SETTINGS
};

const defaultState = {
    appType: '',
    account: {
        id: 0,
        rolesWithReportingRight: [],
        tableSettings: {
            [TableTypes.PROPOSAL]: {
                columns: [
                    'proposalId',
                    'proposalStatus',
                    'proposalRiskLevel',
                    'bpName',
                    'proposalCurrentTask',
                    'proposalCreationDate',
                    'proposalOriginator',
                    'proposalAssessor'
                ],
                pageSize: 30
            },
            [TableTypes.ASSESSED]: {
                columns: [
                    'proposalId',
                    'proposalStatus',
                    'proposalRiskLevel',
                    'bpName',
                    'proposalCreationDate',
                    'proposalOriginator',
                    'proposalAssessor'
                ],
                pageSize: 10
            },
            [TableTypes.ASSESSMENT]: {
                columns: [
                    'proposalId',
                    'proposalRiskLevel',
                    'bpId',
                    'bpName',
                    'proposalLastModificationDate',
                    'proposalOriginator'
                ],
                pageSize: 10
            },
            [TableTypes.ALERTS]: {
                columns: [
                    'alertsAssessmentId',
                    'bpName',
                    'alertsCount',
                    'alertsNewestDate',
                    'alertsOldestDate',
                    'alertsAssessmentStatus'
                ],
                pageSize: 30
            },
            [TableTypes.BUSINESS_PARTNERS]: {
                columns: ['bpId', 'bpName', 'bpCountry', 'bpCity'],
                pageSize: 20
            },
            [TableTypes.EXTERNAL]: {
                columns: ['proposalId', 'bpName', 'proposalOriginator'],
                pageSize: 10
            }
        },
        proposalTableStatuses: {
            [TableTypes.PROPOSAL]: [
                'ABORTED',
                'ACTIVE',
                'APPROVED',
                'APPROVED_RESTRICTIONS',
                'AUTO_APPROVED',
                'AUTO_DECLINED',
                'CLOSED',
                'DECLINED',
                'PENDING',
                'PENDING_LEVEL2',
                'FEEDBACK_REQUESTED'
            ],
            [TableTypes.ASSESSMENT]: ['PENDING', 'FEEDBACK_REQUESTED'],
            [TableTypes.ASSESSED]: [
                'ACTIVE',
                'APPROVED',
                'APPROVED_RESTRICTIONS',
                'AUTO_APPROVED',
                'AUTO_DECLINED',
                'DECLINED',
                'PENDING',
                'PENDING_LEVEL2'
            ]
        },
        locales: [defaultAppValues.defaultLocale],
        mainLocale: defaultAppValues.defaultLocale,
        displayName: '',
        isDataProtectionNoticeAvailable: false,
        possibleDimensions: [],
        availableRoles: []
    },
    user: {
        features: [],
        isSupplementaryDataRequired: false
    }
};

const sessionContextReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_TENANT_SETTINGS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.tenantSettings.account,
                    tableSettings: {
                        ...state.account.tableSettings,
                        ...action.tenantSettings.account.tableSettings
                    },
                    proposalTableStatuses: {
                        ...state.account.proposalTableStatuses,
                        ...action.tenantSettings.account.proposalTableStatuses
                    }
                },
                user: {
                    ...state.user,
                    ...action.tenantSettings.user
                },
                appType: action.tenantSettings.appType
            };
        }
        case UPDATE_USER_INFO: {
            return handle(state, action, {
                success: () => ({
                    ...state,
                    ...payload,
                    user: {
                        ...state.user,
                        ...payload.user
                    }
                })
            });
        }
        default: {
            return state;
        }
    }
};
export default sessionContextReducer;
