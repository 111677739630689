import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import {getProposalTaskNameFromId} from 'lib/proposalUtils';
import CollapsibleContainer from './CollapsibleContainer';
import TaskOverview from './approvalPage/TaskOverview';
import Loader from 'components/loader/CircularLoader';

/**
 * Custom form field which takes in an array of {@link ProposalTask}.
 *
 * It fetches the data of each proposal task from the
 * ProposalTask endpoint and renders each in a collapsible container
 * using a {@link TaskOverview} component.
 * Each task data is fetched, except the approval page, announcement and
 * a risk-low-or-else task.
 *
 * A {@link Loader} is rendered when no tasks have been provided
 * or the component still fetches the process step.
 */
const ApprovalOverviewField = () => {
    const dispatch = useDispatch();

    const [fetchedSteps, setFetchedSteps] = useState([]);

    const {proposalId, tasks} = useSelector(({process}) => ({
        proposalId: process.proposal.id,
        tasks: process.tasks
    }));

    const getTasksToFetchEndpoints = () =>
        tasks.map(mapTasksToTasksUrlPath).filter((task) => task);

    const mapTasksToTasksUrlPath = (task) => {
        if (
            !task.id.includes('approval') &&
            !task.id.includes('risk-low-or-else') &&
            !task.id.includes('announcement') &&
            !(
                task.id.includes('acknowledge-process') &&
                task.status !== 'COMPLETED'
            )
        ) {
            // TODO 12705 adjust "risk-low-or-else" filter
            return `proposals/${proposalId}/tasks/${task.id}`;
        }
    };

    useEffect(() => {
        const fetchAllStepsAsync = async () => {
            const prevTasksUrls = getTasksToFetchEndpoints();
            try {
                const steps = await Promise.all(
                    prevTasksUrls.map((taskUrl) => HTTPClient.get(taskUrl))
                );
                setFetchedSteps(steps);
            } catch (error) {
                dispatch({type: 'TRANSCLUDE_TASK_FAILED', error});
            }
        };
        if (tasks && tasks.length) {
            fetchAllStepsAsync();
        }
    }, [tasks]);

    if (!fetchedSteps.length) {
        return <Loader />;
    }

    return (
        <div>
            {fetchedSteps.map((task, i) => (
                <CollapsibleContainer
                    id={task.id}
                    isInitiallyOpen={false}
                    titleKey={`tp.process.${getProposalTaskNameFromId(
                        task.id
                    )}.short.title`}
                    key={`form-${i}`}
                    className="bk-cs-box--bordered bk-cs-margin-bottom--md">
                    <TaskOverview currentTask={task} />
                </CollapsibleContainer>
            ))}
        </div>
    );
};

export default ApprovalOverviewField;
