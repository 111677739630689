// The user role 'MANAGER' is generic and only used when manager level is irrelevant.
const UserRoleTypes = {
    APPLICANT: 'APPLICANT',
    MANAGER_L1: 'MANAGER_L1',
    MANAGER_L2: 'MANAGER_L2',
    MANAGER_L3: 'MANAGER_L3',
    ADMIN: 'ADMIN',
    ALERT_MANAGER: 'ALERT_MANAGER',
    EXTERNAL: 'EXTERNAL',
    AUDITOR: 'AUDITOR',
    MANAGER: 'MANAGER'
};

export default UserRoleTypes;
