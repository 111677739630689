import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import UploadFileField from './UploadFileField';
import {proposalRunningTypes} from 'types/StatusTypes';

const ApprovalUploadFileField = (props) => {
    const status = useSelector((state) => state.process.proposal.status);

    const isRunning = proposalRunningTypes.includes(status);

    return (
        <UploadFileField
            {...props}
            formContext={{
                ...props.formContext,
                formId: ''
            }}
            disabled={!isRunning}
            readonly={!isRunning}
        />
    );
};

ApprovalUploadFileField.propTypes = {
    formContext: PropTypes.object.isRequired
};

export default ApprovalUploadFileField;
