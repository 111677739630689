import React, {useContext} from 'react';
import {AuthContext} from 'components/authorization/AuthProvider';
import CircularLoader from 'components/loader/CircularLoader';

/*
 *   This component is going to logout the user
 */
const Logout = () => {
    const auth = useContext(AuthContext);
    auth.signout();
    return <CircularLoader overlay={true} />;
};

export default Logout;
