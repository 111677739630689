const extraFields = {
    id: {
        'ui:disabled': true
    },
    created: {
        'ui:disabled': true,
        'ui:widget': 'cs-date'
    },
    updated: {
        'ui:disabled': true,
        'ui:widget': 'cs-date'
    },
    isActive: {
        'ui:widget': 'radio',
        'ui:options': {
            inline: true
        }
    },
    autoRegistered: {
        'ui:disabled': true,
        'ui:widget': 'radio',
        'ui:options': {
            inline: true
        }
    },
    emailAddress: {
        'ui:disabled': true
    }
};

const singleRight = {
    'ui:widget': 'radio',
    'ui:options': {
        inline: true
    }
};

const dimension = {
    'ui:placeholder': 'cs.form.please.select',
    'ui:options': {
        sorted: true
    }
};

export default {
    extraFields,
    dimension,
    singleRight
};
