import React from 'react';
import PropTypes from 'prop-types';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import RedFlagSectionTemplate from './RedFlagSectionTemplate';

/**
 * Field displaying the Reco and Action Plan section.
 */
const RedFlagsField = (props) => {
    // extend RJSF's registry to provide custom array field template here only
    const objectFieldProps = {
        ...props,
        registry: {
            ...props.registry,
            ArrayFieldTemplate: RedFlagSectionTemplate
        }
    };
    return (
        <div className="tp-red-flags">
            <ObjectField {...objectFieldProps} />
        </div>
    );
};

RedFlagsField.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired,
    formData: PropTypes.shape({
        entries: PropTypes.array.isRequired
    }).isRequired,
    registry: PropTypes.object.isRequired
};
export default RedFlagsField;
