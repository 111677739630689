import React from 'react';
import PropTypes from 'prop-types';
import {ProposalForm} from 'components/tpForm/TpForm';
import DuplicateCoreDataList from 'components/proposal/DuplicateCoreDataList';
import InternetResearchDataList from 'components/internetResearch/InternetResearchDataList';
import RiskMatrix from 'components/riskMatrix/RiskMatrix';
import TpButton from 'components/common/buttons/TpButton';
import {
    proposalTaskActionObjects,
    proposalTaskActions,
    taskFinishedFilter
} from 'lib/proposalUtils';
import FeatureTypes from 'types/FeatureTypes';
import StatusTypes from 'types/StatusTypes';
import ViewType from 'types/ViewTypes';

/**
 * The SubTaskContent takes in a task of the proposal
 * process and provides, depending on
 * the given task properties, the correct input method.
 *
 * The currentTask.properties property determine the input method.
 * A TpForm component is shown when the property contains a formDefinition
 * property. The duplicate check table is displayed when
 * duplicateCheckResult is present. Depending on the value of
 * the actions property, additional interactive elements are
 * provided (e.g. the go back button after the duplicate check form).
 *
 * This component does not display only one content case exclusively,
 * but every available option.
 *
 * @class
 */
export default class SubTaskContent extends React.Component {
    static propTypes = {
        currentTask: PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.oneOf(Object.values(StatusTypes.Task)).isRequired,
            properties: PropTypes.shape({
                fieldData: PropTypes.object,
                formDefinition: PropTypes.PropTypes.shape({
                    formId: PropTypes.string.isRequired,
                    formSchema: PropTypes.object.isRequired,
                    uiSchema: PropTypes.object
                }),
                actions: PropTypes.arrayOf(PropTypes.string),
                duplicateCheckResult: PropTypes.shape({
                    duplicates: PropTypes.array.isRequired
                }),
                internetSearchResult: PropTypes.shape({
                    searchQueryResults: PropTypes.arrayOf(PropTypes.object)
                        .isRequired
                }),
                riskMatrix: PropTypes.object
            }).isRequired,
            isLastSubTask: PropTypes.bool
        }).isRequired,
        view: PropTypes.oneOf(Object.values(ViewType)).isRequired,
        onActionTaken: PropTypes.func.isRequired,
        onErrors: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        forceDisable: PropTypes.bool,
        onFormChange: PropTypes.func
    };

    static defaultProps = {
        forceDisable: false,
        onFormChange: () => undefined
    };

    /**
     * Handles the occurring errors of the TpForm and DuplicateCoreDataList.
     *
     * @param {Object[]} errors
     */
    handleError = (errors) => {
        /* set in webpack.config for testing back-end validation errors */
        if (process.env.DISABLE_FE_FORM_VALIDATION) {
            if (errors.length > 0) {
                this.props.onSubmit();
            }
        } else {
            this.props.onErrors(errors);
        }
    };

    /**
     * Handles the occurring goBack button in a content area
     *
     * @return {*}
     */
    displayGoBackButton = () => {
        const {
            properties: {actions}
        } = this.props.currentTask;
        const isTaskUnfinished = !taskFinishedFilter(this.props.currentTask);

        return isTaskUnfinished &&
            actions &&
            actions.includes(proposalTaskActions.goBack) ? (
            <div className="bk-cs-vertical-margin">
                <TpButton
                    id="btn_goBack_subTask"
                    className="bk-cs-button--tertiary"
                    onClick={() =>
                        this.props.onActionTaken(
                            proposalTaskActionObjects.goBack
                        )
                    }
                    webStringKey="cs.button.back"
                />
            </div>
        ) : null;
    };

    render() {
        const {
            id,
            properties: {
                formData,
                internetSearchResult,
                riskMatrix,
                formDefinition,
                fieldData,
                duplicateCheckResult
            },
            isLastSubTask
        } = this.props.currentTask;
        const isTaskFinished = !!taskFinishedFilter(this.props.currentTask);

        const showButtonBelowForm =
            !this.props.forceDisable &&
            !isLastSubTask &&
            this.props.view === ViewType.APPLICANT;
        const showFormAsReadonly = this.props.forceDisable || isTaskFinished;
        return (
            <div className="tp-task-content">
                {internetSearchResult
                    ? internetSearchResult.searchQueryResults.map(
                          (result, key) => (
                              <InternetResearchDataList
                                  key={key}
                                  searchQueryResult={result}
                              />
                          )
                      )
                    : null}
                {riskMatrix ? <RiskMatrix riskMatrix={riskMatrix} /> : null}
                {formDefinition ? (
                    <ProposalForm
                        featureType={FeatureTypes.PROPOSAL}
                        id={formDefinition.formId}
                        schema={formDefinition.formSchema}
                        uiSchema={formDefinition.uiSchema || {}}
                        formData={formData || {}}
                        fieldData={fieldData || {}}
                        onChange={this.props.onFormChange}
                        onSubmit={this.props.onSubmit}
                        onError={this.handleError}
                        disabled={showFormAsReadonly}
                        showSubmitButton={showButtonBelowForm}
                    />
                ) : null}
                {duplicateCheckResult ? (
                    <DuplicateCoreDataList
                        id={id}
                        duplicates={duplicateCheckResult.duplicates}
                        onActionTaken={this.props.onActionTaken}
                        disabled={this.props.forceDisable || isTaskFinished}
                        onError={this.handleError}
                    />
                ) : null}
                {this.displayGoBackButton()}
            </div>
        );
    }
}
