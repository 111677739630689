import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Form from 'components/tpForm/Form';
import HTTPClient from 'lib/HTTPClient';
import LinearLoader from 'components/loader/LinearLoader';
import RegistrationOutcomeAlert from './RegistrationOutcomeAlert';
import registrationFormSchema from './registrationFormSchema';
import registrationFormUiSchema from './registrationFormUiSchema';
import requestOutcomeTypes from './requestOutcomeTypes';

/**
 * A section handling the self-registration form and taking care of request submission and
 * error handling.
 */
export class RegistrationSection extends React.Component {
    static propTypes = {
        regId: PropTypes.string.isRequired,
        stringResources: PropTypes.object
    };

    static defaultProps = {
        stringResources: {}
    };

    state = {
        isRequesting: false,
        requestOutcome: {type: '', responseStatus: null, responseData: null},
        formData: {}
    };

    handleFormSubmission = async ({formData}) => {
        this.setState({
            requestOutcome: {
                // type '' indicates that there is no response in the state; it's very not so clean; should be put into it's own class
                type: '',
                responseStatus: null,
                responseData: null
            },
            isRequesting: true
        });
        try {
            await HTTPClient.post(
                `register/${this.props.regId.toUpperCase()}`,
                formData
            );
            this.setState({
                requestOutcome: {type: requestOutcomeTypes.SUCCESS},
                isRequesting: false
            });
        } catch (e) {
            this.setState({
                requestOutcome: {
                    type: requestOutcomeTypes.ERROR,
                    responseStatus: e.response ? e.response.status : null,
                    responseData: e.response ? e.response.data : null
                },
                isRequesting: false
            });
        }
    };

    getRegistrationOutcomeAlert = () => (
        <RegistrationOutcomeAlert
            key={'registration-alert'}
            type={this.state.requestOutcome.type}
            responseStatus={this.state.requestOutcome.responseStatus}
            responseData={this.state.requestOutcome.responseData}
        />
    );

    render() {
        const {requestOutcome} = this.state;

        let registrationOutcomeAlert = null;
        if (this.state.requestOutcome.type) {
            registrationOutcomeAlert = this.getRegistrationOutcomeAlert();
            if (
                RegistrationOutcomeAlert.evaluateRequestOutcome(requestOutcome)
                    .isProcessFinalized
            ) {
                // the registration flow ends here
                return registrationOutcomeAlert;
            }
        }

        return (
            <div>
                {registrationOutcomeAlert}
                <Form
                    key={'registration-form'}
                    showSubmitButton={!this.state.isRequesting}
                    disabled={this.state.isRequesting}
                    formData={this.state.formData}
                    onChange={(rjsfObject) =>
                        this.setState({formData: rjsfObject.formData})
                    }
                    onSubmit={this.handleFormSubmission}
                    id={'registration-form'}
                    schema={registrationFormSchema}
                    uiSchema={registrationFormUiSchema}
                    submitButtonId={'btn-submit-registration'}
                    submitButtonWebStringKey={'tp.registration.submit.title'}
                    stringResources={this.props.stringResources}
                />
                <LinearLoader
                    key={'registration-loader'}
                    visible={this.state.isRequesting}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    stringResources: state.settings.i18n.stringResources
});

export default connect(mapStateToProps)(RegistrationSection);
