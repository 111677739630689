import {handle} from 'redux-pack';
import StatusTypes from 'types/StatusTypes';

const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
const LOAD_PROPOSAL = 'LOAD_PROPOSAL';
const LOAD_PROPOSAL_TASKS = 'LOAD_PROPOSAL_TASKS';
const LOAD_PROPOSAL_TASK_BY_ID = 'LOAD_PROPOSAL_TASK_BY_ID';
const SWITCH_PROPOSAL_TASK_AUTOSAVE = 'SWITCH_PROPOSAL_TASK_AUTOSAVE';
const UPDATE_CURRENT_TASK = 'UPDATE_CURRENT_TASK';
const AUTOSAVE_CURRENT_TASK = 'AUTOSAVE_CURRENT_TASK';
const SAVE_CURRENT_TASK = 'SAVE_CURRENT_TASK';
const CLEAR_PROPOSAL = 'CLEAR_PROPOSAL';
const ABORT_PROPOSAL = 'ABORT_PROPOSAL';
const RECHECK_PROPOSAL = 'RECHECK_PROPOSAL';
const HIDE_ERRORS = 'HIDE_ERRORS';
const SHOW_ERRORS = 'SHOW_ERRORS';
const SET_PROPOSAL_INACTIVE = 'SET_PROPOSAL_INACTIVE';
const GET_IMPORTED_PROPOSAL = 'GET_IMPORTED_PROPOSAL';

export const ACTION_TYPES = {
    CREATE_PROPOSAL,
    LOAD_PROPOSAL,
    LOAD_PROPOSAL_TASKS,
    LOAD_PROPOSAL_TASK_BY_ID,
    SWITCH_PROPOSAL_TASK_AUTOSAVE,
    UPDATE_CURRENT_TASK,
    AUTOSAVE_CURRENT_TASK,
    SAVE_CURRENT_TASK,
    CLEAR_PROPOSAL,
    ABORT_PROPOSAL,
    RECHECK_PROPOSAL,
    HIDE_ERRORS,
    SHOW_ERRORS,
    SET_PROPOSAL_INACTIVE,
    GET_IMPORTED_PROPOSAL
};

const defaultState = {
    loadingProposal: false,
    loadingTask: false,
    loadingTasks: false,
    savingCurrentTask: false,
    proposal: {},
    currentTask: null,
    currentTaskErrors: [],
    tasks: [],
    view: null
};

/*
 * the main baseReducers to handle all states
 *
 * @param {object} state
 * @param {object} action
 * @returns {*}
 */
const processReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_PROPOSAL:
        case LOAD_PROPOSAL:
            return handle(state, action, {
                start: () => ({
                    ...defaultState,
                    loadingProposal: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    loadingProposal: false,
                    proposal: payload.data,
                    view: payload.view
                }),
                failure: (prevState) => ({
                    ...prevState,
                    loadingProposal: false,
                    forbiddenOrNotFound: true
                })
            });
        case LOAD_PROPOSAL_TASKS:
            return handle(state, action, {
                start: (prevState) => ({...prevState, loadingTasks: true}),
                success: (prevState) => ({
                    ...prevState,
                    loadingTasks: false,
                    tasks: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    loadingTasks: true,
                    tasks: defaultState.tasks
                })
            });
        case LOAD_PROPOSAL_TASK_BY_ID:
            return handle(state, action, {
                start: (prevState) => ({...prevState, loadingTask: true}),
                success: (prevState) => ({
                    ...prevState,
                    loadingTask: false,
                    currentTask: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    loadingTask: false,
                    currentTask: null
                })
            });
        case SAVE_CURRENT_TASK:
            return handle(state, action, {
                start: (prevState) => ({...prevState, savingCurrentTask: true}),
                success: (prevState) => ({
                    ...prevState,
                    currentTask: payload,
                    savingCurrentTask: false,
                    currentTaskErrors: []
                }),
                failure: (prevState) => ({
                    ...prevState,
                    savingCurrentTask: false
                })
            });
        case AUTOSAVE_CURRENT_TASK:
            return handle(state, action, {
                success: (prevState) => {
                    const newState = {
                        ...prevState,
                        currentTask: payload
                    };
                    newState.currentTask.properties.formData =
                        prevState.currentTask.properties.formData;
                    return newState;
                }
            });
        case ABORT_PROPOSAL:
        case RECHECK_PROPOSAL:
            return handle(state, action, {
                success: () => ({
                    ...state,
                    proposal: {
                        ...state.proposal,
                        status: StatusTypes.Proposal.CLOSED
                    }
                }),
                failure: (prevState) => ({
                    ...prevState
                })
            });
        case CLEAR_PROPOSAL:
            return handle(state, action, {
                finish: () => defaultState
            });
        case SET_PROPOSAL_INACTIVE:
            return handle(state, action, {
                success: () => ({
                    ...state,
                    proposal: {
                        ...state.proposal,
                        status: StatusTypes.Proposal.INACTIVE
                    }
                })
            });
        case UPDATE_CURRENT_TASK:
            return {
                ...state,
                currentTask: payload
            };
        case SHOW_ERRORS:
            return {
                ...state,
                currentTaskErrors: action.errors
            };
        case HIDE_ERRORS:
            return {
                ...state,
                currentTaskErrors: []
            };
        case GET_IMPORTED_PROPOSAL:
            return handle(state, action, {
                start: () => ({
                    ...defaultState
                }),
                success: (prevState) => ({
                    ...prevState,
                    proposal: {
                        ...payload,
                        id: action.meta.proposalId,
                        status: payload.formData.approvalStatus,
                        owner: payload.formData.owner,
                        scope: payload.formData.scope,
                        imported: payload.formData.imported
                    }
                }),
                failure: (prevState) => ({
                    ...prevState
                })
            });
        case SWITCH_PROPOSAL_TASK_AUTOSAVE:
        default:
            return state;
    }
};

export default processReducer;
