import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const RiskAlertsBPName = ({name}) => (
    <div id="risk-alert-name">
        <WebString
            webStringKey="tp.form.dowjones.name.title"
            className="tp-risk-alerts__heading"
        />
        {name}
    </div>
);

RiskAlertsBPName.propTypes = {
    name: PropTypes.string
};

RiskAlertsBPName.defaultProps = {
    name: ''
};

export default RiskAlertsBPName;
