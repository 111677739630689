import React from 'react';
import PropTypes from 'prop-types';
import {Alert, AlertTitle} from '@material-ui/lab';
import WebString from 'components/webString/WebString';
import requestOutcomeTypes from './requestOutcomeTypes';

export class RegistrationOutcomeAlert extends React.PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(Object.values(requestOutcomeTypes)).isRequired,
        responseStatus: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        responseData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static errorResponseTitlesToAlertMap = new Map([
        [
            'default',
            {
                severity: 'error',
                key: 'internalserver',
                isProcessFinalized: false
            }
        ],
        [
            'offline',
            {
                severity: 'error',
                key: 'offline',
                isProcessFinalized: false
            }
        ],
        [
            'timeout',
            {
                severity: 'error',
                key: 'timeout',
                isProcessFinalized: false
            }
        ],
        [
            'SELF_REGISTER_USER_ALREADY_EXISTS_BUT_UNCONFIRMED',
            {
                severity: 'warning',
                key: 'registrationpending',
                isProcessFinalized: true
            }
        ],
        [
            'SELF_REGISTER_USER_ALREADY_EXISTS_AND_CONFIRMED',
            {
                severity: 'warning',
                key: 'registrationfinished',
                isProcessFinalized: true
            }
        ],
        [
            'SELF_REGISTER_EMAIL_DOMAIN_NOT_ALLOWED',
            {
                severity: 'error',
                key: 'invalidemaildomain',
                isProcessFinalized: false
            }
        ],
        [
            'EMAIL_INVALID',
            {
                severity: 'error',
                key: 'invalidemailformat',
                isProcessFinalized: false
            }
        ]
    ]);

    /**
     *
     * @param type
     * @param status
     * @returns {{severity: string, key: string, isProcessFinalized: boolean}}
     */
    static evaluateRequestOutcome = ({type, responseStatus, responseData}) => {
        if (type === requestOutcomeTypes.SUCCESS) {
            return {
                severity: 'success',
                key: 'registered',
                isProcessFinalized: true
            };
        }

        let errorKey = 'default';

        if (!responseStatus) {
            // responseStatus = 0
            // connection error
            errorKey = 'offline';
        } else if (responseStatus === 408 || responseStatus === 504) {
            // timeout
            errorKey = 'timeout';
        } else {
            if (responseData.errors) {
                const [error] = responseData.errors;
                errorKey = error.title;
            }
        }
        return (
            RegistrationOutcomeAlert.errorResponseTitlesToAlertMap.get(
                errorKey
            ) ||
            RegistrationOutcomeAlert.errorResponseTitlesToAlertMap.get(
                'default'
            )
        );
    };

    buildAlert = ({severity, key}) => (
        <Alert severity={severity}>
            <AlertTitle>
                <WebString webStringKey={`tp.registration.${severity}.title`} />
            </AlertTitle>
            <WebString webStringKey={`tp.registration.${severity}.${key}`} />
        </Alert>
    );

    render() {
        return this.buildAlert(
            RegistrationOutcomeAlert.evaluateRequestOutcome(this.props)
        );
    }
}

export default RegistrationOutcomeAlert;
