import React from 'react';
import propTypes from 'prop-types';
import WebString from 'components/webString/WebString';

class EditInput extends React.Component {
    static propTypes = {
        initialInputValue: propTypes.string.isRequired,
        onInputChange: propTypes.func.isRequired,
        onKeyPress: propTypes.func.isRequired,
        onBlur: propTypes.func.isRequired
    };

    state = {
        inputValue: this.props.initialInputValue
    };

    handleKeyPress = (event) => {
        this.props.onKeyPress(event);
    };

    handleChange = (event) => {
        this.props.onInputChange(event.target.value);
    };

    handleBlur = () => {
        this.props.onBlur();
    };

    render() {
        return (
            <div>
                <label className="tp-edit-input__label">
                    <WebString webStringKey="cs.text.sendquestionnairetoemail" />
                </label>
                <input
                    id="tp-edit-input__email-field"
                    type="email"
                    className="tp-edit-input__inputfield"
                    defaultValue={this.props.initialInputValue}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }
}

export default EditInput;
