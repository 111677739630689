import React from 'react';
import has from 'lodash.has';
import jsonQ from 'jsonq';
import PropTypes from 'prop-types';
import RedFlagsAndPlansTemplate from '../RedFlagsAndPlansTemplate';

/**
 * Uses the prop path to find the correct values in the form structure and returns the modified
 * UIschema.
 * @param propPaths
 * @param props
 * @returns {*}
 */
const buildJsonQObject = (propPaths, props) => {
    const propsQ = jsonQ(props.uiSchema);
    let modifiedUISchema = {};
    propPaths.map((property) => {
        modifiedUISchema = propsQ
            .find(property)
            .find('hideHeader')
            .value('true').jsonQ_root;
    });
    return modifiedUISchema;
};

/**
 * Looks if certain properties are present in the UI schema and if so - changes the values of the
 * headers so they aren't shown.
 * @param props
 * @returns {*}
 * @constructor
 */
const TextOnlyRedFlagsAndPlansTemplate = (props) => {
    if (has(props, 'uiSchema.entries.items.additionalAfterContractAction')) {
        const uiSchemaPaths = [
            'additionalAfterContractAction',
            'additionalBeforeContractAction',
            'customRedFlags'
        ];
        const uiSchemaWithSomeHiddenHeaders = buildJsonQObject(
            uiSchemaPaths,
            props
        );

        const propsWithModifiedUISchema = {
            ...props,
            uiSchema: uiSchemaWithSomeHiddenHeaders
        };
        return <RedFlagsAndPlansTemplate {...propsWithModifiedUISchema} />;
    } else return <RedFlagsAndPlansTemplate {...props} />;
};

TextOnlyRedFlagsAndPlansTemplate.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired
};

export default TextOnlyRedFlagsAndPlansTemplate;
