import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import {wrapInDispatch} from 'store/actions/actionUtils';
import {removeSnack} from 'store/actions/snackActions';

export const TpSnackbarTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
};

/**
 * Renders a snackbar which has 4 different options(TpSnackbarTypes) => SUCCESS, INFO, ERROR and WARNING depends on snack.snackProperties.snackType
 * It can be set auto hide duration time of the snackbar with snack.snackProperties.autoHideDuration
 * If snack.snackProperties.autoHideDuration is not set, it can be removed by the user manually
 * It can be created by using the action snackActions.createSnack(snackProperties)
 */
export class TpSnackbar extends React.Component {
    static propTypes = {
        snack: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
            snackProperties: PropTypes.shape({
                id: PropTypes.string.isRequired,
                snackType: PropTypes.string.isRequired,
                message: PropTypes.any.isRequired,
                autoHideDuration: PropTypes.number
            }).isRequired
        }).isRequired,
        removeSnack: PropTypes.func.isRequired
    };

    static defaultProps = {
        onError: () => undefined,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
    };

    handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            this.props.removeSnack();
        }
    };

    render() {
        const {snack} = this.props;
        return (
            <Snackbar
                id={snack.snackProperties.id}
                className={`tp-snackbar__snackbar--${snack.snackProperties.snackType}`}
                open={snack.isVisible}
                onClose={this.handleClose}
                autoHideDuration={snack.snackProperties.autoHideDuration}
                vertical={snack.snackProperties.verticalPosition}
                horizontal={snack.snackProperties.horizontalPosition}>
                <SnackbarContent
                    message={
                        <React.Fragment>
                            <i
                                className={`tp-snackbar__content-icon--${snack.snackProperties.snackType}`}
                            />
                            <span
                                className="tp-snackbar__content-message"
                                id={`snackbar-message-${snack.snackProperties.id}`}>
                                {snack.snackProperties.message}
                            </span>
                        </React.Fragment>
                    }
                    action={[
                        <IconButton
                            className="tp-snackbar__close"
                            key="close"
                            onClick={this.handleClose}>
                            <i className="tp-snackbar__close--icon" />
                        </IconButton>
                    ]}
                />
            </Snackbar>
        );
    }
}

const mapStateToProps = (state) => ({
    snack: state.snack
});

const mapDispatchToProps = (dispatch) => ({
    removeSnack: wrapInDispatch(removeSnack, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TpSnackbar);
