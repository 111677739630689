import React from 'react';
import RiskAlertsField from '../RiskAlertsField';

/**
 * Text-only version of the risk alert custom field, for display
 * in the approval step.
 * Does not need the RJSF wiring, as we are only displaying
 * read-only information, not rendering any form elements.
 * @extends RiskAlertsField
 */

const TextOnlyRiskAlertsField = (props) => (
    <RiskAlertsField {...props} textOnly={true} />
);

export default TextOnlyRiskAlertsField;
