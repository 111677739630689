import settings from './settingsReducer';
import sessionContext from './sessionContextReducer';
import mainErrors from './mainErrorsReducer';
import form from './formReducer';
import snack from './snackReducer';
import oidc from './oidcReducer';

const baseReducers = {
    sessionContext,
    settings,
    mainErrors,
    form,
    snack,
    oidc
};

export default baseReducers;
