import React from 'react';
import PropTypes from 'prop-types';

/**
 * Text only version of Base Input widget.
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */

class TextOnlyBaseInputWidget extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        value: PropTypes.any
    };

    static defaultProps = {
        value: null
    };

    render() {
        const displayValue =
            !this.props.value && this.props.value !== 0
                ? '-'
                : this.props.value;
        return (
            <div className="bk-cs-left-pad-multiline-text">
                <span id={this.props.id} className="bk-cs-form-field-textonly">
                    {displayValue}
                </span>
            </div>
        );
    }
}

export default TextOnlyBaseInputWidget;
