import React from 'react';
import PropTypes from 'prop-types';
import TpRadioWidget from 'components/tpForm/widgets/TpRadioWidget';
import WebString from 'components/webString/WebString';

const SSOUsageSelectionField = ({
    handleSSOUsageSelection,
    createUserInCLUsingPasswordEnabled
}) => {
    const ssoSelectionOptions = {
        enumOptions: [
            {
                value: true,
                label: 'cs.text.answer.yes'
            },
            {
                value: false,
                label: 'cs.text.answer.no'
            }
        ],
        inline: true
    };

    const handleChange = (selectedOption) => {
        handleSSOUsageSelection(selectedOption);
    };

    return (
        <div className={'tp-import-users-csv__sso-election-radio-container'}>
            <WebString
                className="tp-import-users-csv__ssousage-webstring"
                webStringKey={`tp.importuserscsv.ssoused.text`}
            />
            <TpRadioWidget
                onChange={handleChange}
                id="tp-import-users-csv__sso-selection-radio"
                className={`tp-import-users-csv__sso-selection-radio`}
                options={ssoSelectionOptions}
                value={!createUserInCLUsingPasswordEnabled}
            />
        </div>
    );
};

SSOUsageSelectionField.propTypes = {
    handleSSOUsageSelection: PropTypes.func.isRequired,
    createUserInCLUsingPasswordEnabled: PropTypes.bool.isRequired
};

export default SSOUsageSelectionField;
