import React from 'react';
import {IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const EditToggle = ({inputValue, onToggle}) => {
    return (
        <div className="tp-edit-toggle__wrapper">
            <label className="tp-edit-input__label">
                <WebString webStringKey="cs.text.sendquestionnairetoemail" />
            </label>
            <p className="tp-edit-toggle__email">{inputValue}</p>
            <IconButton
                className="tp-edit-toggle__editbutton"
                key="edit"
                onClick={onToggle}>
                <i className="tp-edit-toggle__editicon" />
            </IconButton>
        </div>
    );
};

EditToggle.propTypes = {
    inputValue: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired
};

export default EditToggle;
