import React from 'react';
import PropTypes from 'prop-types';
import RiskAlertsDowJonesImage from './RiskAlertsDowJonesImage';
import DisplayJsonObject from 'components/tpForm/fields/DisplayJsonObject';
import TpPopup from 'components/common/TpPopup';
import CircularLoader from 'components/loader/CircularLoader';
import ToggleContainerState from '../ToggleContainerState';

const RiskAlertsPopUp = ({
    headerWebstringKey,
    onClose,
    content,
    show = false
}) => {
    if (!show) {
        return null;
    }

    const config = {
        headerInfo: {
            showCloseButton: true,
            type: 'generic',
            webStringKey: headerWebstringKey
        },
        buttons: [
            {
                webStringKey: 'OK',
                id: 'btn-show-profile-ok'
            }
        ],
        size: 'full',
        onClose
    };

    return (
        <TpPopup {...config}>
            <RiskAlertsDowJonesImage />
            <ToggleContainerState scope="container" />
            {content ? (
                <DisplayJsonObject jsonObject={content} />
            ) : (
                <CircularLoader />
            )}
        </TpPopup>
    );
};

RiskAlertsPopUp.propTypes = {
    headerWebstringKey: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.object,
    show: PropTypes.bool
};

RiskAlertsPopUp.defaultProps = {
    headerWebstringKey: '',
    content: null,
    show: false
};

export default RiskAlertsPopUp;
