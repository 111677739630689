import React from 'react';
import PropTypes from 'prop-types';
import SHA1 from 'crypto-js/sha1';
import Timer from 'lib/Timer';
import WebString from 'components/webString/WebString';
import IconButton from 'components/tpForm/fields/iconButtons/IconButton';
import defaultAppValues from 'lib/defaultAppValues';
import timeUtils from 'lib/timeUtils';

/**
 * Timer component which will save via the passed in function
 * every duration set by the timerDuration prop, or when the
 * user clicks the 'save now' link.
 * The userData is hashed to see if it has changed since the last
 * save. If not, the save does not happen.
 * Whether the timer is displayed is controlled by the showTimer
 * prop function.
 */

export class AutoSave extends React.Component {
    static propTypes = {
        onAutosave: PropTypes.func.isRequired,
        isSavingLocked: PropTypes.bool,
        autosaveInterval: PropTypes.number,
        locale: PropTypes.string,
        lastSavedDate: PropTypes.number,
        contentToSave: PropTypes.object,
        isVisible: PropTypes.func
    };

    static defaultProps = {
        isSavingLocked: false,
        autosaveInterval: defaultAppValues.defaultAutoSaveInterval,
        locale: defaultAppValues.defaultLocale,
        lastSavedDate: null,
        contentToSave: {},
        isVisible: () => true
    };

    constructor(props) {
        super(props);
        this.displayName = 'AutoSave';
        this.lastSavedHash = '';
        this.display = false;

        if (props.isVisible()) {
            this.createTimer(props.contentToSave);
        }
    }

    UNSAFE_componentWillReceiveProps({contentToSave}) {
        this.props.isVisible()
            ? this.createTimer(contentToSave)
            : this.destroyTimer();
    }

    componentWillUnmount() {
        this.destroyTimer();
    }

    autosaveContent = () => {
        if (!this.props.isSavingLocked) {
            this.props.onAutosave();
            this.lastSavedHash = this.hash(this.props.contentToSave);
        }
        this.timer.reset();
    };

    handleManualSave = () => {
        this.autosaveContent();
    };

    handleAutosave = () => {
        if (
            this.display &&
            this.lastSavedHash !== this.hash(this.props.contentToSave)
        ) {
            // display is true and formData in properties has been changed
            this.autosaveContent();
        }
    };

    hash = (content) => {
        const dataToHash = JSON.stringify(content || '');
        return SHA1(dataToHash).toString();
    };

    createTimer = (properties) => {
        if (!this.timer) {
            this.lastSavedHash = this.hash(properties);
            this.timer = new Timer(
                this.handleAutosave,
                this.props.autosaveInterval
            );
            this.timer.start();
            this.display = true;
        }
    };

    destroyTimer = () => {
        if (this.timer) {
            this.timer = null;
            this.display = false;
            this.lastSavedHash = '';
        }
    };

    render() {
        if (!this.display || this.props.isSavingLocked) {
            return null;
        }

        const localisedLastModTimestring = timeUtils.getDateDependentTimestring(
            this.props.lastSavedDate
        );

        return (
            <div className="auto-save">
                {localisedLastModTimestring ? (
                    <div className="auto-save__last-save-time">
                        <WebString webStringKey="cs.text.lastsaved" />:{' '}
                        {localisedLastModTimestring}
                    </div>
                ) : null}
                <IconButton
                    id="bk-tp-manualSaveButton"
                    onClick={this.handleManualSave}
                    iconClassName={'auto-save__manual-save-icon'}
                    className={'auto-save__manual-save'}>
                    <WebString webStringKey="cs.button.manualsave" />
                </IconButton>
            </div>
        );
    }
}

export default AutoSave;
