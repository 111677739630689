import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import WebString from 'components/webString/WebString';

const columns = [
    {
        id: (entity) => `critical-risk-factors-${entity.title.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.riskfactor" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.title} />,
        width: 160
    },
    {
        id: (entity) =>
            `critical-risk-factors-${entity.answerKey.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.value" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.answerKey} />,
        width: 160
    }
];

const CriticalRiskFactors = (props) => {
    const {criticalRiskFactors} = props;

    return (
        <div>
            <h4>
                <WebString webStringKey="tp.riskmatrix.criticalriskfactor.title" />
            </h4>
            {criticalRiskFactors &&
            criticalRiskFactors !== null &&
            criticalRiskFactors.length > 0 ? (
                <ReactTable
                    className="-striped tp-risk-matrix__info-table"
                    data={criticalRiskFactors}
                    columns={columns}
                    showPagination={false}
                    defaultPageSize={criticalRiskFactors.length}
                    resizable={false}
                    sortable={false}
                />
            ) : (
                <WebString
                    id="tp-riskmatrix-criticalriskfactor-nosriskfactors__text"
                    webStringKey="tp.riskmatrix.criticalriskfactor.noriskfactors.text"
                />
            )}
        </div>
    );
};

CriticalRiskFactors.propTypes = {
    criticalRiskFactors: PropTypes.array.isRequired
};

export default CriticalRiskFactors;
