import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import fileSaveUtils from 'lib/fileSavingUtils';
import TpButton from 'components/common/buttons/TpButton';
import ExportUsersUtils from './exportUsersUtils';

export const ExportUsersButton = ({stringResources, users, saveDataToFile}) => {
    const handleOnClick = () => {
        const usersAsCsvData = ExportUsersUtils.usersJsonArrayToCSVForMultipleRoles(
            users,
            stringResources
        );
        saveDataToFile('exportUsers', usersAsCsvData);
    };

    if (!users.length) {
        return null;
    }

    return (
        <TpButton
            id={'tp-exportusers__button'}
            webStringKey={'tp.button.exportusers'}
            className={'tp-exportusers__button'}
            onClick={handleOnClick}
        />
    );
};

ExportUsersButton.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            roles: PropTypes.arrayOf(
                PropTypes.shape({
                    roleConfiguration: PropTypes.shape({
                        role: PropTypes.string.isRequired
                    }),
                    scope: PropTypes.arrayOf(
                        PropTypes.shape({
                            dimension: PropTypes.string.isRequired,
                            values: PropTypes.arrayOf(PropTypes.string)
                                .isRequired
                        })
                    ).isRequired
                })
            )
        })
    ).isRequired,
    stringResources: PropTypes.object.isRequired,
    saveDataToFile: PropTypes.func
};

ExportUsersButton.defaultProps = {
    saveDataToFile: fileSaveUtils.saveDataToCsvFile
};

const mapStateToProps = (state) => {
    const {stringResources} = state.settings.i18n;
    const userManagementTableAndRolesStringResources = Object.fromEntries(
        Object.entries(stringResources).filter(
            ([key]) =>
                key.startsWith('tp.table.usermanagement.') ||
                key.startsWith('tp.text.role.')
        )
    );
    return {
        stringResources: userManagementTableAndRolesStringResources
    };
};

export default connect(mapStateToProps)(ExportUsersButton);
