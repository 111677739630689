import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as proposalActions from 'store/actions/proposalActions';
import * as processActions from 'store/actions/processActions';
import Loader from 'components/loader/CircularLoader';
import ProgressBar from 'components/proposal/progressBar/ProgressBar';
import ProposalTaskContent from 'components/proposal/ProposalTaskContent';
import NotFound from 'components/common/errorHandling/NotFound';
import ViewType from 'types/ViewTypes';
import MetaInfoBar from 'components/proposal/metaInfoBar/MetaInfoBar';
import urls from 'routes/urls';

const isInvalidId = (id) => isNaN(id) || id < 1 || id > Number.MAX_SAFE_INTEGER;

const NotFoundProcess = () => (
    <NotFound
        to={urls.PROPOSALS_URL}
        linkTextWebStringKey={'tp.link.backtooverview'}
    />
);

/*
 * Loads the proposal with current task, and all proposal tasks.
 * For a newly created proposal, the proposal will already be in the Redux store,
 * so we only fetch the current task.
 * Contains the ProgressBar and the ProposalTaskContent.
 */
const ProposalProcess = (props) => {
    const {proposalId: proposalIdFromUrl} = useParams();
    const proposalId = parseInt(proposalIdFromUrl);
    const isValidProposalId = !isInvalidId(proposalId);

    if (!isValidProposalId) {
        return <NotFoundProcess />;
    }

    const dispatch = useDispatch();

    const {
        loadingTask,
        savingCurrentTask,
        proposal,
        tasks,
        currentTask,
        forbiddenOrNotFound
    } = useSelector((state) => state.process);

    useEffect(() => {
        if (!proposal || isEmpty(proposal) || proposal.id !== proposalId) {
            dispatch(proposalActions.initProcess(proposalId, props.view));
        }
    }, [proposalId]);

    useEffect(() => {
        return () => {
            dispatch(processActions.clearProposal());
        };
    }, []);

    if (forbiddenOrNotFound) {
        return <NotFoundProcess />;
    }

    if (loadingTask || !currentTask || !proposal || isEmpty(proposal)) {
        return <Loader overlay={true} />;
    }

    /* show the CircularLoader inside the main container during saving.
     * during saving the whole data in the main container should be available if back end errors */
    return (
        <div className={'proposal-process'}>
            {savingCurrentTask ? <Loader overlay={true} /> : null}
            <MetaInfoBar />
            {props.view === ViewType.APPLICANT ? (
                <ProgressBar
                    id={proposal.id}
                    tasks={tasks}
                    currentTask={currentTask}
                />
            ) : null}
            <ProposalTaskContent
                proposalId={proposal.id}
                proposalStatus={proposal.status}
                currentTask={currentTask}
                tasks={tasks}
            />
        </div>
    );
};

ProposalProcess.propTypes = {
    view: PropTypes.oneOf(Object.values(ViewType)).isRequired
};

export default ProposalProcess;
