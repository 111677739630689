import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

/**
 * Renders the `children` property if `requiredAppTypes` contains
 * `appType` otherwise renders `defaultChildren`
 *
 *
 * Note: It can only wrap one react component. If you want to render
 * multiple components based on one feature, please wrap them in
 * another element.
 */
const AppTypeToggle = (props) => {
    const {requiredAppTypes, children, defaultChildren} = props;

    const appType = useSelector((state) => state.sessionContext.appType);
    return requiredAppTypes.includes(appType) ? children : defaultChildren;
};

AppTypeToggle.propTypes = {
    requiredAppTypes: PropTypes.array.isRequired,
    children: PropTypes.object.isRequired,
    defaultChildren: PropTypes.object
};
AppTypeToggle.defaultProps = {
    defaultChildren: null
};

export default AppTypeToggle;
