/**
 * Return the string cut down to the last full word up to the character limit,
 * with ellipsis ('…') character on the end.
 * If the string starts with a word longer than the character limit, break the word and end with ellipsis.
 * @param {string} string - original string
 * @param {number} characterLimit - the number of characters at which to truncate
 * @returns {string} truncated string
 */
export function truncateString(string, characterLimit) {
    // return empty string if non valid string was given.
    if (!string) {
        return '';
    }

    // return whole string if shorter than limit
    if (string.length <= characterLimit) {
        return string;
    }

    // split at characterLimit
    let truncatedString = string.substring(0, characterLimit);

    // if this is a natural word limit, just add ellipsis
    if (string.charAt(characterLimit) === ' ') {
        return `${truncatedString}…`;
    }

    // if not, go back to previous word break
    const lastSpacePosn = truncatedString.lastIndexOf(' ');
    if (lastSpacePosn > 0) {
        truncatedString = truncatedString.substring(0, lastSpacePosn);
    }

    // if previous word break not found, e.g. the first word is over the limit, then split and add ellipsis
    return `${truncatedString}…`;
}

/**
 * Returns the string after escaping the <a></<a> tags with only link text, if there is an anchortag embedded in the webstring,
 * @param {string} string - original string
 * @returns {string} string - without <a></a> tag
 */

export function escapeAnchorTagsInString(string) {
    //regex to find anchor tag
    const regexforAnchorTag = /<a\b[^>]*>(.*?)<\/a>/;

    if (regexforAnchorTag.test(string)) {
        let anchorTag = regexforAnchorTag.exec(string)[0];
        return string.replace(anchorTag, regexforAnchorTag.exec(string)[1]);
    }

    return string;
}
