import axios from 'axios';
import deepFreeze from 'deep-freeze-node';
import isRetryAllowed from 'is-retry-allowed';
import {getApiVersionForEndpoint} from './HTTPClientUtils';
import defaultAppValues from 'lib/defaultAppValues';

const instance = axios.create({
    timeout: defaultAppValues.defaultTimeout,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

let apiVersionConfig = {};

/* INSTANCE MODIFYING FUNCTIONS */

/**
 * Sets the common request header "Authorization" to "Bearer <accesstoken>".
 * After calling this, all requests will have the header set.
 * @param accessToken The OpenIDConnect implicit flow access token
 * @returns {undefined}
 */
const setAuthAccessToken = (accessToken) => {
    if (accessToken) {
        instance.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${accessToken}`;
    }
};

/**
 * Removes the header with the passed in parameter using delete
 * from the default common headers.
 * Is used primarily for testing.
 * @param {string} headerName The header title to remove
 * @returns {undefined}
 */
const removeCommonHeader = (headerName) => {
    delete instance.defaults.headers.common[headerName];
};

/**
 * Sets the axios base URL.
 * The basePath can be set f.e. to /tp/api/.
 * The Base URL will be {window.location.origin}/{basePath}
 *
 * @param {string} basePath The basePath to append as a prefix to the URL to call.
 * @returns {undefined}
 */
const setApiBasePath = (basePath) => {
    instance.defaults.baseURL = `${window.location.origin}${basePath}`;
};

/**
 * Sets the Api version data.
 * Each Api call has a version number, set in the app's config json file.
 * @param _apiVersionConfig
 */
const setApiVersionConfig = (_apiVersionConfig) => {
    apiVersionConfig = _apiVersionConfig;
};

/**
 * Merges the passed in custom headers into the defaults of the HTTP client which is being used by the
 * application.
 * @param {object} _customHeaders The key-value pairs to be merged into the default headers.
 */
const mergeIntoDefaultHeaders = (_customHeaders) => {
    instance.defaults.headers = {
        ...instance.defaults.headers,
        ..._customHeaders
    };
};

let currentRequestConfig = {
    retryCount: 0,
    retryInterval: defaultAppValues.defaultRetryInterval,
    maxRetries: defaultAppValues.defaultMaxRetries,
    retryCondition: defaultAppValues.defaultRetryCondition,
    retryCallback: defaultAppValues.defaultRetryCallback,
    retrySuccessCallback: defaultAppValues.defaultRetrySuccessCallback
};

const initDefaultConfig = {...currentRequestConfig};
deepFreeze(initDefaultConfig);

/**
 * Assigns the key values of the incoming config to the current
 * request config.
 * @param {object} newConf The keys to assign
 * @returns {undefined}
 */
const mergeConfigToCurrentRequestConfig = (newConf) => {
    currentRequestConfig = {...currentRequestConfig, ...newConf};
};

/**
 * Restores the currentRequestConfig to the initial state.
 * @returns {undefined}
 */
const resetCurrentRequestConfig = () => {
    currentRequestConfig = {...initDefaultConfig};
};

/**
 * Request interceptor to look up and add the API version
 * number for a given call, and add it as a header to this request
 * based on URL and method.
 */
instance.interceptors.request.use((callConfig) => {
    const apiVersionHeader = getApiVersionForEndpoint(
        callConfig,
        apiVersionConfig
    );
    if (apiVersionHeader) {
        callConfig.headers.common['Api-Version'] = apiVersionHeader;
    }
    return callConfig;
});

/**
 * Retry axios interceptor who uses the currentRequestConfig
 * to retry http requests which fail because of a network error.
 * This interceptor only interveines between failed requests,
 * so succeeding http requests are not being handled.
 *
 * The interceptor uses the package is-retry-allowed, which basically
 * is a whitelist and blacklist of request error types.
 */
instance.interceptors.response.use(
    (response) => {
        if (response.config.retryCount) {
            // request previously failed but is now fixed
        }

        return Promise.resolve(response);
    },
    (error) => {
        const errorConfig = error.config;

        if (!errorConfig) {
            return Promise.reject(error);
        }

        const config = {...currentRequestConfig, ...errorConfig};

        const shouldRetry =
            config.retryCondition(error, config.retryCount) &&
            error.code !== 'ECONNABORTED' &&
            (config.retryCount < config.maxRetries ||
                config.maxRetries === -1) &&
            isRetryAllowed(error);

        if (shouldRetry) {
            config.retryCount++;

            // Axios fails merging this configuration to the default configuration because it has an issue
            // with circular structures
            if (instance.defaults.agent === config.agent) {
                delete config.agent;
            }
            if (instance.defaults.httpAgent === config.httpAgent) {
                delete config.httpAgent;
            }
            if (instance.defaults.httpsAgent === config.httpsAgent) {
                delete config.httpsAgent;
            }

            return new Promise((resolve, reject) => {
                try {
                    setTimeout(() => {
                        resolve(config);
                    }, config.retryInterval);
                } catch (ex) {
                    reject(error, ex);
                }
            }).then(
                (conf) => {
                    conf.retryCallback(error, conf.retryCount);

                    return instance(conf);
                },
                (error) => Promise.reject(error)
            );
        }

        return Promise.reject(error);
    }
);

/* API CALLS */

/**
 * Sends a request to the URL endpoint using the http method GET.
 * The getData parameter will automatically be http encoded.
 * @param {string} link The URL to call
 * @param {object} getData The request parameters to include in the URL
 * @returns {axios.Promise} The result of the call
 */
const get = (link, getData = {}) => {
    return instance
        .get(link, {
            params: {
                ...getData
            }
        })
        .then((response) => Promise.resolve(response.data));
};

const getBinary = (url) => {
    return instance
        .get(url, {
            responseType: 'arraybuffer'
        })
        .then((response) => Buffer.from(response.data || '', 'base64'));
};

/**
 * Sends a request to the URL endpoint using the http method GET .
 * @param {string} URL to call
 * @returns {object} file content and response headers
 */

const getBinaryWithHeaders = (url) => {
    return instance
        .get(url, {
            responseType: 'arraybuffer'
        })
        .then((response) => {
            const fileData = {
                fileContent: Buffer.from(response.data || '', 'base64'),
                fileHeaders: response.headers
            };
            return Promise.resolve(fileData);
        });
};

/**
 * Sends a request to the URL endpoint using the http method POST.
 * @param {string} link The URL to call
 * @param {object} postData The data to set as the request body.
 * @param {config} config The request-individual config. Request-specific retry strategies can be defined here.
 * @returns {Promise} The result of the request.
 */
const post = (link, postData, config = {}) => {
    return instance
        .post(link, postData, config)
        .then((response) => Promise.resolve(response.data));
};

/**
 * Sends a request to the URL endpoint using the http method PUT.
 * The getData parameter will automatically be http encoded.
 * @param {string} link The URL to call
 * @param {object} putData The request parameters to include in the URL
 * @param {config} config The request-individual config. Request-specific retry strategies can be defined here.
 * @returns {axios.Promise} The result of the call
 */
const put = (link, putData, config) => {
    return instance
        .put(link, putData, config)
        .then((response) => Promise.resolve(response.data));
};

/**
 * Sends a request to the URL endpoint using the http method PATCH.
 * The getData parameter will automatically be http encoded.
 * @param {string} link The URL to call
 * @param {object} patchData The request parameters to include in the URL
 * @param {config} config The request-individual config. Request-specific retry strategies can be defined here.
 * @returns {axios.Promise} The result of the call
 */
const patch = (link, patchData, config) => {
    return instance
        .patch(link, patchData, config)
        .then((response) => Promise.resolve(response.data));
};

/**
 * Sends a request to the URL endpoint using the http method delete.
 * Used delete_fn instead of simply delete because of JavaScript Reserved Words.
 *
 * @param {string} link The URL to call
 * @param {config} config The request-individual config. Request-specific retry strategies can be defined here.
 * @returns {axios.Promise} The result of the call
 */
const delete_fn = (link, config = {}) => {
    return instance
        .delete(link, config)
        .then((response) => Promise.resolve(response.data));
};

export default {
    setAuthAccessToken,
    removeCommonHeader,
    setApiBasePath,
    setApiVersionConfig,
    mergeIntoDefaultHeaders,
    mergeConfigToCurrentRequestConfig,
    resetCurrentRequestConfig,
    get,
    getBinary,
    getBinaryWithHeaders,
    post,
    put,
    patch,
    delete: delete_fn
};
