import React from 'react';
import TextOnlyBaseInputWidget from './TextOnlyBaseInputWidget';
import i18nUtils from 'lib/i18nUtils';
import PropTypes from 'prop-types';

/**
 * Matches a specific webstring key with the same key in the given StringResources
 * and returns the matching webstring.
 * @param props
 * @returns {*}
 */
export const TextOnlyDisplayWebstringWidget = (props) => {
    const {
        formContext: {stringResources},
        value
    } = props;
    return (
        <TextOnlyBaseInputWidget
            id={props.id}
            value={i18nUtils.getTranslatedWebStringKey(stringResources, value)}
        />
    );
};

TextOnlyDisplayWebstringWidget.propTypes = {
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
};

export default TextOnlyDisplayWebstringWidget;
