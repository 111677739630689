import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {wrapInDispatch} from 'store/actions/actionUtils';
import {
    fetchNewPreCheck,
    updatePreCheck,
    showPreCheckErrors,
    clearPreCheckErrors
} from 'store/actions/preCheckActions';
import {PreCheckForm} from 'components/tpForm/TpForm';
import Loader from 'components/loader/CircularLoader';
import NotificationMessage from 'domain/NotificationMessage';
import NotificationPanel from 'components/notificationPanel/NotificationPanel';
import ProcessLayout from '../common/ProcessLayout';
import urls from 'routes/urls';

export class PreCheckPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {}
        };
    }
    static propTypes = {
        preCheck: PropTypes.object,
        isPreCheckLoading: PropTypes.bool.isRequired,

        initProcess: PropTypes.func.isRequired,
        showErrors: PropTypes.func.isRequired,
        hideErrors: PropTypes.func.isRequired,
        updatePreCheck: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,

        notifications: PropTypes.arrayOf(
            PropTypes.instanceOf(NotificationMessage)
        )
    };

    static defaultProps = {
        preCheck: null,
        notifications: []
    };

    componentDidMount() {
        this.props.initProcess();
        this.props.hideErrors();
    }

    handleError = (errors) => {
        /* set in webpack.config for testing back-end validation errors */
        if (process.env.DISABLE_FE_FORM_VALIDATION) {
            if (errors.length > 0) {
                this.handleSubmit();
            }
        } else {
            this.props.showErrors(errors);
        }
    };

    handleChange = (formData) => {
        this.setState({
            formData: formData
        });
    };

    handleSubmit = () => {
        this.props.hideErrors();
        this.props
            .updatePreCheck(this.props.preCheck.id, this.state.formData)
            .then((response) => {
                // if isToBeVetted is true go to info page, if not go to dashboard
                const route = `${
                    response.payload.isToBeVetted ? urls.PROPOSAL_INFO_URL : '/'
                }`;
                this.props.history.push(route);
            });
    };

    render() {
        const {preCheck, isPreCheckLoading} = this.props;

        if (!preCheck || isPreCheckLoading) {
            return <Loader overlay={true} />;
        }

        const contentWebStringKeyBase = `tp.process.precheck`;

        const preCheckForm = (
            <PreCheckForm
                id={preCheck.id.toString()}
                isPreCheckForm={true}
                schema={preCheck.formDefinition.formSchema}
                uiSchema={preCheck.formDefinition.uiSchema}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                onError={this.handleError}
            />
        );

        const errorContent = (
            <NotificationPanel
                title="tp.errormsg.validation.paneltitle"
                notifications={this.props.notifications}
            />
        );

        return (
            <ProcessLayout
                contentWebStringKeyBase={contentWebStringKeyBase}
                formPanel={preCheckForm}
                errorPanel={errorContent}
                actionPanel={null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const {preCheck} = state;

    return {
        preCheck: preCheck.preCheck,
        isPreCheckLoading: preCheck.isPreCheckLoading,
        notifications: preCheck.preCheckErrors
    };
};

const mapDispatchToProps = (dispatch) => ({
    initProcess: wrapInDispatch(fetchNewPreCheck, dispatch),
    updatePreCheck: wrapInDispatch(updatePreCheck, dispatch),
    showErrors: wrapInDispatch(showPreCheckErrors, dispatch),
    hideErrors: wrapInDispatch(clearPreCheckErrors, dispatch)
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreCheckPage)
);
