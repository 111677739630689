import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const LabelForUploadFileField = ({title, required}) => {
    const requiredFieldClass = required ? ' bk-cs-form-label--required' : '';
    return (
        <label
            id={`fileupload__label::${title}`}
            className={`bk-cs-form-label bk-cs-field-label${requiredFieldClass}`}>
            <WebString
                className="bk-cs-form-label__text"
                webStringKey={title}
            />
        </label>
    );
};

LabelForUploadFileField.propTypes = {
    title: PropTypes.string.isRequired,
    required: PropTypes.bool
};

LabelForUploadFileField.defaultProps = {
    required: false
};

export default LabelForUploadFileField;
