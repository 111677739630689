import {handle} from 'redux-pack';

const LOAD_ALL_BUSINESSPARTNERS = 'LOAD_ALL_BUSINESSPARTNERS';
const LOAD_BUSINESSPARTNER = 'LOAD_BUSINESSPARTNER';
const RECHECK_BUSINESSPARTNER = 'RECHECK_BUSINESSPARTNER';
const UPDATE_CORE_DATA = 'UPDATE_CORE_DATA';
export const ACTION_TYPES = {
    LOAD_ALL_BUSINESSPARTNERS: LOAD_ALL_BUSINESSPARTNERS,
    LOAD_BUSINESSPARTNER,
    RECHECK_BUSINESSPARTNER,
    UPDATE_CORE_DATA
};

const defaultState = {
    businessPartnersTable: {
        currentPage: 0,
        businessPartners: [],
        totalPages: 0
    },
    allBusinessPartnersLoading: false,
    businessPartner: {},
    isBusinessPartnerLoading: false
};

const businessPartnerSectionReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_ALL_BUSINESSPARTNERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    allBusinessPartnersLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    allBusinessPartnersLoading: false,
                    businessPartnersTable: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    allBusinessPartnersLoading: false
                })
            });
        case LOAD_BUSINESSPARTNER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isBusinessPartnerLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    isBusinessPartnerLoading: false,
                    businessPartner: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isBusinessPartnerLoading: false
                })
            });
        case RECHECK_BUSINESSPARTNER:
            return {
                ...state
            };
        case UPDATE_CORE_DATA:
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    businessPartner: payload
                })
            });
        default:
            return state;
    }
};

export default businessPartnerSectionReducer;
