import PropTypes from 'prop-types';
import React from 'react';
import i18nUtils from 'lib/i18nUtils';
import WebString from 'components/webString/WebString';
import {connect} from 'react-redux';
import defaultAppValues from 'lib/defaultAppValues';
import * as settingsActions from 'store/actions/settingsActions';
import {wrapInDispatch} from 'store/actions/actionUtils';

export const LocaleSelection = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    const selectedLocale =
        props.localeList.find((key) => key === props.locale) ||
        props.localeList[0];

    const localeListWithValues = props.localeList.map((locale) => {
        const localeKey = `cs.text.language.${locale
            .replace('-', '')
            .toLowerCase()}`;

        return [
            locale,
            i18nUtils.getTranslatedWebStringKey(
                props.stringResources,
                localeKey
            )
        ];
    });

    return (
        <>
            <span className="bk-cs-margin-horiz--sm">
                <WebString webStringKey="cs.text.selectedlanguage" />
            </span>
            <select
                id="footer__select-language"
                value={selectedLocale}
                onChange={handleChange}
                className="bk-cs-select--small">
                {localeListWithValues.map(([key, value], i) => (
                    <option key={`${i}-${key}`} value={key}>
                        {value}
                    </option>
                ))}
            </select>
        </>
    );
};

LocaleSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired,
    locale: PropTypes.string,
    localeList: PropTypes.arrayOf(PropTypes.string)
};

LocaleSelection.defaultProps = {
    locale: defaultAppValues.defaultLocale,
    localeList: [defaultAppValues.defaultLocale]
};

const mapStateToProps = ({settings, sessionContext}) => {
    return {
        stringResources: settings.i18n.stringResources,
        locale: settings.i18n.locale,
        localeList: sessionContext.account.locales
    };
};

const mapDispatchToProps = (dispatch) => ({
    onChange: wrapInDispatch(settingsActions.loadGlobalStrings, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LocaleSelection);
