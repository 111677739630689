import {handle} from 'redux-pack';

const FETCH_NEW_PRECHECK = 'FETCH_NEW_PRECHECK';
const UPDATE_PRECHECK = 'UPDATE_PRECHECK';
const SHOW_PRECHECK_ERRORS = 'SHOW_PRECHECK_ERRORS';
const HIDE_PRECHECK_ERRORS = 'HIDE_PRECHECK_ERRORS';

export const ACTION_TYPES = {
    FETCH_NEW_PRECHECK,
    UPDATE_PRECHECK,
    SHOW_PRECHECK_ERRORS,
    HIDE_PRECHECK_ERRORS
};

const defaultState = {
    preCheck: undefined,
    isPreCheckLoading: false,
    preCheckErrors: []
};

const preCheckReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FETCH_NEW_PRECHECK:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isPreCheckLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    isPreCheckLoading: false,
                    preCheck: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isPreCheckLoading: false
                })
            });

        case UPDATE_PRECHECK:
            return {
                ...state,
                preCheck: undefined,
                preCheckErrors: []
            };

        case SHOW_PRECHECK_ERRORS:
            return {
                ...state,
                preCheckErrors: action.errors
            };

        case HIDE_PRECHECK_ERRORS:
            return {
                ...state,
                preCheckErrors: []
            };

        default:
            return state;
    }
};

export default preCheckReducer;
