import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import i18nUtils from 'lib/i18nUtils';
import timeUtils from 'lib/timeUtils';

export const DateRangeFilter = (props) => {
    const handleChange = (startDate, endDate) => {
        props.onChange({
            startDate,
            endDate
        });
    };

    const {
        column: {id},
        filter,
        locale,
        stringResources
    } = props;

    let startDate = null;
    let endDate = null;

    if (filter) {
        startDate = filter.value.startDate;
        endDate = filter.value.endDate;
    }

    const placeholderTextStartDate = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        'cs.text.startdate'
    );
    const placeholderTextEndDate = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        'cs.text.enddate'
    );

    const generalProps = {
        showMonthDropdown: true,
        showYearDropdown: true,
        peekNextMonth: true,
        autoComplete: 'off',
        dropdownMode: 'select',
        dateFormat: timeUtils.dateFnsFormat.P,
        locale,
        startDate,
        endDate
    };
    return (
        <>
            <DatePicker
                id={`${id}Start`}
                selected={startDate}
                selectsStart
                onChange={(value) => handleChange(value, endDate)}
                placeholderText={placeholderTextStartDate}
                {...generalProps}
            />
            <DatePicker
                id={`${id}End`}
                selected={endDate}
                minDate={startDate}
                selectsEnd
                onChange={(value) => handleChange(startDate, value)}
                placeholderText={placeholderTextEndDate}
                {...generalProps}
            />
        </>
    );
};
DateRangeFilter.propTypes = {
    column: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired,
    locale:
        PropTypes.string
            .isRequired /* one of the imported and registered locale from date-fns library (see in lib/reactDatePickerLocale.js) */,
    filter: PropTypes.shape({
        value: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date)
        }).isRequired
    })
};

DateRangeFilter.defaultProps = {
    filter: null
};

const mapStateToProps = ({
    settings: {
        i18n: {stringResources, locale}
    }
}) => ({
    stringResources,
    locale
});

export default connect(mapStateToProps)(DateRangeFilter);
