import {saveAs} from 'file-saver';
import timeUtils from 'lib/timeUtils';

/**
 * @param {string} fileName
 * @param {string} csvData
 *
 * */

const saveDataToCsvFile = (fileName, csvData) => {
    const csvFile = new Blob([csvData], {
        type: 'text/csv;charset=utf-8;'
    });
    saveAs(
        csvFile,
        `${fileName}_${timeUtils.getFormattedUnixTimestring()}.csv`
    );
};

export default {
    saveDataToCsvFile
};
