import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox widget where we need to be able to validate a certain value,
 * e.g. a check list where the user must confirm that the items have been
 * completed. This is not possible with a regualar checkbox widget.
 */
export default class TpCheckBoxRequiredWidget extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOf(['true', 'false']),
        disabled: PropTypes.bool
    };

    static defaultProps = {
        value: 'false',
        disabled: false
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.state = {checked: this.props.value === 'true'};
    }

    /**
     * Toggles the state for checked (true and false).
     * Toggles the value of the property value ('true' and 'false').
     * The value of the value property must be saved as a string because of validation using pattern.
     */
    handleChange() {
        this.setState({checked: !this.state.checked}, () => {
            this.props.onChange(this.state.checked ? 'true' : 'false');
        });
    }

    render() {
        return (
            <div className="form-control bk-cs-no-border bk-cs-no-shadow">
                <input
                    id={this.props.id}
                    type="checkbox"
                    disabled={this.props.disabled}
                    checked={this.state.checked}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}
