import {ACTION_TYPES as ALL_PRECHECK_ACTION_TYPES} from '../reducers/preCheckReducer';
import HTTPClient from 'lib/HTTPClient';
import {transformOutgoingFormData} from 'lib/OutgoingSchemaTransformer';

const fetchNewPreCheck = (meta) => {
    return {
        type: ALL_PRECHECK_ACTION_TYPES.FETCH_NEW_PRECHECK,
        promise: HTTPClient.post(`pre-checks`),
        meta
    };
};

const updatePreCheck = (preCheckId, preCheckData, meta) => {
    return {
        type: ALL_PRECHECK_ACTION_TYPES.UPDATE_PRECHECK,
        promise: HTTPClient.put(`pre-checks/${preCheckId}`, {
            formData: transformOutgoingFormData(preCheckData)
        }),
        meta
    };
};

const showPreCheckErrors = (errors, validationErrorType = '') => ({
    type: ALL_PRECHECK_ACTION_TYPES.SHOW_PRECHECK_ERRORS,
    errorsType: validationErrorType,
    errors
});

const clearPreCheckErrors = () => ({
    type: ALL_PRECHECK_ACTION_TYPES.HIDE_PRECHECK_ERRORS
});

export {
    fetchNewPreCheck,
    updatePreCheck,
    showPreCheckErrors,
    clearPreCheckErrors
};
