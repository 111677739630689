const convertUserDTOWithMultipleRolesToUserData = (userDTO) => {
    if (!userDTO) return null;
    const rolesFormData = {};
    const singleRightsData = {};
    const {
        firstName,
        lastName,
        active,
        status,
        autoRegistered,
        id,
        created,
        updated,
        email
    } = userDTO;

    userDTO.roles.forEach((r) => {
        const role = r.roleConfiguration.role;
        const scope = {};
        r.scope.forEach((sc) => {
            Object.assign(scope, {[sc.dimension]: sc.values});
        });

        r.roleConfiguration.singleRights.forEach((sr) => {
            Object.assign(singleRightsData, {[sr]: true});
        });
        Object.assign(rolesFormData, {
            [role]: {
                isRoleActive: true,
                scope
            }
        });
    });

    return {
        generalFormData: {
            id,
            created,
            updated,
            isActive: active,
            status,
            autoRegistered,
            firstName,
            lastName,
            emailAddress: email
        },
        rolesFormData,
        singleRightsData
    };
};

const convertUserDataToUserDTOWithMultipleRoles = (userData) => {
    const {
        generalFormData: {firstName, lastName, emailAddress, isActive},
        rolesFormData,
        singleRightsData
    } = userData;

    const roles = Object.keys(rolesFormData)
        .filter((r) => rolesFormData[r].isRoleActive)
        .map((r) => {
            const userDataScope = rolesFormData[r].scope;
            const scope = Object.keys(userDataScope).map((key) => ({
                dimension: key,
                values: userDataScope[key]
            }));

            return {
                scope,
                roleConfiguration: {
                    role: r,
                    singleRights: Object.keys(singleRightsData).filter(
                        (singleRight) => singleRightsData[singleRight]
                    )
                }
            };
        });
    return {
        firstName,
        surName: lastName,
        emailAddress,
        active: isActive,
        roles
    };
};

export default {
    convertUserDTOWithMultipleRolesToUserData,
    convertUserDataToUserDTOWithMultipleRoles
};
