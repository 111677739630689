import HTTPClient from './HTTPClient';

const defaultAppSettings = {
    AuthAuthorityURI: 'https://tp-env-01.bk-intra.net/cl',
    DashboardURL: null,
    ApiVersionConfig: {
        defaultVersion: '2.0',
        calls: [
            {
                name: 'getAllProposals',
                method: 'get',
                urlPattern: '/proposals$',
                version: '3.0'
            },
            {
                name: 'getAssessables',
                method: 'get',
                urlPattern: '/assessable-proposals$',
                version: '3.0'
            },
            {
                name: 'addApplication',
                method: 'post',
                urlPattern: '/proposals$',
                version: '2.0'
            },
            {
                name: 'getApplicationById',
                method: 'get',
                urlPattern: '/proposals/\\d+',
                version: '2.0'
            },
            {
                name: 'deliverFormToBusinessPartner',
                method: 'post',
                urlPattern:
                    '/proposals/\\d+/commands/deliver-form-to-business-partner',
                version: '1.0'
            },
            {
                name: 'cancelProposal',
                method: 'post',
                urlPattern: '/proposals/\\d+/commands/abort-proposal',
                version: '2.0'
            },
            {
                name: 'getAllStringsForSupportedLocales',
                method: 'get',
                urlPattern: '/strings$',
                version: '1.0'
            },
            {
                name: 'getAllStringsForLocale',
                method: 'get',
                urlPattern: '/strings/.+',
                version: '1.0'
            },
            {
                name: 'getTasks',
                method: 'get',
                urlPattern: '/proposals/\\d+/tasks$',
                version: '2.0'
            },
            {
                name: 'getTaskById',
                method: 'get',
                urlPattern: '/proposals/\\d+/tasks/.+',
                version: '2.0'
            },
            {
                name: 'updateTask',
                method: 'put',
                urlPattern: '/proposals/\\d+/tasks/.+',
                version: '2.0'
            },
            {
                name: 'getAllAlerts',
                method: 'get',
                urlPattern: '/alerts$',
                version: '3.0'
            }
        ]
    },
    isTestSystem: false,
    isShowAllWebStringsButtonActivated: false,
    oidcClientId: 'client',
    loginUrlRedirects: {},
    customHeaders: {
        'App-Type': 'tp'
    }
};

/**
 * Load the external application settings file and return its contents.
 * This should only be used if the settings are different from the defaults in the variables declared above in this file.
 * E.g. for production / testing servers, or where the PC's IP is needed instead of 'localhost'.
 * For local development environment, this file is not required, so an empty object is returned if the file does not exist.
 *
 * @returns {Object} settings loaded from file, or empty object if file not present.
 */
async function fetch(appContextPath) {
    let response = {};
    try {
        response = await HTTPClient.get(
            `${appContextPath}conf/fe-settings.json`
        );
    } catch (exceptionforConfigFetching) {
        try {
            response = await HTTPClient.get(
                `${appContextPath}conf/tp-fe-settings.json`
            );
        } catch (exceptionForOldSettingsFileFetching) {}
    }

    return {
        ...defaultAppSettings,
        ApiBasePath: '/tp/api/',
        ...response
    };
}

/**
 * Export system relevant settings.
 * Exports fetchSettings which calls the system_settings
 * endpoint to retrieve the server-specific configuration file.
 * SystemSettings also contains the default settings of
 * the client and partner app.
 * @type {object}
 */
export const SystemSettings = {
    fetch,
    defaultAppSettings
};
