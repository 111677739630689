import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import TpImage from 'components/common/TpImage';

/**
 * Returns the header logo element depending on the provided
 * account displayName. If account displayName is present, the name of the company
 * will be shown.
 * Otherwise the Business Keeper AG logo will be shown.
 * The content will be rendered as a {@link Link}.
 * */

const HeaderLogo = () => {
    const tenantAccount = useSelector((state) => state.sessionContext.account);

    const linkContent = tenantAccount ? (
        tenantAccount.displayName
    ) : (
        <>
            <TpImage
                className="bk-cs-max-width-height--100pc"
                src={'bk-logo-w.png'}
                alt="Company Name"
            />
            <span>
                BKMS<sup>®</sup> CS
            </span>
        </>
    );

    return (
        <Link className="bk-cs-header-link bk-cs-bold" to="/">
            {linkContent}
        </Link>
    );
};

export default HeaderLogo;
