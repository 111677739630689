import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import StringPlaceholder from 'lib/StringPlaceholder';

/**
 * Custom Field for RJSF to render a block of HTML in a form.
 * Using input fields values (formData) data can be passed to WebString for {name} placeholders.
 * If the value has a specific type e.g. CURRENCY it can be defined in uiSchema:  'webStringArgTypes':{ 'name': 'CURRENCY'}.
 * For more types see info in class StringPlaceholder.ArgumentTypes.
 */
const MarkupBlockField = (props) => {
    if (!props.uiSchema.webStringKey) {
        return null;
    }

    const {formData, uiSchema} = props;

    const placeholders = Object.keys(formData).map((key) => {
        // find argument type in uiSchema under webStringArgTypes for the key in formData
        const argumentType = uiSchema.webStringArgTypes
            ? uiSchema.webStringArgTypes[key]
            : null;
        return new StringPlaceholder(key, formData[key], argumentType);
    });

    return (
        <div className="bk-tp-field-markup">
            <WebString
                webStringKey={uiSchema.webStringKey}
                placeholder={placeholders}
            />
        </div>
    );
};

MarkupBlockField.propTypes = {
    formData: PropTypes.object,
    schema: PropTypes.shape({
        properties: PropTypes.object
    }),
    uiSchema: PropTypes.shape({
        webStringKey: PropTypes.string
    })
};

MarkupBlockField.defaultProps = {
    formData: {},
    schema: {
        properties: {}
    },
    uiSchema: {
        webStringKey: null
    }
};

export default MarkupBlockField;
