import React from 'react';
import PropTypes from 'prop-types';

/**
 * Plugin replacement for RJSF ObjectField's DefaultTemplate.
 * Gets around the problem of Firefox unable to print fieldsets of
 * more than 1 page.
 * @param props
 * @returns {*}
 * @constructor
 */
export const FirefoxObjectFieldTemplate = (props) => {
    const {TitleField, DescriptionField} = props;
    return (
        <div>
            {(props.uiSchema['ui:title'] || props.title) && (
                <TitleField
                    id={`${props.idSchema.$id}__title`}
                    title={props.title || props.uiSchema['ui:title']}
                    required={props.required}
                    formContext={props.formContext}
                />
            )}
            {props.description && (
                <DescriptionField
                    id={`${props.idSchema.$id}__description`}
                    description={props.description}
                    formContext={props.formContext}
                />
            )}
            {props.properties.map((prop) => prop.content)}
        </div>
    );
};

FirefoxObjectFieldTemplate.propTypes = {
    TitleField: PropTypes.func.isRequired,
    DescriptionField: PropTypes.func.isRequired
};
