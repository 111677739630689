import StringPlaceholder from '../lib/StringPlaceholder';

/**
 * @class
 * @classdesc  Class representing a NotificationMessage using in NotificationPanel.
 */
export default class NotificationMessage {
    /**
     *
     * @param {String} message
     * @param {StringPlaceholder[] || StringPlaceholder } placeholder: values to be replaced in message
     * @param {String} title
     * @param {String} anchor
     */
    constructor(message, placeholder = null, title = null, anchor = null) {
        this.message = message;
        this.placeholder = placeholder;
        this.title = title;
        this.anchor = anchor;
    }
}
