import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as businessPartnersActions from 'store/actions/businessPartnersActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import Loader from 'components/loader/CircularLoader';
import BusinessPartnerDetailsDisplay from './BusinessPartnerDetailsDisplay';
import WebString from 'components/webString/WebString';
import TpButton from 'components/common/buttons/TpButton';
import TpPopup from 'components/common/TpPopup';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import AccountFeatureTypes from 'types/AccountFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserFeatureTypes from 'types/UserFeatureTypes';
import BusinessPartnerEditCoreData from './BusinessPartnerEditCoreData';

const triggerStates = {
    enabled: 'ENABLED',
    disabled: 'DISABLED',
    hidden: 'HIDDEN'
};

export class BusinessPartnerDetailsPage extends React.Component {
    static propTypes = {
        businessPartner: PropTypes.object.isRequired,
        isBusinessPartnerLoading: PropTypes.bool.isRequired,
        initProcess: PropTypes.func.isRequired,
        recheckBusinessPartner: PropTypes.func.isRequired,
        getBusinessPartner: PropTypes.func.isRequired,
        stringResources: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.object.isRequired
        }).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            showRecheckPopUp: false,
            showEditCoreDataForm: false
        };

        this.recheckPopupConfig = {
            headerInfo: {
                webStringKey: 'tp.businesspartnerdetails.recheck.confirm.title',
                type: 'warning',
                showCloseButton: true
            },
            buttons: [
                {
                    webStringKey: 'cs.text.answer.no',
                    id: `btn-cancel-recheck-${this.props.businessPartner.id}-no`
                },
                {
                    webStringKey: 'cs.text.answer.yes',
                    id: `btn-approve-recheck-${this.props.businessPartner.id}-yes`,
                    action: this.triggerRecheckBusinessPartner
                }
            ]
        };
    }

    componentDidMount() {
        this.props.initProcess();
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleRecheckButtonClick = () => {
        this.setState({showRecheckPopUp: true});
    };

    handleEditButtonClick = () => {
        this.setState({
            showEditCoreDataForm: true
        });
    };

    closeEditForm = () => {
        this.setState({
            showEditCoreDataForm: false
        });
    };

    closeRecheckPopup = () => {
        this.setState({showRecheckPopUp: false});
    };

    triggerRecheckBusinessPartner = () => {
        const {
            businessPartner: {id}
        } = this.props;

        this.props.recheckBusinessPartner([id]).then((response) => {
            if (!response.error) {
                this.props.getBusinessPartner(id);
            }
        });
    };

    render() {
        const {
            businessPartner,
            stringResources,
            isBusinessPartnerLoading
        } = this.props;

        const {showRecheckPopUp, showEditCoreDataForm} = this.state;

        if (isBusinessPartnerLoading) {
            return <Loader />;
        }

        return (
            <div className="tp-business-partner-details">
                <h1 className="bk-cs-h1">
                    <WebString webStringKey="tp.businesspartnerdetails.title" />
                </h1>
                {showRecheckPopUp ? (
                    <TpPopup
                        {...this.recheckPopupConfig}
                        onClose={this.closeRecheckPopup}>
                        <WebString webStringKey="tp.businesspartnerdetails.recheck.confirm.description" />
                    </TpPopup>
                ) : null}
                <UserFeatureToggle
                    requiredFeatureOneOf={[UserFeatureTypes.ASSESSMENT]}>
                    <div className="tp-business-partner-button-panel">
                        {!showEditCoreDataForm ? (
                            <AccountFeatureToggle
                                requiredAccountFeature={
                                    AccountFeatureTypes.EDIT_CORE_DATA
                                }>
                                <TpButton
                                    id="tp-business-partner-edit-button"
                                    onClick={this.handleEditButtonClick}
                                    webStringKey="tp.businesspartnerdetails.editbutton"
                                    className="bk-cs-margin-r--small"
                                />
                            </AccountFeatureToggle>
                        ) : null}
                        {businessPartner.retriggerAction !==
                        triggerStates.hidden ? (
                            <TpButton
                                id="tp-business-partner-recheck-button"
                                onClick={this.handleRecheckButtonClick}
                                webStringKey="tp.businesspartnerdetails.recheckbutton"
                                className="bk-cs-float-right"
                                disabled={
                                    businessPartner.retriggerAction ===
                                    triggerStates.disabled
                                }
                            />
                        ) : null}
                    </div>
                </UserFeatureToggle>
                {!showEditCoreDataForm ? (
                    <BusinessPartnerDetailsDisplay
                        businessPartner={businessPartner}
                        stringResources={stringResources}
                    />
                ) : null}

                {showEditCoreDataForm ? (
                    <BusinessPartnerEditCoreData
                        businessPartner={businessPartner}
                        onCloseEditForm={this.closeEditForm}
                    />
                ) : null}

                <TpButton
                    id="tp-business-partner-back-button"
                    onClick={this.goBack}
                    webStringKey="cs.button.back"
                    className="bk-cs-margin-top--lg"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    businessPartner: state.businessPartnerSection.businessPartner,
    isBusinessPartnerLoading:
        state.businessPartnerSection.isBusinessPartnerLoading,
    stringResources: state.settings.i18n.stringResources
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initProcess() {
        dispatchProps.getBusinessPartner(
            ownProps.match.params.businessPartnerId
        );
    }
});

const mapDispatchToProps = (dispatch) => ({
    getBusinessPartner: wrapInDispatch(
        businessPartnersActions.getBusinessPartnerById,
        dispatch
    ),
    recheckBusinessPartner: wrapInDispatch(
        businessPartnersActions.recheckBusinessPartner,
        dispatch
    )
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(BusinessPartnerDetailsPage)
);
