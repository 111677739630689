import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {initProcess} from 'store/actions/proposalActions';
import * as processActions from 'store/actions/processActions';
import * as snackActions from 'store/actions/snackActions';
import {convertSelectValue} from 'lib/proposalUtils';
import i18nUtils from 'lib/i18nUtils';
import HTTPClient from 'lib/HTTPClient';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import WebString from 'components/webString/WebString';
import ScopeElements from './ScopeElements';
import OwnerElement from './OwnerElement';
import ProposalClassificationButtonPanel from './ProposalClassificationButtonPanel';

export const EditOwnerScopeField = (props) => {
    const {stringResources, proposal, view, scopesAndRoles} = useSelector(
        (state) => ({
            stringResources: state.settings.i18n.stringResources,
            scopesAndRoles: state.sessionContext.user.userRoleAndScope,
            proposal: state.process.proposal,
            view: state.process.view
        })
    );
    const {id, scope, owner, imported} = proposal;

    const [
        proposalClassificationData,
        setProposalClassificationData
    ] = useState({});

    const [owners, setOwners] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState(
        convertSelectValue(owner)
    );
    const [selectedScope, setSelectedScope] = useState(scope || []);
    const [saveError, setSaveError] = useState(false);
    const [ownerScopeError, setOwnerScopeError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const getOwnersBasedOnScopeSelected = async (scopeSelected) => {
            let ownersResponse;
            try {
                ownersResponse = await HTTPClient.get(`users`, {
                    ...scopeSelected,
                    role: 'APPLICANT',
                    active: true
                });
                setOwners(ownersResponse.users);
            } catch (error) {
                dispatch({type: 'GET_OWNERS_FAILED', error});
            }
        };
        getOwnersBasedOnScopeSelected(selectedScope);
    }, [selectedScope]);

    useEffect(() => {
        if (owners.length !== 0) {
            canPreviousOwnerViewProposal();
        }
    }, [owners]);

    useEffect(() => {
        const saveChanges = async () => {
            try {
                if (Object.entries(proposalClassificationData).length) {
                    await HTTPClient.put(
                        `proposals/${id}`,
                        proposalClassificationData
                    );
                    props.onClosePopup();
                    if (imported === true) {
                        dispatch(processActions.getImportedProposal(id));
                    } else {
                        dispatch(initProcess(id, view));
                    }
                    dispatch(
                        snackActions.createSnack({
                            id: `snack-success-proposal-classification-${id}`,
                            snackType: TpSnackbarTypes.SUCCESS,
                            autoHideDuration: 4000,
                            message: `${i18nUtils.getTranslatedWebStringKey(
                                stringResources,
                                'tp.proposalclassification.notification.message.success'
                            )}`
                        })
                    );
                }
            } catch (error) {
                dispatch({type: 'SAVE_CLASSIFICATION_FAILED', error});
                setSaveError(true);
            }
        };
        saveChanges();
    }, [proposalClassificationData]);

    const getScopes = () => {
        const dimensionsWithUniqueValues = new Map();
        if (scopesAndRoles) {
            Object.values(scopesAndRoles).forEach((roles) => {
                roles.forEach(({dimension, values}) => {
                    const valuesToSave = dimensionsWithUniqueValues.has(
                        dimension
                    )
                        ? [
                              ...dimensionsWithUniqueValues.get(dimension),
                              ...values
                          ]
                        : values;

                    dimensionsWithUniqueValues.set(
                        dimension,
                        Array.from(new Set(valuesToSave))
                    );
                });
            });
        }
        return Array.from(
            dimensionsWithUniqueValues,
            ([dimension, values]) => ({
                dimension,
                values
            })
        );
    };

    const onScopeChange = (selectedScopeValue) => {
        setSelectedScope(selectedScopeValue);
    };

    const onOwnerChange = (ownerValue) => {
        setSelectedOwner(ownerValue);
    };

    const discardChanges = () => {
        setOwnerScopeError(false);
        setSelectedScope(scope);
        setSelectedOwner(convertSelectValue(owner));
    };

    const getOwnerIdOfSelectedOwner = (ownerValue) => {
        const foundOwner = owners.find((owner) => owner.email === ownerValue);
        if (foundOwner !== undefined) {
            return foundOwner.id;
        }
        return null;
    };

    const onSaveChangesClick = () => {
        setProposalClassificationData({
            ...proposalClassificationData,
            classification: selectedScope,
            ownerId: getOwnerIdOfSelectedOwner(selectedOwner.value)
        });
    };

    const isOwnerSelectedWithinScope = () => {
        return !owners.find((owner) => owner.email === selectedOwner.value);
    };

    const canPreviousOwnerViewProposal = () => {
        if (owner) {
            const foundOwner = !owners.find(
                (ownerItem) => ownerItem.email === owner
            );
            setOwnerScopeError(foundOwner);
        }
    };

    const shouldDisplayErrorMessage = () => {
        if (ownerScopeError === true && isOwnerSelectedWithinScope()) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="tp-proposal-classification__form-div">
                <ScopeElements
                    selectedScope={selectedScope}
                    scopes={getScopes()}
                    onScopeChange={onScopeChange}
                    stringResources={stringResources}
                />
                <OwnerElement
                    selectedOwner={selectedOwner}
                    owners={owners}
                    stringResources={stringResources}
                    onOwnerChange={onOwnerChange}
                />
                <div className="tp-proposal-classification__ownererror-div">
                    {shouldDisplayErrorMessage() ? (
                        <WebString
                            className="tp-proposal-classification__webstring-ownernotinscopeerror"
                            id="tp-proposal-classification__webstring-ownernotinscopeerror"
                            webStringKey="tp.proposalclassification.ownernotinscopeerror.error"
                        />
                    ) : null}
                </div>
            </div>
            <ProposalClassificationButtonPanel
                onSaveChangesClick={onSaveChangesClick}
                discardChanges={discardChanges}
                isSaveButtonDisabled={isOwnerSelectedWithinScope()}
            />
            {saveError ? (
                <WebString
                    className="tp-proposal-classification__webstring-saveerror"
                    id="tp-proposal-classification__webstring-saveerror"
                    webStringKey="tp.proposalclassification.save.error"
                />
            ) : null}
        </>
    );
};

EditOwnerScopeField.propTypes = {
    onClosePopup: PropTypes.func.isRequired
};

export default EditOwnerScopeField;
