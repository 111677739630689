import React from 'react';
import WebString from 'components/webString/WebString';

const getUserTableColumnsFromConfig = (usersTableColumnsConfig) => {
    return usersTableColumnsConfig.map((columnConfig) => {
        return {
            id: columnConfig.id,
            Header: <WebString webStringKey={columnConfig.webstringKey} />,
            accessor: columnConfig.accessor,
            Cell: columnConfig.cellValue,
            filterable: columnConfig.filterable,
            sortable:
                columnConfig.sortable !== undefined
                    ? columnConfig.sortable
                    : true,
            Filter: columnConfig.filterType,
            className: `tp-users__column tp-all-users-table__${columnConfig.classNameSuffix}`,
            headerClassName: `tp-all-users-table__${columnConfig.headerClassName}`,
            preventDefaultRowClick: columnConfig.preventDefaultRowClick
                ? columnConfig.preventDefaultRowClick
                : false
        };
    });
};

export {getUserTableColumnsFromConfig};
