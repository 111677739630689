import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import AlertsTable from 'components/alerts/AlertsTable';
import ProcessAlerts from 'components/alerts/ProcessAlerts';
import UserFeatureTypes from 'types/UserFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import NotFound from 'components/common/errorHandling/NotFound';

const AlertsRoute = () => {
    const {path} = useRouteMatch();
    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[UserFeatureTypes.ALERTS]}
            defaultChildren={<NotFound />}>
            <Switch>
                <Route
                    exact={true}
                    path={`${path}/:alertId`}
                    component={ProcessAlerts}
                />
                <Route path={path} component={AlertsTable} />
            </Switch>
        </UserFeatureToggle>
    );
};

export default AlertsRoute;
