import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleContainer from './CollapsibleContainer';
import DisplayDowJonesArticle from './DisplayDowJonesArticle';

const isObject = (value) => typeof value === 'object';

/**
 * Checks if the array contains a value of type 'object'.
 * If one of the values is an 'object' type it returns false, otherwise true.
 * @param {any[]} arrayValues
 * @returns {boolean}
 */
const _isArrayWithoutObjects = (arrayValues) =>
    arrayValues.every((value) => !isObject(value));

/**
 * Checks if string contains either 'http' or 'www'.
 * If the string contains one of the values 'true' will be returned. Otherwise 'false'.
 * @param text
 * @returns {boolean}
 */
const isLinkableText = (text) => {
    const linkableTextRegex = RegExp('http|www');
    return linkableTextRegex.test(text);
};

/**
 * Checks if the string is a URL path.
 * @param {string} string
 * @returns {boolean}
 */
const isURL = (string) =>
    typeof string === 'string' && string.match(/^https?:\/\//);

/**
 * Displays jsonObject in a form of HTML structure:
 * - array will be displayed in a form of a table.
 * - empty(null) will be displayed as '-'
 *
 */
export default class DisplayJsonObject extends React.Component {
    static propTypes = {
        jsonObject: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.defaultValue = '-';
    }

    shouldComponentUpdate(nextProps) {
        return this.props.jsonObject !== nextProps.jsonObject;
    }

    /**
     * Creates a clickable link based on the text and url that has been passed in.
     * if no text has been provided the url will be displayed as link text instead.
     *
     * @param {String} url
     * @param {String} text
     * @returns {ReactElement} -  html link element
     * @private
     */
    _buildUrl = (url, text = null) => {
        if (url.includes('factiva')) {
            const regexp = /^.*AN=([a-zA-Z0-9]+)&.*/gm;
            const articleId = regexp.exec(url)[1];
            const djArticleProps = {
                articleId
            };
            if (text) {
                djArticleProps.headerText = text;
            }
            return (
                <div className={'tp-link-djarticle-block'}>
                    <div className="tp-link-djarticle">{text || url}</div>
                    <DisplayDowJonesArticle {...djArticleProps} />
                </div>
            );
        } else {
            return (
                <a className="tp-json__link" href={url} target="_blank">
                    {text || url}
                </a>
            );
        }
    };

    /**
     * Checks if the header contains only '..Value' and '...Type'.
     * @param {string[]} keysForHeader - values for header
     * @returns {boolean}
     * @private
     */
    _isHeaderContainsOnlyTypeAndValue = (keysForHeader) =>
        keysForHeader.length === 2 &&
        keysForHeader[0].includes('Value') &&
        keysForHeader[1].includes('Type');

    /**
     * Goes through all objects in an array and returns all the keys (unique) found.
     *
     * * @example
     * // returns ['key1', 'key2', 'key3', 'key4']
     * _getKeysForHeader([
     * {'key1':'valueKey1', 'key2':'valueKey2'},
     * {'key1':'valueKey1', 'key3':'valueKey3'},
     * {'key1':'valueKey1', 'key4':'valueKey4'},
     * ])
     *
     * @param {object[]} arrayOfObjects
     * @returns {Array} - uniques keys for header
     * @private
     */
    _getKeysForHeader = (arrayOfObjects) => {
        const allKeys = arrayOfObjects.reduce(
            (keys, obj) => [...keys, ...Object.keys(obj)],
            []
        );
        return Array.from(new Set(allKeys));
    };

    _mapSimpleArrayDataIntoDiv = (item, key) => (
        <div key={`table-section-${key}`} className="tp-json__table-section">
            {this._getTransformedKey(item[Object.keys(item)[1]])}
            {this._getArrayHtmlStructure(item[Object.keys(item)[0]])}
        </div>
    );

    _mapHeaderKeysToTableHeader = (thItem) => (
        <th className="tp-json__table-cell" key={`head-tr-th-${thItem}`}>
            {this._getTransformedKey(thItem)}
        </th>
    );

    /**
     * Checks a string.
     * If it contains ' (http' a separate substring for the text respectively the url will be created.
     * If no specific link text is specified the url will be used instead.
     *
     * @param item
     * @returns {{text: (*|string), url: (*|string)}}
     * @private
     */
    _extractLinkTextAndUrl(item) {
        const linkIndex = item.indexOf(' (http');
        if (linkIndex === -1) {
            return {text: item, url: item};
        }
        const linkText = item.substring(0, linkIndex);
        const linkUrl = item.substring(linkIndex);
        const linkUrlRegex = / \((.*)\)$/;
        const regexResult = linkUrlRegex.exec(linkUrl);

        const text = linkText || linkUrl;
        const url = regexResult ? regexResult[1] : linkUrl;
        return {text, url};
    }

    /**
     * Checks array data.
     * If it is an empty array will display a default value.
     * If this is an array containing only simple values (not an object), values will be displayed, separated by commas, in one line.
     * Otherwise, the values will be displayed in the form of an html table.
     *
     *
     * @param {any[]} arrayData
     * @returns {(string|ReactElement)}
     * @private
     */
    _getArrayHtmlStructure = (arrayData) => {
        const arrayItemsToRender = arrayData.filter((listItem) => {
            return listItem !== null && Object.keys(listItem).length !== 0;
        });

        if (arrayItemsToRender.length === 0) {
            return null;
        }

        if (_isArrayWithoutObjects(arrayItemsToRender)) {
            return this._buildListFromArrayWithoutObjects(arrayItemsToRender);
        }

        const keysForHeader = this._getKeysForHeader(arrayItemsToRender);

        if (this._isHeaderContainsOnlyTypeAndValue(keysForHeader)) {
            return (
                <div>
                    {arrayItemsToRender.map(this._mapSimpleArrayDataIntoDiv)}
                </div>
            );
        }

        return (
            <table className="tp-json__table">
                <thead className="tp-json__table-head">
                    <tr>
                        {keysForHeader.map(this._mapHeaderKeysToTableHeader)}
                    </tr>
                </thead>
                <tbody className="tp-json__table-body">
                    {arrayItemsToRender.map((trItems, key) => (
                        <tr key={`body-tr-${key}`}>
                            {keysForHeader.map((tdItem, key) => (
                                <td
                                    key={`body-tr-td-${key}`}
                                    className="tp-json__table-cell">
                                    {this._getItemValue(trItems[tdItem])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    /**
     * Checks an array.
     * If the array has a length of 1 and the contained value isn't a linkable value the value will be returned without modifications.
     * Otherwise the values will be rendered as list items.
     *
     * @param arrayData
     * @returns {*}
     * @private
     */
    _buildListFromArrayWithoutObjects(arrayData) {
        if (arrayData.length === 1 && !isLinkableText(arrayData[0])) {
            return arrayData[0];
        }

        return (
            <ul className="tp-json__unordered-list">
                {arrayData.map(this._mapArrayItemToListItem)}
            </ul>
        );
    }

    /**
     * Checks the type of item.
     * If the item is linkable the link and link-text (if available) will be displayed as a list item.
     * If the item isn't linkable it will be displayed without changes as a list item.
     *
     * @param item
     * @param i
     * @returns {*}
     * @private
     */

    _mapArrayItemToListItem = (item, i) => {
        let content;
        if (isLinkableText(item)) {
            const {text, url} = this._extractLinkTextAndUrl(item);
            content = this._buildUrl(url, text);
        } else {
            content = <span>{item}</span>;
        }
        return (
            <li key={`li-${i}`} className="tp-json__list-item">
                {content}
            </li>
        );
    };

    /**
     * Displays json object (keys and values) using html structure.
     * For the type of object CollapsibleContainer will be used as a section separator.
     *
     * @param {object} jsonObj
     * @returns {any[]}
     * @private
     */
    _getJsonObjectHtmlStructure = (jsonObj) => {
        return Object.entries(jsonObj).map(([key, value]) => {
            if (!value) {
                return null;
            }
            if (this._isUnrenderableArray(value)) {
                return null;
            }

            if (isObject(value)) {
                return (
                    <CollapsibleContainer
                        id={`container-${key}`}
                        titleKey={this._getTransformedKey(key)}
                        isInitiallyOpen={false}
                        key={`form-${key}`}
                        className="tp-json__container"
                        labelClassName="tp-json__container-label"
                        lazilyRender={true}>
                        <div className="tp-json__container-value">
                            {this._getItemValue(value)}
                        </div>
                    </CollapsibleContainer>
                );
            }

            const itemValue = this._getItemValue(value);

            const itemValueText =
                itemValue.length > 100 ? (
                    <pre className="tp-json__pretext">{itemValue}</pre>
                ) : (
                    itemValue
                );

            return (
                <div key={`text-${key}`}>
                    <span className="tp-json__label">
                        {this._getTransformedKey(key)}:
                    </span>
                    {itemValueText}
                </div>
            );
        });
    };

    _isUnrenderableArray(value) {
        return (
            Array.isArray(value) &&
            (value.length === 0 ||
                value.every((listItem) => {
                    return (
                        listItem === null || Object.keys(listItem).length === 0
                    );
                }))
        );
    }

    /**
     * Divides the lines by capital letters. The first element starts with uppercase.
     *
     * * @example
     * // returns "Company Details"
     * _getTransformedKey('companyDetails')
     *
     * @param {string} key
     * @returns {string}
     * @private
     */
    _getTransformedKey = (key) => {
        const createdString = key.replace(/([A-Z]+)/g, ' $1').trim();
        return (
            createdString.substring(0, 1).toUpperCase() +
            createdString.substring(1, createdString.length)
        );
    };

    /**
     * Checks the type of item.
     * If the item is an empty string or null will return default value.
     * It the type of items is a type of 'object' and not an 'array' will call _getJsonObjectHtmlStructure().
     * It the type of items is an 'array' will call _getArrayHtmlStructure().
     * Otherwise, the element is returned, having checked beforehand whether it should be displayed as a link.
     *
     * @param {*} item
     * @returns {*}
     * @private
     */
    _getItemValue = (item) => {
        if (!item) {
            return this.defaultValue;
        }

        if (isURL(item)) {
            return this._buildUrl(item);
        }

        if (isObject(item)) {
            return Array.isArray(item)
                ? this._getArrayHtmlStructure(item)
                : this._getJsonObjectHtmlStructure(item);
        }

        return item;
    };

    render() {
        return (
            <div>{this._getJsonObjectHtmlStructure(this.props.jsonObject)}</div>
        );
    }
}
