import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as alertActions from 'store/actions/alertActions';
import {getAlertsTableColumns} from 'components/dashboard/utilities/tableColumns';
import TpTable from 'components/tables/TpTable';
import TableTypes from 'types/TableTypes';
import {convertToApiSearchParams} from 'components/dashboard/utilities/searchParamConverter';
import urls from 'routes/urls';

const AlertsTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {tableInfo, tableSettings, locale} = useSelector((state) => ({
        tableInfo: state.riskAlerts,
        tableSettings:
            state.sessionContext.account.tableSettings[TableTypes.ALERTS],
        locale: state.settings.i18n.locale
    }));

    const {
        isLoading: isFetchingData,
        allAlerts: data,
        searchParams,
        totalPages
    } = tableInfo;

    const {pageSize: defaultPageSize, columns} = tableSettings;

    const tableColumns = getAlertsTableColumns(columns);

    const fetchData = (reactTableSearchParams, locale) => {
        const apiSearchParams = convertToApiSearchParams(
            reactTableSearchParams,
            locale
        );
        dispatch(
            alertActions.getAllAlerts(apiSearchParams, reactTableSearchParams)
        );
    };

    const onTableRowClick = (row) => {
        history.push(`${urls.ALERTS_URL}/${row.alertsAssessmentId}`);
    };

    const mappedStateAndProps = {
        data,
        searchParams,
        totalPages,
        isFetchingData,
        locale,
        defaultPageSize,
        tableColumns,
        fetchData,
        onTableRowClick,
        titleWebstringKey: 'tp.table.alerts.text.title',
        descriptionWebstringKey: 'tp.table.alerts.text',
        defaultSorted: [
            {
                id: 'alertsAssessmentId',
                desc: true
            }
        ]
    };

    return <TpTable {...mappedStateAndProps} />;
};

export default AlertsTable;
