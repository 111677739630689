import React from 'react';
import PropTypes from 'prop-types';

const BoxInformation = ({headerText, content}) => (
    <div className={'bk-cs-content container'}>
        <div className="bk-cs-box bk-cs-box--large bk-cs-box--bordered bk-cs-centred">
            <header className="bk-cs-box__header">
                <h2 className="bk-cs-h2">{headerText}</h2>
            </header>
            <div className="bk-cs-box__content">{content}</div>
        </div>
    </div>
);

BoxInformation.propTypes = {
    headerText: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired
};

export default BoxInformation;
