import React from 'react';
import PropTypes from 'prop-types';

/**
 * Looks for the correct dimension to populate and add's whitespace to separate the values.
 * A separate element, encapsulating and coloring values depending on the associated role
 * is created.
 */

export const DimensionValues = ({roleObject, dimensionName}) => {
    const foundDimension = roleObject.scope.find(
        (scopeDefinition) => scopeDefinition.dimension === dimensionName
    );
    if (foundDimension) {
        let dimensionValuesWithWhitespace = foundDimension.values.join(', ');

        if (dimensionValuesWithWhitespace.length) {
            return (
                <div className={`tp-all-users-table__dimensionValues`}>
                    {dimensionValuesWithWhitespace}
                </div>
            );
        }
    }
    return null;
};

DimensionValues.propTypes = {
    roleObject: PropTypes.shape({
        scope: PropTypes.arrayOf(
            PropTypes.shape({
                dimension: PropTypes.string,
                values: PropTypes.arrayOf(PropTypes.string)
            }).isRequired
        )
    }).isRequired,
    dimensionName: PropTypes.string.isRequired
};

export default DimensionValues;
