/**
 * Used for getting the extension using mime type.
 *
 * The old uiSchema has 'allowedFileTypes' for setting mime types (e.g. 'application/msword').
 * The new uiSchema has 'allowedFileExtensions' for setting the allowed extensions (e.g. 'png').
 * The new implementation checks only the extension of the file. We need to stay compatible with old proposals, so the mapping is needed.
 */
const fileMimeTypes = new Map([
    ['application/msword', 'doc'],
    [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'docx'
    ],
    ['application/vnd.ms-excel', 'xls'],
    [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'xlsx'
    ],
    ['application/vnd.ms-powerpoint', 'ppt'],
    [
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'pptx'
    ],
    ['image/png', 'png'],
    ['image/jpeg', 'jpeg'],
    ['application/pdf', 'pdf']
]);

export default fileMimeTypes;
