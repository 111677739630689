import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {useDispatch} from 'react-redux';
import {saveTenantSession} from 'store/actions/sessionContextActions';
import AccessUnavailablePanel, {
    AccessUnavailablePanelTypes
} from 'components/landingPage/AccessUnavailablePanel';
import CircularLoader from 'components/loader/CircularLoader';
import HTTPClient from 'lib/HTTPClient';
import GlobalStringsLoader from 'components/authorization/GlobalStringsLoader';

const TenantSessionLoader = ({children}) => {
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(true);
    const [userIsActive, setUserIsActive] = useState(false);

    const loadTenant = async () => {
        try {
            const tenantSettings = await HTTPClient.get('settings');
            if (isEmpty(tenantSettings.user.features)) {
                setShowLoader(false);
            } else {
                setUserIsActive(true);
                dispatch(saveTenantSession(tenantSettings));
                setShowLoader(false);
            }
        } catch (error) {
            dispatch({type: 'LOAD_TENANT_SETTINGS_FAILED', error});
        }
    };

    useEffect(() => {
        loadTenant();
    }, []);

    if (showLoader) {
        return <CircularLoader overlay={true} />;
    }

    return !userIsActive ? (
        <div className={'bk-cs-content container'}>
            <AccessUnavailablePanel
                typeOfError={AccessUnavailablePanelTypes.NO_ACTIVE_USER}
            />
        </div>
    ) : (
        <GlobalStringsLoader>{children}</GlobalStringsLoader>
    );
};

TenantSessionLoader.propTypes = {
    children: PropTypes.any.isRequired
};
export default TenantSessionLoader;
