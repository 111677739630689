import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

// TODO: needs to be approved with TP-Aufgabe #25393
export const TpRadioWidget = (props) => (
    <div id={props.id} className={'field-radio-group'}>
        {props.options.enumOptions.map((option, index) => {
            const value = option.value;
            const checked = value === props.value;
            const disabled = props.disabled || props.readonly;

            return (
                <label
                    key={index}
                    className={`bk-cs-form-label-radio${
                        props.options.inline ? '--inline' : ''
                    }`}>
                    <input
                        type="radio"
                        checked={checked}
                        required={props.required}
                        value={value}
                        disabled={disabled}
                        onChange={() => props.onChange(value)}
                    />
                    <WebString webStringKey={option.label} />
                </label>
            );
        })}
    </div>
);

TpRadioWidget.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array.isRequired
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

TpRadioWidget.defaultProps = {
    value: null,
    disabled: false,
    readonly: false,
    required: false
};

export default TpRadioWidget;
