import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import GeneralForm from './GeneralForm';
import RoleFormTabs from './RoleFormTabs';
import TpButton from 'components/common/buttons/TpButton';
import UserStatusCell from 'components/users/userManagementTable/UserStatusCell';
import UserSchemaNameTypes from '../userSchemaNameTypes';
import UserStatusTypes from 'types/UserStatusTypes';

const UserPanelView = (props) => {
    const {
        userData,
        saveButtonDisabled,
        userSchemaNameType,
        stringResources,
        generalFormButtonRef,
        generateRefs,
        onSaveClicked,
        handleOnSubmit,
        handleOnGeneralFormDataChange,
        handleOnRolesFormDataChange,
        handleGeneralFormError,
        handleRoleFormError,
        goBack
    } = props;

    /**
     * Returns true if the user is active and has a status of "unconfirmed" or "unknown"
     * @param generalFormData
     * @returns {boolean|*}
     */
    const shouldRenderStatusCell = (generalFormData) => {
        const unconfirmedStates = [
            UserStatusTypes.unconfirmed,
            UserStatusTypes.unknown
        ];
        return (
            unconfirmedStates.includes(generalFormData.status) &&
            generalFormData.isActive
        );
    };

    /**
     * Renders the userstatus field if 'shouldRenderStatusCell' is true.
     * @param generalFormData
     * @returns {*}
     */
    const renderStatusField = (generalFormData) => {
        if (generalFormData && shouldRenderStatusCell(generalFormData)) {
            return (
                <div className="user-status-text-field">
                    <UserStatusCell
                        value={generalFormData.status}
                        userId={generalFormData.id}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className="tp-user-panel">
            <h2>
                <WebString
                    webStringKey={`tp.userpanel.header.${
                        userSchemaNameType === UserSchemaNameTypes.UPDATE
                            ? 'update'
                            : 'create'
                    }user.title`}
                />
            </h2>
            {renderStatusField(userData.generalFormData)}
            <GeneralForm
                generalFormData={userData.generalFormData}
                stringResources={stringResources}
                userSchemaNameType={userSchemaNameType}
                onSubmit={handleOnSubmit}
                onChange={handleOnGeneralFormDataChange}
                onError={handleGeneralFormError}>
                <button
                    type="submit"
                    className="submit bk-cs-hidden"
                    ref={generalFormButtonRef}
                />
            </GeneralForm>
            <h3>
                <WebString webStringKey="tp.userpanel.roles.title" />
            </h3>
            <RoleFormTabs
                rolesFormData={userData.rolesFormData}
                singleRightsData={userData.singleRightsData}
                userSchemaNameType={userSchemaNameType}
                handleOnSubmit={handleOnSubmit}
                handleOnRolesFormDataChange={handleOnRolesFormDataChange}
                handleRoleFormError={handleRoleFormError}
                generateRefs={generateRefs}
            />
            <div className="tp-user-panel__buttons">
                <TpButton
                    id="tp-user-panel-back-button"
                    webStringKey="cs.button.back"
                    onClick={goBack}
                />
                <TpButton
                    id="tp-user-panel-save-button"
                    webStringKey="cs.button.save"
                    disabled={saveButtonDisabled}
                    onClick={onSaveClicked}
                />
            </div>
        </div>
    );
};

UserPanelView.propTypes = {
    userData: PropTypes.shape({
        generalFormData: PropTypes.object,
        rolesFormData: PropTypes.object,
        singleRightsData: PropTypes.object
    }).isRequired,
    saveButtonDisabled: PropTypes.bool.isRequired,
    userSchemaNameType: PropTypes.oneOf(Object.values(UserSchemaNameTypes))
        .isRequired,
    stringResources: PropTypes.object.isRequired,
    generalFormButtonRef: PropTypes.object.isRequired,
    generateRefs: PropTypes.func.isRequired,
    onSaveClicked: PropTypes.func.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
    handleOnGeneralFormDataChange: PropTypes.func.isRequired,
    handleOnRolesFormDataChange: PropTypes.func.isRequired,
    handleGeneralFormError: PropTypes.func.isRequired,
    handleRoleFormError: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
};

export default UserPanelView;
