import React from 'react';
import PropTypes from 'prop-types';
import ArrayField from 'react-jsonschema-form/lib/components/fields/ArrayField';

/**
 *  Extended ArrayField of react-jsonschema-form, but with the logic changed for onChange property.
 *  RJSF's ArrayField auto validates the top level parent object (in which the array is found) on array item removal (and reorder), but we
 *  want to override this behaviour.
 *
 * @param {Object} props
 *
 * @returns {ArrayField}  react-jsonschema-form ArrayField with changes in onChange property
 */
const TpArrayField = (props) => (
    <ArrayField {...props} onChange={(value) => props.onChange(value)} />
);

TpArrayField.propTypes = {
    onChange: PropTypes.func.isRequired
};
export default TpArrayField;
