import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Form from 'components/tpForm/Form';
import {updateUserInfo} from 'store/actions/sessionContextActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import TpPopup from 'components/common/TpPopup';
import WebString from 'components/webString/WebString';

export class InitialUserSetupPopup extends React.Component {
    static propTypes = {
        isSupplementaryDataRequired: PropTypes.bool.isRequired,
        onSubmitUserInfo: PropTypes.func.isRequired,
        stringResources: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            showErrorMessage: false,
            schema: null
        };
        this.tpPopupRef = React.createRef();
        this.popupConfig = {
            headerInfo: {
                webStringKey: 'tp.text.initialusersetuppopup.title',
                showCloseButton: false
            },
            size: 'dialog'
        };
    }

    static onClose = () => {
        return null;
    };

    handleSubmitUserInfo = ({formData}) =>
        this.props.onSubmitUserInfo(formData).then((result) => {
            if (result.error) {
                this.setState({
                    showErrorMessage: true
                });
                return;
            }
            this.setState({
                showErrorMessage: false
            });
            this.tpPopupRef.current.closePopup();
        });

    getSchema = () => ({
        type: 'object',
        required: ['firstName', 'surName'],
        properties: {
            firstName: {
                type: 'string',
                title: 'tp.text.initialusersetuppopup.firstname',
                minLength: 1,
                maxLength: 250
            },
            surName: {
                type: 'string',
                title: 'tp.text.initialusersetuppopup.surname',
                minLength: 1,
                maxLength: 250
            }
        }
    });

    getUiSchema = () => ({
        hideHeader: true
    });

    render() {
        const {isSupplementaryDataRequired} = this.props;
        const {showErrorMessage} = this.state;

        if (!isSupplementaryDataRequired) {
            return null;
        }

        return (
            <TpPopup
                {...this.popupConfig}
                onClose={InitialUserSetupPopup.onClose}
                ref={this.tpPopupRef}>
                {showErrorMessage ? (
                    <WebString
                        className="tp-initial-user-setup-popup__error"
                        webStringKey="tp.text.initialusersetuppopup.errormsg"
                    />
                ) : null}
                <p>
                    <WebString webStringKey="tp.text.initialusersetuppopup.description" />
                </p>
                <Form
                    id="tp-initial-user-setup-popup-form"
                    schema={this.getSchema()}
                    uiSchema={this.getUiSchema()}
                    onSubmit={this.handleSubmitUserInfo}
                    stringResources={this.props.stringResources}
                    hideBorder={true}
                />
            </TpPopup>
        );
    }
}

const mapStateToProps = (state) => ({
    isSupplementaryDataRequired:
        state.sessionContext.user.isSupplementaryDataRequired,
    stringResources: state.settings.i18n.stringResources
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitUserInfo: wrapInDispatch(updateUserInfo, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialUserSetupPopup);
