import React from 'react';
import WebString from 'components/webString/WebString';
import PropTypes from 'prop-types';

const BusinessPartnerDisplayElement = (props) => {
    const {webStringKey, displayValue} = props;

    return (
        <div className="tp-business-partner-details__info-element">
            <WebString
                className="tp-business-partner-details__info-element-webstring"
                webStringKey={`${webStringKey}`}
            />
            <div className="tp-business-partner-details__info-element-value">
                {displayValue}
            </div>
        </div>
    );
};

BusinessPartnerDisplayElement.propTypes = {
    webStringKey: PropTypes.string.isRequired,
    displayValue: PropTypes.string
};

BusinessPartnerDisplayElement.defaultprops = {
    displayValue: ''
};

export default BusinessPartnerDisplayElement;
