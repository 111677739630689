import StatusTypes from 'types/StatusTypes';
import {transformOutgoingFormData} from 'lib/OutgoingSchemaTransformer';

/**
 * Will extract key from id for processWebString:
 *      1.Removes everything that follows after colon (:).
 *      2.Removes all special characters, only letters and numbers are allowed.
 *      3.Removes 'process' from the end of the string if it exists.
 *      Example: 'business-partner-core-data-process:17' after extracting will be 'businesspartnercoredata'.
 *
 * @param {string} proposalTaskId
 * @returns {string} modified string from id
 */
export const getProposalTaskNameFromId = (proposalTaskId) => {
    if (proposalTaskId && typeof proposalTaskId === 'string') {
        let proposalTaskName = proposalTaskId
            .replace(/(.+?:).*/, '$1')
            .replace(/[_\W]+/g, '');
        const proposalTaskNameLength = proposalTaskName.length;
        const wordToRemove = 'process';
        const wordToRemoveLength = wordToRemove.length;

        if (
            proposalTaskName.substring(
                proposalTaskNameLength - wordToRemoveLength
            ) === wordToRemove
        ) {
            proposalTaskName = proposalTaskName.substring(
                0,
                proposalTaskNameLength - wordToRemoveLength
            );
        }

        return proposalTaskName;
    } else {
        return null;
    }
};

/**
 * all necessary names of actions for proposal tasks in order to proceed in back-end
 *
 * @type {{goBack: string, noneSelected: string}}
 */
export const proposalTaskActions = {
    goBack: 'goBack',
    noneSelected: 'noneSelected',
    useExistingBusinessPartner: 'useExistingBusinessPartner'
};

/**
 * all necessary objects for proposal tasks in order to proceed in back-end
 */
export class proposalTaskActionObjects {
    static goBack = {action: proposalTaskActions.goBack};
    static noneSelected = {
        selection: {action: proposalTaskActions.noneSelected}
    };

    /**
     * is used to get object for selection
     *
     * @param action
     * @param id
     * @returns {{selection: {action: *, id: *}}}
     */
    static selected = (action, id) => {
        return {
            selection: {action: action, id: id}
        };
    };
}

export const taskFinishedFilter = (task) =>
    task.status === StatusTypes.Task.COMPLETED ||
    task.status === StatusTypes.Task.ABORTED;

/**
 * Reduce data for back-end.
 * Will retrieve only id, status and properties. For properties action, formData, selection if they are exist.
 * FormData will be transformed.
 *
 * @param {Object} task
 * @returns {Object} task with new data for back-end
 */
export const prepareTaskForTaskAPI = (task) => {
    const {id, status, properties} = task;
    const {action, formData, selection} = properties;

    const valuesForProps = {};
    if (action) {
        valuesForProps.action = action;
    }
    if (formData) {
        valuesForProps.formData = transformOutgoingFormData(formData);
    }
    if (selection) {
        valuesForProps.selection = selection;
    }

    return {
        id: id,
        status: status,
        properties: valuesForProps
    };
};

/*
 * maps values to be used in searchable select
 * used to map scope dynamically
 *  @param {value} string
 * */

export const convertSelectValue = (value) => ({
    value,
    label: value
});

const {
    ACTIVE,
    PENDING,
    PENDING_LEVEL2,
    FEEDBACK_REQUESTED,

    APPROVED,
    AUTO_APPROVED,
    APPROVED_RESTRICTIONS,
    AUTO_DECLINED,
    ABORTED,
    CLOSED,
    EXTERNAL
} = StatusTypes.Proposal;

export const proposalRunningTypes = [
    ACTIVE,
    PENDING,
    PENDING_LEVEL2,
    FEEDBACK_REQUESTED
];
export const proposalClosedTypes = [
    APPROVED,
    AUTO_APPROVED,
    APPROVED_RESTRICTIONS,
    AUTO_DECLINED,
    ABORTED,
    CLOSED,
    EXTERNAL
];
