import React from 'react';
import {convertToApiSearchParams} from 'components/dashboard/utilities/searchParamConverter';
import {ACTION_TYPES as USERS_ACTIONS} from 'store/reducers/userManagementReducer';
import HTTPClient from 'lib/HTTPClient';

const createUser = (userData, meta) => ({
    type: USERS_ACTIONS.CREATE_USER,
    promise: HTTPClient.post('users', userData),
    meta
});

const getAllUsers = (apiSearchParams, reactTableSearchParams) => ({
    type: USERS_ACTIONS.LOAD_ALL_USERS,
    promise: HTTPClient.get('users', {
        ...apiSearchParams
    }),
    meta: {
        searchParams: reactTableSearchParams,
        apiSearchParams
    }
});

const getAllUsersWithLastSearchParams = () => (dispatch, getState) => {
    const {searchParams} = getState().userManagement.table;
    const {locale} = getState().settings.i18n;
    const apiSearchParams = convertToApiSearchParams(searchParams, locale);
    dispatch({
        type: USERS_ACTIONS.LOAD_ALL_USERS,
        promise: HTTPClient.get('users', {...apiSearchParams})
    });
};

const saveTableSettings = (filterSettings) => ({
    type: USERS_ACTIONS.SAVE_USERS_TABLE_SETTINGS,
    filterSettings
});
/*
 *Below added actions are for storing the ShowMore component added for role, if number of roles is more than minimum number to show
 *
 * */
const toggleShowMore = (id) => ({
    type: USERS_ACTIONS.TOGGLE_SHOW_MORE,
    id
});

const setShowMoreOrLess = (id, showMore) => ({
    type: USERS_ACTIONS.SET_SHOW_MORE_OR_LESS,
    id,
    showMore
});

const resetShowMoreOrLess = (id) => ({
    type: USERS_ACTIONS.RESET_SHOW_MORE_OR_LESS,
    id
});

export {
    getAllUsers,
    getAllUsersWithLastSearchParams,
    createUser,
    saveTableSettings,
    setShowMoreOrLess,
    resetShowMoreOrLess,
    toggleShowMore
};
