import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import ProposalsPage from 'components/dashboard/ProposalsPage';
import ProposalInfo from 'components/proposal/ProposalInfo';
import ApplicantProcess from 'components/proposal/ApplicantProcess';
import ProposalDetails from 'components/proposal/ProposalDetails';
import ManagerProcess from 'components/proposal/ManagerProcess';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserFeatureTypes from 'types/UserFeatureTypes';
import NotFound from 'components/common/errorHandling/NotFound';

const ProposalsRoute = () => {
    const {path} = useRouteMatch();

    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[
                UserFeatureTypes.ASSESSMENT,
                UserFeatureTypes.AUDIT,
                UserFeatureTypes.PROPOSAL
            ]}
            defaultChildren={<NotFound />}>
            <Switch>
                <Route
                    exact={true}
                    path={`${path}/proposal`}
                    component={ProposalInfo}
                />
                <Route
                    exact={true}
                    path={`${path}/proposal/info`}
                    component={ProposalInfo}
                />
                <Route
                    exact={true}
                    path={`${path}/proposal/:proposalId`}
                    component={ApplicantProcess}
                />
                <Route
                    exact={true}
                    path={`${path}/proposal/:proposalId/details`}
                    component={ProposalDetails}
                />

                <Route
                    exact={true}
                    path={`${path}/assessment`}
                    component={ProposalInfo}
                />
                <Route
                    exact={true}
                    path={`${path}/assessment/:proposalId`}
                    component={ManagerProcess}
                />

                <Route path={path} component={ProposalsPage} />
            </Switch>
        </UserFeatureToggle>
    );
};

export default ProposalsRoute;
