import React from 'react';
import PropTypes from 'prop-types';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';

const ScopeField = (props) => {
    const findCurrentlySelectedFormDataName = (dim) => {
        return dim.name === props.formData.name;
    };

    const getPossibleDimensionPointValues = () => {
        const selectedDimension = props.schema.dimensions.find(
            findCurrentlySelectedFormDataName
        );
        return selectedDimension ? selectedDimension.values : [];
    };

    const getJsonSchema = () => ({
        $schema: 'http://json-schema.org/draft-04/schema#',
        type: 'object',
        title: props.schema.title,
        required: ['name', 'values'],
        properties: {
            name: {
                type: 'string',
                title: props.schema.properties.name.title
            },
            values: {
                type: 'array',
                title: props.schema.properties.values.title,
                items: {
                    type: 'string',
                    enum: getPossibleDimensionPointValues()
                },
                uniqueItems: true,
                minItems: 1
            }
        }
    });

    const getUiSchema = () => {
        return {
            name: {
                'ui:readonly': true
            },
            values: {
                'ui:options': {
                    sorted: true
                }
            }
        };
    };

    return (
        <ObjectField
            {...props}
            schema={getJsonSchema()}
            uiSchema={getUiSchema()}
        />
    );
};

ScopeField.propTypes = {
    formData: PropTypes.shape({
        name: PropTypes.string.isRequired
    }).isRequired,
    schema: PropTypes.shape({
        dimensions: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired
};
export default ScopeField;
