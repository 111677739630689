const commonColumnIdToApiQueryMapping = {
    proposalAssessor: 'managerEmail',
    bpId: 'businessPartnerId',
    bpName: 'businessPartnerName',
    bpCity: 'city',
    bpCountry: 'country',
    proposalId: 'proposalId',
    proposalIdBusinessPartner: 'proposalId',
    proposalOriginator: 'originatorEmail',
    proposalOwner: 'ownerEmail',
    proposalOriginatorBusinessPartner: 'originatorEmail',
    proposalOwnerBusinessPartner: 'ownerEmail',
    proposalRiskLevel: 'riskLevel',
    proposalStatus: 'proposalStatus',
    firstName: 'firstName',
    lastName: 'lastName',
    bpIdAssessment: 'businessPartnerId',
    bpNameAssessment: 'businessPartnerName',
    bpNameBusinessPartner: 'businessPartnerName',
    countryAssessment: 'country',
    proposalIdAssessment: 'proposalId',
    proposalOriginatorAssessment: 'originatorEmail',
    proposalOwnerAssessment: 'ownerEmail',
    proposalAssessorAssessment: 'managerEmail',
    proposalRiskLevelAssessment: 'riskLevel',
    proposalStatusAssessment: 'proposalStatus',
    userId: 'userId',
    userFirstName: 'firstName',
    userLastName: 'lastName',
    userRoles: 'role',
    userEmail: 'email',
    userCreationDate: 'created',
    alertsAssessmentId: 'alertsAssessmentId',
    businessPartnerId: 'businessPartnerId',
    alertsCount: 'numberOfAlerts',
    alertsOldestDate: 'oldestAlertDate',
    alertsNewestDate: 'newestAlertDate',
    alertsAssessmentStatus: 'assessmentStatus',
    alertCriticality: 'alertCriticality',
    alertProcessedDate: 'alertProcessedDate',
    alertDeliveryDate: 'alertDeliveryDate'
};

const columnIdToApiSearchQueryMapping = {
    ...commonColumnIdToApiQueryMapping,
    proposalCreationDate: 'createdDate',
    proposalCreationDateAssessment: 'createdDate',
    proposalLastModificationDate: 'updatedDate',
    proposalLastModificationDateAssessment: 'updatedDate',
    proposalSubmissionDate: 'submittedDate',
    proposalSubmissionDateAssessment: 'submittedDate'
};

const columnIdToApiSortQueryMapping = {
    ...commonColumnIdToApiQueryMapping,
    proposalCreationDate: 'created',
    proposalCreationDateAssessment: 'created',
    proposalLastModificationDate: 'updated',
    proposalLastModificationDateAssessment: 'updated',
    proposalSubmissionDate: 'submitted',
    proposalSubmissionDateAssessment: 'submitted'
};

/**
 * Dimension columns to be mapped dynamically for api searching
 *
 * @param {String} nameOfTheColumn
 * @returns {String}
 *
 * @example
 * // returns department
 * checkIfDimensionColumn(departmentDimensionAssessment);
 *
 * @example
 * // returns departmentAssessment
 * checkIfDimensionColumn(departmentAssessment);
 *
 *  * @example
 * // returns departmentDimension
 * checkIfDimensionColumn(department);
 */

const ifDimensionColumnReturnOnlyName = (nameOfTheColumn) => {
    const result = nameOfTheColumn.split('Dimension');
    return result.length > 1 ? result[0] : nameOfTheColumn;
};

const extractSearchParams = (reactTableSearchObj, locale) => {
    const {page: pageNumber, pageSize, sorted} = reactTableSearchObj;
    const {id, desc} = sorted[0];
    return {
        locale,
        pageNumber,
        pageSize,
        sortBy:
            columnIdToApiSortQueryMapping[id] ||
            ifDimensionColumnReturnOnlyName(id),
        sortDirection: desc ? 'desc' : 'asc'
    };
};

const convertToApiSearchParams = (
    reactTableSearchObj,
    locale,
    dynamicColumnIdToApiSearchQueryMapping = {
        ...columnIdToApiSearchQueryMapping
    }
) => {
    const searchParams = extractSearchParams(reactTableSearchObj, locale);

    reactTableSearchObj.filtered.forEach((filter) => {
        const {id, value} = filter;
        if (value !== 'ANY') {
            const searchData =
                dynamicColumnIdToApiSearchQueryMapping[id] ||
                ifDimensionColumnReturnOnlyName(id);
            if (typeof value === 'object') {
                // in case of date object
                const {startDate, endDate} = value;

                if (startDate) {
                    searchParams[`${searchData}Min`] = startDate.toString();
                    console.log(`${searchData}Min`);
                }
                if (endDate) {
                    searchParams[`${searchData}Max`] = endDate.toString();
                }
            } else {
                searchParams[searchData] = value;
            }
        }
    });
    return searchParams;
};
export {convertToApiSearchParams};
