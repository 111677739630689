import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import i18nUtils from 'lib/i18nUtils';

const filterValuesByTranslations = (
    {valueTranslation: translatedA},
    {valueTranslation: translatedB}
) => {
    if (translatedA > translatedB) {
        return 1;
    }
    if (translatedA < translatedB) {
        return -1;
    }
    return 0;
};

const getModifiedValue = (value, webStringKeyPrefix) =>
    webStringKeyPrefix
        ? value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
        : value;

export const DropdownFilter = ({
    filter,
    onChange,
    stringResources,
    column,
    filterValues,
    webStringKeyPrefix,
    webStringKeySuffix
}) => (
    <select
        id={column.id}
        onChange={(event) => onChange(event.target.value)}
        style={{width: '100%'}}
        value={filter ? filter.value : 'ANY'}>
        <option id="column_id_any" value="ANY">
            {i18nUtils.getTranslatedWebStringKey(
                stringResources,
                'cs.text.any'
            )}
        </option>
        {filterValues
            .map((value) => ({
                value,
                valueTranslation: i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    `${webStringKeyPrefix}${
                        value
                            ? getModifiedValue(value, webStringKeyPrefix)
                            : 'unknown'
                    }${webStringKeySuffix}`
                )
            }))
            .sort(filterValuesByTranslations)
            .map(({value, valueTranslation}, index) => {
                return (
                    <option
                        key={index}
                        id={`column_id_${
                            value ? value.toLowerCase() : column.id
                        }`}
                        value={value}>
                        {valueTranslation}
                    </option>
                );
            })}
    </select>
);

DropdownFilter.propTypes = {
    stringResources: PropTypes.object.isRequired,
    column: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }),
    filterValues: PropTypes.array.isRequired,
    webStringKeyPrefix: PropTypes.string,
    webStringKeySuffix: PropTypes.string
};

DropdownFilter.defaultProps = {
    webStringKeyPrefix: '',
    webStringKeySuffix: ''
};

const mapStateToProps = (state) => ({
    stringResources: state.settings.i18n.stringResources
});

export default connect(mapStateToProps)(DropdownFilter);
