import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import IconButton from './IconButton';

/**
 * Button which toggles a show/hide section.
 */
export default class IconButtonOpenClose extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        isOpen: PropTypes.bool
    };

    static defaultProps = {
        isOpen: false
    };

    render() {
        const iconClassName = this.props.isOpen
            ? 'icon-button__icon--open'
            : 'icon-button__icon--closed';

        return (
            <IconButton
                id={this.props.id}
                onClick={this.props.onClick}
                className="icon-button--borderless"
                iconClassName={iconClassName}>
                <WebString webStringKey="tp.form.dowjones.show.tooltip" />
            </IconButton>
        );
    }
}
