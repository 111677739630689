import React from 'react';
import PropTypes from 'prop-types';

const TpImage = (props) => {
    const fullPath = require(`assets/images/${props.src}`);

    const allProps = {
        ...props,
        src: fullPath
    };
    if (props.alt) {
        allProps.alt = props.alt;
    }
    if (props.className) {
        allProps.className = props.className;
    }

    return <img {...allProps} />;
};

TpImage.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    alt: PropTypes.string
};

TpImage.defaultProps = {
    className: undefined,
    alt: undefined
};

export default TpImage;
