import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import WebString from 'components/webString/WebString';
import TpLink from 'components/common/TpLink';
import urls from 'routes/urls';

/**
 *  Recheck Information Component to be used in meta bar, gives the id of the original proposal
 *  as a link if proposal is a re-check and is accessible, otherwise it's displayed as a text.
 *  On click, it will navigate to the original proposal if the user has access to it.
 */

export const RecheckInformation = (props) => {
    const {originalProposalId} = props;

    if (!originalProposalId) return null;

    const [originalProposalData, setOriginalProposalData] = useState(null);

    useEffect(() => {
        async function getOriginalProposalData() {
            try {
                const originalProposalData = await HTTPClient.get(
                    `proposals/${originalProposalId}/info`
                );
                setOriginalProposalData(originalProposalData);
            } catch (error) {}
        }
        getOriginalProposalData();
    }, []);

    const navigationURL = `${urls.PROPOSAL_URL}/${originalProposalId}`;

    return (
        <li className="tp-meta-info-bar__recheck-information">
            <WebString
                id="tp-meta-info-bar__webstring-recheckinformation-text"
                webStringKey="tp.recheck.recheckinformation.text"
            />
            {originalProposalData ? (
                <TpLink
                    id="tp-recheck-information-original-proposal-id"
                    className="tp-recheck-information-original-proposal-id"
                    webStringKey={''}
                    to={
                        originalProposalData.imported
                            ? `${navigationURL}/details`
                            : navigationURL
                    }>
                    {originalProposalId}
                </TpLink>
            ) : (
                <span className="tp-recheck-information-original-proposal-id">
                    {originalProposalId}
                </span>
            )}
        </li>
    );
};

RecheckInformation.propTypes = {
    originalProposalId: PropTypes.number
};

RecheckInformation.defaultProps = {
    originalProposalId: null
};

const mapStateToProps = (state) => ({
    originalProposalId: state.process.proposal.originalProposalId
});

export default connect(mapStateToProps)(RecheckInformation);
