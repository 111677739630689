import React from 'react';
import PropTypes from 'prop-types';
import RJSFArrayField from 'react-jsonschema-form/lib/components/fields/ArrayField';

/**
 * UI schema prop "ui:options" to disable the buttons on arrays
 */
const noButtonUiSchemaUiOptionsProps = {
    addable: false,
    removable: false,
    orderable: false
};

/**
 * For text-only arrays, we never want to show the add / remove / re-order buttons.
 * @param props
 * @returns {*}
 */
const TextOnlyArrayField = (props) => {
    const propsWithButtonsRemoved = {
        ...props,
        uiSchema: {
            items: props.uiSchema.items,
            'ui:options': noButtonUiSchemaUiOptionsProps
        }
    };
    return (
        <RJSFArrayField
            {...propsWithButtonsRemoved}
            onChange={(value) => props.onChange(value)}
        />
    );
};

TextOnlyArrayField.propTypes = {
    uiSchema: PropTypes.shape({
        items: PropTypes.object
    }).isRequired
};

export default TextOnlyArrayField;
