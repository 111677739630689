import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {connect} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import PercentProgressBar from './PercentProgressBar';
import WebString from 'components/webString/WebString';

const CancelToken = axios.CancelToken;
let cancelRequest;

const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return 'n/a';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
        return `${bytes} ${sizes[i]})`;
    }
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

/**
 * Displays uploading file, with progress bar.
 * Success and error states, buttons to retry and cancel in error state.
 */
export class UploadFile extends React.Component {
    static propTypes = {
        file: PropTypes.shape({
            name: PropTypes.string.isRequired,
            size: PropTypes.number.isRequired
        }).isRequired,
        filesEndpointUrlPath: PropTypes.string.isRequired,

        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onError: PropTypes.func,

        fileUploadExternalId: PropTypes.string
    };

    static defaultProps = {
        onError: () => undefined,
        fileUploadExternalId: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            progressPercent: 0,
            errorDuringUpload: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.uploadFileAsync(this.props.file);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    async uploadFileAsync(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('externalId', this.props.fileUploadExternalId);

        const config = {
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    this.updateProgressBar(
                        progressEvent.loaded / progressEvent.total
                    );
                }
            },
            cancelToken: new CancelToken((cancelFunction) => {
                cancelRequest = cancelFunction;
            })
        };

        try {
            const response = await HTTPClient.post(
                this.props.filesEndpointUrlPath,
                formData,
                config
            );
            this.uploadSuccess(response);
        } catch (error) {
            this.uploadFailure(error);
        }
    }

    /**
     * Update the state for progressPercent.
     *
     * @param {Number} progress
     */
    updateProgressBar = (progress) => {
        if (this.mounted) {
            this.setState({progressPercent: progress * 100});
        }
    };

    uploadSuccess = (resp) => {
        this.props.onSuccess(resp);
    };

    uploadFailure = (error) => {
        if (this.mounted) {
            this.setState({errorDuringUpload: true}, () => {
                this.props.onError(error);
            });
        }
    };

    handleCancelUpload = () => {
        cancelRequest();
        this.props.onCancel();
    };

    handleRefreshUpload = () => {
        this.setState({progressPercent: 0, errorDuringUpload: false}, () => {
            this.uploadFileAsync(this.props.file);
        });
    };

    render() {
        return (
            <div className="file-upload__uploading-row">
                <div className="file-upload__text">
                    {this.props.file.name} ({bytesToSize(this.props.file.size)})
                </div>
                <PercentProgressBar
                    progress={this.state.progressPercent}
                    error={this.state.errorDuringUpload}
                    onCancel={this.handleCancelUpload}
                    onRefresh={this.handleRefreshUpload}
                />
                {this.state.errorDuringUpload ? (
                    <WebString
                        className="file-upload__text file-upload__text--error"
                        webStringKey="tp.errormsg.validation.uploadfailed"
                    />
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onError(error) {
        dispatch({type: 'UPLOAD_FILE_FAILED', error});
    }
});

export default connect(null, mapDispatchToProps)(UploadFile);
