import React from 'react';
import {useRouteMatch, Route, Switch} from 'react-router-dom';
import Logout from 'components/authorization/Logout';
import LogoutCallback from 'components/authorization/LogoutCallback';

const LogoutRoute = () => {
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact={true} path={path} component={Logout} />
            <Route
                exact={true}
                path={`${path}/callback`}
                component={LogoutCallback}
            />
        </Switch>
    );
};

export default LogoutRoute;
