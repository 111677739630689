import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AccountFeatureTypes from 'types/AccountFeatureTypes';

export const AccountFeatureToggle = (props) => {
    return props.accountFeatures.includes(props.requiredAccountFeature)
        ? props.children
        : props.defaultChildren;
};

AccountFeatureToggle.propTypes = {
    accountFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    requiredAccountFeature: PropTypes.string.isRequired,
    children: PropTypes.any,
    defaultChildren: PropTypes.any
};

AccountFeatureToggle.defaultProps = {
    defaultChildren: null
};

const featuresToCheck = [...Object.values(AccountFeatureTypes)];

const mapStateToProps = (state) => {
    const {account} = state.sessionContext;
    const enabledFeatures = featuresToCheck
        .map((feat) => (account[feat] ? feat : undefined))
        .filter((feat) => !!feat);
    return {
        accountFeatures: enabledFeatures
    };
};

export default connect(mapStateToProps)(AccountFeatureToggle);
