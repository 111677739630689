import React from 'react';
import PropTypes from 'prop-types';
import HTTPClient from 'lib/HTTPClient';
import TpButton from 'components/common/buttons/TpButton';
import Loader from 'components/loader/CircularLoader';
import TpPopup from 'components/common/TpPopup';
import WebString from 'components/webString/WebString';

/**
 * Component used for displaying Dowjones articles on a pop-up window
 * on click of a button without having to navigate to the url by clicking
 * the link
 **/
export default class DisplayDowJonesArticle extends React.Component {
    static propTypes = {
        headerText: PropTypes.string
    };
    static defaultProps = {
        headerText: 'Dow Jones Article'
    };

    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            dowJonesArticle: null,
            fetchingFailed: false
        };
    }

    static popupSize = 'full';
    static popupButtons = [
        {
            webStringKey: 'OK',
            id: 'btn-show-profile-ok'
        }
    ];
    static popupHeaderInfo = {
        showCloseButton: true,
        type: 'generic'
    };

    /**
     * Closes the modal window.
     */
    handleOnClosePopup = () => {
        this.setState({showPopup: false, dowJonesArticle: null});
    };

    /**
     * gets dowjones article JSON based on articleId
     **/
    async getDowJonesArticleAsync(articleId) {
        let response;
        this.setState({showPopup: true});
        try {
            response = await HTTPClient.get(`article/${articleId}`);
            this.setState({dowJonesArticle: response});
        } catch (error) {
            this.setState({
                dowJonesArticle: null,
                showPopup: false,
                fetchingFailed: true
            });
        }
    }

    /**
     * Opens up the popup dialog if the article is fetched and showPopup=true.
     **/
    showPopup = () => {
        return this.state.showPopup ? (
            <TpPopup
                headerInfo={{
                    ...DisplayDowJonesArticle.popupHeaderInfo,
                    webStringKey: this.props.headerText
                }}
                buttons={DisplayDowJonesArticle.popupButtons}
                size={DisplayDowJonesArticle.popupSize}
                onClose={this.handleOnClosePopup}>
                {this.state.dowJonesArticle ? (
                    <div className="dowjones-article">
                        <pre className={'tp-dowjones-article__pretext'}>
                            <h3>{this.state.dowJonesArticle.title}</h3>
                            <p>{this.state.dowJonesArticle.content}</p>
                        </pre>
                    </div>
                ) : (
                    <Loader />
                )}
            </TpPopup>
        ) : null;
    };

    /**
     *
     * Button click handler call to get data
     **/
    _handleOnClickDisplayArticle = () => {
        this.getDowJonesArticleAsync(this.props.articleId);
    };

    render() {
        if (this.state.fetchingFailed) {
            return (
                <div className="bk-tp-djarticle__error">
                    <WebString
                        webStringKey="tp.form.dowjonesarticle.fetcherror"
                        className="tp-djarticle__error"
                    />
                </div>
            );
        }
        return (
            <div>
                <TpButton
                    id="readArticle"
                    visible={true}
                    disabled={false}
                    webStringKey="tp.button.readarticle"
                    onClick={this._handleOnClickDisplayArticle}
                    className="bk-tp-djarticle__displaybutton"
                />
                {this.state.dowJonesArticle && this.showPopup()}
            </div>
        );
    }
}
