import {ACTION_TYPES} from 'store/reducers/snackReducer';
import i18nUtils from 'lib/i18nUtils';

/**
 * @param snack: {
 *     id: string,
 *     snackType: string,
 *     message: string,
 *     autoHideDuration: number (millisecond)
 * }
 * @returns {{snackProperties: *, type: string}}
 *
 * @example
 * // returns {
        type: ACTION_TYPES.CREATE_SNACK,
        snackProperties: {
            id: '5',
            snackType: 'SUCCESS',
            message: 'You have a message',
            autoHideDuration: 4000
        }
    }
 createSnack({
            id: '5',
            snackType: 'SUCCESS',
            message: 'You have a message',
            autoHideDuration: 4000
        })
 */
export function createSnack(snack) {
    return {
        type: ACTION_TYPES.CREATE_SNACK,
        snackProperties: snack
    };
}

export function createTranslatedSnack(
    id,
    snackType,
    messageWebStringKey,
    autoHideDuration = 4000
) {
    return (dispatch, getState) => {
        const stringResources = getState().settings.i18n.stringResources;
        dispatch({
            type: ACTION_TYPES.CREATE_SNACK,
            snackProperties: {
                id,
                snackType,
                autoHideDuration,
                message: i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    messageWebStringKey
                )
            }
        });
    };
}

export function removeSnack() {
    return {
        type: ACTION_TYPES.REMOVE_SNACK,
        promise: new Promise((resolve) => setTimeout(resolve, 1000))
    };
}
