import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

/**
 * Class for rendering a button element according to the props passed with default styling and webString for internationalisation.
 * The default classes can be overridden with new classes passed in props if necessary.
 * For avoiding a double click locks itself for a short time after button click. For visual effect opacity is applied.
 */
export default class TpButton extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        webStringKey: PropTypes.string.isRequired,
        webStringClassName: PropTypes.string,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        isDefaultClass: PropTypes.bool,
        disabled: PropTypes.bool,
        style: PropTypes.object,
        visible: PropTypes.bool,
        type: PropTypes.string
    };

    static defaultProps = {
        onClick: () => undefined,
        className: '',
        iconClassName: '',
        isDefaultClass: true,
        webStringClassName: '',
        disabled: false,
        style: null,
        visible: true,
        type: 'button'
    };

    constructor(props) {
        super(props);
        this.state = {locked: false};
        this.unlockTimeout = null;
    }

    componentWillUnmount() {
        clearTimeout(this.unlockTimeout);
    }

    /**
     * Locks for a short time, helps to avoid double click.
     *
     * @param {Object} event
     * @returns {undefined}
     */
    handleOnClick = (event) => {
        if (this.props.type !== 'submit') {
            event.preventDefault();
        }
        if (!this.state.locked) {
            this.setState({locked: true}, () => {
                this.props.onClick(event);
            });

            this.unlockTimeout = setTimeout(() => {
                this.setState({locked: false});
            }, 2000);
        }
    };

    render() {
        if (!this.props.visible) {
            return null;
        }

        const {
            id,
            className,
            webStringKey,
            iconClassName,
            webStringClassName,
            isDefaultClass,
            children,
            disabled,
            style,
            type
        } = this.props;

        const buttonProps = {
            id,
            className: isDefaultClass
                ? `bk-cs-button bk-cs-button--wide ${className}`
                : className,
            disabled: disabled,
            style: this.state.locked ? {...style, opacity: '0.3'} : style,
            onClick: this.handleOnClick,
            type
        };

        return (
            <button {...buttonProps}>
                {iconClassName ? <span className={`${iconClassName}`} /> : null}
                <WebString
                    className={webStringClassName}
                    webStringKey={webStringKey}
                />
                {children}
            </button>
        );
    }
}
