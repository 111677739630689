import React from 'react';
import getIdentityConfig from 'auth/authConst';
import {UserManager, Log} from 'oidc-client';
import HTTPClient from 'lib/HTTPClient';
import urls from 'routes/urls';

// class to manage the UserManager object
export default class AuthService {
    UserManager;
    AppSettings;

    constructor(appSettings) {
        this.AppSettings = appSettings;

        const identityConfig = getIdentityConfig(
            this.AppSettings.AppContextPath,
            this.AppSettings.AuthAuthorityURI,
            this.AppSettings.oidcClientId
        );

        this.UserManager = new UserManager(identityConfig);

        // Logger
        if (process.env.ENABLE_OIDC_LOG) {
            const {Log} = require('oidc-client');
            Log.logger = console;
            Log.level = Log.DEBUG;
        }

        this.UserManager.events.addAccessTokenExpiring(() => {
            this.signinSilent();
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            this.signout();
        });
    }

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                HTTPClient.setAuthAccessToken(user.access_token);
            })
            .catch((err) => {
                console.log('error during signing silent: ', err);
                setTimeout(() => {
                    this.signinSilent();
                }, 15000); // 15 seconds
            });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    /**
     * check if user exists and its token is still valid
     */
    getLoggedInUser = async () => {
        const user = await this.UserManager.getUser();
        if (user && user.access_token && user.expires_at) {
            const expiresAt = user.expires_at * 1000 + new Date().getTime();
            const expired = new Date().getTime() > expiresAt;
            if (expired) {
                return null;
            }
            HTTPClient.setAuthAccessToken(user.access_token);
            return user.profile;
        }
        return null;
    };

    signinRedirect = (args) => {
        localStorage.setItem('redirectUri', window.location);
        // this.props.location.search is the whole query string in the URL with the format "?q1=a1&q2=a2[...]" or emtpy ""
        this.UserManager.settings._redirect_uri += window.location.search;
        try {
            this.UserManager.signinRedirect(args);
        } catch (error) {
            console.error(
                'Central login not reachable. Chances are that the SSL-Certificate of the central login server is not accepted: ' +
                    error.message,
                error
            );
        }
    };

    signinRedirectCallback = () => {
        try {
            this.UserManager.signinRedirectCallback().then((user) => {
                let redirectUri = localStorage.getItem('redirectUri');
                if (redirectUri.indexOf(urls.LOGIN_URL) !== -1) {
                    redirectUri = this.AppSettings.AppContextPath;
                }

                window.location =
                    new URLSearchParams(window.location.search).get(
                        'redirect'
                    ) || redirectUri;
                localStorage.removeItem('redirectUri');
            });
        } catch (error) {
            console.error(
                'There was an error handling the token callback: ' +
                    error.message,
                error
            );
        }
    };

    signout = async () => {
        await this.UserManager.signoutRedirect();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(this.AppSettings.AppContextPath);
            this.UserManager.removeUser();
            this.UserManager.clearStaleState();
        });
    };
}
