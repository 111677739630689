import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const EmailList = ({emailList}) => {
    if (!emailList || emailList.length === 0) {
        return null;
    }
    let index = 0;
    return (
        <ul className="tp-email-list">
            {emailList.map((emailListItem) => (
                <li
                    key={`${emailListItem.email}-listitem-${index++}`}
                    className="tp-email-list__list-item">
                    <div className="tp-email-list__item-date">
                        <WebString
                            className="tp-email-list__label-senddate"
                            webStringKey="cs.text.questionnairesendate"
                        />{' '}
                        <span className="tp-email-list__senddate-value">
                            {moment(emailListItem.creationTimestamp).format(
                                'DD-MM-YYYY'
                            )}
                        </span>
                    </div>
                    <div className="tp-email-list__item-email">
                        <WebString
                            className="tp-email-list__label-to"
                            webStringKey="cs.text.to"
                        />{' '}
                        <span className="tp-email-list__email-value">
                            {emailListItem.email}
                        </span>
                        .
                    </div>
                </li>
            ))}
        </ul>
    );
};

EmailList.propTypes = {
    emailList: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            creationTimestamp: PropTypes.number.isRequired
        })
    ).isRequired
};

export default EmailList;
