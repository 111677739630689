import * as processActions from './processActions';
import {taskFinishedFilter} from 'lib/proposalUtils';
import StatusTypes from 'types/StatusTypes';
import ViewTypes from 'types/ViewTypes';
import urls from 'routes/urls';

const fetchProposalTasksForApplicant = (dispatch, data, view) => {
    dispatch(
        processActions.getProposalTasks(data.id, ViewTypes.APPLICANT, {
            onSuccess(tasks) {
                // get proposal task by fetching the last completed status
                const lastFinishedTask = tasks.filter(taskFinishedFilter).pop();
                dispatch(
                    processActions.getProposalTaskById(
                        data.id,
                        lastFinishedTask.id,
                        view
                    )
                );
            }
        })
    );
};

const fetchProposalTasksForManager = (dispatch, data, view) => {
    dispatch(
        processActions.getProposalTasks(data.id, view, {
            onSuccess(tasks) {
                const lastFinishedTask = tasks.filter(taskFinishedFilter).pop();
                // get proposal task by fetching the last completed status
                dispatch(
                    processActions.getProposalTaskById(
                        data.id,
                        lastFinishedTask.id,
                        view,
                        {
                            onSuccess() {
                                dispatch(
                                    processActions.getProposalTasks(
                                        data.id,
                                        ViewTypes.APPLICANT
                                    )
                                );
                            }
                        }
                    )
                );
            }
        })
    );
};

const artificialManagerProposalOverviewTask = {
    id: 'genericmanageroverview',
    status: 'COMPLETED',
    name: 'Manager generic proposal overview',
    properties: {
        formDefinition: {
            formSchema: {
                type: 'object',
                title: 'tp.form.approval.title',
                formid: 'approval-form',
                $schema: 'http://json-schema.org/draft-04/schema#'
            },
            uiSchema: {
                hideBorder: true,
                hideHeader: true,
                'ui:field': 'approvalOverviewField'
            },
            formId: 'genericmanageroverview'
        }
    },
    startTimestamp: 0,
    lastModificationTimestamp: 0,
    isLastSubTask: true,
    isLastTask: true
};

const createGenericProposalOverviewForManager = (dispatch, data) => {
    dispatch(
        processActions.getProposalTasks(data.id, ViewTypes.APPLICANT, {
            onSuccess() {
                // get proposal task by fetching the last completed status
                dispatch(
                    processActions.updateCurrentTask(
                        artificialManagerProposalOverviewTask
                    )
                );
            }
        })
    );
};

const fetchProposalTasksForExternalQuestionnaire = (dispatch, data, view) => {
    dispatch(
        processActions.getProposalTasks(data.id, view, {
            onSuccess(tasks) {
                // get proposal task by fetching the last completed status
                const lastFinishedTask = tasks.filter(taskFinishedFilter).pop();
                dispatch(
                    processActions.getProposalTaskById(
                        data.id,
                        lastFinishedTask.id,
                        view
                    )
                );
            }
        })
    );
};

const statesWithoutManagerProcess = [
    'ANNOUNCED',
    'AUTO_APPROVED',
    'AUTO_DECLINED',
    'APPROVAL_NOT_NEEDED'
];
const fetchProposalsCurrentTaskAndTasks = (dispatch, proposal) => {
    const {data, view} = proposal;
    if (data.currentTaskId) {
        // process is not completed, fetch current proposal task by id and all tasks
        dispatch(
            processActions.getProposalTaskById(
                data.id,
                data.currentTaskId,
                view
            )
        );
        if (view === ViewTypes.BUSINESS_PARTNER) {
            return;
        }
        dispatch(processActions.getProposalTasks(data.id, ViewTypes.APPLICANT));
    } else {
        // process is finalised; fetch all tasks and figure out the last task to display
        if (view === ViewTypes.APPLICANT) {
            fetchProposalTasksForApplicant(dispatch, data, view);
        } else if (view === ViewTypes.MANAGER) {
            if (statesWithoutManagerProcess.includes(proposal.data.status)) {
                createGenericProposalOverviewForManager(dispatch, data);
            } else {
                fetchProposalTasksForManager(dispatch, data, view);
            }
        } else if (view === ViewTypes.BUSINESS_PARTNER) {
            fetchProposalTasksForExternalQuestionnaire(dispatch, data, view);
        }
    }
};

/**
 * Loads the proposal with the proposalId and ViewType.
 * When loaded, the current task and all tasks will be fetched and set as the current task in
 * the application state.
 * When the process is finalised (all tasks set to "COMPLETED", the last "COMPLETED"
 * task will be fetched.
 *
 * viewType: MANAGER
 * If the proposal is in one of the states that don't have a manager process (ANNOUNCED,
 * AUTO_DECLINED, AUTO_APPROVED), it sets an artificial formSchema and fetches all applicant tasks.
 *
 * @param {number} proposalId - The Id of the proposal to load.
 * @param {ViewTypes} one of the value in ViewTypes
 * @returns {function(*=)} - The redux-thunk function.
 */
const initProcess = (proposalId, viewType) => (dispatch) => {
    dispatch(
        processActions.getProposalAsView(proposalId, viewType, {
            onSuccess(proposal) {
                fetchProposalsCurrentTaskAndTasks(dispatch, proposal);
            }
        })
    );
};

/**
 * Switch the task from a click in the progress bar.
 * Autosaves if the current task is RUNNING or WAITING. Nothing will happen
 * when the taskId of the task to load is the same as the currentTask id.
 *
 * @param {number} proposalId
 * @param {string} taskId
 * @param {object} currentTask
 * @returns {function(*=)}
 */
const setTaskFromProgressBar = (proposalId, taskId, currentTask) => (
    dispatch
) => {
    if (currentTask) {
        const {status, id: currentTaskId, properties} = currentTask;

        if (currentTaskId === taskId) {
            // the clicked task is the same as loaded. No reload needed.
            return;
        }

        if (
            properties &&
            properties.actions &&
            properties.actions.includes('update')
        ) {
            // the current task can be saved by the user
            if (
                status === StatusTypes.Task.RUNNING ||
                status === StatusTypes.Task.WAITING
            ) {
                // task should be autosaved to prevent data loss
                dispatch(
                    processActions.switchTaskAutosave(
                        proposalId,
                        currentTask,
                        ViewTypes.APPLICANT
                    )
                );
            }
        }
    }

    dispatch(processActions.clearTaskErrors());
    dispatch(
        processActions.getProposalTaskById(
            proposalId,
            taskId,
            ViewTypes.APPLICANT
        )
    );
};

/**
 * Creates a new proposal and fetches the current task and all possible tasks.
 * Then navigate to it using the react-router-dom history API.
 * @param {object} selectedScopePoint - selected scope point chosen by applicant
 * @param {object} history - history object from react-router-dom  for updating browser URL
 * @returns {function(*=)}
 */
const createAndNavigateToNewProposal = (selectedScopePoint, history) => (
    dispatch
) => {
    dispatch(
        processActions.createProposal(selectedScopePoint, {
            onSuccess(result) {
                const {data, view} = result;
                dispatch(
                    processActions.getProposalTaskById(
                        data.id,
                        data.currentTaskId,
                        view,
                        {
                            onSuccess() {
                                history.push(`${urls.PROPOSAL_URL}/${data.id}`);
                            }
                        }
                    )
                );
                dispatch(processActions.getProposalTasks(data.id, view));
            }
        })
    );
};

/**
 * Save current task and show the next subtask or task.
 * After the final task and the proposal approval is decided,
 * stay on the last task, as read only.
 *
 * @param {number} proposalId
 * @param {object} task
 * @param {string} view
 * @returns {function(*=)}
 */
const saveTaskAndUpdateProposal = (proposalId, task, view) => (dispatch) => {
    dispatch(
        processActions.completeCurrentTask(proposalId, task, view, {
            onSuccess(result) {
                window.scrollTo(0, 0);
                if (
                    result.status === StatusTypes.Task.COMPLETED ||
                    result.status === StatusTypes.Task.ABORTED
                ) {
                    dispatch(initProcess(proposalId, view));
                }
            }
        })
    );
};

/**
 * Dispatch an action of type 'REMUNERATION_CALCULATION_FAILED'.
 * @param {object} error
 * @returns {object} The action object.
 */
const showCalculationValidationError = (error) => ({
    type: 'REMUNERATION_CALCULATION_FAILED',
    error
});

export {
    initProcess,
    setTaskFromProgressBar,
    createAndNavigateToNewProposal,
    saveTaskAndUpdateProposal,
    showCalculationValidationError
};
