export default {
    id: 'de',
    stringMap: {
        'cs.text.selectedlanguage': 'Ausgewählte Sprache',
        'cs.text.language.engb': 'English',
        'cs.text.language.de': 'Deutsch',
        'tp.registration.title':
            'Willkommen im BKMS<sup>®</sup> Compliance System',
        'tp.registration.unavailable':
            'Eine Selbstregistrierung ist für dieses System nicht möglich.',
        'tp.registration.form.title': 'Registrierung',
        'tp.registration.introductiontext':
            '<p>Ihre Organisation hat Ihnen den Registrierungslink zum BKMS<sup>®</sup> Compliance System zur Verfügung gestellt. </p><p>Um Ihren eigenen Benutzeraccount zu erstellen, verwenden Sie bitte ausschließliche Ihre geschäftliche E-Mailadresse. </p><p>Nachdem Sie Ihre geschäftliche E-Mailadresse eingegeben haben, werden Sie eine Bestätigungsemail erhalten. Bitte folgen Sie den Anweisungen in der Bestätigungsemail, um den Registrierungsprozess abzuschließen. </p><p>Danach können Sie sich einloggen und auf das BKMS<sup>®</sup> Compliance System Ihrer Organisation zugreifen.</p>',
        'tp.registration.email.title': 'Ihre E-Mailadresse:',
        'tp.registration.submit.title': 'Anmelden',
        'tp.errormsg.validation.paneltitle':
            'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Angaben.',
        'tp.errormsg.validation.required': 'Das Feld darf nicht leer sein.',
        'tp.errormsg.validation.toofewcharacters':
            'Das Feld muss mindestens {0} Zeichen enthalten.',
        'tp.errormsg.validation.toomanycharacters':
            'Das Feld darf nicht mehr als {0} Zeichen enthalten.',
        'tp.errormsg.validation.email': 'Ungültige E-Mailadresse.',
        'tp.registration.success.title': 'Registrierung erfolgreich',
        'tp.registration.success.registered':
            'Eine Registrierungsemail wurde an Sie verschickt. Bitte folgen Sie den Anweisungen in dieser E-Mail, um den Registrierungsprozess abzuschließen.',
        'tp.registration.warning.title': 'Warnung',
        'tp.registration.warning.registrationpending':
            'Für diese E-Mailadresse wurde bereits ein Benutzeraccount angelegt, aber noch nicht bestätigt. Es wurde eine neue Registrierungsemail an Sie gesendet.',
        'tp.registration.warning.registrationfinished':
            'Diese E-Mailadresse wird bereits verwendet. Wenn dies Ihre E-Mailadresse ist, können Sie sich entweder mit Ihrem Passwort einloggen oder auf die Schaltfläche "Passwort vergessen?" klicken und Ihr Passwort zurücksetzen.',
        'tp.registration.error.title': 'Fehler',
        'tp.registration.error.offline':
            'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
        'tp.registration.error.timeout':
            'Der Vorgang hat das Zeitlimit überschritten. Bitte versuchen Sie es erneut.',
        'tp.registration.error.internalserver':
            'Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitte Ihre zuständige Kontaktperson.',
        'tp.registration.error.invalidemaildomain':
            'Die E-Mail Domain der angegebenen E-Mailadresse ist nicht erlaubt. Bitte verwenden Sie die E-Mailadresse, die Ihnen von Ihrem Arbeitgeber zur Verfügung gestellt wird.',
        'tp.registration.error.invalidemailformat': 'Ungültige E-Mailadresse.'
    }
};
