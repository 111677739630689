import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

/**
 * @{custom field Template for the custom Form schema }
 * @param props
 */
const AdHocCheckCustomFieldTemplate = (props) => {
    const {id, label, classNames, required, children} = props;

    return (
        <div className={classNames}>
            <label className="tp-adhoccheck-field_label" htmlFor={id}>
                {required ? (
                    <span className="tp-adhoccheck-required">*</span>
                ) : null}
                <WebString webStringKey={label} />
            </label>
            {children}
        </div>
    );
};

AdHocCheckCustomFieldTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    classNames: PropTypes.string,
    required: PropTypes.bool,
    errors: PropTypes.element
};

AdHocCheckCustomFieldTemplate.defaultProps = {
    id: '',
    classNames: '',
    label: '',
    required: false,
    errors: null
};

export default AdHocCheckCustomFieldTemplate;
