import React from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import MainErrorBar from 'components/mainError/MainErrorBar';

const MainLayout = ({children}) => (
    <>
        <Header />
        <MainErrorBar />
        {children}
        <Footer />
    </>
);

export default MainLayout;
