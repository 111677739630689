import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

/**
 * wrapper around TpForm which renders the form as a div
 * element rather than a form.
 * I can't remember why, sorry.
 */
class DivRenderableForm extends Form {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object,
        formData: PropTypes.any,
        widgets: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ),
        fields: PropTypes.objectOf(PropTypes.func),
        ArrayFieldTemplate: PropTypes.func,
        ObjectFieldTemplate: PropTypes.func,
        FieldTemplate: PropTypes.func,
        ErrorList: PropTypes.func,
        onChange: PropTypes.func,
        onError: PropTypes.func,
        showErrorList: PropTypes.bool,
        onSubmit: PropTypes.func,
        id: PropTypes.string,
        className: PropTypes.string,
        name: PropTypes.string,
        method: PropTypes.string,
        target: PropTypes.string,
        disabled: PropTypes.bool,
        action: PropTypes.string,
        autocomplete: PropTypes.string,
        enctype: PropTypes.string,
        acceptcharset: PropTypes.string,
        noValidate: PropTypes.bool,
        noHtml5Validate: PropTypes.bool,
        liveValidate: PropTypes.bool,
        transformErrors: PropTypes.func,
        safeRenderCompletion: PropTypes.bool,
        formContext: PropTypes.object,
        customFormats: PropTypes.object,
        additionalMetaSchemas: PropTypes.arrayOf(PropTypes.object),
        renderAsDiv: PropTypes.bool.isRequired
    };

    render() {
        return this.props.renderAsDiv
            ? {...super.render(), type: 'div'}
            : super.render();
    }
}

export default DivRenderableForm;
