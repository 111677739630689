import React from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import TpTable from '../tables/TpTable';
import * as tpProposalActions from 'store/actions/processActions';
import {getProposalsTableColumns} from './utilities/tableColumns';
import TableTypes from 'types/TableTypes';
import CreateProposalButton from './utilities/CreateProposalButton';
import urls from 'routes/urls';
import UserRoleTypes from 'types/UserRoleTypes';
import getTableDataFromStateAndDispatchHelper from 'components/dashboard/utilities/tablesStateWithDispatchHelper';

/**
 * Provides table configuration and API call action to TpTable component.
 */

const ApplicantProposalsTable = ({userRole}) => {
    const history = useHistory();
    const dataFromState = getTableDataFromStateAndDispatchHelper(
        userRole,
        TableTypes.PROPOSAL,
        tpProposalActions.getAllProposals
    );
    const tableColumns = getProposalsTableColumns(
        dataFromState.columns,
        dataFromState.filterOptions
    );
    const onTableRowClick = (row) => {
        const url = row.imported
            ? `${urls.PROPOSAL_URL}/${row.id}/details`
            : `${urls.PROPOSAL_URL}/${row.id}`;
        history.push(url);
    };

    const mappedStateAndProps = {
        data: dataFromState.data,
        isFetchingData: dataFromState.isFetchingData,
        searchParams: dataFromState.searchParams,
        totalPages: dataFromState.totalPages,
        locale: dataFromState.locale,
        defaultPageSize: dataFromState.defaultPageSize,
        fetchData: dataFromState.fetchData,
        tableColumns,
        onTableRowClick,
        titleWebstringKey: 'tp.table.proposals.title',
        descriptionWebstringKey: 'tp.table.proposals.text',
        defaultSorted: [
            {
                id: 'proposalId',
                desc: true
            }
        ],
        customButton: <CreateProposalButton />
    };

    return <TpTable {...mappedStateAndProps} />;
};

ApplicantProposalsTable.propTypes = {
    userRole: PropTypes.oneOf(Object.values(UserRoleTypes)).isRequired
};

export default ApplicantProposalsTable;
