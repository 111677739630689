import React from 'react';
import PropTypes from 'prop-types';
import StringPlaceholder from 'lib/StringPlaceholder';
import WebString from 'components/webString/WebString';

const ErrorField = ({
    invalidDataError,
    invalidFormatError,
    invalidEmailError,
    fileName,
    importError
}) => {
    return (
        <div className="tp-import-users-csv__error-container">
            {invalidEmailError || invalidDataError || invalidFormatError ? (
                <div className={'tp-import-users-csv-error-header'}>
                    <WebString
                        className="tp-import-csv__error-header-webstring"
                        webStringKey={`tp.importuserscsverror.header.text`}
                        placeholder={
                            new StringPlaceholder('fileName', fileName)
                        }
                    />
                </div>
            ) : null}
            {invalidDataError ? (
                <WebString
                    className="tp-import-csv__error-invaliddata-webstring"
                    webStringKey={`tp.importuserscsverror.invaliddata.text`}
                />
            ) : null}
            {invalidFormatError ? (
                <WebString
                    className="tp-import-csv__error-invalidformat-webstring"
                    webStringKey={`tp.importuserscsverror.invalidformat.text`}
                />
            ) : null}
            {invalidEmailError ? (
                <WebString
                    className="tp-import-csv__error-invalidemail-webstring"
                    webStringKey={`tp.importuserscsverror.invalidemail.text`}
                />
            ) : null}
            {importError ? (
                <WebString
                    className="tp-import-csv__error-importerror-webstring"
                    webStringKey={`tp.importuserscsverror.importerror.text`}
                />
            ) : null}
        </div>
    );
};

ErrorField.propTypes = {
    invalidDataError: PropTypes.bool.isRequired,
    invalidFormatError: PropTypes.bool.isRequired,
    invalidEmailError: PropTypes.bool.isRequired,
    importError: PropTypes.bool.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ErrorField;
