import React from 'react';
import PropTypes from 'prop-types';

/**
 * Box in bottom of right of form area containing information about
 * the next user actions. Most commonly 'Next' and 'Cancel' buttons,
 * but can contain any content.
 */
export default class ActionPanel extends React.Component {
    static propTypes = {
        //object type is used to pass WebString object
        bodyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        bodyContent: '',
        footerContent: ''
    };

    constructor(props) {
        super(props);
        this.displayName = 'ActionPanel';
    }

    render() {
        return (
            <div
                id="next-task-panel"
                className="bk-cs-box bk-cs-box--bordered bk-cs-bg-white">
                <div className="bk-cs-box__content">
                    {this.props.bodyContent}
                </div>
                <div className="bk-cs-box__footer">
                    {this.props.footerContent}
                </div>
            </div>
        );
    }
}
