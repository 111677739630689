import React, {useEffect} from 'react';
import {IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    setShowMoreOrLess,
    resetShowMoreOrLess,
    toggleShowMore
} from 'store/actions/userManagementActions';

export const ShowMore = (props) => {
    useEffect(() => {
        // Anything in here is fired on component mount.
        props.setShowMoreOrLess(props.id, false);
        return () => {
            // Anything in here is fired on component unmount.
            props.resetShowMoreOrLess(props.id);
        };
    }, []);

    const handleOnClick = () => {
        props.toggleShowMore(props.id);
    };

    return (
        <div className="tp-all-users-table__iconbutton">
            {!props.isShowingMore ? (
                <IconButton
                    className="tp-all-users-table__showmorebutton"
                    key="showmore"
                    onClick={handleOnClick}>
                    <i className="tp-all-users-table__showmoreicon" />
                </IconButton>
            ) : (
                <IconButton
                    className="tp-all-users-table__showlessbutton"
                    key="showless"
                    onClick={handleOnClick}>
                    <i className="tp-all-users-table__showlessicon" />
                </IconButton>
            )}
        </div>
    );
};
ShowMore.propTypes = {
    id: PropTypes.number.isRequired,
    isShowingMore: PropTypes.bool.isRequired,
    setShowMoreOrLess: PropTypes.func.isRequired,
    resetShowMoreOrLess: PropTypes.func.isRequired,
    toggleShowMore: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    setShowMoreOrLess(id, showMore) {
        dispatch(setShowMoreOrLess(id, showMore));
    },
    resetShowMoreOrLess(id) {
        dispatch(resetShowMoreOrLess(id));
    },
    toggleShowMore(id) {
        dispatch(toggleShowMore(id));
    }
});

export default connect(null, mapDispatchToProps)(ShowMore);
