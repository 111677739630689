import React from 'react';
import PropTypes from 'prop-types';
import * as widgetUtils from '../widgetUtils';
import i18nUtils from 'lib/i18nUtils';
import TextOnlyBaseInputWidget from './TextOnlyBaseInputWidget';

/**
 * Fetches the key for the selectedValue and retrieves the webstring value with the webstringkey.
 * @param {Object} stringResources
 * @param {Object} enumOptions
 * @param {String} selectedValue
 * @return {String} The translated value for the webstrings.
 */
const translateEnumValue = (stringResources, enumOptions, selectedValue) => {
    const webstringKeyOfSelection = widgetUtils.getLabelOfSelectedOption(
        enumOptions,
        selectedValue
    );
    return i18nUtils.getTranslatedWebStringKey(
        stringResources,
        webstringKeyOfSelection
    );
};

/**
 * Text only display of select widget.
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */
export const TextOnlySelectWidget = (props) => {
    const {
        formContext: {stringResources},
        options: {enumOptions},
        value
    } = props;

    const displayValue = [];
    if (Array.isArray(value)) {
        value.forEach((val) =>
            displayValue.push(
                translateEnumValue(stringResources, enumOptions, val)
            )
        );
    } else {
        displayValue.push(
            translateEnumValue(stringResources, enumOptions, value)
        );
    }

    return (
        <TextOnlyBaseInputWidget
            id={props.id}
            value={displayValue.join(', ')}
        />
    );
};

TextOnlySelectWidget.propTypes = {
    id: PropTypes.string.isRequired,
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array
    }),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

TextOnlySelectWidget.defaultProps = {
    options: {
        enumOptions: []
    },
    value: null
};

export default TextOnlySelectWidget;
