import React from 'react';
import PropTypes from 'prop-types';
import StatusTypes from 'types/StatusTypes';
import WebString from 'components/webString/WebString';

/**
 * Single item on the ProgressBar component.
 * Allows click actions via prop.
 */
export default class ProgressBarTask extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        status: PropTypes.oneOf([...Object.values(StatusTypes.Task), 'EMPTY'])
            .isRequired,
        webStringKey: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    handleClick = () => {
        this.props.onClick(this.props.id);
    };

    render() {
        return (
            <li
                onClick={this.handleClick}
                id={this.props.id}
                ref={this.props.innerRef}
                className={`cs-progressbar__task cs-progressbar__task--state-${this.props.status.toLowerCase()}`}>
                <WebString
                    className={'cs-progressbar__label'}
                    webStringKey={this.props.webStringKey}
                />
            </li>
        );
    }
}
