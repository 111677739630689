import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

/**
 * Represents a row in the duplicate core data list.
 */
export default class DuplicateCoreDataRow extends React.Component {
    static propTypes = {
        _partnerOneOfType: PropTypes.string.isRequired,
        address: PropTypes.shape({
            country: PropTypes.string.isRequired,
            firstAddressField: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
            secondAddressField: PropTypes.string
        }).isRequired,
        firstName: PropTypes.string,
        surname: PropTypes.string,
        companyName: PropTypes.string,
        legalForm: PropTypes.string,
        dunsNumber: PropTypes.shape({
            value: PropTypes.string.isRequired
        }),
        imported: PropTypes.bool
    };

    static defaultProps = {
        firstName: '',
        surName: '',
        companyName: '',
        legalForm: '',
        dunsNumber: null,
        imported: false
    };

    renderTypeAndFlag = () => {
        const duplicate = this.props;
        return (
            <div className="row" id="dublicate_typeAndFlag_section">
                <div className={`col-md-6 bk-tp-duplicate-table__data-block`}>
                    <WebString webStringKey="tp.table.duplicate.coredata.partnertype.title" />
                    :
                    <WebString
                        className="bk-cs-padding-l--small bk-cs-inline"
                        webStringKey={`tp.table.duplicate.coredata.partnertype.${duplicate._partnerOneOfType}`}
                    />
                </div>
                <div className={`col-md-6 bk-tp-duplicate-table__data-block`}>
                    {duplicate.imported ? (
                        <WebString
                            className={`label label-default bk-tp-duplicate-table__imported-flag`}
                            webStringKey="tp.table.duplicate.coredata.imported"
                        />
                    ) : null}
                </div>
            </div>
        );
    };

    renderName = () => {
        const duplicate = this.props;
        return duplicate.companyName ? (
            <div
                className="bk-tp-duplicate-table__data-block"
                id="dublicate_name_section_company">
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.companyname.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {duplicate.companyName}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.legalform.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {duplicate.legalForm}
                    </span>
                </div>
                {!!duplicate.dunsNumber ? (
                    <div>
                        <WebString webStringKey="tp.table.duplicate.coredata.duns.title" />
                        :
                        <span className="bk-cs-padding-l--small">
                            {duplicate.dunsNumber && duplicate.dunsNumber.value}
                        </span>
                    </div>
                ) : null}
            </div>
        ) : (
            <div
                className={`bk-tp-duplicate-table__data-block`}
                id="dublicate_name_section_person">
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.firstname.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {duplicate.firstName}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.surname.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {duplicate.surname}
                    </span>
                </div>
            </div>
        );
    };

    renderAddress = () => {
        const {address} = this.props;
        return (
            <div
                className="bk-tp-duplicate-table__data-block"
                id="dublicate_address_section">
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.country.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {address.country}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.location.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {address.location}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.postcode.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {address.postcode}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.address1.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {address.firstAddressField}
                    </span>
                </div>
                <div>
                    <WebString webStringKey="tp.table.duplicate.coredata.address2.title" />
                    :
                    <span className="bk-cs-padding-l--small">
                        {address.secondAddressField}
                    </span>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="bk-tp-duplicate-table__col-data">
                {this.renderTypeAndFlag()}
                {this.renderName()}
                {this.renderAddress()}
            </div>
        );
    }
}
