export default {
    title: 'tp.registration.form.title',
    type: 'object',
    required: ['email'],
    properties: {
        email: {
            type: 'string',
            title: 'tp.registration.email.title',
            maxLength: 254,
            pattern:
                '^([^\\s@]{1,64}@([^_+*~:;,#\'?!\\"§$%&/()=?`´²³{\\[\\]}\\s]+\\.)+([a-zA-z]+)$|)$'
        }
    }
};
