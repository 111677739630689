import React from 'react';
import BoxInformation from 'components/authorization/BoxInformation';

const LoginPageWithBoxInfo = () => (
    <BoxInformation
        headerText={'Achtung / Attention'}
        content={
            <div>
                <p>
                    Bitte melden Sie sich an, um auf die Seite zugreifen zu
                    können.
                </p>
                <p>Please log in to access this page.</p>
            </div>
        }
    />
);

export default LoginPageWithBoxInfo;
