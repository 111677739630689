import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/tpForm/Form';
import ScopeField from './fields/ScopeField';
import ObjectFieldTemplate from './fields/ObjectFieldTemplate';
import FieldTemplate from './fields/FieldTemplate';
import ArrayFieldTemplate from './fields/ArrayFieldTemplate';

export const UserForm = (props) => {
    const submitButton = {
        submitButtonWebStringKey: 'cs.button.save',
        submitButtonClassNames: 'bk-cs-float-right',
        submitButtonId: 'btn-save-user',
        submitButtonIsDisabled: props.submitButtonIsDisabled,
        showSubmitButton: props.showSubmitButton
    };

    return (
        <Form
            id={'user-form'}
            {...submitButton}
            onSubmit={props.onSubmit}
            onChange={props.onChange}
            onError={props.onError}
            formData={props.formData}
            schema={props.schema}
            uiSchema={props.uiSchema}
            customFields={{scopeField: ScopeField}}
            customFieldTemplate={FieldTemplate}
            customObjectFieldTemplate={ObjectFieldTemplate}
            customArrayFieldTemplate={ArrayFieldTemplate}
            stringResources={props.stringResources}
            disabled={props.isDisabled}
            hideBorder={true}>
            {props.children}
        </Form>
    );
};

UserForm.propTypes = {
    stringResources: PropTypes.object.isRequired,

    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired,

    onChange: PropTypes.func.isRequired,

    onSubmit: PropTypes.func.isRequired,
    submitButtonIsDisabled: PropTypes.bool,
    showSubmitButton: PropTypes.bool,

    isDisabled: PropTypes.bool,
    formData: PropTypes.object
};

UserForm.defaultProps = {
    isDisabled: false,
    submitButtonIsDisabled: false,
    showSubmitButton: true,
    formData: {}
};

export default UserForm;
