import {country} from './AdHocCheckCountryObject';

/**
 * form Schemas :
 * AdHocCheckUnknown : Selection of type of Business partner
 * AdHocCheckCompanySchema: Type of business partner is company
 * AdHocCheckPersonSchema : Type of business partner is person
 * uiSchemas:
 * AdHocCheckUnknownUiSchema : Selection of type of Business partner
 * AdHocCheckCompanyUiSchema: Type of business partner is company
 * AdHocCheckPersonUiSchema : Type of business partner is person
 */

// reused literals -- schema building blocks
const typeOfBP = {
    type: 'string',
    title: 'tp.form.adhoccheck.partnertype.title',
    enum: ['PERSON', 'COMPANY'],
    enumNames: [
        'tp.form.adhoccheck.partnertype.person',
        'tp.form.adhoccheck.partnertype.company'
    ]
};
const name = {
    type: 'string',
    title: 'tp.form.adhoccheck.companyname.title',
    maxLength: 150
};
const firstName = {
    type: 'string',
    title: 'tp.form.adhoccheck.firstname.title',
    maxLength: 150
};

const lastName = {
    type: 'string',
    title: 'tp.form.adhoccheck.lastname.title',
    maxLength: 150
};

const uiSchemaFormRowClassName = 'tp-adhoccheck__form-row';
const uiSchemaClassNames = {
    classNames: 'tp-adhoccheck__form-subrow'
};
const uiSchemaPropertyWithPlaceholder = {
    ...uiSchemaClassNames,
    'ui:placeholder': 'cs.form.please.select'
};

// schemas
const AdHocCheckUnknown = {
    type: 'object',
    required: ['typeOfBP'],
    properties: {
        typeOfBP
    }
};

const AdHocCheckUnknownUiSchema = {
    'ui:order': ['typeOfBP'],
    classNames: uiSchemaFormRowClassName,
    typeOfBP: uiSchemaPropertyWithPlaceholder
};

const AdHocCheckCompanySchema = {
    type: 'object',
    required: ['typeOfBP', 'name'],
    properties: {
        typeOfBP,
        country,
        name
    }
};

const AdHocCheckCompanyUiSchema = {
    classNames: uiSchemaFormRowClassName,
    'ui:order': ['typeOfBP', 'name', 'country'],
    typeOfBP: uiSchemaPropertyWithPlaceholder,
    name: uiSchemaClassNames,
    country: {
        ...uiSchemaPropertyWithPlaceholder,
        'ui:options': {
            sorted: true
        }
    }
};

const AdHocCheckPersonSchema = {
    type: 'object',
    required: ['typeOfBP', 'name'],
    properties: {
        typeOfBP,
        country,
        name: lastName,
        firstName
    }
};
const AdHocCheckPersonUiSchema = {
    classNames: uiSchemaFormRowClassName,
    'ui:order': ['typeOfBP', 'firstName', 'name', 'country'],
    typeOfBP: uiSchemaPropertyWithPlaceholder,
    firstName: uiSchemaClassNames,
    name: uiSchemaClassNames,
    country: {
        ...uiSchemaPropertyWithPlaceholder,
        'ui:options': {
            sorted: true
        }
    }
};

export {
    AdHocCheckPersonSchema,
    AdHocCheckCompanySchema,
    AdHocCheckUnknown,
    AdHocCheckUnknownUiSchema,
    AdHocCheckCompanyUiSchema,
    AdHocCheckPersonUiSchema
};
