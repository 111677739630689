const StatusTypes = {
    Task: {
        OPEN: 'OPEN',
        WAITING: 'WAITING',
        RUNNING: 'RUNNING',
        ABORTED: 'ABORTED',
        COMPLETED: 'COMPLETED'
    },
    Proposal: {
        ACTIVE: 'ACTIVE',
        INACTIVE: 'INACTIVE',
        APPROVED: 'APPROVED',
        AUTO_APPROVED: 'AUTO_APPROVED',
        APPROVED_RESTRICTIONS: 'APPROVED_RESTRICTIONS',
        PENDING: 'PENDING',
        PENDING_LEVEL2: 'PENDING_LEVEL2',
        PENDING_LEVEL3: 'PENDING_LEVEL3',
        DECLINED: 'DECLINED',
        AUTO_DECLINED: 'AUTO_DECLINED',
        CLOSED: 'CLOSED',
        ABORTED: 'ABORTED',
        EXTERNAL: 'EXTERNAL',
        FEEDBACK_REQUESTED: 'FEEDBACK_REQUESTED',
        ANNOUNCED: 'ANNOUNCED',
        SUBMITTED: 'SUBMITTED',
        APPROVAL_NOT_NEEDED: 'APPROVAL_NOT_NEEDED'
    },
    Alerts: {
        Assessment: {
            OPEN: 'OPEN',
            CLOSED: 'CLOSED'
        },
        Processing: {
            WAITING: 'WAITING',
            COMPLETED: 'COMPLETED'
        }
    }
};

const {
    ACTIVE,
    PENDING,
    PENDING_LEVEL2,
    PENDING_LEVEL3,
    FEEDBACK_REQUESTED,
    SUBMITTED,
    INACTIVE,
    APPROVED,
    AUTO_APPROVED,
    APPROVED_RESTRICTIONS,
    AUTO_DECLINED,
    ABORTED,
    CLOSED,
    EXTERNAL,
    DECLINED,
    APPROVAL_NOT_NEEDED,
    ANNOUNCED
} = StatusTypes.Proposal;

export const proposalRunningTypes = [
    ACTIVE,
    PENDING,
    SUBMITTED,
    PENDING_LEVEL2,
    PENDING_LEVEL3,
    FEEDBACK_REQUESTED
];
export const proposalClosedTypes = [
    APPROVED,
    AUTO_APPROVED,
    APPROVED_RESTRICTIONS,
    AUTO_DECLINED,
    ABORTED,
    CLOSED,
    EXTERNAL,
    INACTIVE,
    DECLINED,
    ANNOUNCED,
    APPROVAL_NOT_NEEDED
];

export default StatusTypes;
