import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import AdHocCheckResultEntry from './AdHocCheckResultEntry';

/**
 * Render the ad hoc check result rows in a table.
 * The results are sorted by descending risk match percentage.
 */
export const AdHocCheckResultsTable = (props) => {
    const sortedEntries = props.data
        .sort(function (a, b) {
            return a.matchPct - b.matchPct;
        })
        .reverse();
    return (
        <div className="tp-adhoccheck-results">
            <table id="adhoccheck-table" className="tp-adhoccheck__table">
                <caption className="tp-adhoccheck-table__caption">
                    <WebString webStringKey="tp.form.adhoccheck.table.header" />
                </caption>
                <thead className="tp-adhoccheck__table-head">
                    <tr className="tp-adhoccheck__table-head-row">
                        <th className="tp-adhoccheck__column-header">
                            <WebString webStringKey="tp.form.adhoccheck.flags.header" />
                        </th>
                        <th className="tp-adhoccheck__column-name-header">
                            <WebString webStringKey="tp.form.adhoccheck.name.header" />
                        </th>
                        <th className="tp-adhoccheck__column-header">
                            <WebString webStringKey="tp.form.adhoccheck.country.header" />
                        </th>
                        <th className="tp-adhoccheck__column-header">
                            <WebString webStringKey="tp.form.adhoccheck.match.header" />
                        </th>
                    </tr>
                </thead>
                {sortedEntries.map((tableRow) => {
                    return (
                        <AdHocCheckResultEntry
                            key={tableRow.peId}
                            tableRow={tableRow}
                        />
                    );
                })}
            </table>
        </div>
    );
};

AdHocCheckResultsTable.propTypes = {
    data: PropTypes.array.isRequired
};

export default AdHocCheckResultsTable;
