import {handle} from 'redux-pack';
import {getShowMore} from '../../components/users/rolesUtils';

const CREATE_USER = 'CREATE_USER';
const DELETE_USER = 'DELETE_USER';
const LOAD_ALL_USERS = 'LOAD_ALL_USERS';
const SAVE_USERS_TABLE_SETTINGS = 'SAVE_USERS_TABLE_SETTINGS';
const TOGGLE_SHOW_MORE = 'TOGGLE_SHOW_MORE';
const SET_SHOW_MORE_OR_LESS = 'SET_SHOW_MORE_OR_LESS';
const RESET_SHOW_MORE_OR_LESS = 'RESET_SHOW_MORE_OR_LESS';
export const ACTION_TYPES = {
    CREATE_USER,
    DELETE_USER,
    LOAD_ALL_USERS,
    SAVE_USERS_TABLE_SETTINGS,
    TOGGLE_SHOW_MORE,
    SET_SHOW_MORE_OR_LESS,
    RESET_SHOW_MORE_OR_LESS
};

const defaultState = {
    table: {
        allUsersLoading: false,
        users: [],
        totalPages: 0,
        searchParams: {},
        roleInfoDisplayStateArray: []
    }
};

const filterExistingShowMoreIds = (showmoreitems, id) => {
    return [...showmoreitems].filter((item) => item.id !== id);
};

const userManagementReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    let showMoreitems = [];
    switch (type) {
        case CREATE_USER:
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    table: {
                        users: [payload, ...prevState.table.users],
                        totalPages: prevState.table.totalPages
                    }
                })
            });
        case LOAD_ALL_USERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    table: {
                        ...state.table,
                        allUsersLoading: true
                    }
                }),
                success: (prevState) => ({
                    ...prevState,
                    table: {
                        ...state.table,
                        totalPages: payload.totalPages,
                        searchParams: {
                            ...action.meta.searchParams
                        },
                        allUsersLoading: false,
                        users: payload.users
                    }
                }),
                failure: (prevState) => ({
                    ...prevState,
                    table: {
                        ...state.table,
                        allUsersLoading: false
                    }
                })
            });
        case SAVE_USERS_TABLE_SETTINGS:
            return {
                ...state,
                table: {
                    ...state.table,
                    searchParams: {
                        ...state.table.searchParams,
                        ...action.filterSettings
                    }
                }
            };
        case SET_SHOW_MORE_OR_LESS:
            showMoreitems = filterExistingShowMoreIds(
                state.table.roleInfoDisplayStateArray,
                action.id
            );
            showMoreitems.push({id: action.id, showMore: action.showMore});
            return {
                ...state,
                table: {
                    ...state.table,
                    roleInfoDisplayStateArray: showMoreitems
                }
            };
        case RESET_SHOW_MORE_OR_LESS:
            showMoreitems = filterExistingShowMoreIds(
                state.table.roleInfoDisplayStateArray,
                action.id
            );
            return {
                ...state,
                table: {
                    ...state.table,
                    roleInfoDisplayStateArray: showMoreitems
                }
            };
        case TOGGLE_SHOW_MORE:
            showMoreitems = filterExistingShowMoreIds(
                state.table.roleInfoDisplayStateArray,
                action.id
            );
            const showingMore = getShowMore(
                state.table.roleInfoDisplayStateArray,
                action.id
            );
            showMoreitems.push({id: action.id, showMore: !showingMore});
            return {
                ...state,
                table: {
                    ...state.table,
                    roleInfoDisplayStateArray: showMoreitems
                }
            };
        default:
            return state;
    }
};

export default userManagementReducer;
