const getReportsSchema = (maximumDate) => {
    return {
        $schema: 'http://json-schema.org/draft-04/schema#',
        title: 'tp.form.reportgeneration.title',
        type: 'object',
        required: ['dateRange', 'reportType'],
        properties: {
            dateRange: {
                title: 'tp.form.reportgeneration.daterange.title',
                type: 'object',
                required: ['startDate', 'endDate'],
                properties: {
                    startDate: {
                        title: 'tp.form.reportgeneration.startdate.title',
                        type: 'number',
                        maximum: maximumDate
                    },
                    endDate: {
                        title: 'tp.form.reportgeneration.enddate.title',
                        type: 'number',
                        maximum: maximumDate
                    }
                }
            },
            reportType: {
                type: 'string',
                title: 'tp.form.reportgeneration.reporttype.title',
                enum: ['COMPLETE', 'IMPORTED', 'ATTACHMENTS'],
                enumNames: [
                    'tp.form.reportgeneration.reporttype.option.completeexcelreport',
                    'Imported proposals',
                    'Attachments'
                ]
            }
        }
    };
};

const generateReportsUiSchema = {
    'ui:order': ['dateRange', 'reportType'],
    dateRange: {
        'ui:field': 'dateRangeField',
        hideHeader: true
    },
    reportType: {
        'ui:placeholder': 'cs.form.please.select'
    },
    disableFoldingHeader: true,
    hideHeader: true
};

export {getReportsSchema, generateReportsUiSchema};
