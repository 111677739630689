import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'components/tpForm/Form';
import WebString from 'components/webString/WebString';
import * as businessPartnersActions from 'store/actions/businessPartnersActions';
import * as snackActions from 'store/actions/snackActions';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import TpButton from 'components/common/buttons/TpButton';
import StringPlaceholder from 'lib/StringPlaceholder';
import businessPartnerUtils from './businessPartnerUtils';
import BusinessPartnerTypes from 'types/BusinessPartnerTypes';

const getSuccessSnackProperties = (businessPartnerId, name) => ({
    id: `snack-edit-${businessPartnerId}-business-partner-success`,
    snackType: TpSnackbarTypes.SUCCESS,
    autoHideDuration: 2000,
    message: (
        <WebString
            placeholder={new StringPlaceholder('bpname', name)}
            webStringKey={'tp.businesspartnerdetails.editcoredata.success'}
        />
    )
});

const BusinessPartnerEditCoreData = (props) => {
    const [formData, setFormData] = useState({});
    const stringResources = useSelector(
        (state) => state.settings.i18n.stringResources
    );
    const dispatch = useDispatch();

    useEffect(() => {
        const bpFormData = businessPartnerUtils.pickBusinessPartnerFormData(
            props.businessPartner
        );
        setFormData(bpFormData);
    }, []);

    const handleEditCoreData = async ({formData}) => {
        const result = await dispatch(
            businessPartnersActions.updateCoreData(
                props.businessPartner.id,
                formData
            )
        );

        if (!result.error) {
            props.onCloseEditForm();
            dispatch(
                snackActions.createSnack(
                    getSuccessSnackProperties(
                        props.businessPartner.id,
                        formData.name
                    )
                )
            );
        }
    };

    const handleCancel = () => {
        props.onCloseEditForm();
    };

    const handleChange = ({formData}) => {
        setFormData(formData);
    };

    return (
        <Form
            id="form-edit-core-data"
            onSubmit={handleEditCoreData}
            onChange={handleChange}
            {...businessPartnerUtils.getBusinessPartnerSchemas(
                props.businessPartner.partnerType
            )}
            formData={formData}
            stringResources={stringResources}
            showSubmitButton={false}>
            <div className={'bk-cs-margin-l--lg bk-cs-margin-bottom--lg'}>
                <TpButton
                    type={'submit'}
                    className={'bk-cs-margin-r--small'}
                    id={'tp-business-partner-edit-core-data-savebtn'}
                    webStringKey={
                        'tp.businesspartnerdetails.editcoredata.savebutton'
                    }
                />
                <TpButton
                    onClick={handleCancel}
                    id={'tp-business-partner-edit-core-data-cancelbtn'}
                    webStringKey={
                        'tp.businesspartnerdetails.editcoredata.cancelbutton'
                    }
                />
            </div>
        </Form>
    );
};

BusinessPartnerEditCoreData.propTypes = {
    onCloseEditForm: PropTypes.func.isRequired,
    businessPartner: PropTypes.shape({
        partnerType: PropTypes.oneOf(Object.values(BusinessPartnerTypes))
            .isRequired,
        id: PropTypes.number.isRequired
    }).isRequired
};

export default BusinessPartnerEditCoreData;
