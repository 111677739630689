import {ACTION_TYPES as PROCESS_ACTION_TYPES} from './processReducer';
import {findRootFieldId} from 'components/tpForm/fields/fieldsUtils';

const OPEN_CONTAINER = 'OPEN_CONTAINER';
const OPEN_PARENT_CONTAINER = 'OPEN_PARENT_CONTAINER';
const BATCH_OPEN_CONTAINERS = 'BATCH_OPEN_CONTAINERS';
const BATCH_CLOSE_CONTAINERS = 'BATCH_CLOSE_CONTAINERS';
const TOGGLE_CONTAINER = 'TOGGLE_CONTAINER';
const REGISTER_CONTAINER = 'REGISTER_CONTAINER';
const UNREGISTER_CONTAINER = 'UNREGISTER_CONTAINER';

export const ACTION_TYPES = {
    OPEN_CONTAINER,
    OPEN_PARENT_CONTAINER,
    BATCH_OPEN_CONTAINERS,
    BATCH_CLOSE_CONTAINERS,
    TOGGLE_CONTAINER,
    REGISTER_CONTAINER,
    UNREGISTER_CONTAINER
};

const defaultState = {
    containers: []
};

const getContainersExcluding = (containers, id) => {
    return [...containers].filter((container) => container.id !== id);
};

const getContainerOpenState = (containers, id) => {
    const container = containers.find((container) => container.id === id);
    return container ? container.isOpen : false;
};

const setContainerState = (stateContainers, changedContainers, isOpen) => {
    const stateContainersCopy = stateContainers.map((container) => ({
        id: container.id,
        isOpen: container.isOpen
    }));

    changedContainers.forEach((container) => {
        stateContainersCopy.find(
            (stateContainer) => container.id === stateContainer.id
        ).isOpen = isOpen;
    });

    return stateContainersCopy;
};

const formReducer = (state = defaultState, action) => {
    let containers = undefined;

    switch (action.type) {
        case OPEN_CONTAINER:
            containers = getContainersExcluding(state.containers, action.id);
            containers.push({id: action.id, isOpen: true});
            return {
                ...state,
                containers
            };
        case OPEN_PARENT_CONTAINER:
            containers = getContainersExcluding(state.containers, action.id);
            const parentId = findRootFieldId(action.id, state.containers);
            containers.push({id: parentId, isOpen: true});
            return {
                ...state,
                containers
            };
        case BATCH_OPEN_CONTAINERS:
            return {
                ...state,
                containers: setContainerState(
                    state.containers,
                    action.containers,
                    true
                )
            };
        case BATCH_CLOSE_CONTAINERS:
            return {
                ...state,
                containers: setContainerState(
                    state.containers,
                    action.containers,
                    false
                )
            };
        case TOGGLE_CONTAINER:
            containers = getContainersExcluding(state.containers, action.id);
            const openState = getContainerOpenState(
                state.containers,
                action.id
            );
            containers.push({id: action.id, isOpen: !openState});
            return {
                ...state,
                containers
            };
        case REGISTER_CONTAINER: // TODO check undefined values?
            containers = getContainersExcluding(state.containers, action.id);
            containers.push({id: action.id, isOpen: action.isOpen});
            return {
                ...state,
                containers
            };
        case UNREGISTER_CONTAINER:
            containers = getContainersExcluding(state.containers, action.id);
            return {
                ...state,
                containers
            };
        // we also want to open all containers with errors on validation error
        case PROCESS_ACTION_TYPES.SHOW_ERRORS:
            // find container for each error
            const errorContainerIds = new Set(
                action.errors.map((error) =>
                    findRootFieldId(error.anchor, state.containers)
                )
            );

            // remove them from the state containers
            containers = state.containers.filter(
                (container) => !errorContainerIds.has(container.id)
            );

            // add them as open
            errorContainerIds.forEach((id) => {
                if (id !== undefined) {
                    containers.push({id: id, isOpen: true});
                }
            });

            return {
                ...state,
                containers
            };
        default:
            return state;
    }
};

export default formReducer;
