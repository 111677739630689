import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a global error, not related to a specific page / route.
 * Usually used for things over which the user has no control, such as
 * no connection the API.
 * Removed from display when the user clicks the close button.
 */
export const MainError = (props) => {
    const _close = () => {
        props.onClose(props.errKey);
    };

    return (
        <div className="tp-main-error container-fluid">
            <i className="tp-main-error__dismiss" onClick={_close} />
            <div className="bk-cs-bold">{props.title}</div>
            {props.webStringComponent}
        </div>
    );
};

MainError.propTypes = {
    /* The errKey will be passed on close */
    errKey: PropTypes.number.isRequired,
    /* The error title to show */
    title: PropTypes.string.isRequired,
    /* the function to execute when close is clicked */
    onClose: PropTypes.func.isRequired,
    /* WebString component */
    webStringComponent: PropTypes.element.isRequired
};
