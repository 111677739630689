import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import WebString from 'components/webString/WebString';

const columns = [
    {
        id: (entity) => `due-diligence-${entity.level.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.duediligence.columnheader.level" />
        ),
        accessor: 'level',
        width: 50
    },
    {
        id: (entity) => `due-diligence-${entity.name.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.duediligence.columnheader.name" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.name} />,
        width: 180
    },
    {
        id: (entity) => `due-diligence-${entity.points.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.duediligence.columnheader.points" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.points} />,
        width: 100
    }
];

const DueDiligence = (props) => {
    const {dueDiligence} = props;

    return (
        <div>
            <h4>
                <WebString webStringKey="tp.riskmatrix.duediligence.title" />
            </h4>
            <ReactTable
                className="-striped tp-risk-matrix__info-table"
                data={dueDiligence}
                columns={columns}
                showPagination={false}
                defaultPageSize={dueDiligence.length}
                resizable={false}
                sortable={false}
            />
        </div>
    );
};

DueDiligence.propTypes = {
    dueDiligence: PropTypes.array.isRequired
};

export default DueDiligence;
