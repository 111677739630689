import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {getAccountProductName} from 'lib/accountSpecific';

const HeaderNaviList = () => {
    const tenantAccount = useSelector((state) => state.sessionContext.account);

    if (!tenantAccount || !tenantAccount.id) {
        return null;
    }

    return (
        <ul className="bk-cs-header__nav-list bk-cs-nomargin bk-cs-nopadding">
            <li
                id="tp-module-link"
                className="bk-cs-header__nav-item bk-cs-color-grey03">
                <NavLink
                    className="bk-cs-header-link bk-cs-padding-horiz--sm"
                    activeClassName="bk-cs-header__nav-item--active bk-cs-bg-color--green01"
                    to={'/'}>
                    {getAccountProductName(tenantAccount.id)}
                </NavLink>
            </li>
        </ul>
    );
};

export default HeaderNaviList;
