import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import RenderRole from './RenderRole';
import {
    getRoleFromObject,
    getShowMore,
    MIN_NUM_OF_ROLES_TO_SHOW
} from '../../rolesUtils';
import ShowMore from '../ShowMore';

export const RoleValues = (props) => {
    if (!props || !props.value || props.value.length === 0) {
        return null;
    }
    const {value: roles} = props;

    let isShowingMore = false;
    const [rolesToShow, setRolesToShow] = useState(
        roles.length > 3 ? MIN_NUM_OF_ROLES_TO_SHOW : roles.length
    );
    const [showMore, setIsShowingMore] = useState(false);

    useEffect(() => {
        isShowingMore = getShowMore(
            props.roleInfoDisplayStateArray,
            props.row.userId
        );
        setRolesToShow(isShowingMore ? roles.length : MIN_NUM_OF_ROLES_TO_SHOW);
        setIsShowingMore(isShowingMore);
    }, [props.roleInfoDisplayStateArray]);

    return (
        <div className="tp-all-users-table__roles">
            {roles.slice(0, rolesToShow).map((roleItem, index) => {
                const role = getRoleFromObject(roleItem);
                let uniqueKey = `${role}-${props.index}_${index}`;
                return <RenderRole key={uniqueKey} role={role.toUpperCase()} />;
            })}
            {roles.length > MIN_NUM_OF_ROLES_TO_SHOW ? (
                <ShowMore isShowingMore={showMore} id={props.row.userId} />
            ) : null}
        </div>
    );
};
const mapStateToProps = (state) => ({
    roleInfoDisplayStateArray:
        state.userManagement.table.roleInfoDisplayStateArray
});

RoleValues.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    roleInfoDisplayStateArray: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            showMore: PropTypes.bool.isRequired
        })
    )
};

RoleValues.defaultProps = {
    roleInfoDisplayStateArray: []
};

export default connect(mapStateToProps)(RoleValues);
