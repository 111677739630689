import React, {useState} from 'react';
import TpButton from 'components/common/buttons/TpButton';
import TpPopup from 'components/common/TpPopup';
import EditOwnerScopeField from './EditOwnerScopeField';

const ProposalClassificationButton = () => {
    const [showPopUp, setShowPopUp] = useState(false);

    const proposalClassificationPopupConfig = {
        headerInfo: {
            webStringKey: 'tp.proposalclassification.header.text',
            type: 'generic',
            showCloseButton: true,
            className: 'tp-proposalclassification__popup-header'
        },
        size: 'large',
        className: 'tp-proposal-classification__pop-up'
    };

    const onClosePopup = () => {
        setShowPopUp(false);
    };

    const displayProposalClassificationPopUp = () => {
        setShowPopUp(true);
    };

    return (
        <div className="bk-cs-inline-block">
            <TpButton
                asLink={true}
                className={`bk-cs-link tp-meta-info-bar__proposal-classification-button`}
                iconClassName="tp-meta-info-bar__proposal-classification-button-icon"
                id="proposal-classification-btn"
                webStringKey="tp.button.proposalclassification"
                webStringClassName="tp-meta-info-bar__proposal-classification-button-webstring"
                onClick={displayProposalClassificationPopUp}
                isDefaultClass={false}
            />
            {showPopUp ? (
                <TpPopup
                    {...proposalClassificationPopupConfig}
                    onClose={onClosePopup}>
                    <EditOwnerScopeField onClosePopup={onClosePopup} />
                </TpPopup>
            ) : null}
        </div>
    );
};

export default ProposalClassificationButton;
