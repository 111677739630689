import React from 'react';
import PropTypes from 'prop-types';
import i18nUtils from 'lib/i18nUtils';
import TextOnlyBaseInputWidget from './TextOnlyBaseInputWidget';

/**
 * Text only display of checkbox widget.
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */
const TextOnlyCheckboxWidget = (props) => {
    const value = props.value === 'true' ? 'yes' : 'no';
    const valueWebstringKey = `cs.text.answer.${value}`;
    const label = i18nUtils.getTranslatedWebStringKey(
        props.formContext.stringResources,
        valueWebstringKey
    );
    return <TextOnlyBaseInputWidget id={props.id} value={label} />;
};

TextOnlyCheckboxWidget.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired
};

TextOnlyCheckboxWidget.defaultProps = {
    value: 'false'
};

export default TextOnlyCheckboxWidget;
