import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

/**
 * Will show text as info-i
 *
 * @param {object} props
 *
 * @returns {ReactElement} details element with its own style
 */
const TpDetailsInfo = (props) => (
    <details className="tp-details" open={!!props.detailsInfo.isOpenByDefault}>
        <summary
            id={`tp-details__icon::${props.detailsInfo.infoText}`}
            className="tp-details__icon"
        />
        <div className="tp-details__text">
            <WebString
                id={`tp-details__text::${props.detailsInfo.infoText}`}
                webStringKey={props.detailsInfo.infoText}
            />
        </div>
    </details>
);

TpDetailsInfo.propTypes = {
    detailsInfo: PropTypes.shape({
        infoText: PropTypes.string.isRequired,
        isOpenByDefault: PropTypes.bool
    }).isRequired
};

export default TpDetailsInfo;
