import i18nUtils from 'lib/i18nUtils';

/**
 * Finds the label of the selected dropdown value in the enumOptions.
 * An enumOptions element has the form {value, label}.
 * For example, 'value' could be the 2 letter country code and 'label' the
 * translated country name.
 *
 * @param {Array} enumOptions The array of country code and country name value pair.
 * @param {String} selected The 2 letter country code to search for.
 * @returns {String} The translated country name. If the corresponding option was not found, the
 * selected parameter is returned.
 */
export const getLabelOfSelectedOption = (enumOptions, selected) => {
    const enumOption = enumOptions.find((o) => o.value === selected);

    if (enumOption) {
        return enumOption.label;
    }

    return selected;
};

/**
 * Comparator function to sort objects alphabetically by label, case insensitively.
 *
 * @param {String} a
 * @param {String} b
 * @returns {Number} negative number: a is put before b;
 * 0: a is equal to b; positive number: a is put after b.
 */
export const sortByLabel = (a, b) => {
    const labelA = i18nUtils.normaliseAccentedCharacters(a.label.toLowerCase());
    const labelB = i18nUtils.normaliseAccentedCharacters(b.label.toLowerCase());

    if (labelA < labelB) {
        return -1;
    }
    if (labelA > labelB) {
        return 1;
    }

    return 0;
};

/**
 * Translates labels in enumOptions
 *
 * @param {Object} stringResources
 * @param {Object} options
 */
export const translateLabelsInEnumOptions = (stringResources, options) => ({
    ...options,
    enumOptions: options.enumOptions.map((option) => ({
        ...option,
        label: i18nUtils.getTranslatedWebStringKey(
            stringResources,
            option.label
        )
    }))
});
