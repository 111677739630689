import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import PreCheckPage from 'components/preCheck/PreCheckPage';
import UserFeatureTypes from 'types/UserFeatureTypes';
import NotFound from 'components/common/errorHandling/NotFound';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import AccountFeatureTypes from 'types/AccountFeatureTypes';

const PreCheckRoute = () => {
    const {path} = useRouteMatch();
    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[UserFeatureTypes.PROPOSAL]}
            defaultChildren={<NotFound />}>
            <AccountFeatureToggle
                requiredAccountFeature={AccountFeatureTypes.PRE_CHECK}
                defaultChildren={<NotFound />}>
                <Route path={path} component={PreCheckPage} />
            </AccountFeatureToggle>
        </UserFeatureToggle>
    );
};

export default PreCheckRoute;
