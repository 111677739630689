import React from 'react';
import PropTypes from 'prop-types';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import * as fieldsUtils from './fieldsUtils';

/**
 * Extended SchemaField of react-jsonschema-form with onBlur event.
 * For the type "string" trim the text
 * For the type "number" (not cs-date) parse to float.
 * react-jsonschema-form has a bug, it represents decimal numbers ending in '0' as a string.
 * Parsing to float helps to resolve it.
 *
 * @returns {SchemaField}
 */
const TpSchemaField = (props) => {
    const {
        schema: {type},
        uiSchema
    } = props;

    const isDateField = fieldsUtils.isDateField(uiSchema);

    const handleTypeArray = (value) => {
        if (typeof value === 'string' && type.includes('string')) {
            props.onChange(value ? value.trim() : '');
        } else if (type.includes('number')) {
            if (
                typeof value === 'string' &&
                !isNaN(parseFloat(value.replace(',', '.'))) &&
                !isDateField
            ) {
                // Helps to avoid the validation error for the decimal numbers ending in '0'.
                const newValue = parseFloat(value.replace(',', '.'));
                props.onChange(!isNaN(newValue) ? newValue : null);
            } else if (typeof value === 'number' && !isDateField) {
                props.onChange(value);
            } else if (value === '') {
                props.onChange(null);
            }
        }
    };

    const handleSingleType = (value) => {
        if (type === 'string') {
            props.onChange(value ? value.trim() : undefined);
        } else if (type === 'number' && !isDateField) {
            // Helps to avoid the validation error for the decimal numbers ending in '0'.
            const newValue = parseFloat(value.replace(',', '.'));
            props.onChange(!isNaN(newValue) ? newValue : null);
        }
    };

    const handleOnBlur = (id, value) => {
        if (Array.isArray(type)) {
            handleTypeArray(value);
        } else {
            handleSingleType(value);
        }
    };

    return <SchemaField {...props} onBlur={handleOnBlur} />;
};

TpSchemaField.propTypes = {
    onChange: PropTypes.func.isRequired,
    schema: PropTypes.shape({
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        ])
    }).isRequired,
    registry: PropTypes.object.isRequired
};

export default TpSchemaField;
