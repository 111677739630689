import moment from 'moment';

const dateFnsFormat = {
    // for more formats please see https://date-fns.org/docs/format
    P: 'P' /* 05/29/1998 */
};

const momentTimeFormat = {
    LT: 'LT' /* "hh:mm A" */,
    // LTS: 'LTS', /*  "hh:mm:ss A" */
    L: 'L' /* "MM/DD/YYYY" */,
    // l: 'l', /* "M/D/YYYY" */
    // LL: 'LL', /* "MMMM Do YYYY" */
    // ll: 'll', /*  "MMM D YYYY" */
    // LLL: 'LLL', /*  "MMMM Do YYYY LT" */
    lll: 'lll' /*  MMM D YYYY LT */
    //  LLLL: 'LLLL', /*  "dddd, MMMM Do YYYY LT" */
    //  llll: 'llll'  /*  "ddd, MMM D YYYY LT" */
};

/**
 * will translate UNIX_timestamp date to string using the
 * current set momentjs locale and the provided momentjs format.
 *
 * @param {number} unixMili_timestamp
 * @param {string} format One of momentTimeFormat
 * @returns {string} translated date
 */
const getFormattedUnixTimestring = (
    unixMili_timestamp,
    format = momentTimeFormat.lll
) => moment(unixMili_timestamp).format(format);
/**
 * will compare UNIX_timestamp day with today day
 * Compares the input unix timestamp with the timestamp of today
 * to see if the day, month and year matches.
 *
 * @param {number} UNIX_timestamp The timestamp in ms to check.
 * @returns {boolean} If the input timestamp contains the current date.
 */
const isToday = (UNIX_timestamp) =>
    new Date().toDateString() === new Date(UNIX_timestamp).toDateString();

/**
 * Formats a input unix-based timestamp depending on the date.
 * When the timestamp date represents the current date, a short format
 * of hh:mm A is returned, with A being (AM or PM for 12h clocks).
 * Otherwise the timestamp will be formatted as a detailes date with a
 * MMM D YYYY LT format.
 *
 * For localization, the momentjs library and its current set
 * locale will be used.
 *
 * @param {number} UNIX_timestamp The unix-based timestamp in ms.
 * @returns {string} The timestamp date as a localized string.
 */
const getDateDependentTimestring = (UNIX_timestamp) => {
    if (!UNIX_timestamp) {
        return null;
    }
    const format = isToday(UNIX_timestamp)
        ? momentTimeFormat.LT
        : momentTimeFormat.lll;

    return getFormattedUnixTimestring(UNIX_timestamp, format);
};

/**
 * Converting from UTC +- 0 to midnight local time.
 * @param {number} UNIX_timestamp
 * @returns {number} modified UNIX_timestamp
 */
const convertToLocalMidnight = (UNIX_timestamp) =>
    UNIX_timestamp + new Date(UNIX_timestamp).getTimezoneOffset() * 60 * 1000;

/**
 * Converting from midnight local time to midnight UTC +-0.
 * @param {number} UNIX_timestamp
 * @returns {number} modified UNIX_timestamp
 */
const convertToUtcMidnight = (UNIX_timestamp) =>
    UNIX_timestamp - new Date(UNIX_timestamp).getTimezoneOffset() * 60 * 1000;

export default {
    convertToLocalMidnight,
    convertToUtcMidnight,
    getDateDependentTimestring,
    getFormattedUnixTimestring,
    isToday,
    momentTimeFormat,
    dateFnsFormat
};
