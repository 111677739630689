import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import WebString from 'components/webString/WebString';
import {convertSelectValue} from 'lib/proposalUtils';
import i18nUtils from 'lib/i18nUtils';

const OwnerElement = (props) => {
    const {selectedOwner, owners, stringResources, onOwnerChange} = props;

    const ownerValues =
        owners && owners.map((user) => convertSelectValue(user.email));

    const handleOwnerChange = (selectedOption) => {
        onOwnerChange(selectedOption);
    };

    return (
        <>
            <label className="tp-proposal-classification__label-owner">
                <WebString
                    id="tp-proposal-classification__webstring-owner"
                    webStringKey="cs.text.owner"
                />
            </label>
            <Select
                className="tp-proposal-classification__select-owner tp-proposal-classification__select"
                id="select-owner"
                options={ownerValues}
                onChange={handleOwnerChange}
                placeholder={i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    'cs.form.please.select'
                )}
                value={selectedOwner}
                isSearchable={true}
            />
        </>
    );
};

OwnerElement.propTypes = {
    selectedOwner: PropTypes.object.isRequired,
    owners: PropTypes.array.isRequired,
    onOwnerChange: PropTypes.func.isRequired,
    stringResources: PropTypes.object.isRequired
};
export default OwnerElement;
