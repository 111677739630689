import React from 'react';
import AuthService from 'auth/AuthService';

export const AuthContext = React.createContext({
    AppSettings: {},
    signinSilent: () => {}, //NOSONAR
    signinSilentCallback: () => {}, //NOSONAR
    signinRedirect: () => {}, //NOSONAR
    signinRedirectCallback: () => {}, //NOSONAR
    signout: () => {}, //NOSONAR
    signoutRedirectCallback: () => {}, //NOSONAR
    getLoggedInUser: () => {} //NOSONAR
});

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({appSettings, children}) => {
    const authService = new AuthService(appSettings);
    return (
        <AuthContext.Provider value={authService}>
            {children}
        </AuthContext.Provider>
    );
};
