import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays animated loading symbol, for use while we wait for API calls to complete,
 * or other actions where we need to tell the user that something is happening.
 * Optionally also displays a dark background over the whole page content.
 */
export default class CircularLoader extends React.PureComponent {
    static propTypes = {
        overlay: PropTypes.bool,
        label: PropTypes.string
    };

    static defaultProps = {
        overlay: false,
        label: 'Loading...'
    };

    render() {
        const elementModifier = this.props.overlay
            ? '--overlay-on'
            : '--overlay-off';
        return (
            <div className={`tp-loader tp-loader${elementModifier}`}>
                <div
                    className={`tp-loader__wrapper-outer tp-loader__wrapper-outer${elementModifier}`}>
                    <div className="tp-loader__wrapper-inner">
                        <div className="tp-loader__spinner" />
                        <span className="tp-loader__text">
                            {this.props.label}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
