import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import * as snackActions from 'store/actions/snackActions';
import * as userManagementActions from 'store/actions/userManagementActions';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import i18nUtils from 'lib/i18nUtils';
import TpButton from 'components/common/buttons/TpButton';
import csvUtils from './importCsvUsersFromFileUtils';
import DownloadCsvTemplateButton from './DownloadCsvTemplateButton';
import CsvUploadField from './CsvUploadField';
import SSOUsageSelectionField from './SSOUsageSelectionField';
import ErrorField from './ErrorField';
import WebString from 'components/webString/WebString';

const ImportUsersFromCsvDialog = (props) => {
    const [csvArray, setCsvArray] = useState([]);
    const [csvFile, setCsvFile] = useState(null);
    const [showFileName, setShowFileName] = useState(false);
    const [invalidFormatError, setInvalidFormatError] = useState(false);
    const [invalidEmail, setInvalidEmailError] = useState(false);
    const [invalidDataError, setInvalidDataError] = useState(false);
    const [importError, setImportError] = useState(false);
    const [
        createUserInCLUsingPasswordEnabled,
        setCreateUserInCLUsingPasswordEnabled
    ] = useState(true);
    const {stringResources} = useSelector((state) => ({
        stringResources: state.settings.i18n.stringResources
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        if (csvArray.length !== 0) {
            setInvalidFormatError(false);
            setInvalidDataError(!csvUtils.isCsvDataFieldsEmpty(csvArray));
            setInvalidEmailError(!csvUtils.isEmailFieldsValid(csvArray));
        }
    }, [csvArray]);

    const isUserImportDataValid = useCallback(() => {
        return !(invalidFormatError || invalidDataError || invalidEmail);
    }, [invalidFormatError, invalidDataError, invalidEmail]);

    useEffect(() => {
        if (csvArray.length > 0) {
            if (isUserImportDataValid()) {
                setShowFileName(true);
            } else {
                setShowFileName(false);
            }
        }
    }, [
        csvArray,
        invalidFormatError,
        invalidDataError,
        invalidEmail,
        isUserImportDataValid
    ]);

    const handleUpload = (csvFile) => {
        setCsvFile(csvFile);
        async function doFileRead() {
            const textFromFile = await csvUtils.readFileAsText(csvFile);
            const parsedArray = csvUtils.parseCSVFile(textFromFile.trim());
            if (parsedArray.length > 0) {
                setCsvArray(parsedArray);
            } else {
                setInvalidFormatError(true);
            }
        }
        doFileRead();
    };

    const importUsers = async (importData) => {
        try {
            await HTTPClient.post('users/import', importData);
        } catch (error) {
            dispatch({type: 'IMPORT_USERS_FAILED', error});
            setImportError(true);
        }
    };

    const onImportUsersClick = async () => {
        const importData = {
            userCreateDto: csvArray,
            createUserInCLUsingPasswordEnabled
        };
        await importUsers(importData);
        props.onClosePopup();
        dispatch(userManagementActions.getAllUsersWithLastSearchParams());
        dispatch(
            snackActions.createSnack({
                id: `snack-success-import-users`,
                snackType: TpSnackbarTypes.SUCCESS,
                autoHideDuration: 4000,
                message: `${i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    'tp.importuserscsv.notification.message.success'
                )}`
            })
        );
    };

    const handleSSOUsageSelection = (selectedOption) => {
        setCreateUserInCLUsingPasswordEnabled(!selectedOption);
    };

    const isSSOSelectionAndButtonEnabled = () => {
        return !!(csvArray.length !== 0 && isUserImportDataValid());
    };

    return (
        <div className="tp-import-users-csv__container">
            <div className="tp-import-users-csv__content">
                <div className="tp-import-csv__subheader-container">
                    <WebString
                        className="tp-import-csv__subheader-webstring"
                        webStringKey={`tp.importuserscsv.subheader.text`}
                    />
                </div>
                <DownloadCsvTemplateButton />
                <CsvUploadField
                    handleFileUpload={handleUpload}
                    showFileName={showFileName}
                    fileName={csvFile ? csvFile.name : ''}
                />
                <ErrorField
                    invalidEmailError={invalidEmail}
                    importError={importError}
                    invalidFormatError={invalidFormatError}
                    fileName={csvFile ? csvFile.name : ''}
                    invalidDataError={invalidDataError}
                />
                {isSSOSelectionAndButtonEnabled() ? (
                    <SSOUsageSelectionField
                        createUserInCLUsingPasswordEnabled={
                            createUserInCLUsingPasswordEnabled
                        }
                        handleSSOUsageSelection={handleSSOUsageSelection}
                    />
                ) : null}
            </div>
            <div className="tp-import-users-csv__button-container">
                <TpButton
                    className="tp-import-users-csv__button-importusers"
                    id="btn-import-users-csv"
                    webStringKey="tp.button.importuserscsv.import.text"
                    onClick={() => onImportUsersClick()}
                    webStringClassName="tp-import-users-csv__button-importusers-text"
                    disabled={!isSSOSelectionAndButtonEnabled()}
                />
            </div>
        </div>
    );
};

ImportUsersFromCsvDialog.propTypes = {
    onClosePopup: PropTypes.func.isRequired
};

export default ImportUsersFromCsvDialog;
