import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import isEqual from 'lodash.isequal';
import WebString from 'components/webString/WebString';
import {
    registerContainer,
    toggleContainer,
    unregisterContainer
} from 'store/actions/formActions';

/**
 * Container Component with form style title and chevron icon showing if open or closed.
 * The whole header is clickable to toggle open / closed state.
 * Child components are rendered as the collapsible content.
 * If `lazilyRender` is set to true, the children will only be rendered
 * when the container is opened.
 * Uses Redux for open state, so can be controlled from other components, e.g.
 * open section when an error panel error is clicked.
 */
export class CollapsibleContainer extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        toggleContainer: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        isContainerOpen: PropTypes.bool.isRequired,
        registerContainer: PropTypes.func,
        unregisterContainer: PropTypes.func,
        titleKey: PropTypes.string,
        className: PropTypes.string,
        isInitiallyOpen: PropTypes.bool,
        labelClassName: PropTypes.string,
        lazilyRender: PropTypes.bool
    };

    static defaultProps = {
        registerContainer: () => undefined,
        unregisterContainer: () => undefined,
        titleKey: '',
        className: '',
        isInitiallyOpen: true,
        labelClassName: 'bk-cs-form-label',
        lazilyRender: false
    };

    UNSAFE_componentWillMount() {
        this.props.registerContainer(this.props.id, this.props.isInitiallyOpen);
    }

    componentWillUnmount() {
        this.props.unregisterContainer(this.props.id);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
        );
    }

    _renderChildren = () => {
        if (this.props.lazilyRender) {
            if (this.props.isContainerOpen) {
                return this.props.children;
            }

            return <div />;
        }

        return this.props.children;
    };

    _handleLabelOnClick = () => {
        this.props.toggleContainer(this.props.id);
    };

    render() {
        const chevronClass = this.props.isContainerOpen
            ? 'bk-cs-form-label__collapse-icon'
            : 'bk-cs-form-label__expand-icon';

        /*Added to support the styling in IE11 , can be removed if IE is no more in use */
        const theme =
            (navigator.userAgent.match(/Trident.*rv[ :]*11./i) !== null || //for IE 11
                navigator.userAgent.match(/msie/i) !== null) && // conditional IE code
            this.props.isContainerOpen === true
                ? {
                      collapse:
                          'tp-ReactCollapse--collapse--isopen ReactCollapse--collapse',
                      content: 'ReactCollapse--content'
                  }
                : {
                      collapse: 'ReactCollapse--collapse',
                      content: 'ReactCollapse--content'
                  };

        return (
            <div className={this.props.className}>
                <div className="bk-cs-form-header">
                    <label
                        id={`form__section-header::${this.props.titleKey}`}
                        className={this.props.labelClassName}
                        onClick={() => this._handleLabelOnClick()}>
                        <WebString webStringKey={this.props.titleKey} />
                        <i
                            id={`form__section-header-icon::${this.props.titleKey}`}
                            className={`bk-cs-float-right ${chevronClass}`}
                        />
                    </label>
                </div>
                <Collapse theme={theme} isOpened={this.props.isContainerOpen}>
                    {this._renderChildren()}
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isContainerOpen: !!(
        state.form.containers &&
        state.form.containers.find(
            (c) => c.id === ownProps.id && c.isOpen === true
        )
    )
});

const mapDispatchToProps = (dispatch) => ({
    registerContainer(id, isOpen) {
        dispatch(registerContainer(id, isOpen));
    },
    toggleContainer(id) {
        dispatch(toggleContainer(id));
    },
    unregisterContainer(id) {
        dispatch(unregisterContainer(id));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollapsibleContainer);
