import React from 'react';
import PropTypes from 'prop-types';
import UserForm from '../userForm/UserForm';
import {getGeneralFormSchema} from 'components/users/createOrUpdateUser/formSchema/getUserSchema';
import {getGeneralFormUiSchema} from 'components/users/createOrUpdateUser/uiSchema/getUserUiSchema';
import UserSchemaNameTypes from '../userSchemaNameTypes';

const getGeneralFormSchemas = (userSchemaNameType) => {
    return {
        schema: getGeneralFormSchema(userSchemaNameType),
        uiSchema: getGeneralFormUiSchema(userSchemaNameType)
    };
};

const GeneralForm = (props) => (
    <UserForm
        {...getGeneralFormSchemas(props.userSchemaNameType)}
        stringResources={props.stringResources}
        formData={props.generalFormData}
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        onError={props.onError}
        showSubmitButton={false}>
        {props.children}
    </UserForm>
);

GeneralForm.propTypes = {
    stringResources: PropTypes.object.isRequired,
    userSchemaNameType: PropTypes.oneOf(Object.values(UserSchemaNameTypes))
        .isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
    generalFormData: PropTypes.object
};

GeneralForm.defaultProps = {
    generalFormData: {}
};

export default GeneralForm;
