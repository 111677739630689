import React from 'react';
import PropTypes from 'prop-types';
import DuplicateCoreDataRow from './DuplicateCoreDataRow';
import WebString from 'components/webString/WebString';
import TpButton from 'components/common/buttons/TpButton';
import {
    proposalTaskActionObjects,
    proposalTaskActions
} from 'lib/proposalUtils';
import NotificationMessage from 'domain/NotificationMessage';

/**
 * Displays a list of proposal business partner duplicates with radio buttons
 * for the user to select.
 * @class
 */
export default class DuplicateCoreDataList extends React.Component {
    static propTypes = {
        onActionTaken: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        duplicates: PropTypes.array.isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: ''
        };
    }

    applyDuplicate = () => {
        if (this.state.selectedOption) {
            this.props.onActionTaken(
                proposalTaskActionObjects.selected(
                    proposalTaskActions.useExistingBusinessPartner,
                    this.state.selectedOption
                )
            );
        } else {
            this.props.onError([
                new NotificationMessage(
                    'tp.errormsg.validation.nothingselected'
                )
            ]);
        }
    };

    cancelDuplicate = () => {
        this.props.onActionTaken(proposalTaskActionObjects.noneSelected);
    };

    handleOptionChange = (changeEvent) => {
        this.setState({
            selectedOption: changeEvent.target.value
        });
    };

    _mapDuplicatesToRadioInput = (duplicate) => (
        <label
            id={`duplicate-table__element-label::${duplicate.id}`}
            key={`duplicate-table__element-label::${duplicate.id}`}
            className="bk-tp-duplicate-table__label">
            <input
                id={`duplicate-table__element-input::${duplicate.id}`}
                type="radio"
                disabled={this.props.disabled}
                name="duplicate_opt_radio"
                key={`duplicate-table__element-input::${duplicate.id}`}
                className="bk-tp-duplicate-table__radio"
                value={duplicate.id}
                onChange={this.handleOptionChange}
            />
            <DuplicateCoreDataRow {...duplicate} />
        </label>
    );

    render() {
        let buttonsContent;

        if (!this.props.disabled) {
            buttonsContent = (
                <div className="bk-cs-vertical-margin">
                    <TpButton
                        id="btn_duplicate_select"
                        onClick={this.applyDuplicate}
                        webStringKey="cs.button.selectduplicate"
                        className="bk-cs-float-right"
                    />
                    <TpButton
                        id="btn_duplicate_cancel"
                        onClick={this.cancelDuplicate}
                        webStringKey="cs.button.cancelduplicate"
                    />
                </div>
            );
        }

        return (
            <div className="bk-cs-duplicate-table-container">
                <h3>
                    <WebString
                        id="duplicate-table__title"
                        webStringKey="tp.table.duplicate.coredata.title"
                    />
                </h3>
                <WebString webStringKey="tp.table.duplicate.coredata.text" />
                <div
                    id="duplicate-table__list"
                    className="bk-cs-duplicate-table">
                    {this.props.duplicates.map(this._mapDuplicatesToRadioInput)}
                </div>
                {buttonsContent}
            </div>
        );
    }
}
