import {handle} from 'redux-pack';
import TableTypes from 'types/TableTypes';

const LOAD_ALL_PROPOSALS = 'LOAD_ALL_PROPOSALS';

export const ACTION_TYPES = {
    LOAD_ALL_PROPOSALS
};

const tablesForDefaultState = [
    TableTypes.PROPOSAL,
    TableTypes.ASSESSED,
    TableTypes.ASSESSMENT,
    TableTypes.EXTERNAL
];

const defaultTableData = {
    isLoading: false,
    totalPages: 0,
    proposals: [],
    searchParams: {}
};

const geAllTablesWithDefaultValues = () => {
    let tables = null;
    for (let i of tablesForDefaultState) {
        tables = {...tables, [i]: defaultTableData};
    }
    return tables;
};

const defaultState = {
    ...geAllTablesWithDefaultValues()
};

const updateData = (state, action) => {
    return handle(state, action, {
        start: (prevState) => ({
            ...prevState,
            isLoading: true
        }),
        success: (prevState) => ({
            ...prevState,
            totalPages: action.payload.totalPages,
            proposals: action.payload.proposals,
            searchParams: action.meta.searchParams,
            isLoading: false
        }),
        failure: (prevState) => ({
            ...prevState,
            isLoading: false
        })
    });
};

const dashboardReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_ALL_PROPOSALS:
            const tableType = action.meta.tableType;
            return {
                ...state,
                [tableType]: updateData(state[tableType], action)
            };
        default:
            return state;
    }
};

export default dashboardReducer;
