import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const RiskAlertsBPDunsNumber = ({dunsNumber}) => (
    <div id="risk-alert-duns">
        <WebString
            webStringKey="tp.form.dowjones.duns.title"
            className="tp-risk-alerts__heading"
        />
        {dunsNumber}
    </div>
);

RiskAlertsBPDunsNumber.propTypes = {
    dunsNumber: PropTypes.string
};

RiskAlertsBPDunsNumber.defaultProps = {
    dunsNumber: ''
};

export default RiskAlertsBPDunsNumber;
