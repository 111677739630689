import React from 'react';
import PropTypes from 'prop-types';
import TextOnlyDateWidget from './TextOnlyDateWidget';

/**
 * CsDate is a simple date picker based on react-datepicker and momentjs.
 * The props are passed into this component through react-jsonschema-form.
 * Only dates after the 01.01.1900 can be selected.
 * `CsDate.undefinedDateValue` is used to represent an empty value (as numbers cannot be empty)
 * @class
 */
export default class TextOnlyDisplayStringAsDateWidget extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    };

    render() {
        const timeStamp = Number(this.props.value);
        return (
            <TextOnlyDateWidget id={this.props.id} value={timeStamp || null} />
        );
    }
}
