import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import {
    batchOpenContainers,
    batchCloseContainers
} from 'store/actions/formActions';

export class ToggleContainerState extends React.Component {
    static propTypes = {
        containers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                isOpen: PropTypes.bool
            })
        ).isRequired,
        collapseGroup: PropTypes.func.isRequired,
        expandGroup: PropTypes.func.isRequired,
        scope: PropTypes.string
    };

    expandContainers = () => {
        const {containers, expandGroup, scope} = this.props;
        const closedContainers = containers.filter(
            (container) => !container.isOpen
        );
        if (scope) {
            const closedContainersWithinScope = closedContainers.filter(
                (container) => this.getContainersForScope(container.id, scope)
            );
            expandGroup(closedContainersWithinScope);
        } else {
            expandGroup(closedContainers);
        }
    };

    collapseContainers = () => {
        const {containers, collapseGroup, scope} = this.props;
        const openContainers = containers.filter(
            (container) => container.isOpen
        );
        if (scope) {
            const openContainersWithinScope = openContainers.filter(
                (container) => this.getContainersForScope(container.id, scope)
            );
            collapseGroup(openContainersWithinScope);
        } else {
            collapseGroup(openContainers);
        }
    };

    /**
     * Checks if the containerID is within a given scope.
     * @param containerID
     * @param scope
     * @returns {boolean}
     */
    getContainersForScope = (containerID, scope) => {
        const scopeFilterRegex = new RegExp('\\b(\\w*' + scope + '\\w*)\\b');
        return containerID.match(scopeFilterRegex);
    };

    render() {
        return (
            <div className="tp-toggle-container-state">
                <span>
                    <WebString webStringKey="tp.form.togglecontainerstate.title" />{' '}
                </span>
                <div className="tp-toggle-container-state__icon-wrapper">
                    <i
                        id="tp-toggle-container-state__icon--collapse"
                        onClick={() => this.collapseContainers()}
                        className="bk-cs-form-label__collapse-icon"
                    />
                    <i
                        id="tp-toggle-container-state__icon--expand"
                        onClick={() => this.expandContainers()}
                        className="bk-cs-form-label__expand-icon"
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    expandGroup(containers) {
        dispatch(batchOpenContainers(containers));
    },
    collapseGroup(containers) {
        dispatch(batchCloseContainers(containers));
    }
});

const mapStateToProps = (state) => ({
    containers: state.form.containers
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToggleContainerState);
