import React from 'react';
import PropTypes from 'prop-types';
import timeUtils from 'lib/timeUtils';
import TextOnlyBaseInputWidget from './TextOnlyBaseInputWidget';

/**
 * Text only display of Date widget.
 * @param {Object} props
 * @returns {ReactElement} markup
 * @constructor
 */
const TextOnlyDateWidget = (props) => {
    const dateStringOrBlank =
        props.value === null
            ? '-'
            : timeUtils.getFormattedUnixTimestring(props.value, 'LL');
    return <TextOnlyBaseInputWidget id={props.id} value={dateStringOrBlank} />;
};

TextOnlyDateWidget.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.number
};

TextOnlyDateWidget.defaultProps = {
    value: null
};

export default TextOnlyDateWidget;
