import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import RiskAlertTableEntry from './RiskAlertTableEntry';

/**
 * Render the risk alert rows in a table.
 * The alert are sorted by descending risk match percentage.
 * @param props
 * @returns {*}
 * @constructor
 */
export const RiskAlertTableArrayFieldTemplate = (props) => {
    const sortedEntries = props.items.sort(
        (a, b) =>
            b.children.props.formData.matchPct -
            a.children.props.formData.matchPct
    );

    return (
        <table id="risk-alert-table" className="tp-risk-alerts__table">
            <thead>
                <tr className="bk-cs-underlined">
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.flags.header" />
                    </th>
                    <th className="tp-risk-alerts__column-name-header">
                        <WebString webStringKey="tp.form.dowjones.name.header" />
                    </th>
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.country.header" />
                    </th>
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.match.header" />
                    </th>
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.status.header" />
                    </th>
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.critical.header" />
                    </th>
                    <th className="tp-risk-alerts__column-header">
                        <WebString webStringKey="tp.form.dowjones.show.header" />
                    </th>
                </tr>
            </thead>
            {sortedEntries.map((item, index) => {
                const entryProps = {
                    id: index.toString(),
                    ...item.children.props
                };
                return (
                    <RiskAlertTableEntry
                        key={index}
                        displayRiskProfileOnClick={
                            props.formContext.displayRiskProfileOnClick
                        }
                        {...entryProps}
                    />
                );
            })}
        </table>
    );
};

RiskAlertTableArrayFieldTemplate.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.object.isRequired
        })
    ),
    formContext: PropTypes.object
};

RiskAlertTableArrayFieldTemplate.defaultProps = {
    items: []
};
