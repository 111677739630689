import React from 'react';
import PropTypes from 'prop-types';
import timeUtils from 'lib/timeUtils';
import i18nUtils from 'lib/i18nUtils';
import BusinessPartnerDisplayElement from './BusinessPartnerDisplayElement';

const preWebStringKey = 'tp.businesspartnerdetails.';

const getCountryTranslation = (businessPartner, stringResources) =>
    i18nUtils.getTranslatedWebStringKey(
        stringResources,
        `cs.text.country.${businessPartner.country}`
    );

const convertValueOfImported = (businessPartner, stringResources) => {
    const webStringKeySuffix = businessPartner.imported ? 'yes' : 'no';
    return i18nUtils.getTranslatedWebStringKey(
        stringResources,
        `cs.text.${webStringKeySuffix}`
    );
};

const personDisplayOrder = [
    {
        property: 'partnerType',
        getValue: (businessPartner, stringResources) =>
            i18nUtils.getTranslatedWebStringKey(
                stringResources,
                `${preWebStringKey}partnertype.${businessPartner.partnerType}`
            ),
        webStringKey: `${preWebStringKey}partnertype`
    },
    {
        property: 'firstName',
        getValue: (businessPartner) => businessPartner.firstName,
        webStringKey: `${preWebStringKey}firstname`
    },
    {
        property: 'name',
        getValue: (businessPartner) => businessPartner.name,
        webStringKey: `${preWebStringKey}surname`
    },
    {
        property: 'email',
        getValue: (businessPartner) => businessPartner.email,
        webStringKey: `${preWebStringKey}email`
    },
    {
        property: 'firstAddressField',
        getValue: (businessPartner) => businessPartner.firstAddressField,
        webStringKey: `${preWebStringKey}firstaddressfield`
    },
    {
        property: 'secondAddressField',
        getValue: (businessPartner) => businessPartner.secondAddressField,
        webStringKey: `${preWebStringKey}secondaddressfield`
    },
    {
        property: 'location',
        getValue: (businessPartner) => businessPartner.location,
        webStringKey: `${preWebStringKey}location`
    },
    {
        property: 'postCode',
        getValue: (businessPartner) => businessPartner.postCode,
        webStringKey: `${preWebStringKey}postcode`
    },
    {
        property: 'country',
        getValue: (businessPartner, stringResources) =>
            getCountryTranslation(businessPartner, stringResources),
        webStringKey: `${preWebStringKey}country`
    },
    {
        property: 'created',
        getValue: (businessPartner) =>
            timeUtils.getFormattedUnixTimestring(businessPartner.created),
        webStringKey: `${preWebStringKey}created`
    },
    {
        property: 'imported',
        getValue: (businessPartner, stringResources) =>
            convertValueOfImported(businessPartner, stringResources),
        webStringKey: `${preWebStringKey}imported`
    }
];

const companyDisplayOrder = [
    {
        property: 'partnerType',
        getValue: (businessPartner, stringResources) =>
            i18nUtils.getTranslatedWebStringKey(
                stringResources,
                `${preWebStringKey}partnertype.${businessPartner.partnerType}`
            ),
        webStringKey: `${preWebStringKey}partnertype`
    },
    {
        property: 'name',
        getValue: (businessPartner) => businessPartner.name,
        webStringKey: `${preWebStringKey}companyname`
    },
    {
        property: 'legalForm',
        getValue: (businessPartner) => businessPartner.legalForm,
        webStringKey: `${preWebStringKey}legalform`
    },
    {
        property: 'email',
        getValue: (businessPartner) => businessPartner.email,
        webStringKey: `${preWebStringKey}email`
    },
    {
        property: 'dunsNumber',
        getValue: (businessPartner) => businessPartner.dunsNumber,
        webStringKey: `${preWebStringKey}dunsnumber`
    },
    {
        property: 'firstAddressField',
        getValue: (businessPartner) => businessPartner.firstAddressField,
        webStringKey: `${preWebStringKey}firstaddressfield`
    },
    {
        property: 'secondAddressField',
        getValue: (businessPartner) => businessPartner.secondAddressField,
        webStringKey: `${preWebStringKey}secondaddressfield`
    },
    {
        property: 'location',
        getValue: (businessPartner) => businessPartner.location,
        webStringKey: `${preWebStringKey}location`
    },
    {
        property: 'postCode',
        getValue: (businessPartner) => businessPartner.postCode,
        webStringKey: `${preWebStringKey}postcode`
    },
    {
        property: 'country',
        getValue: (businessPartner, stringResources) =>
            getCountryTranslation(businessPartner, stringResources),
        webStringKey: `${preWebStringKey}country`
    },
    {
        property: 'created',
        getValue: (businessPartner) =>
            timeUtils.getFormattedUnixTimestring(businessPartner.created),
        webStringKey: `${preWebStringKey}created`
    },
    {
        property: 'imported',
        getValue: (businessPartner, stringResources) =>
            convertValueOfImported(businessPartner, stringResources),
        webStringKey: `${preWebStringKey}imported`
    }
];

const getOrder = (partnerType) => {
    switch (partnerType) {
        case 'person':
            return personDisplayOrder;
        case 'company':
            return companyDisplayOrder;
        default:
            return [];
    }
};

const BusinessPartnerDetailsDisplay = (props) => {
    const {businessPartner, stringResources} = props;

    return (
        <div className="tp-business-partner-details__info">
            {getOrder(businessPartner.partnerType).map((entry) => {
                if (businessPartner.hasOwnProperty(entry.property))
                    return (
                        <BusinessPartnerDisplayElement
                            key={entry.property}
                            webStringKey={entry.webStringKey}
                            displayValue={entry.getValue(
                                businessPartner,
                                stringResources
                            )}
                        />
                    );
            })}
        </div>
    );
};

BusinessPartnerDetailsDisplay.propTypes = {
    businessPartner: PropTypes.object.isRequired,
    stringResources: PropTypes.object.isRequired
};

export default BusinessPartnerDetailsDisplay;
