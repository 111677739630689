import ErrorTypes from 'types/ErrorTypes';
import StringPlaceholder from 'lib/StringPlaceholder';

const defaultMessage =
    'The action could not be completed. Please try again later. If the problem persists, please contact your responsible department.';

const FORM = new Map([
    ['10102', 'invalidInput'], // 422
    ['10104', 'noData'], // 422
    ['10000', 'required'], // 422
    ['10001', 'invalidInput'], // 422
    ['10002', 'pattern'], // 422
    ['10004', 'minLength'], // 422
    ['10005', 'maxLength'], // 422
    ['10006', 'enum'], // 422
    ['10007', 'required'], // 422
    ['10008', 'required'], // 422
    ['10009', 'wrongEmailFormat'], // 422
    ['10020', 'invalidInput'], // 422
    ['10200', 'bpIsEmployee'], // 422
    ['10300', 'invalidInput'], // 422
    ['10301', 'invalidInput'] // 422
]);
const REMUNERATION = new Map([
    ['10', 'required'], //  422
    ['11', 'invalidInput'], //  422
    ['12', 'order'] //  422
]);

const VALIDATION_MODULES = new Map([
    ['FORM', FORM],
    ['REMUNERATION', REMUNERATION]
]);

const getPlaceholders = (paramsKeyValueMap) =>
    paramsKeyValueMap
        ? Object.keys(paramsKeyValueMap).map(
              (key) => new StringPlaceholder(key, paramsKeyValueMap[key])
          )
        : null;

const isValidationError = (errorCode) => {
    const [module, code] = errorCode.split('-');
    const errorCodeNameMap = VALIDATION_MODULES.get(module);

    return errorCodeNameMap && errorCodeNameMap.has(code);
};

/**
 * @class
 * @classdesc Class representing an ErrorCode.
 * It contains two static methods: getValidationErrorName and getHTTPErrorInfo
 */
export default class ErrorCode {
    /**
     * Returns details information about error using error code (e.g. FORM-10100) from back-end original object.
     * Returns invalidInput if not found.
     *
     * @param {String} errorCode - from back-end original object
     * @returns {string} errorName - mapped error name for error code or default if not found
     */
    static getValidationErrorName(errorCode) {
        const defaultName = 'invalidInput';
        if (!errorCode) {
            return defaultName;
        }
        const [module, code] = errorCode.split('-');
        const errorCodeNameMap = VALIDATION_MODULES.get(module);
        if (!errorCodeNameMap) {
            return defaultName;
        }

        return errorCodeNameMap.get(code);
    }

    /**
     * Extracts information (webStringKey, paramsKeyValueMap) for http error (e.g. 'APP-100') from back-end original error object.
     *
     *
     * @param {Object} error - HTTP error object
     * @returns {Object} - details information for error
     */
    static getHTTPErrorInfo(error) {
        /*
         response: The request was made and the server responded with a status code
         request: The request was made but no response was received
         */
        const {response, request} = error;
        const {status = '', data} = response || request;
        const errors = data ? data.errors : null;
        const errorInfo = {
            titleMessage: `Status code ${status}`,
            defaultMessage,
            webStringKey: '',
            placeholders: null
        };
        if (errors) {
            //it is enough to check only the first error in the array
            const {
                code: errorCode,
                webStringKey,
                paramsKeyValueMap
            } = errors[0];

            //todo we need to change this logic from BE. We do not need to send mainErrors as an array. Related ticket: #27902
            if (errors.find((e) => e.code && isValidationError(e.code))) {
                return {
                    errorType: ErrorTypes.VALIDATION,
                    errors: errors.map((e) => ({
                        ...e,
                        name: this.getValidationErrorName(e.code)
                    }))
                };
            }

            errorInfo.titleMessage += ` (${errorCode})`;
            errorInfo.webStringKey = webStringKey
                ? `tp.errormsg.${webStringKey}`
                : '';
            errorInfo.placeholders = getPlaceholders(paramsKeyValueMap);
        }
        return errorInfo;
    }
}
