import React from 'react';
import PropType from 'prop-types';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import WebString from 'components/webString/WebString';

/**
 * Handles the case where a form expects dynamic form data and we either have to display the
 * entries from the form OR a message saying that there's nothing to display.
 */
const RedFlagsAndPlansTemplate = (props) => {
    /**
     * Will create a webstringkey based on the title of the current form. 'noentriestodisplay' is
     * inserted before .title .
     * @returns {string}
     */

    const {
        schema: {title},
        uiSchema: {formDataAccessProp},
        formData
    } = props;

    const generateWebStringKey = () =>
        title.slice(0, title.indexOf('title')) +
        'noentriestodisplay' +
        title.slice(-6);

    if (formData[formDataAccessProp]) {
        return formData[formDataAccessProp].length ||
            formDataAccessProp === 'entries' ? (
            <ObjectField {...props} />
        ) : (
            <div className={'tp-form__text tp-red-flags__no-entries'}>
                <WebString webStringKey={generateWebStringKey()} />
            </div>
        );
    } else return null;
};

RedFlagsAndPlansTemplate.propTypes = {
    schema: PropType.object.isRequired,
    uiSchema: PropType.object.isRequired,
    idSchema: PropType.object.isRequired,
    formData: PropType.object.isRequired
};

export default RedFlagsAndPlansTemplate;
