import React from 'react';
import TpImage from 'components/common/TpImage';

const RiskAlertsDowJonesImage = () => (
    <div className="tp-risk-alerts__image-wrapper">
        <TpImage src={'DowJones_logo.png'} />
    </div>
);

export default RiskAlertsDowJonesImage;
