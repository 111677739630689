/**
 * Find API version number for this call
 * based on request URL and method, and apiVersionConfig.
 * @param {object} callConfig
 * @param {object} apiVersionConfig
 * @returns {string} version number
 */
export const getApiVersionForEndpoint = (callConfig, apiVersionConfig) => {
    if (!apiVersionConfig || !apiVersionConfig.calls) {
        return undefined;
    }
    const calledEndpoint = `/${callConfig.url}`.replace(/\/$/, '');
    const apiVersionEntry = apiVersionConfig.calls.find(
        (config) =>
            new RegExp(config.urlPattern).test(calledEndpoint) &&
            config.method === callConfig.method
    );
    return apiVersionEntry
        ? apiVersionEntry.version
        : apiVersionConfig.defaultVersion;
};
