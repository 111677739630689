import React from 'react';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';
import jsonQ from 'jsonq';
import additionalMetaSchemas from 'ajv/lib/refs/json-schema-draft-04.json';
import * as validationUtils from 'lib/validationUtils';
import i18nUtils from 'lib/i18nUtils';
import TpFieldTemplate from './fields/TpFieldTemplate';
import tpWidgets from './widgets/widgets';
import tpFields from './fields/fields';
import TpButton from 'components/common/buttons/TpButton';
import DivRenderableForm from './DivRenderableForm';
import TpErrorList from './TpErrorList';

/**
 * Reusable form component wrapping the react-jsonschema-form component.
 * Handles schema translation, error translation, error display
 *
 * @class
 */
export default class Form extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        schema: PropTypes.object.isRequired,
        stringResources: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        uiSchema: PropTypes.object,
        formData: PropTypes.object,
        fieldData: PropTypes.object,
        redFlags: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
        onError: PropTypes.func,
        disabled: PropTypes.bool,
        showSubmitButton: PropTypes.bool,
        submitButtonId: PropTypes.string,
        submitButtonWebStringKey: PropTypes.string,
        submitButtonClassNames: PropTypes.string,
        submitButtonIsDisabled: PropTypes.bool,
        renderAsDiv: PropTypes.bool,
        customFields: PropTypes.object,
        customWidgets: PropTypes.object,
        customFieldTemplate: PropTypes.func,
        customObjectFieldTemplate: PropTypes.func,
        customArrayFieldTemplate: PropTypes.func,
        isLegacyForm: PropTypes.bool,
        showErrorList: PropTypes.bool,
        hideBorder: PropTypes.bool,
        customFormContext: PropTypes.object
    };

    static defaultProps = {
        uiSchema: {},
        formData: null,
        redFlags: [],
        fieldData: {},
        children: null,
        onChange: () => undefined,
        onError: () => undefined,
        disabled: false,
        showSubmitButton: true,
        submitButtonId: 'tp-form-next-button',
        submitButtonWebStringKey: 'cs.button.next',
        submitButtonClassNames: 'bk-cs-float-right bk-cs-vertical-margin',
        submitButtonIsDisabled: false,
        renderAsDiv: false,
        customWidgets: {},
        customFields: {},
        customFieldTemplate: undefined,
        customObjectFieldTemplate: undefined,
        customArrayFieldTemplate: undefined,
        isLegacyForm: false,
        showErrorList: true,
        hideBorder: false,
        customFormContext: {}
    };

    constructor(props) {
        super(props);

        // allow extra custom widgets and fields to be passed in
        this.widgets = {...tpWidgets, ...props.customWidgets};
        this.fields = {...tpFields, ...props.customFields};

        this.state = {
            formData: props.formData ? null : {}
        };
    }

    /**
     * Handle any form change event, including keypresses in text inputs
     * IMPORTANT: only put absolutely necessary code in here, as it runs with every keypress,
     * and can slow down the form's behaviour.
     * @param {Object} event
     * @returns {undefined}
     */
    handleChange = (event) => {
        if (this.props.formData) {
            // formData is passed in; the formData is handled by the parent
            this.props.onChange(event);
        } else {
            // formData is not passed in, this Form handles it's own state
            this.setState({formData: event.formData}, () => {
                this.props.onChange(event);
            });
        }
    };

    /**
     * Filter and modify errors if needed.
     *
     * @param {Array} RJSF_errors
     * @returns {Array} modified and filtered errors
     */
    handleTransformErrors = (RJSF_errors) => {
        const saturatedErrors = RJSF_errors.map(this.saturateError);
        return validationUtils.orderErrorsByUiSchema(
            saturatedErrors,
            this.props.uiSchema
        );
    };

    saturateError = (error) => {
        // RJSForms does not pass down the schema anymore (we need it for titles and such)
        error.missingPropertyPath = [];
        // ajv has a "verbose" parameter for better error messages; maybe we won't need this in the future
        // introducting custom ajv instance to rjsf https://github.com/mozilla-services/react-jsonschema-form/pull/1286
        // verbose keyword and error object structure https://github.com/epoberezkin/ajv#error-objects
        try {
            this.addSchemaToError(error);
        } catch (ex) {
            console.error(
                'An error occured while transforming the RJSF error' + ex
            );
        }

        error.notification = i18nUtils.createNotificationMessageFromError(
            error
        );

        return error;
    };

    addSchemaToError = (error) => {
        const {schema} = this.props;

        const problemPath = error.schemaPath.substring(
            0,
            error.schemaPath.lastIndexOf('/')
        );
        const parentPath = problemPath.substring(2);
        const schemaAtParentPath = get(schema, parentPath.split(/\//g));

        if (schemaAtParentPath) {
            // the errors schemaPath points to a non-existent property (because of our transformations)
            const {missingProperty} = error.params;
            if (
                missingProperty &&
                schemaAtParentPath.properties[missingProperty]
            ) {
                // there is a property at the current schemas properties prop
                error.missingPropertyPath = `${parentPath}/${missingProperty}`
                    .replace(/\./g, '/')
                    .split('/');
                error.schema = schemaAtParentPath.properties[missingProperty];
            } else {
                error.missingPropertyPath = parentPath
                    .replace(/\./g, '/')
                    .split('/');
                error.schema = schemaAtParentPath;
            }
        } else {
            const schemaQ = jsonQ(schema);

            const missingProperty = error.property.substring(
                error.property.lastIndexOf('.') + 1
            );
            const missing = schemaQ.find(missingProperty);
            const alternativeProblemPropertyAtPath = missing.firstElm();
            error.missingPropertyPath = missing.path();
            error.schema = alternativeProblemPropertyAtPath;
        }
    };

    translateKey = (wsKey) =>
        i18nUtils.getTranslatedWebStringKey(this.props.stringResources, wsKey);

    translateArray = (arr) => arr.map(this.translateKey);

    /**
     * translate schema only for enumNames and ui:placeholder, ofr other components WebString will be used for translation
     *
     * @param {Object} schema
     * @param {Object} uiSchema
     * @returns {{translatedSchema: *, translatedUiSchema: *}}
     */
    translateSchemas = (schema, uiSchema) => {
        const translatedSchema = clonedeep(schema);
        const translatedUiSchema = clonedeep(uiSchema);

        jsonQ(translatedSchema)
            .find('enum')
            .parent()
            .each((e, path, val) => {
                // in old forms there are questions which are enums but don't have enumNames
                if (!val.enumNames) {
                    // set enumNames to the enum so we can translate all enumNames in one go
                    val.enumNames = val.enum;
                }
            });

        jsonQ(translatedSchema) // TODO: remove after TP-Aufgabe #25393
            .find('enumNames')
            .value(this.translateArray);
        jsonQ(translatedUiSchema) // TODO: remove after TP-Aufgabe #25393
            .find('ui:placeholder')
            .value(this.translateKey);

        return {
            translatedSchema,
            translatedUiSchema
        };
    };

    getErrorList = (props) => (
        <TpErrorList
            notifications={props.errors.map((err) => err.notification)}
        />
    );

    render() {
        const formContext = {
            stringResources: this.props.stringResources,
            formId: this.props.id,
            /* Allow the BE to provide extra non-form data which the custom fields and widgets can access: */
            fieldData: this.props.fieldData,
            redFlags: this.props.redFlags,
            isLegacyForm: this.props.isLegacyForm,
            ...this.props.customFormContext
        };

        const button = this.props.showSubmitButton ? (
            <TpButton
                id={this.props.submitButtonId}
                type="submit"
                webStringKey={this.props.submitButtonWebStringKey}
                className={this.props.submitButtonClassNames}
                disabled={this.props.submitButtonIsDisabled}
            />
        ) : (
            <button type="submit" className="submit bk-cs-hidden" />
        );

        const formBorderClass =
            this.props.uiSchema.hideBorder || this.props.hideBorder
                ? ''
                : ' bk-cs-box--bordered';

        const {translatedSchema, translatedUiSchema} = this.translateSchemas(
            this.props.schema,
            this.props.uiSchema
        );

        return (
            <div className={`bk-cs-box${formBorderClass}`}>
                <DivRenderableForm
                    id={this.props.id}
                    schema={translatedSchema}
                    uiSchema={translatedUiSchema}
                    formData={this.props.formData || this.state.formData}
                    onSubmit={this.props.onSubmit}
                    onError={this.props.onError}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    noHtml5Validate={true}
                    formContext={formContext}
                    liveValidate={false}
                    FieldTemplate={
                        this.props.customFieldTemplate || TpFieldTemplate
                    }
                    ObjectFieldTemplate={this.props.customObjectFieldTemplate}
                    ArrayFieldTemplate={this.props.customArrayFieldTemplate}
                    fields={this.fields}
                    widgets={this.widgets}
                    transformErrors={this.handleTransformErrors}
                    additionalMetaSchemas={[additionalMetaSchemas]}
                    showErrorList={this.props.showErrorList}
                    ErrorList={this.getErrorList}
                    renderAsDiv={this.props.renderAsDiv}>
                    {button}
                    {this.props.children}
                </DivRenderableForm>
            </div>
        );
    }
}

export {Form};
