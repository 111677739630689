import UserFeatureTypes from 'types/UserFeatureTypes';
import AccountFeatureTypes from 'types/AccountFeatureTypes';
import AppTypes from 'types/AppTypes';

// It maps elementKey with userFeatures, appTypes and accountFeature
// If there is no accountFeature for an element key, there is no need pass accountFeature
const elementKeyFeatureToggleMap = {
    proposals: {
        userFeatures: [
            UserFeatureTypes.ASSESSMENT,
            UserFeatureTypes.AUDIT,
            UserFeatureTypes.PROPOSAL
        ],
        appTypes: [AppTypes.TP, AppTypes.BA]
    },
    businesspartners: {
        userFeatures: [UserFeatureTypes.ASSESSMENT, UserFeatureTypes.AUDIT],
        appTypes: [AppTypes.TP]
    },
    reports: {
        userFeatures: [UserFeatureTypes.ASSESSMENT, UserFeatureTypes.AUDIT],
        appTypes: [AppTypes.TP, AppTypes.BA],
        accountFeature: AccountFeatureTypes.GENERATE_REPORTS
    },
    precheck: {
        userFeatures: [UserFeatureTypes.PROPOSAL],
        appTypes: [AppTypes.TP],
        accountFeature: AccountFeatureTypes.PRE_CHECK
    },
    alerts: {
        userFeatures: [UserFeatureTypes.ALERTS],
        appTypes: [AppTypes.TP, AppTypes.BA]
    },
    users: {
        userFeatures: [UserFeatureTypes.USER_MANAGEMENT],
        appTypes: [AppTypes.TP, AppTypes.BA]
    },
    adhoccheck: {
        userFeatures: [UserFeatureTypes.AD_HOC_CHECK],
        appTypes: [AppTypes.TP]
    },
    external: {
        userFeatures: [UserFeatureTypes.EXTERNAL],
        appTypes: [AppTypes.TP]
    }
};

export default elementKeyFeatureToggleMap;
