import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import UploadFile from './UploadFile';
import StringPlaceholder from 'lib/StringPlaceholder';
import defaultAppValues from 'lib/defaultAppValues';

/**
 * File upload field, with instruction text, information on currently uploaded
 * files and file limit, and upload button
 */
class UploadProcess extends React.Component {
    static propTypes = {
        uploadButtonId: PropTypes.string.isRequired,
        filesEndpointUrlPath: PropTypes.string.isRequired,
        onItemSuccess: PropTypes.func.isRequired,
        allowedFileExtensions: PropTypes.arrayOf(PropTypes.string),
        maxFileSizeInMB: PropTypes.number,
        maxFilesCount: PropTypes.number,
        uploadedFilesCount: PropTypes.number,
        fileUploadExternalId: PropTypes.string
    };

    static defaultProps = {
        allowedFileExtensions: defaultAppValues.fileUploadExtensions,
        maxFileSizeInMB: defaultAppValues.fileUploadMaxSizeInMB,
        maxFilesCount: defaultAppValues.fileUploadMaxFilesCount,
        uploadedFilesCount: 0,
        fileUploadExternalId: ''
    };

    constructor(props) {
        super(props);
        this.state = {files: [], error: null};
        this.inputFileRef = null;
        this.fileTypes = this.normalizeTypesForUserDisplay();
    }

    normalizeTypesForUserDisplay = () =>
        this.props.allowedFileExtensions
            .map((extension) => '*.' + extension)
            .join(', ');

    onUploadItemClick(event) {
        event.preventDefault();
        this.checkDataAndUpdateStateBeforeUpload(event.target.files[0]);
        this.inputFileRef.value = null;
    }

    checkDataAndUpdateStateBeforeUpload = (file) => {
        let error;

        if (file) {
            if (this.isFileNameAllowed(file.name)) {
                error = {
                    webStringKey: 'tp.errormsg.validation.filename',
                    placeholder: new StringPlaceholder('fileName', file.name)
                };
            } else if (this.isFileAlreadyInUpload(file.name)) {
                error = {
                    webStringKey: 'tp.errormsg.validation.filealreadyinupload',
                    placeholder: new StringPlaceholder('fileName', file.name)
                };
            } else if (!this.isFileExtensionAllowed(file.name)) {
                error = {
                    webStringKey: 'tp.errormsg.validation.filetype',
                    placeholder: new StringPlaceholder(
                        'allowedFileTypes',
                        this.fileTypes
                    )
                };
            } else if (!this.isFileSizeAllowed(file.size)) {
                error = {
                    webStringKey: 'tp.errormsg.validation.filesize',
                    placeholder: new StringPlaceholder(
                        'maxFileSizeInMB',
                        this.props.maxFileSizeInMB
                    )
                };
            }

            const newState = !error
                ? {files: [...this.state.files, file], error: null}
                : {error};

            this.setState(newState);
        }
    };

    isFileSizeAllowed = (size) => size / 1048576 <= this.props.maxFileSizeInMB;

    isFileExtensionAllowed = (name) => {
        const extension = name.split('.').pop();
        return new Set([...this.props.allowedFileExtensions]).has(extension);
    };

    isFileAlreadyInUpload = (name) =>
        this.state.files.some((item) => item.name === name);

    isFileNameAllowed = (name) => {
        /* some of the names e.g:- com0 com1 etc are reserved words in windows*/
        const validFileNameRegex = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$|([<>:"\/\\|?*])|(\.|\s)$/gi;
        return validFileNameRegex.test(name);
    };

    getNotMatchedFiles = (name) =>
        this.state.files.filter((item) => item.name !== name);

    handleOnUploadItemSuccess = (resp, fileInfo) => {
        this.setState({files: this.getNotMatchedFiles(fileInfo.name)}, () => {
            this.props.onItemSuccess(resp);
        });
    };

    handleOnCancelItem = (name) => {
        this.setState({files: this.getNotMatchedFiles(name)});
    };

    render() {
        const uploadInfoForWebString = {
            webStringKey: 'cs.text.fileuploadinfo',
            placeholder: [
                new StringPlaceholder('count', this.props.maxFilesCount),
                new StringPlaceholder('types', this.fileTypes),
                new StringPlaceholder('size', this.props.maxFileSizeInMB)
            ]
        };

        const totalItemsLengthInUse =
            this.props.uploadedFilesCount + this.state.files.length;
        const disableUpload = totalItemsLengthInUse >= this.props.maxFilesCount;

        const lengthInfoForWebString = {
            webStringKey: 'cs.text.used_of_max_filescount',
            placeholder: [
                new StringPlaceholder('maxCount', this.props.maxFilesCount),
                new StringPlaceholder('usedCount', totalItemsLengthInUse)
            ]
        };

        return (
            <div className="file-upload__new-files">
                <WebString
                    className="file-upload__text file-upload__text--instructions"
                    {...uploadInfoForWebString}
                />
                <label>
                    <input
                        id={this.props.uploadButtonId}
                        ref={(node) => (this.inputFileRef = node)}
                        disabled={disableUpload}
                        type="file"
                        name="file"
                        onChange={
                            disableUpload
                                ? null
                                : (event) => this.onUploadItemClick(event)
                        }
                    />
                    <WebString
                        className={`bk-cs-button ${
                            disableUpload ? 'bk-cs-button--disabled' : ''
                        }`}
                        webStringKey="cs.button.selectfile"
                    />
                    <WebString
                        className="file-upload__text file-upload__text--file-limit"
                        {...lengthInfoForWebString}
                    />
                </label>
                {this.state.error ? (
                    <WebString
                        className="file-upload__text file-upload__text--error"
                        {...this.state.error}
                    />
                ) : null}
                {this.state.files.map((file) => (
                    <UploadFile
                        key={file.name}
                        onSuccess={(resp) =>
                            this.handleOnUploadItemSuccess(resp, file)
                        }
                        onCancel={() => this.handleOnCancelItem(file.name)}
                        file={file}
                        filesEndpointUrlPath={this.props.filesEndpointUrlPath}
                        fileUploadExternalId={this.props.fileUploadExternalId}
                    />
                ))}
            </div>
        );
    }
}

export default UploadProcess;
