import React from 'react';
import PropTypes from 'prop-types';

/**
 * Represents a row in the internet research data list.
 * @class
 */
export default class InternetResearchDataRow extends React.PureComponent {
    static propTypes = {
        itemInfo: PropTypes.shape({
            htmlFormattedUrl: PropTypes.string.isRequired,
            htmlSnippet: PropTypes.string.isRequired,
            htmlTitle: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired
        }).isRequired,
        classNameBase: PropTypes.string
    };

    static defaultProps = {
        classNameBase: ''
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            link,
            htmlTitle,
            htmlFormattedUrl,
            htmlSnippet
        } = this.props.itemInfo;
        const className = `${this.props.classNameBase}__row-data`;

        return (
            <div className={className}>
                <a
                    className={`${className}__link`}
                    target="_blank"
                    href={link}
                    dangerouslySetInnerHTML={{__html: htmlTitle}}
                />
                <div
                    className={`${className}__url`}
                    dangerouslySetInnerHTML={{__html: htmlFormattedUrl}}
                />
                <div
                    className={`${className}__snippet`}
                    dangerouslySetInnerHTML={{__html: htmlSnippet}}
                />
            </div>
        );
    }
}
