const mainErrorsReducer = (state = [], action) => {
    const newState = [...state];

    switch (action.type) {
        case 'SHOW_ERROR':
            if (
                newState.every(
                    (err) =>
                        err.title !== action.title ||
                        err.message !== action.message ||
                        err.messageDefault !== action.messageDefault
                )
            ) {
                newState.push({
                    title: action.title,
                    message: action.message,
                    messageDefault: action.messageDefault,
                    placeholders: action.placeholders
                });
            }

            return newState;
        case 'HIDE_ERROR':
            newState.splice(action.key, 1);

            return newState;
        default:
            return newState;
    }
};

export default mainErrorsReducer;
