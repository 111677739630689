import AdHocCheckRoute from './AdHocCheckRoute';
import AlertsRoute from './AlertsRoute';
import BusinessPartnersRoute from './BusinessPartnersRoute';
import ExternalQuestionnaireRoute from './ExternalQuestionnaireRoute';
import LoginRoute from './LoginRoute';
import LogoutRoute from './LogoutRoute';
import PreCheckRoute from './PreCheckRoute';
import ProposalsRoute from './ProposalsRoute';
import SelfRegistrationRoute from './SelfRegistrationRoute';
import UsersRoute from './UsersRoute';
import ReportsRoute from './ReportsRoute';

export default {
    AdHocCheckRoute,
    AlertsRoute,
    BusinessPartnersRoute,
    ExternalQuestionnaireRoute,
    LoginRoute,
    LogoutRoute,
    PreCheckRoute,
    ProposalsRoute,
    SelfRegistrationRoute,
    UsersRoute,
    ReportsRoute
};
