import React, {useState} from 'react';
import TpButton from 'components/common/buttons/TpButton';
import TpPopup from 'components/common/TpPopup';
import ImportUsersFromCsvDialog from './ImportUsersFromCsvDialog';

const ImportUsersFromCsvButton = () => {
    const [showPopUp, setShowPopUp] = useState(false);

    const importCsvDialogConfig = {
        headerInfo: {
            webStringKey: 'tp.importuserscsv.header.text',
            type: 'generic',
            showCloseButton: true,
            className: 'tp-import-users-csv__popup-header'
        },
        size: 'large',
        className: 'tp-import-users-csv__pop-up'
    };

    const onClosePopup = () => {
        setShowPopUp(false);
    };

    const displayImportCsvDialog = () => {
        setShowPopUp(true);
    };

    return (
        <>
            <TpButton
                className={'tp-all-users-table__import-users-csv-button'}
                id="import-csv-btn"
                webStringKey="tp.button.importcsv"
                onClick={displayImportCsvDialog}
            />
            {showPopUp ? (
                <TpPopup {...importCsvDialogConfig} onClose={onClosePopup}>
                    <ImportUsersFromCsvDialog onClosePopup={onClosePopup} />
                </TpPopup>
            ) : null}
        </>
    );
};

export default ImportUsersFromCsvButton;
