import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import TpTable from '../tables/TpTable';
import * as tpProposalActions from 'store/actions/processActions';
import {getAssessmentsTableColumns} from './utilities/tableColumns';
import TableTypes from 'types/TableTypes';
import WebString from 'components/webString/WebString';
import urls from 'routes/urls';
import UserRoleTypes from 'types/UserRoleTypes';
import getTableDataFromStateAndDispatchHelper from 'components/dashboard/utilities/tablesStateWithDispatchHelper';

/**
 * Provides table configuration and API call action to TpTable component.
 */

const ManagerAssessableProposalsTable = ({userRole}) => {
    const history = useHistory();

    const dataFromState = getTableDataFromStateAndDispatchHelper(
        userRole,
        TableTypes.ASSESSMENT,
        tpProposalActions.getAllAssessableManagerProposals
    );
    const tableColumnsConfigured = getAssessmentsTableColumns(
        dataFromState.columns,
        dataFromState.filterOptions
    );

    const tableColumns = tableColumnsConfigured.map((column) => {
        let columnToBeModified = Object.assign({}, column);
        if (columnToBeModified.id === 'bpNameAssessment') {
            columnToBeModified.Header = (
                <WebString webStringKey="cs.text.bpname" />
            );
        }
        return columnToBeModified;
    });

    const onTableRowClick = (row) => {
        history.push(`${urls.ASSESSMENT_URL}/${row.id}`);
    };

    const mappedStateAndProps = {
        data: dataFromState.data,
        isFetchingData: dataFromState.isFetchingData,
        searchParams: dataFromState.searchParams,
        totalPages: dataFromState.totalPages,
        locale: dataFromState.locale,
        defaultPageSize: dataFromState.defaultPageSize,
        fetchData: dataFromState.fetchData,
        tableColumns,
        onTableRowClick,
        titleWebstringKey: 'tp.table.assessableproposals.title',
        descriptionWebstringKey: 'tp.table.assessableproposals.text',
        defaultSorted: [
            {
                id: 'proposalLastModificationDateAssessment',
                desc: true
            }
        ]
    };

    return <TpTable {...mappedStateAndProps} />;
};

ManagerAssessableProposalsTable.propTypes = {
    userRole: PropTypes.oneOf(Object.values(UserRoleTypes)).isRequired
};

export default ManagerAssessableProposalsTable;
