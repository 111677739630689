import React from 'react';
import PropType from 'prop-types';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import merge from 'lodash.merge';

/**
 * Represents a Reco and Action Plan red flag entry.
 * Renders a div containing the information related to the red flag, such as
 * the related question and answer that triggered the red flag. It also displays
 * the level and category of the red flag.
 */

const answerWidgetMapping = {
    DATE: 'cs-displayStringAsDate',
    WEB_STRING: 'DisplayValueAsWebstringWidget'
};

/**
 * The ui:widget is changed depending on the answertype sent via formdata.
 * The ID of the entry is appended to the upload field key to be able to
 * distinguish them.
 * @param objectFieldProps
 */
const getObjectFieldProps = (objectFieldProps) => {
    return merge(objectFieldProps, {
        uiSchema: {
            answer: {
                'ui:widget':
                    answerWidgetMapping[objectFieldProps.formData.answerType]
            },
            upload: {
                'ui:options': {
                    keyForUpload: 'upload_' + objectFieldProps.idSchema.$id
                }
            }
        }
    });
};

const RedFlagEntry = (props) => (
    <div className={'tp-red-flags__entry'}>
        <ObjectField {...getObjectFieldProps(props)} />
    </div>
);

RedFlagEntry.propTypes = {
    schema: PropType.object.isRequired,
    uiSchema: PropType.object.isRequired,
    formData: PropType.object.isRequired,
    idSchema: PropType.object.isRequired
};

export default RedFlagEntry;
