import {useState, useEffect} from 'react';
import HTTPClient from 'lib/HTTPClient';
import userUtils from '../../userUtils';
import userSchemaNameTypes from '../userSchemaNameTypes';

/**
 * Custom hook for userData
 * It fetches userDTO and converts into userData
 *
 * generalFormData: Used by GeneralForm
 * rolesFormData: Used by RoleForms
 * singleRightsData: Used by RoleForms
 *
 * @param {Number} userId
 * @param  {UserSchemaNameTypes} userSchemaNameType
 * @param {Function} onError
 * @return {[{generalFormData: object, rolesFormData: array, singleRightsData: object}, *, *, *]}
 */
const useUserData = (userId, userSchemaNameType, onError) => {
    /**
     * generalFormData is an object consists of general data of the user and used by GeneralForm
     */
    const [generalFormData, setGeneralFormData] = useState(() => {});

    /**
     * rolesFormData is an array consists of roleFormData objects used by RoleForm
     * ex: [
     *   APPLICANT: {
     *       isRoleActive: true,
     *       scope: {department: IT}
     *   }
     * ]
     */
    const [rolesFormData, setRolesFormData] = useState(() => {});

    /**
     * singleRightsData is an object consists of single rights used by RoleForm
     * ex: {PROPOSALS_REPORT: true}
     */
    const [singleRightsData, setSingleRightsData] = useState(() => {});

    async function getUser() {
        try {
            const response = await HTTPClient.get(`user/${userId}`);
            const data = userUtils.convertUserDTOWithMultipleRolesToUserData(
                response
            );
            setGeneralFormData((prevState) => ({
                ...prevState,
                ...data.generalFormData
            }));
            setRolesFormData((prevState) => ({
                ...prevState,
                ...data.rolesFormData
            }));
            setSingleRightsData((prevState) => ({
                ...prevState,
                ...data.singleRightsData
            }));
        } catch (error) {
            onError(error, 'GET_USER_FAILED');
        }
    }

    useEffect(() => {
        if (userSchemaNameType === userSchemaNameTypes.UPDATE) {
            getUser();
        }
    }, []);

    return [
        {generalFormData, rolesFormData, singleRightsData},
        setGeneralFormData,
        setRolesFormData,
        setSingleRightsData
    ];
};

export default useUserData;
