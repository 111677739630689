import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders div with classes for form and disabling.
 *
 * @param {Object} props
 * @returns {ReactElement} markup
 */

class DisabledTextBlock extends React.PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        value: PropTypes.any,
        style: PropTypes.object
    };

    static defaultProps = {
        value: '',
        style: {}
    };

    render() {
        const mergedProps = {
            id: this.props.id
        };

        if (this.props.style) {
            mergedProps.style = this.props.style;
        }
        return (
            <div
                {...mergedProps}
                className="form-control form-control--disabled">
                {this.props.value}
            </div>
        );
    }
}

export default DisabledTextBlock;
