import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {flatten} from 'flat';
import getUserStatusOptions from 'components/dashboard/utilities/getUserStatusOptions';
import {convertToApiSearchParams} from './searchParamConverter';

const getTableDataFromStateAndDispatchHelper = (
    userRole,
    tableType,
    functionToDispatch
) => {
    const dispatch = useDispatch();

    const {tableInfo, account, locale, user} = useSelector((state) => ({
        tableInfo: state.dashboard[tableType],
        account: state.sessionContext.account,
        user: state.sessionContext.user,
        locale: state.settings.i18n.locale
    }));

    const {
        isLoading: isFetchingData,
        proposals: data,
        totalPages,
        searchParams
    } = tableInfo;

    const {tableSettings, tableStatuses} = account;

    const {pageSize: defaultPageSize, columns} = tableSettings[tableType];

    let filterOptions = {};

    if (userRole) {
        filterOptions = {
            proposalTableStatuses: getUserStatusOptions(
                tableStatuses,
                userRole,
                tableType
            )
        };
    } else {
        filterOptions = tableStatuses[tableType] || {};
    }

    if (user.userRoleAndScope) {
        const mergedScopes = [];
        Object.entries(user.userRoleAndScope)
            .filter(([roleName]) => roleName.includes(userRole))
            .map(([, scope]) => scope)
            .flat()
            .forEach((userScopes) => {
                const existingScope = mergedScopes.find(
                    (mergedScope) =>
                        mergedScope.dimension === userScopes.dimension
                );
                if (existingScope) {
                    existingScope.values = [
                        ...existingScope.values,
                        ...userScopes.values
                    ];
                } else {
                    mergedScopes.push({...userScopes});
                }
            });
        if (mergedScopes.length !== 0) {
            filterOptions.scope = mergedScopes;
        }
    }

    const fetchData = (reactTableSearchParams, locale) => {
        const apiSearchParams = convertToApiSearchParams(
            reactTableSearchParams,
            locale
        );

        dispatch(
            functionToDispatch(
                apiSearchParams,
                reactTableSearchParams,
                userRole
            )
        );
    };

    return {
        locale,
        isFetchingData,
        data: data.map(flatten),
        totalPages,
        searchParams,
        defaultPageSize,
        columns,
        filterOptions,
        fetchData
    };
};

export default getTableDataFromStateAndDispatchHelper;
