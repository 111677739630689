import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DOMPurify from 'dompurify';
import StringPlaceholder from 'lib/StringPlaceholder';
import {truncateString, escapeAnchorTagsInString} from './stringUtils';
import webStringUtils from 'lib/webStringUtils';
import TpTooltip from 'components/common/TpTooltip';

/**
 * Renders a span or div (if it contains html) with the value of the set webStringKey in the stringResources.
 * Displays the value of the webStringKey found in stringResources or webStringKey if not found.
 * Optionally makes replacements in string for placeholders.
 * Optionally truncates the string after a character limit, or the last full word before, adding ellipsis char at the end.
 *
 * @param {String} webStringKey - webString key to find in stringResources.
 * @param {Object} stringResources - webString keys and current locale values.
 * @param {String} defaultValue - default text to display when string resources is an empty object. Is used for main error display.
 * @param {String} className - classes to render in the containing markup tags, defaults to bk-cs-inline.
 * @param {StringPlaceholder|StringPlaceholder[]} placeholder - data for replacing a placeholder.
 * @param {number} characterLimit - string length to cut off after, or after last full word.

 * @returns {ReactElement} markup -  the internationalised textual element(s)
 */
class WebString extends React.PureComponent {
    static propTypes = {
        webStringKey: PropTypes.string.isRequired,
        webStringValue: PropTypes.string,
        defaultValue: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        placeholder: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.instanceOf(StringPlaceholder)),
            PropTypes.instanceOf(StringPlaceholder)
        ]),
        characterLimit: PropTypes.number,
        showWebStringKeys: PropTypes.bool
    };

    static defaultProps = {
        webStringValue: undefined,
        defaultValue: null,
        className: null,
        id: null,
        placeholder: null,
        characterLimit: null,
        showWebStringKeys: false
    };

    createTooltip = () => (
        <TpTooltip id={`tp-tooltip-webStringKey-${this.props.webStringKey}`}>
            {this.props.webStringKey}
        </TpTooltip>
    );

    render() {
        const {
            webStringValue,
            webStringKey,
            className,
            id,
            defaultValue,
            characterLimit,
            showWebStringKeys
        } = this.props;
        let stringToDisplay = webStringValue;
        // set defaultValue or webStringKey to webStringValue if webStringKey was not found in stringResources
        if (typeof stringToDisplay === 'undefined') {
            stringToDisplay = defaultValue || webStringKey;
        }

        if (!stringToDisplay) {
            return null;
        }

        if (stringToDisplay !== webStringKey && this.props.placeholder) {
            const placeholders = Array.isArray(this.props.placeholder)
                ? this.props.placeholder
                : [this.props.placeholder];

            placeholders.forEach((placeholder) => {
                stringToDisplay = placeholder.replaceIn(stringToDisplay);
            });
            // replace all remaining data in {} with default value
            stringToDisplay = StringPlaceholder.replaceAllWithDefaultIn(
                stringToDisplay
            );
        }

        if (characterLimit) {
            const stringAfterEscapingAnchorTag = escapeAnchorTagsInString(
                webStringValue
            );
            stringToDisplay = truncateString(
                stringAfterEscapingAnchorTag,
                characterLimit
            );
        }

        let tooltipProps = null;
        let tooltip = null;

        let classNameNew = className ? className : '';

        if (showWebStringKeys) {
            tooltip = this.createTooltip();
            tooltipProps = {
                'data-for': `tp-tooltip-webStringKey-${this.props.webStringKey}`,
                'data-tip': true
            };
            classNameNew = classNameNew + ' tp-webStringKey';
        }

        const props = {
            ...tooltipProps,
            ...{id},
            ...(classNameNew ? {className: classNameNew} : null)
        };
        return (
            <React.Fragment>
                {webStringUtils.isHTML(stringToDisplay) ? (
                    <div
                        {...props}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(stringToDisplay)
                        }}
                    />
                ) : (
                    <span {...props}>{stringToDisplay}</span>
                )}
                {tooltip}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    webStringValue: state.settings.i18n.stringResources[ownProps.webStringKey],
    showWebStringKeys: state.settings.i18n.showWebStringKeys
});

export default connect(mapStateToProps)(WebString);
