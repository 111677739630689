import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import IconButtonRemove from '../iconButtons/IconButtonRemove';
import IconButtonDownload from '../iconButtons/IconButtonDownload';
import HTTPClient from 'lib/HTTPClient';
import TpTooltip from 'components/common/TpTooltip';

/**
 * Shows an uploaded file.
 * Provides API call to download and to delete files.
 * DOM ref used to put tooltip on long filenames where they are
 * truncated by CSS.
 *
 */
export class UploadedFileDataRow extends React.PureComponent {
    static propTypes = {
        file: PropTypes.shape({
            fileId: PropTypes.number.isRequired,
            fileMetaData: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        disabled: PropTypes.bool,
        index: PropTypes.number.isRequired,
        filesEndpointUrlPath: PropTypes.string.isRequired,
        onRemoveFile: PropTypes.func,
        onError: PropTypes.func
    };

    static defaultProps = {
        disabled: false,
        onRemoveFile: null,
        onError: () => null
    };

    constructor(props) {
        super(props);
        this.removeFileAsync = this.removeFileAsync.bind(this);
        this.filenameRef = null;
        this.fileApiUrl = `${this.props.filesEndpointUrlPath}/${this.props.file.fileId}`;
        this.state = {
            showFilenameTooltip: false,
            downloadError: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.showTooltipForTruncatedFilename);
        this.showTooltipForTruncatedFilename();
    }

    componentDidUpdate() {
        this.showTooltipForTruncatedFilename();
    }

    /**
     * Only need to add the tooltip where a long name is truncated by CSS
     */
    showTooltipForTruncatedFilename = () => {
        if (this.filenameRef) {
            this.setState({
                showFilenameTooltip:
                    this.filenameRef.offsetWidth < this.filenameRef.scrollWidth
            });
        }
    };

    handleRemoveClick = () => {
        this.removeFileAsync();
    };

    async removeFileAsync() {
        try {
            const {fileId} = this.props.file;
            await HTTPClient.delete(this.fileApiUrl);
            this.props.onRemoveFile(fileId);
        } catch (error) {
            this.props.onError(error, 'REMOVE_FILE_FAILED');
        }
    }

    downloadFile = (file) => {
        return async (done, handleError) => {
            try {
                const response = await HTTPClient.getBinary(this.fileApiUrl);
                return done({
                    contents: response,
                    filename: file.fileMetaData.name,
                    mime: file.fileMetaData.mediaType
                });
            } catch (error) {
                handleError();
                this.props.onError(error, 'DOWNLOAD_FILE_FAILED');
            }
        };
    };

    createFilenameTooltip = () => (
        <TpTooltip id={`toolTip-filename-${this.props.file.fileId}`}>
            {this.props.file.fileMetaData.name}
        </TpTooltip>
    );

    render() {
        const {file} = this.props;

        let filenameTooltip = null;
        let toolTipProps = null;
        if (this.state.showFilenameTooltip) {
            filenameTooltip = this.createFilenameTooltip();
            toolTipProps = {
                'data-for': `toolTip-filename-${file.fileId}`,
                'data-tip': true
            };
        }

        const removeButton =
            !this.props.disabled && this.props.onRemoveFile ? (
                <IconButtonRemove
                    id={`file_${file.fileId}`}
                    onClick={this.handleRemoveClick}
                />
            ) : null;

        return (
            <div className="file-upload__uploaded-row">
                <div
                    ref={(div) => (this.filenameRef = div)}
                    {...toolTipProps}
                    className="file-upload__uploaded-filename">
                    <span>{this.props.index}. </span>
                    <span>{file.fileMetaData.name}</span>
                    {filenameTooltip}
                </div>
                {removeButton}
                <IconButtonDownload generateFile={this.downloadFile(file)} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onError(error, type) {
        dispatch({type, error});
    }
});

export default connect(null, mapDispatchToProps)(UploadedFileDataRow);
