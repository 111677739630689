import React from 'react';
import PropTypes from 'prop-types';
import {ProposalForm} from 'components/tpForm/TpForm';
import InternetResearchDataList from 'components/internetResearch/InternetResearchDataList';
import TextOnlyFieldTemplate from '../textOnly/TextOnlyFieldTemplate';
import TextOnlyUploadFileField from '../textOnly/TextOnlyUploadFileField';
import TextOnlyArrayField from '../textOnly/TextOnlyArrayField';
import TextOnlyRiskAlertsField from '../textOnly/TextOnlyRiskAlertsField';
import TextOnlyRedFlagsAndPlansTemplate from '../textOnly/TextOnlyRedFlagsAndPlansTemplate';
import textOnlyWidgetsBundle from 'components/tpForm/widgets/textOnlyWidgets';
import FeatureTypes from 'types/FeatureTypes';

/**
 * The SubTaskContent takes in a task of the proposal
 * process and provides, depending on
 * the given task properties, the correct input method.
 *
 * The currentTask.properties property determine the input method.
 * A TpForm component is shown when the property contains a formDefinition
 * property. The duplicate check table is displayed when
 * duplicateCheckResult is present.
 *
 * @class
 */
export default class TaskOverview extends React.PureComponent {
    static propTypes = {
        currentTask: PropTypes.shape({
            id: PropTypes.string.isRequired,
            properties: PropTypes.shape({
                formDefinition: PropTypes.PropTypes.shape({
                    formId: PropTypes.string.isRequired,
                    formSchema: PropTypes.object.isRequired,
                    uiSchema: PropTypes.object
                }),
                internetSearchResult: PropTypes.shape({
                    searchQueryResults: PropTypes.arrayOf(PropTypes.object)
                        .isRequired
                }),
                redFlags: PropTypes.arrayOf(PropTypes.string)
            }).isRequired
        }).isRequired
    };

    customFieldTemplate = TextOnlyFieldTemplate;

    textOnlyFields = {
        ArrayField: TextOnlyArrayField,
        formTransclusionField: () => null,
        uploadFileField: TextOnlyUploadFileField,
        riskAlerts: TextOnlyRiskAlertsField,
        redFlagsAndPlansTemplate: TextOnlyRedFlagsAndPlansTemplate
    };

    textOnlyWidgets = textOnlyWidgetsBundle;

    render() {
        const {
            properties: {
                formData,
                formDefinition,
                internetSearchResult,
                fieldData,
                redFlags
            }
        } = this.props.currentTask;
        let internetSearchOutput;
        if (internetSearchResult) {
            internetSearchOutput = internetSearchResult.searchQueryResults.map(
                (result, key) => (
                    <InternetResearchDataList
                        key={key}
                        searchQueryResult={result}
                        isBordered={false}
                    />
                )
            );
        }

        let formOutput;
        if (formDefinition) {
            formOutput = (
                <ProposalForm
                    id={formDefinition.formId}
                    featureType={FeatureTypes.PROPOSAL}
                    disabled={true}
                    hideBorder={true}
                    renderAsDiv={true}
                    showSubmitButton={false}
                    schema={formDefinition.formSchema}
                    uiSchema={formDefinition.uiSchema || {}}
                    formData={formData || {}}
                    fieldData={fieldData || {}}
                    renderAsText={this.props.textOnly}
                    customFields={this.textOnlyFields}
                    customWidgets={this.textOnlyWidgets}
                    customFieldTemplate={this.customFieldTemplate}
                    redFlags={redFlags}
                />
            );
        }

        return (
            <div className="tp-task-content">
                {internetSearchOutput}
                {formOutput}
            </div>
        );
    }
}
