import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import clonedeep from 'lodash.clonedeep';
import SubTaskContent from './SubTaskContent';
import ActionPanel from 'components/proposal/actionPanel/ActionPanel';
import NotificationPanel from 'components/notificationPanel/NotificationPanel';
import WebString from 'components/webString/WebString';
import * as processActions from 'store/actions/processActions';
import * as proposalActions from 'store/actions/proposalActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import * as proposalUtils from 'lib/proposalUtils';
import StatusTypes from 'types/StatusTypes';
import NotificationMessage from 'domain/NotificationMessage';
import TpButton from 'components/common/buttons/TpButton';
import TpLink from 'components/common/TpLink';
import ProcessLayout from '../common/ProcessLayout';
import ViewType from 'types/ViewTypes';
import urls from 'routes/urls';

/**
 * Gives the structure to the process.
 * Requires some information about the current proposal and all
 * information about the currentTask.
 * Renders a ProcessLayout component, which handles the positioning
 * of the elements within.
 *
 * @class
 */

export class ProposalTaskContent extends React.Component {
    static propTypes = {
        /* proposal */
        proposalId: PropTypes.number.isRequired,
        proposalStatus: PropTypes.oneOf(Object.values(StatusTypes.Proposal))
            .isRequired,

        /* current task */
        currentTask: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            startTimestamp: PropTypes.number.isRequired,
            lastModificationTimestamp: PropTypes.number.isRequired,
            status: PropTypes.oneOf(Object.values(StatusTypes.Task)).isRequired,
            isLastSubTask: PropTypes.bool.isRequired,
            properties: PropTypes.shape({
                formDefinition: PropTypes.shape({
                    formId: PropTypes.string.isRequired
                })
            }).required
        }).isRequired,
        updateCurrentTask: PropTypes.func.isRequired,
        saveCurrentTask: PropTypes.func.isRequired,

        /* for navigation to the next task and setting it as current */
        loadCurrentProposalTaskForProposal: PropTypes.func.isRequired,

        view: PropTypes.oneOf(Object.values(ViewType)).isRequired,
        /* current task errors */
        showErrors: PropTypes.func.isRequired,

        hideErrors: PropTypes.func.isRequired,

        /* all tasks in proposal */
        tasks: PropTypes.array.isRequired,

        /* for front-end, back-end validation errors */
        notifications: PropTypes.arrayOf(
            PropTypes.instanceOf(NotificationMessage)
        )
    };

    static defaultProps = {
        notifications: []
    };

    /**
     * When the form is submitted, call the callbacks to hide inline errors and save to the BE.
     * @private
     * @returns {undefined}
     */
    _handleSubmit = () => {
        this.props.hideErrors();
        this.props.saveCurrentTask(
            this.props.proposalId,
            this.props.currentTask,
            this.props.view
        );
    };

    /**
     * When the current task has status RUNNING or WAITING, it triggers the submit button which is
     * embedded by the form. Otherwise it looks for a next standing task
     * and calls the prop loadCurrentProposalTaskForProposal if the next task exists.
     *
     * @private
     * @returns {undefined}
     */
    _handleNavigatorNext = () => {
        const {currentTask} = this.props;
        const {status, id: currentTaskId} = currentTask;

        /* triggers submit form validation and send it via TpForm onSubmit mechanism */
        if (
            status === StatusTypes.Task.RUNNING ||
            status === StatusTypes.Task.WAITING
        ) {
            const formToValidate = [...document.forms].find(
                (form) =>
                    form.id === currentTask.properties.formDefinition.formId
            );
            if (formToValidate) {
                formToValidate.getElementsByClassName('submit')[0].click();
            }
        } else {
            /* goes to the next task if exists */
            const {tasks, proposalId} = this.props;

            if (!Array.isArray(tasks) || tasks.length === 0) {
                return;
            }

            const indexOfCurrentTask = tasks
                .map((task) => task.id)
                .indexOf(currentTaskId);

            if (indexOfCurrentTask + 1 < tasks.length) {
                const nextTaskId = tasks[indexOfCurrentTask + 1].id;

                this.props.loadCurrentProposalTaskForProposal(
                    proposalId,
                    nextTaskId,
                    this.props.view
                );

                window.scrollTo(0, 0);
            }
        }
    };

    /**
     * Update form data in currentTask
     *
     * @private
     * @param {Object} formData
     * @returns {undefined}
     */
    _handleFormChange = (formData) => {
        // todo: #14477 clonedeep is quite slow and this is called on every keystroke. refactor for efficiency.
        const currentTaskCopy = clonedeep(this.props.currentTask);
        currentTaskCopy.properties.formData = formData;
        this.props.updateCurrentTask(currentTaskCopy);
    };

    /**
     * Changes properties in currentTask: only actionObject will be saved.
     * @private
     * @param {Object} actionObject
     * @returns {undefined}
     */
    _handleProcessAction = (actionObject) => {
        const currentTaskCopy = clonedeep(this.props.currentTask);
        currentTaskCopy.properties = actionObject;
        this.props.saveCurrentTask(
            this.props.proposalId,
            currentTaskCopy,
            this.props.view
        );
    };

    _canUserUpdateTask = () => {
        const {currentTask} = this.props;
        return (
            !!currentTask &&
            !!currentTask.properties &&
            !!currentTask.properties.actions &&
            currentTask.properties.actions.includes('update')
        );
    };

    render() {
        const {proposalStatus, currentTask, showErrors, view} = this.props;

        const cancelLinkTo =
            view !== ViewType.BUSINESS_PARTNER ? urls.PROPOSALS_URL : '/';

        const isProposalAborted =
            proposalStatus === StatusTypes.Proposal.ABORTED;
        const isProposalInProgress =
            proposalStatus === StatusTypes.Proposal.ACTIVE;
        const isNextButtonVisible = this._canUserUpdateTask();
        const proposalTaskName = proposalUtils.getProposalTaskNameFromId(
            currentTask.id
        );
        const webStringKeyForNavigatorContent = `${
            view === ViewType.BUSINESS_PARTNER ? 'businesspartner.' : ''
        }${
            isProposalInProgress
                ? proposalTaskName
                : proposalStatus.toLowerCase()
        }`;

        const proposalForm = (
            <SubTaskContent
                forceDisable={isProposalAborted || !this._canUserUpdateTask()}
                currentTask={currentTask}
                onFormChange={this._handleFormChange}
                onSubmit={this._handleSubmit}
                onActionTaken={this._handleProcessAction}
                onErrors={showErrors}
                view={view}
            />
        );
        const errorContent = (
            <NotificationPanel
                title="tp.errormsg.validation.paneltitle"
                notifications={this.props.notifications}
            />
        );
        const actionPanelContent = (
            <WebString
                webStringKey={`tp.process.${webStringKeyForNavigatorContent}.navigator.text`}
            />
        );
        const navigatorActions = (
            <div>
                <TpLink
                    id="leave-process-btn"
                    className="col-xs-8"
                    webStringKey={`tp.process.${webStringKeyForNavigatorContent}.navigator.cancel`}
                    to={cancelLinkTo}
                />
                <TpButton
                    className="col-xs-4"
                    id="buttonGo"
                    visible={isNextButtonVisible}
                    disabled={
                        !currentTask.isLastSubTask && view !== ViewType.MANAGER
                    }
                    webStringKey={`tp.process.${webStringKeyForNavigatorContent}.navigator.button`}
                    onClick={this._handleNavigatorNext}
                />
            </div>
        );
        const actionPanel = (
            <ActionPanel
                bodyContent={actionPanelContent}
                footerContent={navigatorActions}
            />
        );

        const contentWebStringKeyBase = `tp.process.${proposalTaskName}`;
        const approvalStatusWebstringKey =
            (proposalTaskName === 'applicantapproval' ||
                proposalTaskName === 'approval') &&
            proposalStatus !== 'PENDING'
                ? `tp.process.${webStringKeyForNavigatorContent}`
                : null;
        const subtaskWebStringKeyBase = currentTask.properties.subtaskName
            ? `tp.process.${currentTask.properties.subtaskName}`
            : '';
        return (
            <ProcessLayout
                contentWebStringKeyBase={contentWebStringKeyBase}
                subtaskWebStringKeyBase={subtaskWebStringKeyBase}
                approvalStatusWebstringKey={approvalStatusWebstringKey}
                formPanel={proposalForm}
                errorPanel={errorContent}
                actionPanel={actionPanel}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.process.currentTaskErrors,
    view: state.process.view
});

const mapDispatchToProps = (dispatch) => ({
    // todo: #14477 this could be refactored to only update the formdata, and be a lot faster.
    updateCurrentTask: wrapInDispatch(
        processActions.updateCurrentTask,
        dispatch
    ),
    loadCurrentProposalTaskForProposal: wrapInDispatch(
        processActions.getProposalTaskById,
        dispatch
    ),
    saveCurrentTask: wrapInDispatch(
        proposalActions.saveTaskAndUpdateProposal,
        dispatch
    ),
    showErrors: wrapInDispatch(processActions.showTaskErrors, dispatch),
    hideErrors: wrapInDispatch(processActions.clearTaskErrors, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalTaskContent);
