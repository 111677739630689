import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/**
 * Button with an SVG icon as content.
 * Loading state will superimpose an animated spinner over the button, and
 * show a disabled (grey) version of the icon.
 *
 * If a disabled version will be used, there needs to be an icon, e.g.
 * times-disabled.svg in the same directory as times.svg.
 *
 * @param {object} props: {
 *   onClick {function} button event callback.
 *   iconFilename {string} icon to be displayed in button.
 *   isFetchingData {boolean} loading state of button. Shows overlay and disables button.
 *   children {object} A WebString is passed as child component for the tooltip.
 * }
 */
class IconButton extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        iconClassName: PropTypes.string.isRequired,
        children: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        className: PropTypes.string
    };

    static defaultProps = {
        isLoading: false,
        className: ''
    };

    render() {
        const {id, onClick, isLoading, children, className} = this.props;

        const loadingDiv = isLoading ? (
            <div className="tp-loader__spinner tp-loader--small icon-button__loading-overlay" />
        ) : null;

        const classNames = `icon-button ${className}`.trim();
        return (
            <div className={classNames} onClick={onClick}>
                {loadingDiv}
                <i
                    data-tip
                    data-for={`tooltip-icon-btn_${id}`}
                    id={`icon-btn_${id}`}
                    className={`icon-button__icon ${this.props.iconClassName}`}
                />
                <ReactTooltip
                    id={`tooltip-icon-btn_${id}`}
                    type="dark"
                    className="icon-button__tooltip"
                    effect="solid"
                    delayHide={250}
                    delayShow={500}>
                    {children}
                </ReactTooltip>
            </div>
        );
    }
}

export default IconButton;
