import React from 'react';
import PropTypes from 'prop-types';
import InternetResearchDataRow from './InternetResearchDataRow';
import WebString from 'components/webString/WebString';
import FormattedUnixTime from 'components/common/FormattedUnixTime';

/**
 * Displays a list of internet research data row.
 * @class
 */
export default class InternetResearchDataList extends React.Component {
    static propTypes = {
        searchQueryResult: PropTypes.shape({
            executionTimestamp: PropTypes.number.isRequired,
            results: PropTypes.shape({
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        htmlFormattedUrl: PropTypes.string.isRequired,
                        htmlSnippet: PropTypes.string.isRequired,
                        htmlTitle: PropTypes.string.isRequired,
                        link: PropTypes.string.isRequired
                    })
                ),
                searchInformation: PropTypes.shape({
                    formattedSearchTime: PropTypes.string.isRequired,
                    formattedTotalResults: PropTypes.string.isRequired
                }).isRequired
            }).isRequired,
            searchDomain: PropTypes.string.isRequired,
            searchTerm: PropTypes.string.isRequired
        }).isRequired,
        isBordered: PropTypes.bool
    };

    static defaultProps = {
        isBordered: true
    };

    /**
     * Will return colored google domain text
     *
     * @param {String} domain
     *
     * @return {ReactElement}
     */
    getColoredGoogleDomainText(domain) {
        const secondPartAfterDot = domain.split('.', 2).pop();
        const baseClassName = 'bk-cs-google--';

        return (
            <span>
                <span className={`${baseClassName}blue`}>g</span>
                <span className={`${baseClassName}red`}>o</span>
                <span className={`${baseClassName}yellow`}>o</span>
                <span className={`${baseClassName}blue`}>g</span>
                <span className={`${baseClassName}green`}>l</span>
                <span className={`${baseClassName}red`}>e</span>
                <span className={`${baseClassName}blue`}>
                    .{secondPartAfterDot}
                </span>
            </span>
        );
    }

    /**
     * Will return colored domain text as ReactElement using this.getColoredGoogleDomainText()
     * if domain includes 'google' or string itself if not
     *
     * @param {String} domain
     *
     * @return {ReactElement|String}
     */
    getColoredDomainText(domain) {
        return domain.includes('google')
            ? this.getColoredGoogleDomainText(domain)
            : domain;
    }

    /**
     * Remove google search params syntax from string
     *
     * @example <caption>Example of searchTerm string and edited result<caption>:
     * // returns: { onlyIntext: Alex Mustermann
     * //            notIntext: corruption, tax evasion, investigation, criminal charges
     * //          }
     * getOnlyKeywords('intext:Alex intext:Mustermann   "corruption" | "tax evasion" | "investigation" | "criminal charges"')
     *
     * @param {String} searchTerm
     * @return {{onlyIntext: String, notIntext: String}}
     */
    getOnlyKeywords(searchTerm) {
        let [onlyIntext, ...rest] = searchTerm.split('"');
        onlyIntext = onlyIntext.replace(/intext:/g, '');

        rest = new Set(rest);
        rest.delete(' | ');
        rest.delete(' ');
        rest.delete('');

        return {
            onlyIntext,
            notIntext: [...rest].join(', ')
        };
    }

    render() {
        const {
            executionTimestamp,
            searchDomain,
            results,
            searchTerm
        } = this.props.searchQueryResult;
        const classNameBase = 'bk-cs-internet-research';
        const searchKeywords = this.getOnlyKeywords(searchTerm);

        return (
            <div
                className={`${
                    this.props.isBordered ? 'bk-cs-box--bordered ' : ''
                }bk-cs-fontfamily--arial`}>
                <div className="bk-cs-box__content">
                    <div className={`${classNameBase}__summary`}>
                        <span className="bk-cs-fontsize-20">
                            {this.getColoredDomainText(searchDomain)}
                        </span>
                        <FormattedUnixTime
                            value={executionTimestamp}
                            momentTimeFormat="lll"
                            className="bk-cs-float-right"
                        />
                        <div className="bk-cs-line-height--1">
                            <WebString
                                className="bk-cs-inline-block bk-cs-bold"
                                webStringKey="cs.text.keywords"
                            />
                            :&nbsp;
                            {searchKeywords.onlyIntext}
                            <br />
                            {searchKeywords.notIntext}
                        </div>
                        <div>
                            {results.searchInformation.formattedTotalResults}
                            &nbsp;
                            <WebString webStringKey={'cs.text.results'} />
                        </div>
                    </div>
                    {results.items
                        ? results.items.map((item, key) => (
                              <InternetResearchDataRow
                                  classNameBase={classNameBase}
                                  key={key}
                                  itemInfo={item}
                              />
                          ))
                        : ''}
                </div>
            </div>
        );
    }
}
