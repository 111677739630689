import React from 'react';
import WebString from 'components/webString/WebString';
import TpImage from 'components/common/TpImage';
import fileSaveUtils from 'lib/fileSavingUtils';

export const DownloadCsvTemplateButton = () => {
    const columnTitles = ['firstName', 'surName', 'emailAddress'];

    const csvData = columnTitles.join(',') + '\n';

    const handleOnClick = () => {
        fileSaveUtils.saveDataToCsvFile('importTemplate', csvData);
    };

    return (
        <div
            onClick={handleOnClick}
            className="tp-import-users-csv__download-button-container">
            <TpImage
                className="tp-import-users-csv__download-template-button-icon"
                src={`icons/download-green-circle.svg`}
                alt="download_icon"
            />
            <div className="tp-import-users-csv__download-template-button-text">
                <WebString
                    className="tp-import-users-csv__download-template-button-webstring"
                    webStringKey={`tp.button.downloadcsvtemplate`}
                />
            </div>
        </div>
    );
};

export default DownloadCsvTemplateButton;
