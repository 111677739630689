import React from 'react';
import PropTypes from 'prop-types';
import TpButton from 'components/common/buttons/TpButton';

const ProposalClassificationButtonPanel = (props) => {
    const {onSaveChangesClick, discardChanges, isSaveButtonDisabled} = props;

    return (
        <div className="tp-proposal-classification__button-div">
            <TpButton
                isDefaultClass={false}
                className="tp-proposal-classification__button-discardchanges bk-cs-link"
                id="btn-proposal-classification-discardchanges"
                webStringKey="tp.button.proposalclassification.discardchanges.text"
                webStringClassName="tp-proposal-classification__button-discardchanges-text"
                onClick={discardChanges}
            />
            <TpButton
                className="tp-proposal-classification__button-savechanges"
                id="btn-proposal-classification-savechanges"
                webStringKey="tp.button.proposalclassification.savechanges.text"
                onClick={onSaveChangesClick}
                webStringClassName="tp-proposal-classification__button-savechanges-text"
                disabled={isSaveButtonDisabled}
            />
        </div>
    );
};

ProposalClassificationButtonPanel.propTypes = {
    discardChanges: PropTypes.func.isRequired,
    onSaveChangesClick: PropTypes.func.isRequired,
    isSaveButtonDisabled: PropTypes.bool.isRequired
};

export default ProposalClassificationButtonPanel;
