import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Tabs, TabList, TabPanel} from 'react-tabs';
import SingleRightTypes from 'types/SingleRightTypes';
import {useTabIndexContext} from './TabIndexContext';
import RoleForm from './RoleForm';
import RoleFormCustomTab from './RoleFormCustomTab';
import {sortRolesByWebStringValue} from 'components/users/rolesUtils';

/**
 * It merges singleRightsData and related part rolesFormData according to the roleType
 */
const getMergedRoleData = (rolesFormData, singleRightsData, roleType) =>
    rolesFormData[roleType]
        ? {
              ...rolesFormData[roleType],
              singleRights: singleRightsData
          }
        : {};

const checkIfRoleIsActive = (roleType, rolesFormData) =>
    Object.keys(rolesFormData).find(
        () => rolesFormData[roleType] && rolesFormData[roleType].isRoleActive
    );

export const RoleFormTabs = (props) => {
    const {
        rolesFormData,
        singleRightsData,
        availableRoles,
        possibleDimensions,
        singleRightRolesMap,
        userSchemaNameType,
        stringResources,
        handleOnSubmit,
        handleOnRolesFormDataChange,
        handleRoleFormError,
        generateRefs
    } = props;

    const {tabIndexState, setTabIndexState} = useTabIndexContext();
    const [defaultIndex, setDefaultIndex] = useState(-1);

    useEffect(() => {
        if (defaultIndex === -1) {
            const isRoleActive = (role) =>
                !!checkIfRoleIsActive(role, rolesFormData);
            const firstActiveRoleIndex = availableRoles.findIndex(isRoleActive);

            if (firstActiveRoleIndex !== -1) {
                setTabIndexState((prevState) => ({
                    ...prevState,
                    selectedTabIndex: firstActiveRoleIndex
                }));
                setDefaultIndex((prevState) => ({
                    ...prevState,
                    firstActiveRoleIndex
                }));
            }
        }
    }, [rolesFormData]);

    const onSelectTab = (index) => {
        setTabIndexState((prevState) => ({
            ...prevState,
            selectedTabIndex: index
        }));
    };

    return (
        <Tabs
            selectedIndex={tabIndexState.selectedTabIndex}
            onSelect={(index) => onSelectTab(index)}>
            <TabList>
                {availableRoles.map((availableRoleType, index) => (
                    <RoleFormCustomTab
                        key={availableRoleType}
                        roleType={availableRoleType}
                        isRoleActive={
                            !!checkIfRoleIsActive(
                                availableRoleType,
                                rolesFormData
                            )
                        }
                        hasError={tabIndexState.tabIndexesHasError.includes(
                            index
                        )}
                    />
                ))}
            </TabList>
            {availableRoles.map((availableRoleType, index) => (
                <TabPanel key={availableRoleType} forceRender={true}>
                    <RoleForm
                        onSubmit={handleOnSubmit}
                        onChange={({formData}) =>
                            handleOnRolesFormDataChange(
                                availableRoleType,
                                formData,
                                index
                            )
                        }
                        onError={() =>
                            handleRoleFormError(availableRoleType, index)
                        }
                        roleType={availableRoleType}
                        possibleDimensions={possibleDimensions}
                        singleRightRolesMap={singleRightRolesMap}
                        userSchemaNameType={userSchemaNameType}
                        roleFormData={getMergedRoleData(
                            rolesFormData,
                            singleRightsData,
                            availableRoleType
                        )}
                        stringResources={stringResources}>
                        <button
                            type="submit"
                            className="submit bk-cs-hidden"
                            ref={(ref) => generateRefs(ref, availableRoleType)}
                        />
                    </RoleForm>
                </TabPanel>
            ))}
        </Tabs>
    );
};

RoleFormTabs.propTypes = {
    availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    possibleDimensions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.string).isRequired
        })
    ).isRequired,
    singleRightRolesMap: PropTypes.shape({
        [PropTypes.oneOf(Object.values(SingleRightTypes))]: PropTypes.arrayOf(
            PropTypes.string
        )
    }).isRequired,
    stringResources: PropTypes.object.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
    handleOnRolesFormDataChange: PropTypes.func.isRequired,
    handleRoleFormError: PropTypes.func.isRequired,
    rolesFormData: PropTypes.shape({
        isRoleActive: PropTypes.bool,
        scope: PropTypes.shape({
            [PropTypes.string]: PropTypes.arrayOf(PropTypes.string)
        })
    }),
    singleRightsData: PropTypes.shape({
        [PropTypes.oneOf(Object.values(SingleRightTypes))]: PropTypes.bool
    })
};

RoleFormTabs.defaultProps = {
    rolesFormData: {},
    singleRightsData: {}
};

const mapStateToProps = (state) => {
    const {
        availableRoles,
        possibleDimensions,
        rolesWithReportingRight
    } = state.sessionContext.account;
    const {
        i18n: {stringResources}
    } = state.settings;

    const sortedRoles = sortRolesByWebStringValue(
        availableRoles,
        stringResources
    );

    return {
        stringResources,
        availableRoles: sortedRoles,
        possibleDimensions,
        singleRightRolesMap: !!rolesWithReportingRight.length
            ? {[SingleRightTypes.PROPOSALS_REPORT]: rolesWithReportingRight}
            : {}
    };
};

export default connect(mapStateToProps)(RoleFormTabs);
