import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import i18nUtils from 'lib/i18nUtils';
import {withRouter} from 'react-router-dom';
import ActionPanel from 'components/proposal/actionPanel/ActionPanel';
import {createAndNavigateToNewProposal} from 'store/actions/proposalActions';
import * as snackActions from 'store/actions/snackActions';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import TpLink from 'components/common/TpLink';
import TpButton from 'components/common/buttons/TpButton';
import Loader from 'components/loader/CircularLoader';
import WebString from 'components/webString/WebString';
import GlobalDomMeasurements from 'components/common/globalDomMeasurements';
import {convertSelectValue} from 'lib/proposalUtils';
import sortUtils from 'lib/sortUtils';

const getSnackProperties = (stringResources) => ({
    id: 'snack-error-select-scope',
    snackType: TpSnackbarTypes.ERROR,
    autoHideDuration: 4000,
    message: `${i18nUtils.getTranslatedWebStringKey(
        stringResources,
        'tp.proposalinfo.notification.message.scopenotselected'
    )}`
});

/**
 * Shows an infopage with the Navigator to start a new proposal.
 */
export class ProposalInfo extends React.Component {
    static propTypes = {
        onCreateNewProposal: PropTypes.func.isRequired,
        loadingProposal: PropTypes.bool,
        applicantCanSelectScope: PropTypes.bool.isRequired,
        scopes: PropTypes.array.isRequired,
        stringResources: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        defaultScopeToStartProposal: PropTypes.object.isRequired
    };

    static defaultProps = {
        loadingProposal: false,
        isUserScopeSorted: true
    };

    constructor(props) {
        super(props);
        this.displayName = 'ProposalInfo';
        this.actionPanelRef = null;
        this.state = {
            selectedScopeMap: {}
        };
    }

    componentDidMount() {
        const {scopes, defaultScopeToStartProposal} = this.props;

        window.addEventListener('resize', this._setNavigatorPanelPosition);
        this._setNavigatorPanelPosition();

        const selectedScopeMap = scopes.reduce(
            (acc, scope) => ({
                ...acc,
                [scope.dimension]:
                    scope.values.length === 1 ? scope.values[0] : undefined
            }),
            null
        );

        if (
            defaultScopeToStartProposal &&
            Object.keys(defaultScopeToStartProposal).length !== 0
        ) {
            this.setState({selectedScopeMap: defaultScopeToStartProposal});
        } else {
            this.setState({
                selectedScopeMap
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._setNavigatorPanelPosition);
    }

    _setNavigatorPanelPosition = () => {
        // place the actionPanel at the bottom of the page
        const actionPanelHeight = this.actionPanelRef.getBoundingClientRect()
            .height;
        const windowHeight = window.innerHeight;
        const actionPanelTop =
            windowHeight -
            (actionPanelHeight +
                GlobalDomMeasurements.headerHeight +
                GlobalDomMeasurements.footerHeight +
                2 * GlobalDomMeasurements.spacerHeight);
        this.actionPanelRef.style.top = `${actionPanelTop}px`;
    };

    _handleStartProposalButtonClick = () => {
        this.props.onCreateNewProposal(this.state.selectedScopeMap);
    };

    onSelectChange = (selectedOption, dimension) => {
        this.setSelectedScopeMap(dimension, selectedOption.value);
    };

    convertScopeValues = (scope) => {
        // please put the values in the order you would like to have it displayed
        const valuesNotToBeSorted = [
            'generic',
            'non applicable',
            'other entity',
            'other'
        ];
        const sortedValues = sortUtils.sortAndPutNonSortedValuesAtTheEnd(
            scope.values,
            valuesNotToBeSorted
        );
        return sortedValues.map((value) => convertSelectValue(value));
    };

    setSelectedScopeMap = (dimension, value) => {
        const {selectedScopeMap} = this.state;
        this.setState({
            selectedScopeMap: {
                ...selectedScopeMap,
                [dimension]: value
            }
        });
    };

    getScopeSelectElement = () => {
        const {scopes, stringResources} = this.props;

        return scopes.map((scope) => {
            const preselectedValue =
                scope.values.length === 1 ? scope.values[0] : undefined;
            return (
                <Select
                    className="tp-proposal-info__select"
                    id={`select-scope-${scope.dimension}`}
                    key={scope.dimension}
                    options={this.convertScopeValues(scope)}
                    onChange={(selectedOption) =>
                        this.onSelectChange(selectedOption, scope.dimension)
                    }
                    placeholder={i18nUtils.getTranslatedWebStringKey(
                        stringResources,
                        'cs.form.please.select'
                    )}
                    value={
                        preselectedValue
                            ? convertSelectValue(preselectedValue)
                            : preselectedValue
                    }
                    isSearchable={true}
                />
            );
        });
    };

    render() {
        const {
            applicantCanSelectScope,
            defaultScopeToStartProposal
        } = this.props;

        const nextPanelBodyContent = (
            <WebString webStringKey="tp.text.navigator.proposalinfo.text" />
        );

        const nextPanelFooterText = (
            <div className="bk-cs-float-right">
                <TpLink
                    id="buttonCancel"
                    to={'/'}
                    className="bk-cs-element-right"
                    webStringKey="cs.button.cancel"
                />
                <TpButton
                    id="buttonGo"
                    onClick={this._handleStartProposalButtonClick}
                    webStringKey="tp.button.startproposal"
                />
            </div>
        );
        const loader = this.props.loadingProposal ? (
            <Loader overlay={true} />
        ) : null;
        return (
            <div className="row">
                <div className="col-xs-8">
                    <h1 className="bk-cs-h1">
                        <WebString webStringKey="tp.title.proposalinfo" />
                    </h1>
                    <WebString webStringKey="tp.text.proposalinfo.text" />
                    {applicantCanSelectScope &&
                    defaultScopeToStartProposal &&
                    Object.keys(defaultScopeToStartProposal).length === 0 ? (
                        <WebString webStringKey="tp.text.proposalinfo.selectscope" />
                    ) : null}
                    {applicantCanSelectScope &&
                    defaultScopeToStartProposal &&
                    Object.keys(defaultScopeToStartProposal).length === 0
                        ? this.getScopeSelectElement()
                        : null}
                </div>
                <div
                    className="col-xs-4"
                    ref={(node) => (this.actionPanelRef = node)}>
                    <ActionPanel
                        bodyContent={nextPanelBodyContent}
                        footerContent={nextPanelFooterText}
                    />
                </div>
                {loader}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loadingProposal: state.process.loadingProposal,
    applicantCanSelectScope:
        state.sessionContext.account.applicantCanSelectScope,
    scopes: state.sessionContext.user.scope,
    stringResources: state.settings.i18n.stringResources,
    defaultScopeToStartProposal:
        state.sessionContext.account.defaultScopeToStartProposal
});

const mapDispatchToProps = (dispatch) => ({
    onCreateNewProposal: (point, stringResources, history) => {
        if (point && Object.values(point).includes(undefined)) {
            dispatch(
                snackActions.createSnack(getSnackProperties(stringResources))
            );
            return;
        }
        dispatch(createAndNavigateToNewProposal(point, history));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onCreateNewProposal(selectedScopePoint) {
        const point = stateProps.applicantCanSelectScope
            ? selectedScopePoint
            : null;
        dispatchProps.onCreateNewProposal(
            point,
            stateProps.stringResources,
            ownProps.history
        );
    }
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProposalInfo)
);
