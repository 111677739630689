import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import GlobalFunctions from './header/GlobalFunctions';
import TpSnackbar from 'components/common/TpSnackbar';
import InitialUserSetupPopup from './InitialUserSetupPopup/InitialUserSetupPopup';

/**
 * The Third Party app base content layout component.
 */
const App = ({children}) => {
    const isTestSystem = useSelector((state) => state.settings.isTestSystem);

    let mainDivClassName = 'tp-app';
    let containerClassName = 'bk-cs-content container';

    if (isTestSystem) {
        mainDivClassName += ' tp-app--test-system';
        containerClassName += ' tp-app--test-system__content';
    }

    return (
        <div className={mainDivClassName}>
            <div id="bk-cs-over-content" className="bk-cs-over-content">
                <GlobalFunctions />
            </div>
            <InitialUserSetupPopup />
            <TpSnackbar />
            <div className={containerClassName}>{children}</div>
        </div>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
