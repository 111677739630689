import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import isEmpty from 'lodash.isempty';
import TpSchemaField from './TpSchemaField';

/*
 * @example:
 *       @schema:
 *          "dateRange": {
 *                      "title": "daterange.title",
 *                      "type": "object",
 *                      "required": ["startDate", "endDate"],
 *                      "properties": {
 *                        "startDate": {
 *                            "title": "cs.text.startdate",
 *                            "type": "number",
 *                            "minimum": 123456789          // optional
 *                            "maximum": 987654321,         // optional
 *                        },
 *                        "endDate": {
 *                            "title": "cs.text.enddate",
 *                            "type": "number",
 *                            "minimum": 123456789         // optional
 *                            "maximum": 987654321         // optional
 *                        }
 *                    }
 *                  }
 *       @uiSchema:
 *          "dateRange": {
 *                      "ui:field": "dateRangeField"
 *                     }
 */
const DateRangeField = (props) => {
    const uiSchema = {
        startDate: {
            'ui:widget': 'cs-date',
            hideHeader: true,
            'ui:placeholder': 'cs.text.startdate'
        },
        endDate: {
            'ui:widget': 'cs-date',
            hideHeader: true,
            'ui:placeholder': 'cs.text.enddate'
        },
        hideHeader: true
    };

    const dateRangeError = props.formData.startDate > props.formData.endDate;

    return (
        <div className="date-range-field">
            <label
                className={`col-xs-6 bk-cs-form-label${
                    props.required ? ' bk-cs-form-label--required' : ''
                }${
                    !isEmpty(props.errorSchema) || dateRangeError
                        ? ' field-error'
                        : ''
                }`}>
                <WebString webStringKey={props.schema.title} />
            </label>
            <div className={'col-xs-6 bk-cs-range-field_input'}>
                <TpSchemaField {...props} uiSchema={uiSchema} />
                {dateRangeError ? (
                    <div className={'bk-cs-margin-bottom--md'}>
                        <WebString
                            className={'field-error'}
                            webStringKey="tp.errormsg.validation.daterange"
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

DateRangeField.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    schema: PropTypes.shape({
        title: PropTypes.string,
        required: PropTypes.arrayOf(PropTypes.oneOf(['startDate', 'endDate'])),
        type: PropTypes.oneOf(['object']).isRequired,
        properties: PropTypes.exact({
            startDate: PropTypes.shape({
                title: PropTypes.string,
                type: PropTypes.oneOf(['number']).isRequired,
                maximum: PropTypes.number,
                minimum: PropTypes.number
            }).isRequired,
            endDate: PropTypes.shape({
                title: PropTypes.string,
                type: PropTypes.oneOf(['number']).isRequired,
                maximum: PropTypes.number,
                minimum: PropTypes.number
            }).isRequired
        }).isRequired
    }).isRequired,
    uiSchema: PropTypes.shape({
        'ui:field': PropTypes.oneOf(['dateRangeField']).isRequired
    }).isRequired,
    formData: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number
    }),
    required: PropTypes.bool,
    errorSchema: PropTypes.object
};

DateRangeField.defaultProps = {
    formData: {
        startDate: undefined,
        endDate: undefined
    },
    required: false,
    errorSchema: {}
};

export default DateRangeField;
