import React from 'react';
import Breadcrumb from './Breadcrumb';
import AlertAutoSave from 'components/alerts/AlertAutoSave';
import {isProcessPage, isInfoPage} from 'components/common/checkRelativeURL';
import {useLocation} from 'react-router-dom';

/**
 * Region above the main content, containing breadcrumb trail.
 */
const GlobalFunctions = () => {
    const currentRelativeURL = useLocation().pathname;

    const globalFunctionsBlockModifierClass =
        isProcessPage(currentRelativeURL) && !isInfoPage(currentRelativeURL)
            ? 'cs-global-functions--background-visible'
            : 'cs-global-functions--background-invisible';

    return (
        <div
            className={`cs-global-functions container-fluid ${globalFunctionsBlockModifierClass}`}>
            <div className="row">
                <div className="col-xs-6">
                    <Breadcrumb />
                </div>
                <div className="col-xs-6">
                    <div className="cs-global-functions__save-alerts">
                        <AlertAutoSave />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalFunctions;
