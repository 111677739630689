import React from 'react';
import WebString from 'components/webString/WebString';

export default class ErrorBoundary extends React.Component {
    state = {hasError: false};

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <h1>
                        <WebString webStringKey="tp.text.errorboundary" />
                    </h1>
                </div>
            );
        }
        return this.props.children;
    }
}
