export default class Timer {
    _initTime;
    _onFire;
    _interval;

    get onFire() {
        return this._onFire;
    }

    set onFire(onFireFunc) {
        if (!onFireFunc || typeof onFireFunc !== 'function') {
            throw new Error('onFireFunc is not a function');
        }
        this._onFire = onFireFunc;
    }

    get isRunning() {
        return typeof this._interval !== 'undefined';
    }

    constructor(onFireFunc, timeInterval) {
        if (!onFireFunc || typeof onFireFunc !== 'function') {
            throw new Error('onFireFunc is not a function');
        }
        if (typeof timeInterval !== 'number') {
            throw new Error('timeInterval is not a number');
        }
        if (timeInterval <= 0) {
            throw new Error('timeInterval is less than or equal to 0');
        }
        this._initTime = timeInterval;
        this._onFire = onFireFunc;
    }

    start() {
        this._timerReset();
    }

    reset() {
        this._timerReset();
    }

    stop() {
        clearInterval(this._interval);
        this._interval = undefined;
    }

    _timerReset() {
        this.stop();
        this._interval = setInterval(this._fire.bind(this), this._initTime);
    }

    _fire() {
        this._onFire();
        this._timerReset();
    }
}
