/**
 * Creates a function which calls the actionCreator function inside the dispatch while passing
 * in all parameters
 *
 * Wraps the actionCreator with a function which calls the action creator inside a dispatch.
 * All arguments which are passed in in this function, are directly passed through to the action creator.
 * @param {function} actionCreator a function which gets called inside a dispatch function
 * @param {function} dispatch the redux-stores dispatch function
 * @return {function(...[*]): *}
 */
export const wrapInDispatch = (actionCreator, dispatch) => (...params) =>
    dispatch(actionCreator(...params));
