import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {showWebStringsKeys} from 'store/actions/settingsActions';

export class ShowAllWebStringKeysButton extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired
    };
    state = {
        show: false
    };

    handelOnClick = () => {
        this.setState({show: !this.state.show}, () =>
            this.props.onChange(this.state.show)
        );
    };

    _getLabel = () => {
        return this.state.show ? 'Hide WebString Keys' : 'Show WebString Keys';
    };

    _getButtonClassNames = () => {
        return this.state.show
            ? 'tp-button-showallwebstringkeys tp-button-showallwebstringkeys--active'
            : 'tp-button-showallwebstringkeys';
    };

    render() {
        return (
            <div
                id={'tp-button-showallwebstringkeys'}
                onClick={this.handelOnClick}
                className={this._getButtonClassNames()}>
                {this._getLabel()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onChange: (isActive) => dispatch(showWebStringsKeys(isActive))
});

export default connect(null, mapDispatchToProps)(ShowAllWebStringKeysButton);
