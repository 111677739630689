import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from 'react-jsonschema-form/lib/components/widgets/BaseInput';
import DisabledTextBlock from './DisabledTextBlock';
import WebString from '../../webString/WebString';

/**
 *  Extended BaseInput widget of react-jsonschema-form
 *  Adds extra text before and/or after the input field if they are defined in formSchema.
 *  Returns the normal div element with its own styling if readonly or disabled.
 *
 *  Limits the width of the input field if type of input is number and maximum property is defined in formSchema.
 *  The width will be calculated in accordance with 'one character = 18px'.
 *
 * @param {Object} props
 * @returns {ReactElement} markup
 */
export const TpBaseInput = (props) => {
    const {id, readonly, disabled, value, options, schema} = props;
    const {textBefore, textAfter} = schema;
    const getSmallText = (text, extraClassName = '') =>
        text ? (
            <WebString
                webStringKey={text}
                className={`bk-cs-text--extra-small ${extraClassName}`}
            />
        ) : null;

    const textBeforeSpan = getSmallText(
        textBefore,
        'bk-cs-margin-r--small bk-cs-inline'
    );
    const textAfterSpan = getSmallText(
        textAfter,
        'bk-cs-margin-l-r--small bk-cs-inline'
    );
    const rawHelpSpan = getSmallText(options.help, 'bk-cs-form-hint');
    const styleProps = {};

    const inputNumberWidth =
        schema.type === 'number' && schema.maximum
            ? schema.maximum.toString().length * 18
            : '';
    if (inputNumberWidth) {
        styleProps.style = {width: inputNumberWidth};
    }

    // allow additional CSS classes to be added in ui:options
    const extraClasses = options.className ? ` ${options.className}` : '';

    return (
        <div className={`bk-cs-inline${extraClasses}`}>
            {textBeforeSpan}
            {readonly || disabled ? (
                <DisabledTextBlock id={id} value={value} {...styleProps} />
            ) : (
                <BaseInput {...props} {...styleProps} />
            )}
            {textAfterSpan}
            {rawHelpSpan}
        </div>
    );
};

TpBaseInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    options: PropTypes.object,
    type: PropTypes.string,
    schema: PropTypes.object,
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired
};

TpBaseInput.defaultProps = {
    value: null,
    disabled: false,
    readonly: false,
    options: {},
    schema: {},
    type: 'text'
};

export default TpBaseInput;
