import React from 'react';
import PropTypes from 'prop-types';
import RiskFactors from './RiskFactors';
import CriticalRiskFactors from './CriticalRiskFactors';
import DueDiligence from './DueDiligence';

const RiskMatrix = (props) => {
    const {
        riskMatrix: {riskFactors, dueDiligence}
    } = props;

    return (
        <div className="tp-risk-matrix">
            <RiskFactors riskFactors={riskFactors} />
            <div className="tp-risk-matrix__tables">
                <DueDiligence dueDiligence={dueDiligence} />
                <CriticalRiskFactors
                    criticalRiskFactors={riskFactors.filter(
                        (element) => element.critical
                    )}
                />
            </div>
        </div>
    );
};

RiskMatrix.propTypes = {
    riskMatrix: PropTypes.shape({
        riskFactors: PropTypes.array.isRequired,
        dueDiligence: PropTypes.array.isRequired
    }).isRequired
};

export default RiskMatrix;
