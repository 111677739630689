import React from 'react';
import TpLink from 'components/common/TpLink';
import urls from 'routes/urls';

const CreateUserButton = () => (
    <TpLink
        to={urls.USER_CREATE_URL}
        webStringKey="tp.button.createnewuser"
        className="bk-cs-element-right"
        asButton={true}
        id="tp-createUser-btn"
    />
);

export default CreateUserButton;
