import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import UserFeatureTypes from 'types/UserFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserManagementPage from 'components/users/UserManagementPage';
import UserPanel from 'components/users/createOrUpdateUser/userPanel/UserPanel';
import UpdateUserPage from 'components/users/createOrUpdateUser/UpdateUserPage';
import NotFound from 'components/common/errorHandling/NotFound';

const UsersRoute = () => {
    const {path} = useRouteMatch();
    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[UserFeatureTypes.USER_MANAGEMENT]}
            defaultChildren={<NotFound />}>
            <Switch>
                <Route
                    exact={true}
                    path={`${path}/create`}
                    component={UserPanel}
                />
                <Route
                    exact={true}
                    path={`${path}/:userId`}
                    component={UpdateUserPage}
                />{' '}
                {/* TODO:#34123 add ticket: in UpdateUserPage add not found component (the same logic as in proposals) if the user not found or a bad request */}
                <Route path={path} component={UserManagementPage} />
            </Switch>
        </UserFeatureToggle>
    );
};

export default UsersRoute;
