import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import i18nUtils from 'lib/i18nUtils';

class SearchFilter extends React.Component {
    static propTypes = {
        stringResources: PropTypes.object.isRequired,
        column: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired,
        onChange: PropTypes.func.isRequired,
        filter: PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    };
    static defaultProps = {
        filter: null
    };

    render() {
        const {column, stringResources, filter} = this.props;

        return (
            <input
                id={column.id}
                onChange={(event) => this.props.onChange(event.target.value)}
                value={filter ? filter.value : ''}
                style={{width: '100%'}}
                type="text"
                autoComplete="off"
                placeholder={i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    'cs.text.search'
                )}
            />
        );
    }
}

const mapStateToProps = ({
    settings: {
        i18n: {stringResources}
    }
}) => ({
    stringResources
});

export default connect(mapStateToProps)(SearchFilter);
