import React from 'react';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import has from 'lodash.has';
import isEqual from 'lodash.isequal';
import {connect} from 'react-redux';
import * as transformer from 'lib/SchemaTransformer';
import * as riskAlertsUtils from 'lib/riskAlertsUtils';
import TpFieldTemplate from './fields/TpFieldTemplate';
import defaultAppValues from 'lib/defaultAppValues';
import Loader from 'components/loader/CircularLoader';
import {
    loadFormStrings,
    clearFormStrings,
    loadPreCheckStrings
} from 'store/actions/settingsActions';
import {openContainer} from 'store/actions/formActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import FeatureTypes from 'types/FeatureTypes';
import Form from './Form';

/**
 * Wrapper of our Form component.
 * Handles schema transformations for legacy proposals and proposal forms
 * and the fetching of the form string resources.
 *
 * @class
 */
export default class TpForm extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        schema: PropTypes.object.isRequired,
        stringResources: PropTypes.object.isRequired,
        hasFormStringResources: PropTypes.bool.isRequired,
        getFormStringResources: PropTypes.func.isRequired,
        clearFormStringResources: PropTypes.func.isRequired,
        uiSchema: PropTypes.object,
        formData: PropTypes.object,
        featureType: PropTypes.oneOf([
            FeatureTypes.PROPOSAL,
            FeatureTypes.ALERTS
        ]),
        featureId: PropTypes.number,
        fieldData: PropTypes.object,
        redFlags: PropTypes.arrayOf(PropTypes.string),
        locale: PropTypes.string,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        onError: PropTypes.func,
        disabled: PropTypes.bool,
        showSubmitButton: PropTypes.bool,
        submitButtonWebStringKey: PropTypes.string,
        renderAsDiv: PropTypes.bool,
        customFields: PropTypes.object,
        customWidgets: PropTypes.object,
        customFieldTemplate: PropTypes.func,
        customFormContext: PropTypes.object
    };

    static defaultProps = {
        featureType: FeatureTypes.PROPOSAL,
        featureId: null,
        uiSchema: {},
        formData: {},
        redFlags: [],
        fieldData: {},
        locale: defaultAppValues.defaultLocale,
        onChange: () => undefined,
        onSubmit: () => undefined,
        onError: () => undefined,
        disabled: false,
        showSubmitButton: true,
        submitButtonWebStringKey: 'cs.button.next',
        renderAsDiv: false,
        customFields: {},
        customWidgets: {},
        customFieldTemplate: undefined,
        customFormContext: {}
    };

    constructor(props) {
        super(props);

        const processedSchemas = this.transformSchemas(props);
        this.state = {...processedSchemas};
    }

    componentDidMount() {
        if (this.props.id && this.props.locale) {
            this.props.getFormStringResources({
                formId: this.props.id,
                locale: this.props.locale,
                type: this.props.featureType,
                typeId: this.props.featureId
            });
        }
    }

    componentWillUnmount() {
        this.props.clearFormStringResources(this.props.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const idChanged = nextProps.id !== this.props.id;
        const localeChanged = nextProps.locale !== this.props.locale;
        if (idChanged || localeChanged) {
            this.props.getFormStringResources({
                formId: nextProps.id,
                locale: nextProps.locale,
                type: this.props.featureType,
                typeId: this.props.featureId
            });
        }
        if (idChanged) {
            const processedSchemas = this.transformSchemas(nextProps);
            this.setState({...processedSchemas, formErrors: []});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
        );
    }

    /*
     * All our old forms
     * - dont have "$id"
     * - have the "$schema" property
     * - $schema with 'http://json-schema.org/draft-04/schema#'
     * defined.
     * For those we still want to use the SchemaTransformer.
     */
    isLegacyForm = () =>
        !this.props.schema['$id'] &&
        !!this.props.schema['$schema'] &&
        this.props.schema['$schema'] ===
            'http://json-schema.org/draft-04/schema#';

    /**
     * Update state for formErrors and call onError props
     * @param {Object[]} formErrors
     * @returns {undefined}
     */
    setFormErrors = (formErrors) => {
        this.setState({formErrors}, () => {
            this.props.onError(formErrors.map((error) => error.notification));
        });
    };

    clearFormErrors = () => {
        this.setState({formErrors: []}, () => {
            this.props.onError([]);
        });
    };

    /**
     * Handle any form change event, including keypresses in text inputs
     * IMPORTANT: only put absolutely necessary code in here, as it runs with every keypress,
     * and can slow down the form's behaviour.
     * @param {Object} event
     * @returns {undefined}
     */
    handleChange = (event) => {
        if (this.isLegacyForm()) {
            const oneOfChanged = this.transformOneOfsOnChange(event.formData);
            // if the formData was not updated in transformOneOfsOnChange, then we need to update it
            if (!oneOfChanged) {
                // this is awkward, the call above`transformOneOfsOnChange already sets state
                // we still need to set state w/ formData if no oneOf changed
                this.setState({formData: event.formData}, () => {
                    this.props.onChange(this.state.formData);
                });
            }
        } else {
            this.setState({formData: event.formData}, () => {
                this.props.onChange(this.state.formData);
            });
        }
    };

    /**
     * This is only called when form has passed validation, so we need to clear notifications panel and inline errors
     * @returns {undefined}
     */
    handleSubmit = () => {
        this.clearFormErrors();
        this.props.onSubmit();
    };

    // LEGACY SUPPORT START

    /**
     * Make changes needed for RJSF to render the form from the API data
     * @param {Object} schema
     * @param {Object} formData
     * @param {Object} uiSchema
     * @param {Object} fieldData
     * @param {Boolean} disabled
     * @returns {{schema: Object, uiSchema, formData, oneOfs: Array}}
     */
    transformSchemas = ({schema, formData, uiSchema, fieldData, disabled}) => {
        if (!this.isLegacyForm()) {
            // schema has the new version
            return {schema, formData, uiSchema};
        }

        // transform oneOfs into flat representation
        const processedSchemas = transformer.transformSchemas(
            schema,
            formData,
            uiSchema,
            disabled
        );

        // add any extra formData for Dow Jones risk alert
        processedSchemas.formData = riskAlertsUtils.processRiskAlerts(
            processedSchemas.formData,
            processedSchemas.uiSchema,
            fieldData
        );

        // set the overall disabled status of the form if read-only
        processedSchemas.uiSchema['ui:disabled'] = disabled;

        return processedSchemas;
    };

    /**
     * Change the value of the OneOf and the fields shown below, if there is a new value.
     * Return whether the OneOf was changed.
     * @param {Object} eventFormData
     * @returns {boolean}
     */
    transformOneOfsOnChange = (eventFormData) => {
        let changed = false;
        let updatedFormData = clonedeep(eventFormData);
        for (const oneOf of this.state.oneOfs) {
            // oneOf needs property e.g. business_area.whichBA.bestechungsverdacht.bestechungsverdachtOneOf
            const newValue = this.getOneOfMatchInFormData(
                updatedFormData,
                oneOf
            );
            const originalValue = this.getOneOfMatchInFormData(
                this.state.formData,
                oneOf
            );
            if (newValue !== originalValue) {
                const updatedSchema = this.updateOneOfInSchema(oneOf, newValue);
                updatedFormData = this.clearOneOfFormData(
                    updatedFormData,
                    oneOf
                );
                const updatedUiSchema = transformer.transformUiSchemaForOneOf(
                    this.state.uiSchema,
                    updatedFormData,
                    this.state.oneOfs
                );

                this.setState(
                    {
                        schema: updatedSchema,
                        formData: updatedFormData,
                        uiSchema: updatedUiSchema
                    },
                    () => {
                        this.props.onChange(this.state.formData);
                    }
                );

                this.clearFormErrorsForOneOf(oneOf);

                changed = true;
            }
        }
        return changed;
    };

    /**
     * Return the value of the oneOf selector at this path
     * @param {Object} formData
     * @param {Object} oneOf
     * @returns {String} the selected oneOf value
     */
    getOneOfMatchInFormData = (formData, oneOf) => {
        return get(formData, oneOf.formDataPath) || '';
    };

    /**
     * Remove and clear the form errors belonging to this oneOf, not all errors.
     * @param {Object} oneOf the oneOf metadata object
     * @returns {undefined}
     */
    clearFormErrorsForOneOf = (oneOf) => {
        if (!this.state.formErrors) {
            return;
        }
        const nonOneOfFormErrors = this.state.formErrors.filter((fe) => {
            return fe.property.indexOf(oneOf.formDataContainerPath) === -1;
        });
        this.setFormErrors(nonOneOfFormErrors);
    };

    /**
     * Switch the $ref value to the chosen value in the schema, or clear if new value is undefined
     * @param {Object} oneOf
     * @param {String} newValue
     * @returns {undefined}
     */
    updateOneOfInSchema = (oneOf, newValue) => {
        const updatedSchema = clonedeep(this.state.schema);
        const updatedRef = newValue ? {$ref: oneOf.refs[newValue]} : {};
        set(updatedSchema, oneOf.refPath, updatedRef);
        return updatedSchema;
    };

    /**
     * Clear all formData belonging to the oneOf, but not the oneOf selector
     * @param {Object} eventFormData
     * @param {Object} oneOf
     * @returns {Object} updated formData
     */
    clearOneOfFormData = (eventFormData, oneOf) => {
        const updatedFormData = clonedeep(eventFormData);
        if (has(updatedFormData, oneOf.formDataContainerPath)) {
            set(updatedFormData, oneOf.formDataContainerPath, {});
        }
        return updatedFormData;
    };

    // LEGACY SUPPORT STOP

    render() {
        if (!this.props.hasFormStringResources) {
            return <Loader />;
        }
        return (
            <Form
                id={this.props.id}
                schema={this.state.schema}
                uiSchema={this.state.uiSchema}
                stringResources={this.props.stringResources}
                formData={this.state.formData}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                onError={this.setFormErrors}
                showSubmitButton={this.props.showSubmitButton}
                showErrorList={false}
                customFields={this.props.customFields}
                customWidgets={this.props.customWidgets}
                customFieldTemplate={
                    this.props.customFieldTemplate || TpFieldTemplate
                }
                renderAsDiv={this.props.renderAsDiv}
                redFlags={this.props.redFlags}
                fieldData={this.props.fieldData}
                isLegacyForm={this.isLegacyForm()}
                customFormContext={this.props.customFormContext}
            />
        );
    }
}

// MG: there's a problem while importing the special forms below from a custom field (TaskOverview and FormTransclusionFields)

const mapStateToPropsProposal = (state, ownProps) => {
    const {locale, formStringResources, stringResources} = state.settings.i18n;
    return {
        locale,
        stringResources,
        // todo: #14477 formStringResources should only contain the current form's strings
        hasFormStringResources: !!formStringResources.get(ownProps.id),
        featureId: ownProps.featureId || state.process.proposal.id
    };
};

const mapDispatchToPropsProposal = (dispatch) => ({
    getFormStringResources: wrapInDispatch(loadFormStrings, dispatch),
    clearFormStringResources: wrapInDispatch(clearFormStrings, dispatch),
    openContainer: wrapInDispatch(openContainer, dispatch)
});

const ProposalForm = connect(
    mapStateToPropsProposal,
    mapDispatchToPropsProposal
)(TpForm);

const mapStateToPropsPreCheck = (state, ownProps) => {
    const {locale, formStringResources, stringResources} = state.settings.i18n;
    return {
        locale,
        stringResources,
        // todo: #14477 formStringResources should only contain the current form's strings
        hasFormStringResources: !!formStringResources.get(ownProps.id),
        featureId: ownProps.featureId || state.process.proposal.id
    };
};

const mapDispatchToPropsPreCheck = (dispatch) => ({
    getFormStringResources: wrapInDispatch(loadPreCheckStrings, dispatch),
    clearFormStringResources: wrapInDispatch(clearFormStrings, dispatch),
    openContainer: wrapInDispatch(openContainer, dispatch)
});

const PreCheckForm = connect(
    mapStateToPropsPreCheck,
    mapDispatchToPropsPreCheck
)(TpForm);

export {ProposalForm, PreCheckForm};
