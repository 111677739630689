const TableTypes = {
    PROPOSAL: 'proposal',
    ASSESSMENT: 'assessment',
    ASSESSED: 'assessed',
    ALERTS: 'alerts',
    EXTERNAL: 'external',
    BUSINESS_PARTNERS: 'businessPartners'
};

export default TableTypes;
