import React from 'react';
import PropTypes from 'prop-types';
import MessageEntry from './MessageEntry';
import WebString from 'components/webString/WebString';

class MessagesBlock extends React.Component {
    static propTypes = {
        messages: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                created: PropTypes.number.isRequired,
                author: PropTypes.shape({
                    email: PropTypes.string.isRequired
                }).isRequired
            })
        ).isRequired,
        currentUserEmail: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.messageBlockRef = React.createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.messages.length !== newProps.messages.length) {
            if (this.isMessageBlockScrolledDown()) {
                setTimeout(this.scrollToBottom);
            }
        }
    }

    isMessageBlockScrolledDown = () => {
        var height = this.messageBlockRef.current.clientHeight;
        var scrollHeight = this.messageBlockRef.current.scrollHeight;
        var scrollTop = this.messageBlockRef.current.scrollTop;
        return scrollTop >= scrollHeight - height;
    };

    scrollToBottom = () => {
        this.messageBlockRef.current.scrollTop = this.messageBlockRef.current.scrollHeight;
    };

    getCommentsPlaceholder = () => (
        <div className="bk-tp-chatfield__no-messages-label">
            <WebString webStringKey="tp.text.nocomments" />
        </div>
    );

    displayMessages = () => {
        const {messages, currentUserEmail} = this.props;

        if (!messages.length) {
            return this.getCommentsPlaceholder();
        }

        return messages.map((message) => {
            return (
                <MessageEntry
                    key={message.text + message.created}
                    isAuthorCurrentUser={
                        currentUserEmail === message.author.email
                    }
                    author={message.author.email}
                    text={message.text}
                    created={message.created}
                />
            );
        });
    };

    render() {
        return (
            <div
                className="bk-tp-chatfield__messageblock"
                ref={this.messageBlockRef}>
                {this.displayMessages()}
            </div>
        );
    }
}

export default MessagesBlock;
