import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import defaultAppValues from 'lib/defaultAppValues';
import CircularLoader from 'components/loader/CircularLoader';
import LinearLoader from 'components/loader/LinearLoader';
import WebString from 'components/webString/WebString';
import TpLink from 'components/common/TpLink';
import ErrorBoundary from 'components/common/errorHandling/ErrorBoundary';

/**
 * Displays a list of data in a table using
 * react-table.
 */
export default class TpTable extends React.Component {
    static propTypes = {
        tableClassName: PropTypes.string,
        descriptionWebstringKey: PropTypes.string.isRequired,
        titleWebstringKey: PropTypes.string.isRequired,
        locale: PropTypes.string,
        fetchData: PropTypes.func.isRequired,
        isFetchingData: PropTypes.bool,
        serverSideLogicActivated: PropTypes.bool,
        onTableRowClick: PropTypes.func,
        saveTableSettings: PropTypes.func,

        tableColumns: PropTypes.array.isRequired,

        defaultSorted: PropTypes.array.isRequired,
        defaultPageSize: PropTypes.number,
        searchParams: PropTypes.object,

        totalPages: PropTypes.number,
        data: PropTypes.array,
        customButton: PropTypes.element
    };
    static defaultProps = {
        tableClassName: 'tp-all-proposals-table',
        locale: defaultAppValues.defaultLocale,

        saveTableSettings: () => null,
        onTableRowClick: () => null,
        isFetchingData: false,
        serverSideLogicActivated: true,

        totalPages: 0,
        defaultPageSize: 30,

        searchParams: {},
        data: []
    };

    fetchTimeout = null;

    constructor(props) {
        super(props);

        const defaultSearchParams = {
            filtered: [],
            page: undefined,
            sorted: props.defaultSorted,
            pageSize: props.defaultPageSize
        };

        const tableSettings = {};
        for (const property in props.searchParams) {
            tableSettings[property] = props.searchParams[property];
        }

        this.state = {
            defaultSearchParams,
            forceShowLinearLoader: false,
            searchParams: {
                ...defaultSearchParams,
                ...tableSettings
            }
        };
        this.callFetchData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.locale !== this.props.locale) {
            this.callFetchData(nextProps.locale);
        }
        if (nextProps.isFetchingData === false) {
            this.setState({forceShowLinearLoader: false});
        }
    }

    componentWillUnmount() {
        !this.props.serverSideLogicActivated
            ? this.props.saveTableSettings(this.state.searchParams)
            : null;
    }

    _onGetTdPropsClick = (state, rowInfo, column) => ({
        onClick: () => {
            if (!rowInfo) {
                return;
            }
            if (!column.preventDefaultRowClick) {
                this.props.onTableRowClick(rowInfo.original);
            }
        }
    });

    callFetchData = () => {
        this.props.fetchData(this.state.searchParams, this.props.locale);
    };

    handleOnResetSettings = () => {
        this.setState({searchParams: this.state.defaultSearchParams}, () =>
            this.callFetchData()
        );
    };

    handleOnSearchParamsChange = (key, value) => {
        const searchParams = {
            ...this.state.searchParams,
            [key]: value
        };

        if (key === 'filtered') {
            searchParams['page'] = 0;
        }

        this.setState(
            {
                searchParams
            },
            this.props.serverSideLogicActivated
                ? () => {
                      if (this.fetchTimeout) {
                          clearTimeout(this.fetchTimeout);
                      }
                      this.fetchTimeout = setTimeout(() => {
                          this.callFetchData();
                      }, 1000);
                  }
                : null
        );
    };

    render() {
        const areProposalsInitiallyLoading =
            this.props.isFetchingData && this.props.data.length === 0;

        const settingsProps = {
            page: this.state.searchParams.page,
            onPageChange: (value) =>
                this.handleOnSearchParamsChange('page', value),
            pageSize: this.state.searchParams.pageSize,
            onPageSizeChange: (value) =>
                this.handleOnSearchParamsChange('pageSize', value),
            filtered: this.state.searchParams.filtered,
            onFilteredChange: (value) =>
                this.handleOnSearchParamsChange('filtered', value),
            sorted: this.state.searchParams.sorted,
            onSortedChange: (value) =>
                this.handleOnSearchParamsChange('sorted', value),
            pages: this.props.serverSideLogicActivated
                ? this.props.totalPages
                : undefined
        };

        return (
            //TODO #21323 Refactor className and webstring ID to reflect the actual table
            <ErrorBoundary>
                <div className={this.props.tableClassName}>
                    <h1 className="bk-cs-h1">
                        <WebString
                            id="tp-all-proposals-table__title"
                            webStringKey={this.props.titleWebstringKey}
                        />
                    </h1>
                    <WebString
                        id="tp-all-proposals-table__description"
                        className={'bk-cs-margin-bottom--lg'}
                        webStringKey={this.props.descriptionWebstringKey}
                    />
                    {this.props.children}
                    <div className={'bk-cs-text-right'}>
                        <React.Fragment>
                            <TpLink
                                className="bk-cs-margin-r--lg bk-cs-pointer"
                                id={`button-reset-settings-${this.props.titleWebstringKey}`}
                                webStringKey="cs.button.resetsettings"
                                onClick={this.handleOnResetSettings}
                            />
                            {this.props.customButton}
                        </React.Fragment>
                    </div>
                    <LinearLoader
                        visible={
                            this.props.isFetchingData ||
                            this.state.forceShowLinearLoader
                        }
                    />
                    <ReactTable
                        getTdProps={this._onGetTdPropsClick}
                        className="-striped -highlight"
                        columns={this.props.tableColumns}
                        data={this.props.data}
                        {...settingsProps}
                        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
                        filterable
                        multiSort={false}
                        manual={this.props.serverSideLogicActivated}
                        loading={areProposalsInitiallyLoading}
                        loadingText={<CircularLoader />}
                        nextText={<span>&#10095;</span>}
                        noDataText={
                            <WebString webStringKey="cs.text.nodatafound" />
                        }
                        ofText={<WebString webStringKey="tp.table.alerts.of" />}
                        pageText={
                            <WebString webStringKey="tp.table.alerts.page" />
                        }
                        previousText={<span>&#10094;</span>}
                        rowsText=""
                    />
                </div>
            </ErrorBoundary>
        );
    }
}
