import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/tpForm/Form';
import AdHocCheckCustomFieldTemplate from './AdHocCheckCustomFieldTemplate';
import {
    AdHocCheckPersonSchema,
    AdHocCheckCompanySchema,
    AdHocCheckUnknown,
    AdHocCheckUnknownUiSchema,
    AdHocCheckCompanyUiSchema,
    AdHocCheckPersonUiSchema
} from './AdHocCheckFormSchemas';

export class AdHocCheckForm extends React.Component {
    static propTypes = {
        stringResources: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        formData: {}
    };

    /**
     * Function returns UI Schema based on typeOfBP
     * @returns {Object} ui Schema
     */
    getUiSchema = () => {
        if (!this.state.formData.typeOfBP) {
            return AdHocCheckUnknownUiSchema;
        } else if (this.state.formData.typeOfBP === 'COMPANY') {
            return AdHocCheckCompanyUiSchema;
        } else {
            return AdHocCheckPersonUiSchema;
        }
    };

    /**
     * Function returns form Schema based on typeOfBP
     * @returns {Object} form schema
     */
    getFormSchema = () => {
        if (!this.state.formData.typeOfBP) {
            return AdHocCheckUnknown;
        } else if (this.state.formData.typeOfBP === 'COMPANY') {
            return AdHocCheckCompanySchema;
        } else {
            return AdHocCheckPersonSchema;
        }
    };

    handleChange = ({formData}) => {
        if (formData.typeOfBP !== this.state.formData.typeOfBP) {
            this.setState({
                formData: {
                    typeOfBP: formData.typeOfBP
                }
            });
        } else {
            this.setState({formData});
        }
    };

    render() {
        return (
            <Form
                id="tp-adhoccheck-form"
                className="tp-adhoccheck__horizontal"
                onSubmit={this.props.onSubmit}
                schema={this.getFormSchema()}
                uiSchema={this.getUiSchema()}
                onChange={this.handleChange}
                formData={this.state.formData}
                stringResources={this.props.stringResources}
                customFieldTemplate={AdHocCheckCustomFieldTemplate}
                submitButtonId="btn-adhoccheck-form-submit"
                submitButtonWebStringKey="tp.button.adhoccheck"
            />
        );
    }
}

const mapStateToProps = (state) => ({
    stringResources: state.settings.i18n.stringResources
});

export default connect(mapStateToProps)(AdHocCheckForm);
