import React from 'react';
import {useParams} from 'react-router-dom';
import UserPanel from './userPanel/UserPanel';
import userSchemaNameTypes from './userSchemaNameTypes';

export const UpdateUserPage = () => {
    const {userId} = useParams();
    return (
        <UserPanel
            userId={userId}
            userSchemaNameType={userSchemaNameTypes.UPDATE}
        />
    );
};

export default UpdateUserPage;
