import process from './processReducer';
import riskAlerts from './alertReducer';
import dashboard from './dashboardReducer';
import businessPartnerSection from './businessPartnerSectionReducer';
import preCheck from './preCheckReducer';
import userManagement from './userManagementReducer';
import reportGeneration from './reportGenerationReducer';

const reducers = {
    process,
    riskAlerts,
    dashboard,
    businessPartnerSection,
    preCheck,
    userManagement,
    reportGeneration
};

export default reducers;
