import React from 'react';
import {useSelector} from 'react-redux';
import WebString from 'components/webString/WebString';

/**
 * Display overall risk classification as form element in traffic lights format.
 * Prop `risk` determines which risk level is displayed in text.
 */
const RiskClassificationField = () => {
    const {riskLevel, overrulingRiskLevel} = useSelector(
        (state) => state.process.proposal
    );

    const risk = overrulingRiskLevel ? overrulingRiskLevel : riskLevel;
    return (
        <div>
            <label className="bk-cs-form-label bk-cs-field-label">
                <WebString webStringKey="tp.form.approval.risk.title" />
            </label>
            <div className="bk-cs-form-inputs">
                <div className="bk-cs-labelled-icon">
                    <i
                        className={`bk-cs-labelled-icon__icon bk-cs-labelled-icon__icon--${risk}-risk fa-2x`}
                    />
                    <span className="bk-cs-labelled-icon__text">
                        <WebString
                            webStringKey={`tp.form.approval.risk.${risk}`}
                        />
                        {overrulingRiskLevel ? (
                            <span>
                                {' '}
                                (
                                <WebString
                                    webStringKey={`tp.form.approval.risk.${riskLevel}`}
                                />
                                )
                            </span>
                        ) : null}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RiskClassificationField;
