import HTTPClient from 'lib/HTTPClient';
import {ACTION_TYPES} from 'store/reducers/sessionContextReducer';
import {getCorrectedAppType, getAccountProductName} from 'lib/accountSpecific';

const saveTenantSession = (tenantSettings) => {
    const accountId = tenantSettings.account.id;
    const appType = getCorrectedAppType(accountId);

    document.title = getAccountProductName(accountId);

    return {
        type: ACTION_TYPES.SAVE_TENANT_SETTINGS,
        tenantSettings: {
            ...tenantSettings,
            appType
        }
    };
};

const updateUserInfo = (formdata) => ({
    type: ACTION_TYPES.UPDATE_USER_INFO,
    promise: HTTPClient.put('user', formdata)
});

export {updateUserInfo, saveTenantSession};
