import isEmpty from 'lodash.isempty';

const isHeaderHidden = (uiSchema) => !!uiSchema.hideHeader;

const isHeaderFoldingDisabled = (uiSchema) => !!uiSchema.disableFoldingHeader;

const isType = (schema, type) => schema.type === type;

/**
 * This is to avoid a simple checkbox field having its text duplicated in the label and field description.
 * Checks if the boolean field is a simple field: without enumNames in schema (only one checkbox will be displayed),
 * e.g. "question": {
              "type": "boolean",
              "title": "tp.form.title"
            }
 *
 * @param {Object} schema
 * @returns {Boolean}
 */
const isSimpleBooleanField = (schema) =>
    isType(schema, 'boolean') && !schema.enumNames;

const isErrorInChildren = (children) =>
    !!(
        children &&
        children.props &&
        children.props.errorSchema &&
        !isEmpty(children.props.errorSchema)
    );

const isDateField = (uiSchema) =>
    uiSchema && uiSchema['ui:widget'] === 'cs-date';

/**
 * for form IDs generated by RJSF, find the id of the containing object
 * @param fieldId {string}
 * @param containers {Array}
 * @returns {string} the matching root id
 */
const findRootFieldId = (fieldId, containers = []) => {
    if (!fieldId) {
        return undefined;
    }

    // rows_22_field will match rows_2 and rows_22, so we take the last result, which should be the correct match.
    const matchingContainers = containers
        .filter((c) => fieldId.includes(c.id))
        .map((res) => res.id)
        .sort()
        .reverse();
    return matchingContainers ? matchingContainers[0] : undefined;
};

export {
    isHeaderHidden,
    isHeaderFoldingDisabled,
    isType,
    isErrorInChildren,
    isSimpleBooleanField,
    isDateField,
    findRootFieldId
};
