import React from 'react';
import FieldsetReplacementObjectField from './FieldsetReplacementObjectField';
import MarkupBlockField from './MarkupBlockField';
import TpSchemaField from './TpSchemaField';
import TpArrayField from './TpArrayField';
import WebString from 'components/webString/WebString';
import FormTransclusionField from './FormTransclusionField';
import ApprovalOverviewField from './ApprovalOverviewField';
import RiskClassificationField from './RiskClassificationField';
import UploadFileField from './UploadFileField';
import ApprovalUploadFileField from './ApprovalUploadFileField';
import RiskAlertsField from './RiskAlertsField';
import ChatField from './chat/ChatField';
import RedFlagsField from './RedFlagsField';
import RedFlagsAndPlansTemplate from './RedFlagsAndPlansTemplate';
import RedFlagEntry from './RedFlagEntry';
import DateRangeField from './DateRangeField';
import ExternalQuestionnaireAssignmentField from './externalQuestionnaireAssignment/ExternalQuestionnaireAssignmentField';

export default {
    // extended react-jsonschema-form fields
    TitleField: () => null, // empty title field, to avoid displaying the title twice
    DescriptionField: ({description}) => (
        <WebString
            className="bk-cs-margin-horiz--md"
            webStringKey={description}
        />
    ),
    ObjectField: FieldsetReplacementObjectField,
    ArrayField: TpArrayField,
    SchemaField: TpSchemaField,

    // custom fields
    markupBlock: MarkupBlockField,
    formTransclusionField: FormTransclusionField,
    approvalOverviewField: ApprovalOverviewField,
    riskClassification: RiskClassificationField,
    uploadFileField: UploadFileField,
    approvalUploadFileField: ApprovalUploadFileField,
    redFlags: RedFlagsField,
    redFlagEntry: RedFlagEntry,
    riskAlerts: RiskAlertsField,
    chatField: ChatField,
    redFlagsAndPlansTemplate: RedFlagsAndPlansTemplate,
    dateRangeField: DateRangeField,
    externalQuestionnaireAssignmentField: ExternalQuestionnaireAssignmentField,
    noInputField: () => <div className={'bk-cs-margin-top--lg'} />
};
