import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import TpButton from 'components/common/buttons/TpButton';
import HTTPClient from 'lib/HTTPClient';
import * as proposalActions from 'store/actions/proposalActions';
import getErrorSnackObject from './errorSnackUtils';
import * as snackActions from 'store/actions/snackActions';
import ViewTypes from 'types/ViewTypes';

const SkipExternalQuestionnaireButton = ({
    proposalId,
    view,
    stringResources
}) => {
    const dispatch = useDispatch();

    const handleSkip = async () => {
        try {
            await HTTPClient.post(`externalquestionnaires/${proposalId}/skip`);
            dispatch(proposalActions.initProcess(proposalId, view));
        } catch (error) {
            const snackProperties = getErrorSnackObject(
                proposalId,
                'tp.externalquestionnaireassignment.notification.message.skipquestionnaire.error',
                stringResources
            );
            dispatch(snackActions.createSnack(snackProperties));
        }
    };

    return (
        <TpButton
            id="btn-skip-external-questionnaire"
            onClick={handleSkip}
            webStringKey="tp.button.skipquestionnaire"
            className="tp-externalquestionnaire-assignment__skipbutton"
        />
    );
};

SkipExternalQuestionnaireButton.propTypes = {
    proposalId: PropTypes.number.isRequired,
    view: PropTypes.oneOf(Object.values(ViewTypes)).isRequired,
    stringResources: PropTypes.object.isRequired
};

export default SkipExternalQuestionnaireButton;
