const sortAndPutNonSortedValuesAtTheEnd = (
    valuesToSort,
    valuesNotToBeSorted = []
) => {
    const notSortedValues = valuesNotToBeSorted.map((a) => a.toLowerCase());

    const sortedValues = valuesToSort.sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        const aNotToBeSorted = notSortedValues.includes(a);
        const bNotToBeSorted = notSortedValues.includes(b);

        if (aNotToBeSorted && bNotToBeSorted) {
            const indexA = notSortedValues.indexOf(a);
            const indexB = notSortedValues.indexOf(b);
            return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
        }

        if (aNotToBeSorted) return 1;
        if (bNotToBeSorted) return -1;
        return a < b ? -1 : a > b ? 1 : 0;
    });

    return sortedValues;
};

export default {
    sortAndPutNonSortedValuesAtTheEnd
};
