import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {setupStore} from './store/store';
import reducers from 'store/reducers/reducers';
import Dashboard from 'components/dashboard/Dashboard';
import App from 'components/App';
import MainLayout from 'components/mainLayout/MainLayout';
import RequireAuth from 'components/authorization/RequireAuth';
import {AuthProvider} from 'components/authorization/AuthProvider';
import urls from 'routes/urls';
import allRoutes from 'routes/allRoutes';
import SilentRenew from 'components/authorization/SilentRenew';
import NotFound from 'components/common/errorHandling/NotFound';

/**
 * Self registration handling route.
 * Authentication handling routes. Handles redirect to Central Login and OIDC callback.
 * Routes for application routing with authentication.
 */

const Initial = ({settings}) => {
    const store = setupStore(reducers, {settings});

    return (
        <Provider store={store}>
            <AuthProvider appSettings={settings}>
                <Router basename={settings.AppContextPath}>
                    <MainLayout>
                        <Switch>
                            <Route
                                path={urls.SELF_REGISTRATION_URL}
                                component={allRoutes.SelfRegistrationRoute}
                            />
                            <Route
                                path={urls.LOGIN_URL}
                                component={allRoutes.LoginRoute}
                            />
                            <Route
                                path={urls.LOGOUT_URL}
                                component={allRoutes.LogoutRoute}
                            />
                            <Route
                                exact={true}
                                path={urls.SILENT_RENEW_URL}
                                component={SilentRenew}
                            />
                            <RequireAuth>
                                <App>
                                    <Switch>
                                        <Route
                                            path={urls.PROPOSALS_URL}
                                            component={allRoutes.ProposalsRoute}
                                        />
                                        <Route
                                            path={urls.ALERTS_URL}
                                            component={allRoutes.AlertsRoute}
                                        />
                                        <Route
                                            path={urls.BUSINESS_PARTNERS_URL}
                                            component={
                                                allRoutes.BusinessPartnersRoute
                                            }
                                        />
                                        <Route
                                            path={urls.USERS_URL}
                                            component={allRoutes.UsersRoute}
                                        />
                                        <Route
                                            path={urls.PRE_CHECK_URL}
                                            component={allRoutes.PreCheckRoute}
                                        />
                                        <Route
                                            path={
                                                urls.EXTERNAL_QUESTIONNAIRES_URL
                                            }
                                            component={
                                                allRoutes.ExternalQuestionnaireRoute
                                            }
                                        />
                                        <Route
                                            path={urls.AD_HOC_CHECK_URL}
                                            component={
                                                allRoutes.AdHocCheckRoute
                                            }
                                        />
                                        <Route
                                            path={urls.REPORTS_URL}
                                            component={allRoutes.ReportsRoute}
                                        />
                                        <Route
                                            exact
                                            path="/"
                                            component={Dashboard}
                                        />
                                        <Route
                                            path="/:anyInput"
                                            component={NotFound}
                                        />
                                    </Switch>
                                </App>
                            </RequireAuth>
                        </Switch>
                    </MainLayout>
                </Router>
            </AuthProvider>
        </Provider>
    );
};

Initial.propTypes = {
    settings: PropTypes.shape({
        AppContextPath: PropTypes.string.isRequired,
        AuthAuthorityURI: PropTypes.string.isRequired,
        ApiBasePath: PropTypes.string.isRequired,
        ApiVersionConfig: PropTypes.object,
        loginUrlRedirects: PropTypes.object,
        isTestSystem: PropTypes.bool,
        isShowAllWebStringsButtonActivated: PropTypes.bool,
        DashboardURL: PropTypes.string,
        oidcClientId: PropTypes.string
    }).isRequired
};

export default Initial;
