import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import WebString from 'components/webString/WebString';
import Loader from 'components/loader/CircularLoader';
import AdHocCheckResultsTable from './AdHocCheckResultsTable';
import AdHocCheckForm from './AdHocCheckForm';

export class AdHocCheckPanel extends React.Component {
    static propTypes = {
        onError: PropTypes.func
    };

    static defaultProps = {
        onError: () => undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            adHocCheckResults: [],
            fetchingFailed: false,
            hasDataBeenFetched: false,
            isFetching: false
        };
    }

    /**
     * Fetch the adhoccheck data from BE into state for display.
     * @param {String} type
     * @param {String} name (companyname/lastname)
     * @param {String} country
     * @param {String} firstName
     * @returns undefined
     */

    async adhocCheckDowJonesAsync(type, name, country, firstName) {
        let response;
        try {
            const searchParams =
                type === 'COMPANY'
                    ? {type, name, country}
                    : {type, firstName, name, country};
            response = await HTTPClient.get('dowjones/search', searchParams);
            const responseAsArray = Array.isArray(response)
                ? response
                : [response];

            this.setState({
                adHocCheckResults: responseAsArray,
                isFetching: false,
                hasDataBeenFetched: true
            });
        } catch (error) {
            this.setState(
                {
                    adHocCheckResults: [],
                    isFetching: false,
                    fetchingFailed: true
                },
                () => this.props.onError(error)
            );
        }
    }

    /**
     * This is only called when form has passed validation
     * it hides error panel if validation has passed
     * @returns {undefined}
     */
    handleSubmit = ({formData}) => {
        const {typeOfBP, name, country, firstName} = formData;
        this.setState({isFetching: true});
        this.adhocCheckDowJonesAsync(typeOfBP, name, country, firstName);
    };

    render() {
        return (
            <div className="tp-adhoccheck-component">
                <h1 className="tp-adhockcheck-component__heading">
                    <WebString webStringKey="tp.form.adhoccheck.title" />
                </h1>
                <AdHocCheckForm
                    onSubmit={this.handleSubmit}
                    handleErrors={this.handleError}
                />
                <AdHocCheckResultsTable data={this.state.adHocCheckResults} />
                {this.state.hasDataBeenFetched &&
                this.state.adHocCheckResults.length === 0 ? (
                    <div className="tp-adhoccheck_noresults">
                        <WebString webStringKey="tp.adhoccheck.text.noresults" />
                    </div>
                ) : null}
                {this.state.isFetching ? <Loader /> : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onError(error) {
        dispatch({type: 'GET_DOWJONES_PROFILE_FAILED', error});
    }
});
export default connect(null, mapDispatchToProps)(AdHocCheckPanel);
