import React from 'react';
import {useRouteMatch, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import LoginCallback from 'components/authorization/LoginCallback';
import LoginWithOrWithoutUuid from 'components/authorization/LoginWithOrWithoutUuid';

const getUrlToRedirectTo = (loginUrlRedirects) => {
    const redirectMap = Object.entries(loginUrlRedirects).find(
        ([fromPath]) => fromPath === window.location.pathname
    );
    return redirectMap ? redirectMap[1] : null;
};

/**
 * Redirects the login URL to Central Login or to the location if matching in loginUrlRedirects prop.
 */
const LoginRoute = () => {
    const loginUrlRedirects = useSelector(
        (state) => state.settings.loginUrlRedirects
    );
    const redirectUrl = getUrlToRedirectTo(loginUrlRedirects);
    if (redirectUrl) {
        window.location = redirectUrl;
        return null;
    }
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={LoginWithOrWithoutUuid} />
            <Route
                exact={true}
                path={`${path}/callback`}
                component={LoginCallback}
            />
            <Route
                path={`${path}/:customerUuid`}
                component={LoginWithOrWithoutUuid}
            />
        </Switch>
    );
};

export default LoginRoute;
