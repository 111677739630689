import React from 'react';
import BoxInformation from './BoxInformation';
import CircularLoader from 'components/loader/CircularLoader';

export const ProcessingLoginBox = () => (
    <BoxInformation
        headerText={'Processing...'}
        content={
            <div>
                <CircularLoader label={'Processing login, please wait...'} />
                <p>
                    You will be redirected after successful login verification.
                </p>
            </div>
        }
    />
);

export default ProcessingLoginBox;
