import * as EmailValidator from 'email-validator';

/**
 * @param  {string} fileText
 * @param  {string} delim
 * returns {Array} array of objects
 * */
const parseCSVFile = (fileText, delim = ',') => {
    const expectedHeaders = ['firstName', 'surName', 'emailAddress'];
    const headers = fileText.slice(0, fileText.indexOf('\n')).split(delim);
    const rows = fileText.slice(fileText.indexOf('\n') + 1).split('\n');
    const isCorrectFormat = headers.every((val) =>
        expectedHeaders.includes(val.trim(''))
    );
    if (isCorrectFormat) {
        return rows.map((row) => {
            const values = row.split(delim);
            return headers.reduce((obj, header, i) => {
                obj[header.trim('')] = values[i] ? values[i].trim('') : '';
                return obj;
            }, {});
        });
    } else {
        return [];
    }
};

/**
 * @param  {File} csvFile
 * returns {String} file Text (after resolving the promise)
 * */

const readFileAsText = async (csvFile) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', (evt) => {
            if (reader.result) {
                resolve(reader.result);
            }
        });
        reader.readAsText(csvFile);
    });
};

/**
 * @param  {Array} csvArray
 * returns {boolean}
 * */
const isCsvDataFieldsEmpty = (csvArray) => {
    return csvArray.every(
        ({firstName, surName, emailAddress}) =>
            firstName && surName && emailAddress
    );
};

/**
 * @param  {Array} csvArray
 * returns {boolean}
 * */
const isEmailFieldsValid = (csvArray) => {
    return csvArray.every(({emailAddress}) =>
        EmailValidator.validate(emailAddress)
    );
};

export default {
    isCsvDataFieldsEmpty,
    isEmailFieldsValid,
    parseCSVFile,
    readFileAsText
};
