import {handle} from 'redux-pack';
import clonedeep from 'lodash.clonedeep';
import set from 'lodash.set';

const CLEAR_ALL_ALERTS = 'CLEAR_DAILY_ALERTS';
const LOAD_DAILY_ALERTS = 'LOAD_DAILY_ALERTS';
const LOAD_ALL_ALERTS = 'LOAD_ALL_ALERTS';
const SAVE_DAILY_ALERTS = 'SAVE_DAILY_ALERTS';
const UPDATE_ALERT_FORMDATA = 'UPDATE_ALERT_FORMDATA';

export const ACTION_TYPES = {
    LOAD_DAILY_ALERTS,
    LOAD_ALL_ALERTS,
    SAVE_DAILY_ALERTS,
    UPDATE_ALERT_FORMDATA,
    CLEAR_ALL_ALERTS
};

const defaultState = {
    isLoading: false,
    savingAlerts: false,
    dailyAlerts: {},
    allAlerts: [],
    totalPages: 0,
    searchParams: {}
};

const alertReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_DAILY_ALERTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    dailyAlerts: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    forbiddenOrNotFound: true
                })
            });
        case LOAD_ALL_ALERTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                    totalPages: action.payload.totalPages,
                    allAlerts: action.payload.alertsResources,
                    searchParams: action.meta.searchParams
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isLoading: false
                })
            });
        case SAVE_DAILY_ALERTS:
            return handle(state, action, {
                start: (prevState) => ({...prevState, savingAlerts: true}),
                success: (prevState) => ({
                    ...prevState,
                    savingAlerts: false,
                    alertErrors: []
                }),
                failure: (prevState) => ({
                    ...prevState,
                    savingAlerts: false
                })
            });
        case UPDATE_ALERT_FORMDATA:
            const alertsCopy = clonedeep(state.dailyAlerts);
            set(alertsCopy, 'formData', action.formData);
            return {
                ...state,
                dailyAlerts: alertsCopy
            };
        case CLEAR_ALL_ALERTS:
            return {
                ...state,
                dailyAlerts: {}
            };
        default:
            return state;
    }
};

export default alertReducer;
