import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useParams, Redirect} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {
    clearDailyAlerts,
    getAlertsForId,
    saveAlerts,
    updateAlertFormData
} from 'store/actions/alertActions';
import {clearTaskErrors, showTaskErrors} from 'store/actions/processActions';
import {wrapInDispatch} from 'store/actions/actionUtils';
import {ProposalForm} from 'components/tpForm/TpForm';
import ActionPanel from 'components/proposal/actionPanel/ActionPanel';
import NotificationPanel from 'components/notificationPanel/NotificationPanel';
import TpButton from 'components/common/buttons/TpButton';
import TpLink from 'components/common/TpLink';
import WebString from 'components/webString/WebString';
import CircularLoader from 'components/loader/CircularLoader';
import NotificationMessage from 'domain/NotificationMessage';
import ProcessLayout from 'components/common/ProcessLayout';
import FeatureTypes from 'types/FeatureTypes';
import StatusTypes from 'types/StatusTypes';

/**
 * Page layout component for the Alert Manager's view of risk alerts.
 * Renders the form with the alerts, a notification panel for validation errors
 * and the next step panel.
 * Does not render a progress bar.
 */
export const ProcessAlerts = (props) => {
    const {alertId} = useParams();

    useEffect(() => {
        props.loadAlerts(alertId);
        return () => {
            props.clearAlertsAndErrors();
        };
    }, []);

    /**
     * Click the form submit button to trigger FE validation
     * or TpForm's onSubmit prop action if validation passes.
     * @private
     */
    const handleNavigatorNext = () => {
        const formToValidate = [...document.forms][0];
        if (formToValidate) {
            formToValidate.getElementsByClassName('submit')[0].click();
        }
    };

    const homePageRedirect = () => <Redirect to={'/'} />;

    const {alerts, loadingAlert} = props;
    const featureId = parseInt(alertId) || 0;
    const isReadOnly =
        alerts.assessmentStatus === StatusTypes.Alerts.Assessment.CLOSED;
    const finishAction = isReadOnly
        ? () => homePageRedirect()
        : () => props.saveAlerts(props.alerts, homePageRedirect);

    const formElement = !isEmpty(alerts) ? (
        <ProposalForm
            id="alerts-form"
            featureType={FeatureTypes.ALERTS}
            featureId={featureId}
            formData={alerts.formData || {}}
            schema={alerts.formDefinition.formSchema}
            uiSchema={alerts.formDefinition.uiSchema}
            fieldData={alerts.fieldData}
            onError={props.showErrors}
            onChange={props.updateFormData}
            onSubmit={finishAction}
            disabled={isReadOnly}
            showSubmitButton={false}
        />
    ) : null;

    const errorPanel = (
        <NotificationPanel
            title={'tp.errormsg.validation.paneltitle'}
            notifications={props.notifications}
        />
    );

    const navigatorActions = (
        <div className="bk-cs-float-right">
            <TpLink
                id="leave-process-btn"
                className="bk-cs-element-right"
                webStringKey="cs.button.cancel"
                to={'/'}
            />
            <TpButton
                id="buttonGo"
                visible={true}
                disabled={false}
                webStringKey="cs.button.finish"
                onClick={handleNavigatorNext}
            />
        </div>
    );

    const actionPanel = (
        <ActionPanel
            bodyContent={
                <WebString webStringKey={`tp.form.dowjones.action.text`} />
            }
            footerContent={navigatorActions}
        />
    );
    // Implement crumbs
    return loadingAlert ? (
        <CircularLoader />
    ) : (
        <ProcessLayout
            contentWebStringKeyBase="tp.form.dowjones.alertmanager"
            formPanel={formElement}
            errorPanel={errorPanel}
            actionPanel={actionPanel}
        />
    );
};

ProcessAlerts.propTypes = {
    loadAlerts: PropTypes.func.isRequired,
    saveAlerts: PropTypes.func.isRequired,
    updateFormData: PropTypes.func.isRequired,
    showErrors: PropTypes.func.isRequired,
    clearAlertsAndErrors: PropTypes.func.isRequired,
    loadingAlert: PropTypes.bool,
    alerts: PropTypes.object,
    notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
};

ProcessAlerts.defaultProps = {
    notifications: [],
    alerts: {},
    loadingAlert: true
};

const mapStateToProps = (state) => ({
    alerts: state.riskAlerts.dailyAlerts,
    loadingAlert: state.riskAlerts.isLoading,
    notifications: state.process.currentTaskErrors
});

const mapDispatchToProps = (dispatch) => ({
    loadAlerts: wrapInDispatch(getAlertsForId, dispatch),
    saveAlerts: wrapInDispatch(saveAlerts, dispatch),
    updateFormData: wrapInDispatch(updateAlertFormData, dispatch),
    showErrors: wrapInDispatch(showTaskErrors, dispatch),
    clearAlertsAndErrors() {
        dispatch(clearTaskErrors());
        dispatch(clearDailyAlerts());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessAlerts);
