import React from 'react';
import PropTypes from 'prop-types';
import UserForm from '../userForm/UserForm';
import {getRoleFormSchema} from 'components/users/createOrUpdateUser/formSchema/getUserSchema';
import {getRoleFormUiSchema} from 'components/users/createOrUpdateUser/uiSchema/getUserUiSchema';
import SingleRightTypes from 'types/SingleRightTypes';
import UserRoleTypes from 'types/UserRoleTypes';
import UserSchemaNameTypes from '../userSchemaNameTypes';
import {getWebStringForRole} from '../../rolesUtils';
import i18nUtils from 'lib/i18nUtils';

const getIsRoleActiveFieldTitle = (roleType, stringResources) => {
    const webStringForRole = getWebStringForRole(roleType);
    const stringTranslation = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        `tp.admin.createorupdateform.isroleactive.title`
    );
    const translatedRole = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        webStringForRole
    );
    return stringTranslation.replace(/({role})/g, translatedRole);
};

const getRoleFormSchemas = (
    possibleDimensions,
    singleRightRolesMap,
    roleType,
    userSchemaNameType,
    stringResources
) => {
    const singleRights = Object.entries(singleRightRolesMap)
        .filter(([key, value]) => value.includes(roleType))
        .map(([key]) => key);
    const isActiveDefault =
        userSchemaNameType === UserSchemaNameTypes.CREATE &&
        roleType === UserRoleTypes.APPLICANT;

    return {
        schema: getRoleFormSchema(
            possibleDimensions,
            singleRights,
            isActiveDefault,
            getIsRoleActiveFieldTitle(roleType, stringResources)
        ),
        uiSchema: getRoleFormUiSchema(possibleDimensions, singleRights)
    };
};

const RoleForm = (props) => (
    <UserForm
        {...getRoleFormSchemas(
            props.possibleDimensions,
            props.singleRightRolesMap,
            props.roleType,
            props.userSchemaNameType,
            props.stringResources
        )}
        stringResources={props.stringResources}
        formData={props.roleFormData}
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        onError={props.onError}
        showSubmitButton={false}>
        {props.children}
    </UserForm>
);

RoleForm.propTypes = {
    roleType: PropTypes.string.isRequired,
    stringResources: PropTypes.object.isRequired,
    possibleDimensions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.string).isRequired
        }).isRequired
    ),
    singleRightRolesMap: PropTypes.shape({
        [PropTypes.oneOf(Object.values(SingleRightTypes))]: PropTypes.arrayOf(
            PropTypes.string
        )
    }).isRequired,
    userSchemaNameType: PropTypes.oneOf(Object.values(UserSchemaNameTypes))
        .isRequired,
    children: PropTypes.object.isRequired,
    roleFormData: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

export default RoleForm;
