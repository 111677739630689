import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import isEmpty from 'lodash.isempty';
import WebString from 'components/webString/WebString';
import TpPopupButton from 'components/common/buttons/TpPopupButton';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import AccountFeatureTypes from 'types/AccountFeatureTypes';
import ShowAllWebStringKeysButton from './ShowAllWebStringKeysButton';
import LocaleSelection from './LocaleSelection';

const handleUserPresent = (props) => {
    return (
        <div className="row">
            <div className="col-xs-6">
                <AccountFeatureToggle
                    requiredAccountFeature={
                        AccountFeatureTypes.DATA_PROTECTION_NOTICE
                    }>
                    <TpPopupButton
                        id="btn_data_protection_notice"
                        asLink={true}
                        popupConf={{
                            size: 'full',
                            headerInfo: {
                                webStringKey:
                                    'tp.text.dataprotectionpopup.title',
                                type: 'generic',
                                showCloseButton: true
                            }
                        }}
                        webStringKey="tp.text.dataprotectionlabel.title">
                        <WebString webStringKey="tp.text.dataprotectionpopup.text" />
                    </TpPopupButton>
                </AccountFeatureToggle>
            </div>
            <div className="col-xs-6 bk-cs-text-right">
                {props.isShowAllWebStringsButtonActivated ? (
                    <ShowAllWebStringKeysButton />
                ) : null}
                <LocaleSelection />
            </div>
        </div>
    );
};
const handleUserNotPresent = () => (
    <div className="row">
        <div className="col-xs-6">
            <a href="https://www.business-keeper.com" target="_blank">
                © Business Keeper AG
            </a>
            &#8239; BKMS<sup>®</sup> Compliance System &#8239;
            <a href="https://www.business-keeper.com/impressum">Imprint</a>
        </div>
        <div className="col-xs-6 bk-cs-text-right">
            <span>Support: +49 (0) 30 - 88 77 444-0</span>
            &#8239;
            <span>info@business-keeper.com</span>
        </div>
    </div>
);

export const Footer = (props) => (
    <footer className="bk-cs-footer container">
        {!isEmpty(props.stringResources) ? (
            <div className="bk-cs-footer-content">
                {props.user ? handleUserPresent(props) : handleUserNotPresent()}
            </div>
        ) : (
            ''
        )}
    </footer>
);

Footer.propTypes = {
    user: PropTypes.object,
    stringResources: PropTypes.object,
    isShowAllWebStringsButtonActivated: PropTypes.bool
};

Footer.defaultProps = {
    user: null,
    stringResources: {},
    isShowAllWebStringsButtonActivated: false
};

const mapStateToProps = ({settings, oidc}) => ({
    stringResources: settings.i18n.stringResources,
    user: oidc.user,
    isShowAllWebStringsButtonActivated:
        settings.isShowAllWebStringsButtonActivated
});

export default connect(mapStateToProps)(Footer);
