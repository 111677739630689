export default {
    id: 'en-GB',
    stringMap: {
        'cs.text.selectedlanguage': 'Selected language',
        'cs.text.language.engb': 'English',
        'cs.text.language.de': 'Deutsch',
        'tp.registration.title':
            'Welcome tp BKMS<sup>®</sup> Compliance System',
        'tp.registration.unavailable':
            'There is no registration available for this account.',
        'tp.registration.form.title': 'Registration',
        'tp.registration.introductiontext':
            '<p>You have been provided with the registration link to the BKMS<sup>®</sup> Compliance System by your organisation. </p><p>To create your own user account please use your company email address only.</p><p>After submitting your company email address, you are going to receive a verification mail. Please follow the process indicated in that mail to finish the registration process.</p><p>You can then log in and will have access to the BKMS<sup>®</sup> Compliance System of your company.</p>',
        'tp.registration.email.title': 'Your email address:',
        'tp.registration.submit.title': 'Sign up',
        'tp.errormsg.validation.paneltitle':
            'Errors have occurred, please check your entries.',
        'tp.errormsg.validation.required': 'Must not be empty.',
        'tp.errormsg.validation.toofewcharacters':
            'Must contain at least {0} characters.',
        'tp.errormsg.validation.toomanycharacters':
            'Must not contain more than {0} characters.',
        'tp.errormsg.validation.email': 'Invalid email address.',
        'tp.registration.success.title': 'Successful registration',
        'tp.registration.success.registered':
            'An email for the registration was sent to your mailbox.',
        'tp.registration.warning.title': 'Warning',
        'tp.registration.warning.registrationpending':
            'An account for this e-mail already exists, but has not been confirmed yet. A new email has been sent to you.',
        'tp.registration.warning.registrationfinished':
            'An account for this e-mail already exists. Please use the "Forgot your password" functionality in the login service if necessary.',
        'tp.registration.error.title': 'Error',
        'tp.registration.error.offline':
            'Could not connect to the server. Please check your internet connection and try again.',
        'tp.registration.error.timeout':
            'The server took too long to process the request. Please try again.',
        'tp.registration.error.internalserver':
            'Something went wrong. Please try again. If the problem persists, please contact your responsible contact person.',
        'tp.registration.error.invalidemaildomain':
            'The email domain of the entered address is not allowed. Please enter the address that your employer provides you with.',
        'tp.registration.error.invalidemailformat': 'Invalid email address.'
    }
};
