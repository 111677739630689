import userSchemaNameTypes from '../userSchemaNameTypes';
import uiSchemaBlocks from './uiSchemaBlocks';

export const getGeneralFormUiSchema = (userSchemaNameType) => {
    return userSchemaNameType === userSchemaNameTypes.UPDATE
        ? {...uiSchemaBlocks.extraFields}
        : {};
};

export const getRoleFormUiSchema = (dimensions, singleRights) => {
    const dimensionObject = {};
    dimensions.forEach((d) => {
        dimensionObject[d.name] = uiSchemaBlocks.dimension;
    });

    const uiSchema = {
        'ui:order': ['isRoleActive', 'scope'],
        isRoleActive: {
            'ui:widget': 'radio',
            'ui:options': {
                inline: true
            }
        },
        scope: {
            'ui:order': [...dimensions.map((d) => d.name)],
            ...dimensionObject
        }
    };
    if (singleRights.length > 0) {
        const singleRightObject = {};
        singleRights.forEach((sr) => {
            singleRightObject[sr] = uiSchemaBlocks.singleRight;
        });
        uiSchema.singleRights = {...singleRightObject};
        uiSchema['ui:order'].push('singleRights');
    }

    return uiSchema;
};
