import CsDate from './CsDate';
import TpBaseInputWidget from './TpBaseInput';
import TpTextareaWidget from './TpTextareaWidget';
import TpCheckBoxRequiredWidget from './TpCheckBoxRequiredWidget';
import TpDisplayValueAsWebstringWidget from './TpDisplayWebstringWidget.js';
import TpDisplayStringAsDate from './TpDisplayStringAsDate';
import TpSearchableSelect from './TpSearchableSelect';
import TpDynamicUrl from './TpDynamicUrl';
import TpCurrency from './TpCurrency';
import React from 'react';

/**
 * This file bundles all available custom react-jsonschema-form widgets.
 * If a widget should be used inside a form field, you have to add the
 * ui:widget property to the field inside the ui-schema of the form.
 * The mentioned name must match any of the exported object properties.
 *
 * If the ui:widget name was not found an error is thrown on form rendering.
 */
export default {
    // extended react-jsonschema-form widgets
    TextWidget: TpBaseInputWidget,
    // RadioWidget: TpRadioWidget,
    BaseInput: TpBaseInputWidget,
    TextareaWidget: TpTextareaWidget,
    SelectWidget: TpSearchableSelect,
    DateWidget: CsDate,
    DisplayValueAsWebstringWidget: TpDisplayValueAsWebstringWidget,

    // custom widgets
    'cs-date': CsDate,
    'cs-displayStringAsDate': TpDisplayStringAsDate,
    'cs-checkBoxRequired': TpCheckBoxRequiredWidget,
    'cs-dynamicURL': TpDynamicUrl,
    'cs-currency': TpCurrency
};
