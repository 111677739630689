import {handle} from 'redux-pack';

const GENERATE_REPORT = 'GENERATE_REPORT';
const GET_REPORTS = 'GET_REPORTS';
export const ACTION_TYPES = {
    GENERATE_REPORT,
    GET_REPORTS
};

const defaultState = {
    rolesWithReportingRight: [],
    reportsList: [],
    isReportGenerated: false,
    isReportsLoading: false
};

const reportGenerationReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case GENERATE_REPORT:
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    isReportGenerated: true
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isReportGenerated: false
                })
            });
        case GET_REPORTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isReportsLoading: true
                }),
                success: (prevState) => ({
                    ...prevState,
                    isReportsLoading: false,
                    reportsList: payload
                }),
                failure: (prevState) => ({
                    ...prevState,
                    isReportsLoading: false
                })
            });
        default:
            return state;
    }
};

export default reportGenerationReducer;
