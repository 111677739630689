import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const RiskAlertsBPAddress = ({address}) => (
    <div id="risk-alert-address">
        <WebString
            webStringKey="tp.form.dowjones.address.title"
            className="tp-risk-alerts__heading"
        />
        {address}
    </div>
);

RiskAlertsBPAddress.propTypes = {
    address: PropTypes.string
};

RiskAlertsBPAddress.defaultProps = {
    address: ''
};

export default RiskAlertsBPAddress;
