import React from 'react';
import WebString from 'components/webString/WebString';
import SearchFilter from 'components/dashboard/filters/SearchFilter';
import DropdownFilter from 'components/dashboard/filters/DropdownFilter';
import FormattedUnixTime from 'components/common/FormattedUnixTime';
import defaultAppValues from 'lib/defaultAppValues';
import {getProposalTaskNameFromId} from 'lib/proposalUtils';
import DateRangeFilter from '../filters/DateRangeFilter';
import {
    tableColFallback,
    isArrayNullOrEmpty
} from 'components/tables/tableColumnsUtils';
import StatusTypes from 'types/StatusTypes';
import webStringUtils from 'lib/webStringUtils';

const dateRangeFilter = (filterdata) => <DateRangeFilter {...filterdata} />;
const searchFilter = (filterdata) => <SearchFilter {...filterdata} />;

const injectTableColumnsForProposalsTableWithFilterOptions = (
    filterOptions
) => [
    {
        id: 'proposalId',
        Header: <WebString webStringKey="cs.text.id" />,
        accessor: 'id',
        className: 'tp-all-proposals-table__id-column',
        headerClassName: 'tp-all-proposals-table__id-header',
        Filter: searchFilter
    },
    {
        id: 'proposalStatus',
        Header: <WebString webStringKey="cs.text.status" />,
        accessor: 'status',
        Cell: (props) => (
            <WebString
                webStringKey={`cs.text.status.${props.value
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .toLowerCase()}`}
            />
        ),
        className: 'tp-all-proposals-table__status-column',
        headerClassName: 'tp-all-proposals-table__status-header',
        Filter: (filterdata) => (
            <DropdownFilter
                webStringKeyPrefix="cs.text.status."
                filterValues={filterOptions.proposalTableStatuses}
                {...filterdata}
            />
        )
    },
    {
        id: 'proposalRiskLevel',
        Header: <WebString webStringKey="cs.text.risklevel" />,
        accessor: 'riskLevel',
        Cell: (props) =>
            props.value ? (
                <WebString
                    webStringKey={`cs.text.risklevel.${props.value.toLowerCase()}`}
                />
            ) : (
                ''
            ),
        className: 'tp-all-proposals-table__risk-column',
        headerClassName: 'tp-all-proposals-table__risk-header',
        Filter: (filterdata) => (
            <DropdownFilter
                webStringKeyPrefix="cs.text.risklevel."
                filterValues={['HIGH', 'MEDIUM', 'LOW']}
                {...filterdata}
            />
        )
    },
    {
        id: 'proposalCurrentTask',
        Header: <WebString webStringKey="cs.text.currenttask" />,
        accessor: (entity) =>
            getProposalTaskNameFromId(entity['currentTaskId']),
        Cell: (props) =>
            props.value ? (
                <WebString
                    webStringKey={`tp.process.${getProposalTaskNameFromId(
                        props.value
                    )}.title`}
                />
            ) : (
                ''
            ),
        filterable: false
    },
    {
        id: 'proposalCreationDate',
        Header: <WebString webStringKey="cs.text.created" />,
        accessor: 'creationTimestamp',
        Cell: (props) => <FormattedUnixTime value={props.value} />,
        Filter: dateRangeFilter
    },
    {
        id: 'proposalLastModificationDate',
        Header: <WebString webStringKey="cs.text.lastmod" />,
        accessor: 'lastModificationTimestamp',
        Cell: (props) => <FormattedUnixTime value={props.value} />,
        Filter: dateRangeFilter
    },
    {
        id: 'proposalSubmissionDate',
        Header: <WebString webStringKey="cs.text.submissiondate" />,
        accessor: 'submissionTimestamp',
        Cell: (props) => {
            return props.value ? (
                <FormattedUnixTime value={props.value} />
            ) : null;
        },
        Filter: dateRangeFilter
    },
    {
        id: 'proposalOriginator',
        Header: <WebString webStringKey="cs.text.originator" />,
        accessor: 'originator',
        Filter: searchFilter
    },
    {
        id: 'proposalOwner',
        Header: <WebString webStringKey="cs.text.owner" />,
        accessor: 'owner',
        Filter: searchFilter
    },
    {
        id: 'proposalAssessor',
        Header: <WebString webStringKey="cs.text.assessor" />,
        accessor: 'assessor',
        Filter: searchFilter
    },
    {
        id: 'firstName',
        Header: <WebString webStringKey="cs.text.firstname" />,
        accessor: 'firstName',
        Filter: searchFilter
    },
    {
        id: 'lastName',
        Header: <WebString webStringKey="cs.text.lastname" />,
        accessor: 'lastName',
        Filter: searchFilter
    },
    {
        id: 'bpName',
        Header: <WebString webStringKey="cs.text.name" />,
        accessor: (entity) => entity['businessPartner.name'],
        Cell: (props) =>
            props.value || defaultAppValues.defaultTableCellPlaceHolder,
        Filter: searchFilter
    },
    {
        id: 'bpId',
        Header: <WebString webStringKey="cs.text.bpid" />,
        accessor: (entity) => entity['businessPartner.id'],
        className: 'tp-all-proposals-table__bp-id-column',
        headerClassName: 'tp-all-proposals-table__bp-id-header',
        Filter: searchFilter
    },
    {
        id: 'bpCountry',
        Header: <WebString webStringKey="cs.text.country" />,
        accessor: (entity) => entity['businessPartner.country'],
        Filter: searchFilter
    },
    {
        id: 'originalProposalId',
        Header: <WebString webStringKey="tp.recheck.recheckinformation.text" />,
        accessor: 'originalProposalId',
        Filter: searchFilter
    }
];

const generateDimensionColumn = (dimensionName, filterOptions) => ({
    id: `${dimensionName}Dimension`,
    Header: (
        <WebString
            webStringKey={webStringUtils.getWebStringForDimension(
                dimensionName
            )}
        />
    ),
    accessor: (entity) => entity[`scope.${dimensionName}`],
    Cell: (props) => <span>{props.value} </span>,
    Filter: (filterdata) => {
        let filterValues = [];
        if (filterOptions && filterOptions.scope) {
            for (const dimension of filterOptions.scope) {
                if (dimension.dimension === dimensionName) {
                    filterValues = dimension.values;
                }
            }
        }
        return <DropdownFilter filterValues={filterValues} {...filterdata} />;
    }
});

const alertsTableColumns = [
    {
        id: 'alertsAssessmentId',
        Header: <WebString webStringKey="tp.table.alerts.id.title" />,
        accessor: 'alertsAssessmentId',
        Filter: searchFilter
    },
    {
        id: 'bpId',
        Header: <WebString webStringKey="tp.table.alerts.bpid.title" />,
        accessor: 'businessPartnerId',
        Filter: searchFilter
    },
    {
        id: 'bpName',
        Header: <WebString webStringKey="tp.table.alerts.name.title" />,
        accessor: 'businessPartnerName',
        Filter: searchFilter
    },
    {
        id: 'alertsCount',
        Header: <WebString webStringKey="tp.table.alerts.number.title" />,
        accessor: 'numberOfAlerts',
        Filter: searchFilter
    },
    {
        id: 'alertsOldestDate',
        Header: <WebString webStringKey="tp.table.alerts.timestamp.title" />,
        accessor: 'oldestAlertDate',
        Cell: (props) => <FormattedUnixTime value={props.value} />,
        Filter: searchFilter
    },
    {
        id: 'alertsNewestDate',
        Header: <WebString webStringKey="tp.table.alerts.newest.title" />,
        accessor: 'newestAlertDate',
        Cell: (props) => <FormattedUnixTime value={props.value} />,
        Filter: searchFilter
    },
    {
        id: 'bpCountry',
        Header: <WebString webStringKey="cs.text.country" />,
        accessor: 'country',
        Filter: searchFilter
    },
    {
        id: 'alertCriticality',
        Header: <WebString webStringKey="tp.table.alerts.criticality.title" />,
        accessor: 'alertCriticality',
        Filter: searchFilter
    },
    {
        id: 'alertProcessedDate',
        Header: (
            <WebString webStringKey="tp.table.alerts.processedDate.title" />
        ),
        accessor: 'alertProcessedDate',
        Cell: (props) => {
            return props.value ? (
                <FormattedUnixTime value={props.value} />
            ) : null;
        },
        Filter: searchFilter
    },
    {
        id: 'alertDeliveryDate',
        Header: <WebString webStringKey="tp.table.alerts.deliveryDate.title" />,
        accessor: 'alertDeliveryDate',
        Cell: (props) => {
            return props.value ? (
                <FormattedUnixTime value={props.value} />
            ) : null;
        },
        Filter: searchFilter
    },
    {
        id: 'alertsAssessmentStatus',
        Header: <WebString webStringKey="tp.table.alerts.status.title" />,
        accessor: 'assessmentStatus',
        Cell: (props) => (
            <WebString
                webStringKey={`tp.table.alerts.status.${props.value.toLowerCase()}`}
            />
        ),
        Filter: (filterdata) => (
            <DropdownFilter
                webStringKeyPrefix="cs.text.status."
                filterValues={Object.values(StatusTypes.Alerts.Assessment)}
                {...filterdata}
            />
        )
    }
];

/**
 * Filters the table columns using the columns id's stated in the configuredColumns param.
 * @param {string[]} configuredColumns - an array containing all column id's to look for
 * @param {object[]} tableColumns - all possible columns for the table
 * @param {object[]} filterOptions  - all possible options for filtering
 * @param {string} idExtension - extension to add to id for all columns
 * @return {object[]} - all table columns ready to use with ReactTable
 */
const getTableColumns = (
    configuredColumns,
    tableColumns,
    filterOptions = {},
    idExtension = ''
) => {
    if (isArrayNullOrEmpty(configuredColumns)) {
        return [];
    }

    const columns = configuredColumns.map((confCol) => {
        if (confCol.startsWith('proposalDimension')) {
            const arrayWithDimensionName = confCol.split('proposalDimension');
            return generateDimensionColumn(
                arrayWithDimensionName[1].toLowerCase(),
                filterOptions
            );
        }
        return (
            tableColumns.find((tableCol) => confCol === tableCol.id) ||
            tableColFallback(confCol)
        );
    });

    if (idExtension) {
        columns.map((col) => {
            col.id = col.id + idExtension;
            return col;
        });
    }

    return columns;
};

/**
 * @param {string[]} configuredColumns - an array containing all column id's to look for
 * @param {object} filterOptions - additional options for the filters like all possible proposalTableStatuses
 * @return {object[]} all table columns ready to use with ReactTable for proposals table
 */
const getProposalsTableColumns = (configuredColumns, filterOptions) =>
    getTableColumns(
        configuredColumns,
        injectTableColumnsForProposalsTableWithFilterOptions(filterOptions),
        filterOptions
    );

/**
 * @param {string[]} configuredColumns - an array containing all column id's to look for
 * @param {object} filterOptions - additional options for the filters like all possible proposalTableStatuses
 * @return {object[]} all table columns ready to use with ReactTable for assessments table
 */
const getAssessmentsTableColumns = (configuredColumns, filterOptions) =>
    getTableColumns(
        configuredColumns,
        injectTableColumnsForProposalsTableWithFilterOptions(filterOptions),
        filterOptions,
        'Assessment'
    );

/**
 * @param {string[]} configuredColumns - an array containing all column id's to look for
 * @return {object[]} all table columns ready to use with ReactTable for alerts table
 */
const getAlertsTableColumns = (configuredColumns) =>
    getTableColumns(configuredColumns, alertsTableColumns);

/**
 * @param {string[]} configuredColumns - an array containing all column id's to look for
 * @param {object} filterOptions - additional options for the filters like all possible proposalTableStatuses
 * @return {object[]} all table columns ready to use with ReactTable for external questionnaire table
 */
const getExternalQuestionnaireTableColumns = (
    configuredColumns,
    filterOptions
) =>
    getTableColumns(
        configuredColumns,
        injectTableColumnsForProposalsTableWithFilterOptions(filterOptions),
        filterOptions,
        'BusinessPartner'
    );

export {
    getProposalsTableColumns,
    getAssessmentsTableColumns,
    getAlertsTableColumns,
    getExternalQuestionnaireTableColumns
};
