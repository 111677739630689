import React, {useRef, useEffect} from 'react';
import scrollBooster from 'scrollbooster';
import PropTypes from 'prop-types';
import ProgressBarTask from './ProgressBarTask';
import StatusTypes from 'types/StatusTypes';

const ProgressBarScroller = (props) => {
    const [progressbarWrapperRef, progressBarTaskRef, progressBarRef] = [
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    useEffect(() => {
        if (
            !progressBarTaskRef.current ||
            !progressBarRef.current ||
            !progressbarWrapperRef.current
        ) {
            return;
        }

        const getElementWidth = (refName) => {
            return refName.current.getBoundingClientRect().width;
        };

        const taskWidth = getElementWidth(progressBarTaskRef);
        const progressBarWidth = getElementWidth(progressBarRef);
        const progressbarWrapperWidth = getElementWidth(progressbarWrapperRef);

        const middleOfActiveTask =
            (props.currentTaskIndex + 1) * taskWidth - taskWidth / 2;
        const requestedX = middleOfActiveTask - progressbarWrapperWidth / 2;
        const maxPossibleX = progressBarWidth - progressbarWrapperWidth;
        const minPossibleX = 0;

        const scroller = new scrollBooster({
            viewport: progressbarWrapperRef.current,
            direction: 'horizontal',
            scrollMode: 'transform',
            friction: 0.2
        });

        /**
         * returns 0 if requested X position is lower than minimum.
         * returns the highest possible X position if requested X is higher than maximum.
         * Otherwise returns requested X position.
         * @returns {number}
         */
        const getValidXPosition = () => {
            if (requestedX <= 0) {
                return minPossibleX;
            } else if (requestedX >= maxPossibleX) {
                return maxPossibleX;
            } else {
                return requestedX;
            }
        };

        (function moveToDefaultPosition() {
            scroller.setPosition({
                x: getValidXPosition(),
                y: 0
            });
        })();

        //Cleans up ScrollBooster on unmount
        return () => {
            scroller.destroy();
        };
    }, []);

    return (
        <div className={'cs-progressbar'} ref={progressbarWrapperRef}>
            <ul className={`cs-progressbar__list`} ref={progressBarRef}>
                {props.tasks.map((task, index) => {
                    return (
                        <ProgressBarTask
                            {...task}
                            key={task.id}
                            innerRef={
                                props.currentTaskIndex === index
                                    ? progressBarTaskRef
                                    : null
                            }
                        />
                    );
                })}
            </ul>
        </div>
    );
};
export default ProgressBarScroller;

ProgressBarScroller.propTypes = {
    currentTaskIndex: PropTypes.number.isRequired,
    tasks: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.oneOf([
                ...Object.values(StatusTypes.Task),
                'EMPTY'
            ]).isRequired,
            webStringKey: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            isLastTask: PropTypes.bool
        })
    ).isRequired
};
