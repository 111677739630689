import {handle} from 'redux-pack';
import defaultAppValues from 'lib/defaultAppValues';

const LOAD_REGISTRATION_STRINGS = 'LOAD_REGISTRATION_STRINGS';
const LOAD_GLOBAL_STRINGS = 'LOAD_GLOBAL_STRINGS';
const SET_APP_TYPE = 'SET_APP_TYPE';
const LOAD_FORM_STRINGS = 'LOAD_FORM_STRINGS';
const CLEAR_FORM_STRINGS = 'CLEAR_FORM_STRINGS';
const LOAD_PRECHECK_STRINGS = 'LOAD_PRECHECK_STRINGS';
const SET_FORM_STRINGS = 'SET_FORM_STRINGS';
const SHOW_WEB_STRINGS_KEYS = 'SHOW_WEB_STRINGS_KEYS';

export const ACTION_TYPES = {
    LOAD_REGISTRATION_STRINGS,
    LOAD_GLOBAL_STRINGS,
    LOAD_FORM_STRINGS,
    CLEAR_FORM_STRINGS,
    LOAD_PRECHECK_STRINGS,
    SET_APP_TYPE,
    SET_FORM_STRINGS,
    SHOW_WEB_STRINGS_KEYS
};

const defaultState = {
    i18n: {
        showWebStringKeys: false,
        locale: defaultAppValues.defaultLocale,
        stringResources: {}, // merged globalStringResources and formStringResources (resources for each form)
        globalStringResources: {},
        formStringResources: new Map() // is a map [key: id of the form, value: object with all strings]
    }
};

/**
 *
 * @param {Object} stringsMap - it is an object of new Map([iterable])
 *
 * @returns {Object} mergedValues -  merged values from stringsMap object
 */
const getMergedFormStringResources = (stringsMap) => {
    const mergedValues = {};
    for (const value of stringsMap.values()) {
        Object.assign(mergedValues, value);
    }
    return mergedValues;
};

const i18nReducer = (state = defaultState.i18n, action) => {
    const {type, payload, meta} = action;

    switch (type) {
        case LOAD_REGISTRATION_STRINGS: {
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    locale: payload.id,
                    stringResources: {
                        ...payload.stringMap
                    }
                })
            });
        }
        case LOAD_GLOBAL_STRINGS: {
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    globalStringResources: payload.stringMap,
                    locale: payload.id,
                    stringResources: {
                        ...payload.stringMap,
                        ...getMergedFormStringResources(
                            prevState.formStringResources
                        )
                    }
                })
            });
        }
        case LOAD_FORM_STRINGS: {
            return handle(state, action, {
                success: (prevState) => {
                    const stringsMap = new Map([
                        ...prevState.formStringResources
                    ]).set(meta.formId, payload.webStrings);
                    return {
                        ...prevState,
                        formStringResources: stringsMap,
                        stringResources: {
                            ...prevState.globalStringResources,
                            ...getMergedFormStringResources(stringsMap)
                        }
                    };
                }
            });
        }
        case SET_FORM_STRINGS: {
            const newFormStringsMap = new Map([
                ...state.formStringResources
            ]).set(action.formId, action.formStringResources);
            return {
                ...state,
                formStringResources: newFormStringsMap
            };
        }
        case CLEAR_FORM_STRINGS: {
            // todo: #14477 formStringResources should only contain the current form's strings
            const stringsMap = new Map([...state.formStringResources]);
            stringsMap.delete(action.formId);

            return {
                ...state,
                formStringResources: stringsMap,
                stringResources: {
                    ...state.globalStringResources,
                    ...getMergedFormStringResources(stringsMap)
                }
            };
        }
        case LOAD_PRECHECK_STRINGS: {
            return handle(state, action, {
                success: (prevState) => {
                    const stringsMap = new Map([
                        ...prevState.formStringResources
                    ]).set(meta.formId, payload.webStrings);
                    return {
                        ...prevState,
                        formStringResources: stringsMap,
                        stringResources: {
                            ...prevState.globalStringResources,
                            ...getMergedFormStringResources(stringsMap)
                        }
                    };
                }
            });
        }
        case SHOW_WEB_STRINGS_KEYS: {
            return {
                ...state,
                showWebStringKeys: action.isActive
            };
        }
        default: {
            return state;
        }
    }
};

const settingsReducer = (state = defaultState, action) => ({
    ...state,
    i18n: i18nReducer(state.i18n, action)
});

export default settingsReducer;
