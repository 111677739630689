import React from 'react';
import SearchFilter from 'components/dashboard/filters/SearchFilter';
import DropdownFilter from 'components/dashboard/filters/DropdownFilter';
import WebString from 'components/webString/WebString';
import DateRangeFilter from 'components/dashboard/filters/DateRangeFilter';
import defaultAppValues from 'lib/defaultAppValues';
import UserStatusCell from './UserStatusCell';
import {FormattedUnixTime} from 'components/common/FormattedUnixTime';
import RoleValues from './roles/RoleValues';
import RenderDimensions from './dimensions/RenderDimensions';
import {isArrayNullOrEmpty} from 'components/tables/tableColumnsUtils';
import {getUserTableColumnsFromConfig} from './userManagementTableUtils';

const dateRangeFilter = (filterdata) => <DateRangeFilter {...filterdata} />;
const defaultCellValue = (props) =>
    props.value || defaultAppValues.defaultTableCellPlaceHolder;
const defaultFilter = (filterdata) => <SearchFilter {...filterdata} />;
const formattedDate = (props) => <FormattedUnixTime value={props.value} />;
const userStatusCell = (props) => <UserStatusCell {...props} />;

const usersTableColumnsConfig = [
    {
        id: 'userId',
        webstringKey: 'tp.table.usermanagement.userid',
        accessor: 'id',
        cellValue: defaultCellValue,
        filterable: true,
        filterType: defaultFilter,
        classNameSuffix: 'user-id-column',
        headerClassName: 'user-id-header'
    },
    {
        id: 'userStatus',
        webstringKey: 'tp.table.usermanagement.userstatus',
        accessor: 'status',
        cellValue: userStatusCell,
        filterable: false,
        sortable: false,
        classNameSuffix: 'user-status-column',
        headerClassName: 'user-status-header rt-sortable-column--disabled',
        preventDefaultRowClick: true
    },
    {
        id: 'userFirstName',
        webstringKey: 'tp.table.usermanagement.firstname',
        accessor: 'firstName',
        cellValue: defaultCellValue,
        filterable: true,
        filterType: defaultFilter,
        classNameSuffix: 'firstname-column',
        headerClassName: 'firstname-header'
    },
    {
        id: 'userLastName',
        webstringKey: 'tp.table.usermanagement.lastname',
        accessor: 'lastName',
        cellValue: defaultCellValue,
        filterable: true,
        filterType: defaultFilter,
        classNameSuffix: 'lastName-column',
        headerClassName: 'lastName-header'
    },
    {
        id: 'userEmail',
        webstringKey: 'tp.table.usermanagement.email',
        accessor: 'email',
        cellValue: defaultCellValue,
        filterable: true,
        filterType: defaultFilter,
        classNameSuffix: 'user-email-column',
        headerClassName: 'user-email-header'
    },
    {
        id: 'userCreationDate',
        webstringKey: 'tp.table.usermanagement.created',
        accessor: 'created',
        cellValue: formattedDate,
        filterable: true,
        filterType: dateRangeFilter,
        classNameSuffix: 'created-date-column',
        headerClassName: 'created-date-header'
    }
];

const usersTableColumns = getUserTableColumnsFromConfig(
    usersTableColumnsConfig
);

const accessorForRoleAndScope = (entity) => entity.roles;

/*
 * Creates Roles column
 * @param availableRoles-used as filterValues - account specific roles available
 * @returns{*}
 * */
const generateRoleColumn = (availableRoles) => {
    return {
        id: `userRoles`,
        Header: <WebString webStringKey="tp.table.usermanagement.role" />,
        accessor: (entity) => accessorForRoleAndScope(entity),
        Cell: (props) => <RoleValues {...props} />,
        filterable: true,
        Filter: (filterdata) => (
            <DropdownFilter
                webStringKeyPrefix="tp.text.role."
                filterValues={availableRoles}
                {...filterdata}
            />
        ),
        className: `tp-users__column tp-all-users-table__role-column`,
        headerClassName: `tp-all-users-table__role-header rt-sortable-column--disabled`,
        sortable: false,
        preventDefaultRowClick: true
    };
};

/**
 * Checks if any dimension has been passed down and if so, returns a customized column.
 * @param dimensions
 * @returns {*}
 */
const generateDimensionColumns = (dimensions) => {
    if (!dimensions && !isArrayNullOrEmpty(dimensions)) {
        return [];
    }

    return dimensions.map((dimension) => {
        const dimensionName = dimension.name;
        return {
            id: `${dimensionName}Dimension`,
            Header: (
                <WebString
                    webStringKey={`tp.text.dimension.${dimensionName}`}
                />
            ),
            accessor: (entity) => accessorForRoleAndScope(entity),
            Cell: (props) => <RenderDimensions {...props} />,
            filterable: true,
            Filter: (filterdata) => <SearchFilter {...filterdata} />,
            className: `tp-all-users-table__userDimension-${dimensionName}-column tp-users__column`,
            headerClassName: `tp-all-users-table__userDimension-${dimensionName}-header rt-sortable-column--disabled`,
            sortable: false,
            dimensionName
        };
    });
};

/**
 * Looks through the users and creates a column for every unique dimension. It returns the
 * statically defined columns plus the dynamically generated ones.
 * @param  possibleDimensionValues :Array(of possible Dimension objects)
 * @param availableRoles: Array of account specific roles
 */
const getUserTableColumns = (possibleDimensionValues, availableRoles) => {
    return [
        ...usersTableColumns,
        generateRoleColumn(availableRoles),
        ...generateDimensionColumns(possibleDimensionValues)
    ];
};

export {getUserTableColumns};
