import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import DisabledTextBlock from './DisabledTextBlock';
import {getLabelOfSelectedOption} from './widgetUtils';

const customStyles = {
    option: (styles, {data}) => ({
        ...styles,
        color: data.color,
        fontWeight: data.fontWeight
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #ccc',
        borderRadius: '4px'
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0.1em',
        zIndex: 1000
    })
};

const TpSearchableSelect = (props) => {
    const handleChange = (objectValue) => {
        const validFormData = objectValue
            ? mapToString(objectValue)
            : undefined;
        props.onChange(validFormData);
    };

    const allOption = {
        label: 'Select all',
        value: '*',
        color: '#005d3f',
        fontWeight: 'bold'
    };

    const mapToString = (objectValues) => {
        return Array.isArray(objectValues)
            ? objectValues.length > 0 &&
              objectValues[objectValues.length - 1].value === allOption.value
                ? enumOptionsToDisplay.map((enumValue) => enumValue.value)
                : objectValues.map((objectValue) => objectValue.value)
            : objectValues.value;
    };

    const getValueAndLabel = (stringValue, enumOptions) => ({
        value: stringValue,
        label: getLabelOfSelectedOption(enumOptions, stringValue)
    });

    const mapToObjectArray = (stringValues, enumOptions) => {
        if (!stringValues) return null;
        return Array.isArray(stringValues)
            ? stringValues.map((stringValue) =>
                  getValueAndLabel(stringValue, enumOptions)
              )
            : getValueAndLabel(stringValues, enumOptions);
    };

    const {
        disabled,
        readonly,
        value,
        placeholder,
        rawErrors,
        schema,
        options: {enumOptions, sorted}
    } = props;

    if (disabled || readonly) {
        return (
            <DisabledTextBlock
                id={props.id}
                value={getLabelOfSelectedOption(enumOptions, value)}
            />
        );
    }

    const className = `tp-searchable-select${rawErrors ? ' has-error' : ''}`;
    let enumOptionsToDisplay = enumOptions;
    if (sorted) {
        const valuesNotToBeSorted = ['other'];
        enumOptionsToDisplay.sort(function (a, b) {
            if (valuesNotToBeSorted.includes(a.value)) return 1;
            if (valuesNotToBeSorted.includes(b.value)) return -1;
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });
    }

    const isArray = schema.type === 'array';
    const listItems = isArray
        ? props.value.length === enumOptionsToDisplay.length
            ? []
            : [allOption, ...enumOptionsToDisplay]
        : enumOptionsToDisplay;

    return (
        <Select
            id={props.id}
            isMulti={isArray}
            className={className}
            onChange={(value) => handleChange(value)}
            allowSelectAll={isArray}
            isClearable={true}
            options={listItems}
            placeholder={
                <WebString
                    webStringKey={placeholder || 'cs.form.please.select'}
                />
            }
            styles={customStyles}
            value={mapToObjectArray(value, enumOptionsToDisplay)}
            classNamePrefix="tp-searchable-select"
        />
    );
};

TpSearchableSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    schema: PropTypes.shape({
        type: PropTypes.string.isRequired
    }).isRequired,
    options: PropTypes.shape({
        sorted: PropTypes.bool,
        enumOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            })
        ).isRequired
    }),
    id: PropTypes.string,
    rawErrors: PropTypes.array,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

TpSearchableSelect.defaultProps = {
    id: null,
    rawErrors: null,
    readonly: false,
    disabled: false,
    value: undefined
};

export default TpSearchableSelect;
