import React, {useEffect, useState} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import WebString from 'components/webString/WebString';
import RegistrationSection from './RegistrationSection';
import HTTPClient from 'lib/HTTPClient';
import requestOutcomeTypes from './requestOutcomeTypes';
import RegistrationOutcomeAlert from './RegistrationOutcomeAlert';
import * as settingsActions from 'store/actions/settingsActions';
import defaultAppValues from 'lib/defaultAppValues';

/**
 * A TP/BA implementation of something that should be provided by a separate BKMS Compliance System
 * subsystem.
 */

const SelfRegistrationPage = () => {
    const dispatch = useDispatch();
    const {encryptedId} = useParams();
    const [isFetching, setIsFetching] = useState(true);
    const [isSelfRegistrationEnabled, setIsSelfRegistrationEnabled] = useState(
        false
    );
    const [requestOutcomeError, setRequestOutcomeError] = useState(null);

    useEffect(() => {
        dispatch(
            settingsActions.loadRegistrationStrings(
                defaultAppValues.defaultLocale
            )
        );

        const getRegisterInfo = async () => {
            try {
                const response = await HTTPClient.get(
                    `register/${encryptedId.toUpperCase()}`
                );
                setIsFetching(false);
                setIsSelfRegistrationEnabled(response.selfRegistrationEnabled);
            } catch (e) {
                setIsFetching(false);
                setRequestOutcomeError({
                    responseStatus: e.response ? e.response.status : null,
                    responseData: e.response ? e.response.data : null
                });
            }
        };
        getRegisterInfo();
    }, []);

    const getHeader = () =>
        isFetching ? (
            <Skeleton variant={'text'} key={'skeleton-header'} />
        ) : (
            <WebString webStringKey="tp.registration.title" />
        );

    const getRegistrationOutcomeErrorAlert = () => (
        <RegistrationOutcomeAlert
            key={'self-registration-alert'}
            type={requestOutcomeTypes.ERROR}
            responseStatus={requestOutcomeError.responseStatus}
            responseData={requestOutcomeError.responseData}
        />
    );

    const getContent = () => {
        if (isFetching) {
            return [
                <Skeleton variant={'text'} key={'content-skeleton-1'} />,
                <Skeleton
                    variant={'text'}
                    width={400}
                    key={'content-skeleton-2'}
                />,
                <Skeleton
                    variant={'text'}
                    width={600}
                    key={'content-skeleton-3'}
                />,
                <Skeleton variant={'text'} key={'content-skeleton-4'} />,
                <Skeleton
                    variant={'rect'}
                    height={200}
                    key={'content-skeleton-5'}
                />
            ];
        }

        if (requestOutcomeError) {
            return getRegistrationOutcomeErrorAlert();
        } else {
            if (isSelfRegistrationEnabled) {
                return [
                    <WebString
                        webStringKey={'tp.registration.introductiontext'}
                        key={'registration-text'}
                    />,
                    <RegistrationSection
                        regId={encryptedId}
                        key={'registration-section'}
                    />
                ];
            } else {
                return (
                    <WebString webStringKey={'tp.registration.unavailable'} />
                );
            }
        }
    };

    return (
        <main className="bk-cs-content bk-cs-content-left bk-cs-content-right bk-cs-content-top">
            <div className="registration-box">
                <header className="registration-box__header-box">
                    <h2 className="registration-box__title">{getHeader()}</h2>
                </header>
                <div className="registration-box__content">{getContent()}</div>
                <footer className="registration-box__footer" />
            </div>
        </main>
    );
};

export default SelfRegistrationPage;
