import {handle} from 'redux-pack';

const CREATE_SNACK = 'CREATE_SNACK';
const REMOVE_SNACK = 'REMOVE_SNACK';

const defaultState = {
    isVisible: false,
    snackProperties: {
        id: '',
        snackType: 'info',
        message: '',
        autoHideDuration: undefined
    }
};

export const ACTION_TYPES = {
    CREATE_SNACK,
    REMOVE_SNACK
};

const snackReducer = (state = defaultState, action) => {
    const {type, snackProperties} = action;
    switch (type) {
        case 'CREATE_SNACK':
            return {
                isVisible: true,
                snackProperties
            };
        case 'REMOVE_SNACK':
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isVisible: false
                }),
                success: () => ({
                    ...defaultState
                })
            });
        default:
            return state;
    }
};

export default snackReducer;
