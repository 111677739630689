import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import TpTable from '../tables/TpTable';
import * as businessPartnersActions from 'store/actions/businessPartnersActions';
import {getBusinessPartnersColumns} from './BusinessPartnersTableColumns';
import {convertToApiSearchParams} from '../dashboard/utilities/searchParamConverter';
import TableTypes from 'types/TableTypes';
import urls from 'routes/urls';

/**
 * The table to fetch and display all business partners.
 * Provides table configuration and API call action to TpTable component.
 */
const BusinessPartnersTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {businessPartnerSection, locale, tableSettings} = useSelector(
        (state) => ({
            businessPartnerSection: state.businessPartnerSection,
            locale: state.settings.i18n.locale,
            tableSettings:
                state.sessionContext.account.tableSettings[
                    TableTypes.BUSINESS_PARTNERS
                ]
        })
    );

    const {
        allBusinessPartnersLoading: isFetchingData,
        businessPartnersTable: {businessPartners, totalPages}
    } = businessPartnerSection;

    const {pageSize: defaultPageSize, columns} = tableSettings;

    const onTableRowClick = (row) => {
        history.push(`${urls.BUSINESS_PARTNERS_URL}/${row.businessPartnerId}`);
    };

    const fetchData = (reactTableSearchParams, locale) => {
        const apiSearchParams = convertToApiSearchParams(
            reactTableSearchParams,
            locale
        );
        dispatch(
            businessPartnersActions.getAllBusinessPartners(apiSearchParams)
        );
    };

    const tableColumns = getBusinessPartnersColumns(columns);

    const mappedStateAndProps = {
        data: businessPartners,
        isFetchingData,
        totalPages,
        locale,
        defaultPageSize,
        tableColumns,
        fetchData,
        onTableRowClick,
        titleWebstringKey: 'tp.table.businesspartners.title',
        descriptionWebstringKey: 'tp.table.businesspartners.text',
        defaultSorted: [
            {
                id: 'bpId',
                desc: true
            }
        ]
    };
    return <TpTable {...mappedStateAndProps} />;
};

export default BusinessPartnersTable;
