import HTTPClient from 'lib/HTTPClient';
import {ACTION_TYPES as ALERTS_ACTIONS} from 'store/reducers/alertReducer';
import StatusTypes from 'types/StatusTypes';

/**
 * clear all alerts from store
 * @returns {{type}}
 */
const clearDailyAlerts = () => ({
    type: ALERTS_ACTIONS.CLEAR_ALL_ALERTS
});

/**
 * Load daily alerts
 * @param {number} dailyAlertsId
 * @returns {{type, promise: axios.Promise, meta: *}}
 */
const getAlertsForId = (dailyAlertsId) => ({
    type: ALERTS_ACTIONS.LOAD_DAILY_ALERTS,
    promise: HTTPClient.get(`alerts/${dailyAlertsId}`)
});

const getAllAlerts = (apiSearchParams, reactTableSearchParams) => ({
    type: ALERTS_ACTIONS.LOAD_ALL_ALERTS,
    promise: HTTPClient.get('alerts', apiSearchParams),
    meta: {
        searchParams: reactTableSearchParams
    }
});

/**
 * Save / Autosave action
 * @param {number} alertsId
 * @param {object} alerts
 * @param {object} meta
 * @returns {{type, promise: axios.Promise, meta: *}}
 * @private
 */
const _saveAlertsAsync = (alertsId, alerts, meta) => ({
    type: ALERTS_ACTIONS.SAVE_DAILY_ALERTS,
    promise: HTTPClient.put(`alerts/${alertsId}`, alerts),
    meta
});

/**
 * autosave / Zwischenspeichern
 * @param {object} alerts
 * @param {object} meta
 */
const autosaveAlerts = (alerts, meta) => {
    const alertsId = alerts.alertsAssessmentId;
    const alertsToSave = {
        formData: alerts.formData || {},
        processingStatus: StatusTypes.Alerts.Processing.WAITING
    };
    return _saveAlertsAsync(alertsId, alertsToSave, meta);
};

/**
 * full save with BE validation
 * @param {object} alerts
 * @param {func} homePageRedirect function to redirect the user back to start page
 * @param {object} meta
 */
const saveAlerts = (alerts, homePageRedirect, meta) => {
    const alertsId = alerts.alertsAssessmentId;
    const alertsToSave = {
        formData: alerts.formData || {},
        processingStatus: StatusTypes.Alerts.Processing.COMPLETED
    };
    const metaWithRedirect = {
        ...meta,
        onSuccess: homePageRedirect
    };
    return _saveAlertsAsync(alertsId, alertsToSave, metaWithRedirect);
};

/**
 * Keep the formData up to date in redux, for saving
 * @param  {object}formData
 * @returns {{type, formData: *}}
 */
const updateAlertFormData = (formData) => ({
    type: ALERTS_ACTIONS.UPDATE_ALERT_FORMDATA,
    formData: formData
});

export {
    clearDailyAlerts,
    getAlertsForId,
    getAllAlerts,
    autosaveAlerts,
    saveAlerts,
    updateAlertFormData
};
