import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TpButton from 'components/common/buttons/TpButton';
import HTTPClient from 'lib/HTTPClient';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import WebString from 'components/webString/WebString';
import * as snackActions from 'store/actions/snackActions';

const possibleUserStates = {
    active: 'active',
    inactive: 'inactive',
    unconfirmed: 'unconfirmed',
    unknown: 'unknown'
};

const resendLinkStates = [possibleUserStates.unconfirmed];

export const UserStatusCell = ({
    userId,
    onResendLinkClick,
    value: userStatus
}) => {
    return (
        <React.Fragment>
            <WebString
                className={'bk-cs-block'}
                webStringKey={`tp.table.usermanagement.userstatus.${userStatus}`}
            />
            {resendLinkStates.includes(userStatus) ? (
                <TpButton
                    id={`tp-btn-send-registration-email__${userId}`}
                    onClick={onResendLinkClick}
                    webStringKey={`tp.usermanagement.sendregistrationmail`}
                    isDefaultClass={false}
                    className="bk-cs-button-secondary"
                />
            ) : (
                ''
            )}
        </React.Fragment>
    );
};

UserStatusCell.propTypes = {
    userId: PropTypes.number.isRequired,
    value: PropTypes.oneOf(Object.values(possibleUserStates)).isRequired,
    onResendLinkClick: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    createSuccessSnack: () => {
        dispatch(
            snackActions.createTranslatedSnack(
                `snack-send-registration-email-success`,
                TpSnackbarTypes.SUCCESS,
                `tp.usermanagement.sendregistrationmail.success`
            )
        );
    },
    createFailureSnack: () => {
        dispatch(
            snackActions.createTranslatedSnack(
                `snack-send-registration-email-failure`,
                TpSnackbarTypes.ERROR,
                `tp.usermanagement.sendregistrationmail.failure`
            )
        );
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const userId = ownProps.row ? ownProps.row.userId : ownProps.userId;
    return {
        ...ownProps,
        userId,
        onResendLinkClick() {
            HTTPClient.post(`users/${userId}/send-registration-email`, null)
                .then(dispatchProps.createSuccessSnack)
                .catch(dispatchProps.createFailureSnack);
        }
    };
};

export default connect(null, mapDispatchToProps, mergeProps)(UserStatusCell);
