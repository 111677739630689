import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import UserFeatureTypes from 'types/UserFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import BusinessPartnersPage from 'components/businesspartners/BusinessPartnersPage';
import BusinessPartnerDetailsPage from 'components/businessPartnerDetails/BusinessPartnerDetailsPage';
import NotFound from 'components/common/errorHandling/NotFound';
import AppTypeToggle from 'components/appTypeToggle/AppTypeToggle';
import AppTypes from 'types/AppTypes';

const BusinessPartnersRoute = () => {
    const {path} = useRouteMatch();
    return (
        <AppTypeToggle
            requiredAppTypes={[AppTypes.TP]}
            defaultChildren={<NotFound />}>
            <UserFeatureToggle
                requiredFeatureOneOf={[
                    UserFeatureTypes.ASSESSMENT,
                    UserFeatureTypes.AUDIT
                ]}
                defaultChildren={<NotFound />}>
                <Switch>
                    <Route
                        exact={true}
                        path={`${path}/:businessPartnerId`}
                        component={BusinessPartnerDetailsPage}
                    />
                    <Route path={path} component={BusinessPartnersPage} />
                </Switch>
            </UserFeatureToggle>
        </AppTypeToggle>
    );
};

export default BusinessPartnersRoute;
