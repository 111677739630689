import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import WebString from 'components/webString/WebString';

const TpLink = ({
    id,
    to,
    className,
    onClick,
    asButton,
    webStringKey,
    iconClassName,
    external,
    children
}) => {
    const iconElement = iconClassName ? (
        <span className={`bk-cs-element-right ${iconClassName}`} />
    ) : null;

    const classNameValue = `${
        asButton ? 'bk-cs-button bk-cs-button--wide ' : ''
    }${className || ''}`.trim();

    const content = (
        <>
            {iconElement}
            <WebString webStringKey={webStringKey} />
            {children}
        </>
    );

    const linkProps = {
        id
    };

    if (classNameValue) {
        linkProps.className = classNameValue;
    }

    if (external) {
        return (
            <a {...linkProps} href={to}>
                {content}
            </a>
        );
    }

    if (onClick) {
        linkProps.onClick = onClick;
    }

    if (!to) {
        linkProps.className = linkProps.className || 'bk-cs-pointer';
        return <span {...linkProps}>{content}</span>;
    }

    linkProps.to = to;

    return <Link {...linkProps}>{content}</Link>;
};

TpLink.propTypes = {
    id: PropTypes.string.isRequired,
    webStringKey: PropTypes.string.isRequired,
    to: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    iconClassName: PropTypes.string,
    asButton: PropTypes.bool,
    external: PropTypes.bool,
    children: PropTypes.any
};

TpLink.defaultProps = {
    to: null,
    className: '',
    onClick: null,
    iconClassName: '',
    asButton: false,
    external: false,
    children: null
};

export default TpLink;
