import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TpButton from './buttons/TpButton';
import WebString from 'components/webString/WebString';

/**
 * Modal dialog / 'popup' box.
 * Fades in and out on open and close.
 * Renders children components as content,
 * and set of buttons based on props.
 * Optionally render close icon in top right of header.
 */
class TpPopup extends React.Component {
    static propTypes = {
        // is used to handle show/hide effect from the parent component
        onClose: PropTypes.func.isRequired,

        headerInfo: PropTypes.shape({
            webStringKey: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['generic', 'warning', 'info']),
            showCloseButton: PropTypes.bool,
            className: PropTypes.string
        }),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                webStringKey: PropTypes.string,
                className: PropTypes.string,
                action: PropTypes.func
            })
        ),
        size: PropTypes.oneOf(['dialog', 'small', 'large', 'full']),
        children: PropTypes.any,
        className: PropTypes.string
    };

    static defaultProps = {
        headerInfo: {
            webStringKey: 'cs.text.alert.warning',
            type: 'warning',
            showCloseButton: true
        },
        size: 'dialog',
        buttons: []
    };

    constructor(props) {
        super(props);
        this.state = {overlayVisibilityClass: 'cs-fade-in'};
    }

    closePopup = () => {
        this.setState({overlayVisibilityClass: 'cs-fade-out'}, () => {
            setTimeout(() => {
                this.props.onClose();
            }, 500);
        });
    };

    handleButtonClick = (actionFunc) => {
        if (actionFunc) {
            actionFunc();
        }
        this.closePopup();
    };

    render() {
        const {headerInfo, buttons} = this.props;
        let header;

        const buttonElements = buttons.map((button, index) => (
            <TpButton
                webStringKey={button.webStringKey}
                onClick={() => this.handleButtonClick(button.action)}
                className={`bk-cs-margin-horiz--sm ${
                    button.className || ''
                }`.trim()}
                id={button.id}
                key={index}
            />
        ));

        if (headerInfo) {
            header = (
                <div
                    className={`cs-popup__header cs-popup__header--${headerInfo.type} ${headerInfo.className}`}>
                    <span
                        className={`cs-popup__header__icon cs-popup__header__icon--left cs-popup__header__icon--${headerInfo.type}`}
                    />
                    <WebString webStringKey={headerInfo.webStringKey} />
                    {headerInfo.showCloseButton ? (
                        <span
                            onClick={this.closePopup}
                            className="cs-popup__header__icon cs-popup__header__icon--close cs-popup__header__icon--right bk-cs-clickable"
                        />
                    ) : null}
                </div>
            );
        }

        return (
            //For the overlay to display correctly on top of the other elements we create a portal
            // which renders the modal at the root level of the DOM tree.
            ReactDOM.createPortal(
                <div
                    className={`${this.state.overlayVisibilityClass} cs-overlay`}>
                    <div
                        className={`cs-popup cs-popup--${this.props.size} ${this.props.className}`}>
                        {header}
                        <div className="cs-popup__body">
                            <div className="cs-popup__content">
                                {this.props.children}
                            </div>
                            <div className="cs-popup__buttons">
                                {buttonElements}
                            </div>
                        </div>
                    </div>
                </div>,
                document.querySelector('#top-level-modal')
            )
        );
    }
}

export default TpPopup;
