import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import Loader from 'components/loader/CircularLoader';
import {ProposalForm} from 'components/tpForm/TpForm';
import TextOnlyFieldTemplate from './textOnly/TextOnlyFieldTemplate';
import TextOnlyArrayField from './textOnly/TextOnlyArrayField';
import textOnlyWidgetsBundle from '../widgets/textOnlyWidgets';
import FeatureTypes from 'types/FeatureTypes';

/**
 * Loads and displays an already completed form.
 * Calls the BE form service to load the form schema, ui schema and form data.
 * Renders either as a disabled form, or as structured text, depending on textOnly prop.
 */

export const FormTransclusionField = (props) => {
    const dispatch = useDispatch();
    const [taskData, setTaskData] = useState(null);

    const proposalId = props.proposalId || props.formContext.proposalId;

    useEffect(() => {
        const getTaskData = async () => {
            try {
                const response = await HTTPClient.get(
                    `proposals/${proposalId}/forms/${props.uiSchema.formTypeName}`
                );
                setTaskData(response);
            } catch (error) {
                dispatch({type: 'TRANSCLUDE_TASK_FAILED', error});
            }
        };
        getTaskData();
    }, []);

    if (!taskData) {
        return <Loader />;
    }

    let customFieldTemplate = undefined;
    let customFields = {};
    let customWidgets = {};

    if (props.uiSchema.textOnly) {
        customFieldTemplate = TextOnlyFieldTemplate;
        customFields = {
            ArrayField: TextOnlyArrayField,
            formTransclusionField: () => null
        };
        customWidgets = textOnlyWidgetsBundle;
    }

    const {
        formData,
        formDefinition: {formSchema, uiSchema, formId}
    } = taskData;

    return (
        <ProposalForm
            id={formId}
            featureType={FeatureTypes.PROPOSAL}
            featureId={proposalId}
            disabled={true}
            hideBorder={true}
            renderAsDiv={true}
            showSubmitButton={false}
            formData={formData}
            schema={formSchema}
            uiSchema={uiSchema}
            customFields={customFields}
            customWidgets={customWidgets}
            customFieldTemplate={customFieldTemplate}
        />
    );
};

FormTransclusionField.propTypes = {
    uiSchema: PropTypes.shape({
        formTypeName: PropTypes.string.isRequired,
        hideHeader: PropTypes.bool,
        textOnly: PropTypes.bool
    }).isRequired,
    proposalId: PropTypes.number,
    formContext: PropTypes.object
};
FormTransclusionField.defaultProps = {
    proposalId: null,
    uiSchema: {
        hideHeader: false,
        textOnly: false
    },
    formContext: {}
};

const FormTransclusionFieldWithStateFromStore = (props) => {
    const proposalId = useSelector((state) => state.process.proposal.id);
    const allProps = {
        ...props,
        proposalId
    };
    return <FormTransclusionField {...allProps} />;
};

export default FormTransclusionFieldWithStateFromStore;
