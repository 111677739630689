import React from 'react';
import PropType from 'prop-types';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import {
    toggleContainer,
    unregisterContainer,
    registerContainer
} from 'store/actions/formActions';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import WebString from 'components/webString/WebString';
import IconButtonOpenClose from 'components/tpForm/fields/iconButtons/IconButtonOpenClose';

/**
 * Represents a Dow Jones risk alert entry.
 * Renders a tbody containing a tr with the display fields and an expand button
 * which opens the user input form fields below.
 */
export class RiskAlertTableEntry extends React.Component {
    static propTypes = {
        formStringResources: PropType.instanceOf(Map).isRequired,
        schema: PropType.object.isRequired,
        uiSchema: PropType.object.isRequired,
        idSchema: PropType.object.isRequired,
        formData: PropType.object.isRequired,
        displayRiskProfileOnClick: PropType.func.isRequired,
        toggleContainer: PropType.func.isRequired,
        unregisterContainer: PropType.func.isRequired,
        registerContainer: PropType.func.isRequired,
        id: PropType.string.isRequired,
        isContainerOpen: PropType.bool.isRequired
    };

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.formStringResources !== this.props.formStringResources ||
            nextProps.isContainerOpen !== this.props.isContainerOpen ||
            nextProps.errorSchema !== this.props.errorSchema ||
            nextProps.formData.status !== this.props.formData.status ||
            nextProps.formData.critical !== this.props.formData.critical ||
            nextProps.formData.comment !== this.props.formData.comment
        );
    }

    componentDidMount() {
        this.props.registerContainer(this.props.idSchema['$id'], false);
    }

    componentWillUnmount() {
        this.props.unregisterContainer(this.props.idSchema['$id']);
    }

    _openProfileOnClick = () => {
        const {formData} = this.props;
        this.props.displayRiskProfileOnClick(formData.peId, formData.name);
    };

    _toggleOpenCloseOnClick = () => {
        this.props.toggleContainer(this.props.idSchema['$id']);
    };

    render() {
        const data = this.props.formData;

        const {
            isContainerOpen,
            registerContainer,
            unregisterContainer,
            toggleContainer,
            ...objectFieldProps
        } = this.props;

        const countryKey =
            data.countryCode === 'NULL'
                ? 'tp.form.dowjones.unknown'
                : `cs.text.country.${data.countryCode}`;
        const statusKey = data.status
            ? `tp.form.dowjones.status.${data.status}`
            : 'tp.form.dowjones.unprocessed';
        const criticalKey =
            data.critical === null
                ? 'tp.form.dowjones.unprocessed'
                : data.critical
                ? 'cs.text.yes'
                : 'cs.text.no';

        return (
            <tbody>
                <tr>
                    <td className="tp-risk-alerts__table-cell">
                        {data.riskFlags}
                    </td>
                    <td className="tp-risk-alerts__table-name-cell">
                        <span
                            id={`risk-alert-profile-${data.peId}`}
                            onClick={this._openProfileOnClick}
                            className="tp-risk-alerts__table-link">
                            {data.name}
                        </span>
                    </td>
                    <td className="tp-risk-alerts__table-cell">
                        <WebString webStringKey={countryKey} />
                    </td>
                    <td className="tp-risk-alerts__table-cell">
                        {data.matchPct}%
                    </td>
                    <td className="tp-risk-alerts__table-cell">
                        <WebString webStringKey={statusKey} />
                    </td>
                    <td className="tp-risk-alerts__table-cell">
                        <WebString webStringKey={criticalKey} />
                    </td>
                    <td className="tp-risk-alerts__table-cell">
                        <IconButtonOpenClose
                            id={`btn-${this.props.id}`}
                            isOpen={isContainerOpen}
                            onClick={this._toggleOpenCloseOnClick}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="5">
                        <Collapse isOpened={isContainerOpen}>
                            <ObjectField {...objectFieldProps} />
                        </Collapse>
                    </td>
                </tr>
            </tbody>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isContainerOpen: !!state.form.containers.find(
        (c) => c.id === ownProps.idSchema['$id'] && c.isOpen === true
    ),
    formStringResources: state.settings.i18n.formStringResources
});

const mapDispatchToProps = (dispatch) => ({
    toggleContainer(id) {
        dispatch(toggleContainer(id));
    },
    registerContainer(id, isOpen) {
        dispatch(registerContainer(id, isOpen));
    },
    unregisterContainer(id) {
        dispatch(unregisterContainer(id));
    },
    onError(error, type) {
        dispatch({type, error});
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RiskAlertTableEntry);
