import React from 'react';
import PropTypes from 'prop-types';
import TpButton from './TpButton';
import TpPopup from '../TpPopup';

/**
 * Render a button (or link via style).
 * When clicked, the children are displayed in a modal window with a fade-in animation.
 * When closed via callpack prop, the windows is hidden with a fade-out animation.
 * Note, the modal window is always present, but is visually hidden or displayed, in order to
 * allow the animations.
 * @param {string} props.webStringKey - webstring key for the button text
 * @param {string} props.className - CSS class for button
 * @param {bool} props.asLink - whether to render this as link, and not as button
 */
export default class TpPopupButton extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        popupConf: PropTypes.object.isRequired,
        webStringKey: PropTypes.string.isRequired,
        children: PropTypes.object.isRequired,
        asLink: PropTypes.bool,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        webStringClassName: PropTypes.string
    };

    static defaultProps = {
        asLink: false,
        className: '',
        iconClassName: '',
        webStringClassName: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            showPopUp: false,
            baseClass: props.asLink
                ? 'bk-cs-link'
                : 'bk-cs-element-right bk-cs-button bk-cs-button--wide'
        };
    }
    UNSAFE_componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _openPopup = () => {
        this.setState({showPopUp: true});
    };

    _closePopup = () => {
        if (this._isMounted) {
            this.setState({showPopUp: false});
        }
    };

    render() {
        const {
            id,
            iconClassName,
            webStringKey,
            className,
            popupConf,
            children,
            webStringClassName
        } = this.props;

        return (
            <div className="bk-cs-inline-block">
                <TpButton
                    id={id}
                    iconClassName={iconClassName}
                    webStringKey={webStringKey}
                    webStringClassName={webStringClassName}
                    className={`${className} ${this.state.baseClass}`.trim()}
                    onClick={this._openPopup}
                    isDefaultClass={false}
                />
                {this.state.showPopUp ? (
                    <TpPopup {...popupConf} onClose={this._closePopup}>
                        {children}
                    </TpPopup>
                ) : null}
            </div>
        );
    }
}
