const UserFeatureTypes = {
    PROPOSAL: 'proposal',
    ASSESSMENT: 'assessment',
    ALERTS: 'alerts',
    EXTERNAL: 'external',
    USER_MANAGEMENT: 'user_management',
    AUDIT: 'audit',
    AD_HOC_CHECK: 'ad_hoc_check'
};

export default UserFeatureTypes;
