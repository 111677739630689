import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import ReportGeneration from 'components/reportGeneration/ReportGeneration';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserFeatureTypes from 'types/UserFeatureTypes';
import AccountFeatureToggle from 'components/accountFeatureToggle/AccountFeatureToggle';
import AccountFeatureTypes from 'types/AccountFeatureTypes';
import NotFound from 'components/common/errorHandling/NotFound';

const ReportsRoute = () => {
    const {path} = useRouteMatch();
    return (
        <UserFeatureToggle
            requiredFeatureOneOf={[
                UserFeatureTypes.ASSESSMENT,
                UserFeatureTypes.AUDIT
            ]}
            defaultChildren={<NotFound />}>
            <AccountFeatureToggle
                requiredAccountFeature={AccountFeatureTypes.GENERATE_REPORTS}
                defaultChildren={<NotFound />}>
                <Route path={path} component={ReportGeneration} />
            </AccountFeatureToggle>
        </UserFeatureToggle>
    );
};

export default ReportsRoute;
