import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import StringPlaceholder from 'lib/StringPlaceholder';

const RiskAlertsBPRiskCount = ({count}) => {
    const countPlaceholder = new StringPlaceholder('count', count);
    return (
        <WebString
            webStringKey="tp.form.dowjones.riskcount.title"
            id="risk-alert-count"
            placeholder={countPlaceholder}
            className="tp-risk-alerts__heading"
        />
    );
};

RiskAlertsBPRiskCount.propTypes = {
    count: PropTypes.number
};

RiskAlertsBPRiskCount.defaultProps = {
    count: 0
};

export default RiskAlertsBPRiskCount;
