import jsonQ from 'jsonq';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';
import isempty from 'lodash.isempty';
import set from 'lodash.set';
import has from 'lodash.has';
import unset from 'lodash.unset';
import last from 'lodash.last';
import head from 'lodash.head';
import drop from 'lodash.drop';
import dropRight from 'lodash.dropright';
import includes from 'lodash.includes';

/**
 * Functions to transform schema, uiSchema and formData from the back-end into exactly what we need for
 * the front-end, for use with react-jsonschema-form.
 * Uses a mixture of jsonQ and lodash for traversing, querying and updating the JSON schemas.
 */

/**
 * Find key which contains element path
 * @param {Array} elementPath
 * @returns {string} the name of the containing property
 */
const _getParentElementName = (elementPath) => {
    if (elementPath.length > 1) {
        return elementPath[elementPath.length - 2];
    }
};

/**
 * Return path of parent as array
 * @param {Array} elementPath
 * @returns {Array}
 */
const _getParentElementPath = (elementPath) => {
    if (elementPath.length > 1) {
        return elementPath.slice(0, elementPath.length - 1);
    }

    return [];
};

/**
 * Return path of parent as string
 * @param {string} path
 * @returns {string}
 */
const _getParentPath = (path) =>
    _getParentElementPath(path.split('.')).join('.');

/**
 * return path of parent of parent as string
 * @param {string} path
 * @returns {string}
 */
const _getGrandparentPath = (path) => _getParentPath(_getParentPath(path));

/**
 * Delete all instances of the key from the schema
 * @param {Object} schema
 * @param {string} key
 * @returns {Object} the schema with the instances of the key removed
 */
const _deleteKeyFromSchema = (schema, key) => {
    const schemaQ = jsonQ(schema);

    const jsonQKeyPath = schemaQ.jsonQ_path[key];

    if (jsonQKeyPath) {
        jsonQKeyPath.forEach((el) => {
            unset(schemaQ.jsonQ_root, el.path);
        });
    }

    return schemaQ.value()[0];
};

/**
 * Remove any references to the _[...]oneOfType in 'required' sections
 * @param {Object} schema
 * @returns {Object} transformed schema
 */
const _deleteOneOfTypeRequiredFieldsFromSchema = (schema) => {
    const schemaQ = jsonQ(schema);
    const requiredFields = schemaQ.find('required');

    // iterate over all 'required' sections
    for (
        let fieldsIndex = 0;
        fieldsIndex < requiredFields.length;
        fieldsIndex++
    ) {
        const requiredField = requiredFields.nthElm(fieldsIndex);

        // we only want the array-type required fields, not field-type
        if (typeof requiredField === 'boolean') {
            continue;
        }

        // take writable copy
        const requiredFieldCopy = requiredField.slice();

        // iterate over all entries in 'required' section
        for (
            let fieldIndex = 0;
            fieldIndex < requiredField.length;
            fieldIndex++
        ) {
            const field = requiredField[fieldIndex];
            // if entry ends in OneOfType

            if (/OneOfType$/.test(field)) {
                // remove entry from section
                requiredFieldCopy.splice(fieldIndex, 1);
            }
        }
        // replace the 'required' section with the modified version
        if (
            Array.isArray(requiredFieldCopy) &&
            requiredFieldCopy.length === 0
        ) {
            unset(schema, requiredFields.jsonQ_current[fieldsIndex].path);
        } else {
            set(
                schema,
                requiredFields.jsonQ_current[fieldsIndex].path,
                requiredFieldCopy
            );
        }
    }

    return schema;
};

/**
 * Delete all instances of keys from back-end which break our validation:
 * additionalProperties, required fields on oneOf fields
 * @param {Object} schema
 * @returns {Object} transformed schema
 */
const _removeAdditionalProperties = (schema) => {
    const clonedSchema = clonedeep(schema);
    const cleanedSchema = _deleteKeyFromSchema(
        clonedSchema,
        'additionalProperties'
    );

    return _deleteOneOfTypeRequiredFieldsFromSchema(cleanedSchema);
};

/**
 * Return the field name from a string path, i.e. the word after the last '.'
 * @param {string} path
 * @returns {string} field name
 */
const _getFieldName = (path) => {
    const lastWord = path.substring(path.lastIndexOf('.') + 1);

    return lastWord || path;
};

/**
 * Tests if this is field in the required section
 * @param {Object} schema
 * @param {string} fieldPath
 * @returns {boolean}
 */
const _isFieldRequired = (schema, fieldPath) => {
    const fieldName = _getFieldName(fieldPath);
    const rootPath = _getGrandparentPath(fieldPath);
    const requiredPath = rootPath ? `${rootPath}.required` : 'required';
    const required = get(schema, requiredPath);

    return includes(required, fieldName);
};

/**
 * Remove any path elements called 'properties'.
 * Utility method to assist converting between schema and uiSchema or formData.
 * @param {*[]} path path elements as array
 */
const _removeProperties = (path) =>
    path.filter((element) => element !== 'properties');

/**
 * Read the corresponding field in uiSchema, and return the "ui:type" if present
 * Checks to see if this is a oneOf in definitions.
 * @param {string[]} fieldPath
 * @param {Object}schemaQ
 * @param {Object} uiSchema
 * @returns {string} the uiType, if given
 */
const _findUiTypeForPath = (fieldPath, schemaQ, uiSchema) => {
    const initialPathElement = fieldPath[0];

    if (initialPathElement === 'definitions') {
        // extract reference from path
        const referencePathElement = fieldPath[1];
        const referenceString = `#/definitions/${referencePathElement}`;
        // find this reference in main schema properties
        const matchedRef = schemaQ
            .find('$ref', function () {
                return this.toString() === referenceString;
            })
            .path();
        const oneOfIndex = matchedRef.indexOf('oneOf');
        const InitialPath = matchedRef.slice(0, oneOfIndex);
        const containerFieldName = last(InitialPath);
        const fieldName = last(fieldPath);

        fieldPath = [
            ...InitialPath,
            `${containerFieldName}OneOfRef`,
            fieldName
        ];
    }

    const uiSchemaPath = _removeProperties(fieldPath);
    return get(uiSchema, uiSchemaPath);
};

/**
 * Sets the name with the value to the path of the provided schema,
 * if there is no property with that name already.
 * @param {Object} schema
 * @param {string[]} path
 * @param {string} name
 * @param {string} value
 * @returns {undefined}
 */
const _setIfAbsent = (schema, path, name, value) => {
    const fullpath = [...path, name];
    if (!has(schema, fullpath)) {
        set(schema, fullpath, value);
    }
};

/**
 * Sets the provided defaultValue as the the 'default' property of the field,
 * when the predicate returns true.
 * @param {Object} schema - The JSON schema of the form.
 * @param {string} fieldPath - The JSON Schema field path to treat.
 * @param {function} predicate
 * @param {*} defaultValue - The value to set if predicate returns true.
 * @return {Object} The outcome JSON Schema.
 */
const _setDefaultInSchemaField = (
    schema,
    fieldPath,
    predicate,
    defaultValue
) => {
    const modifiedSchema = clonedeep(schema);
    const propertyPath = fieldPath.substring(0, fieldPath.lastIndexOf('.'));
    const defaultPath = `${propertyPath}.default`;

    if (predicate(modifiedSchema, propertyPath, defaultPath)) {
        set(modifiedSchema, defaultPath, defaultValue);
    }

    return modifiedSchema;
};

/**
 * Adds default `null` field to the specified `fieldPath` in the schema.
 * It only adds the default if the field is required in the schema and it does not
 * have a 'default' value set.
 * @param {Object} schema - The JSON formSchema to add the default field to.
 * @param {Object} fieldPath - The schema field to add default to.
 * @returns {Object} The manipulated input `schema`.
 */
const _addDefaultToBooleanField = (schema, fieldPath) => {
    return _setDefaultInSchemaField(
        schema,
        fieldPath,
        (modifiedSchema, propertyPath, defaultPath) =>
            _isFieldRequired(modifiedSchema, propertyPath) &&
            !has(modifiedSchema, defaultPath),
        null
    );
};

/**
 * Add default="" to any text input fields which are:
 * * type="string",
 * * do not already have a default value.
 * @param {Object} schema the whole schema
 * @param {string} fieldPath the fieldPath to the field as dot separated string
 * @returns {Object} The outcome JSON Schema.
 */
const _addDefaultToStringField = (schema, fieldPath) => {
    return _setDefaultInSchemaField(
        schema,
        fieldPath,
        (modifiedSchema, propertyPath, defaultPath) => {
            return (
                !has(modifiedSchema, defaultPath) &&
                !has(modifiedSchema, `${propertyPath}.enum`)
            );
        },
        ''
    );
};

/**
 * Add default=0, minimum=1 to mandatory date (widget type 'cs-date') fields or
 * default='' to other mandatory field.
 * If the default or minimum properties have already been set, nothing is added.
 * Date fields are numeric, as timestamps. By using a default 0 and minimum
 * we can enforce mandatory field validation, which would otherwise not be possible.
 *
 * @param {Object} entry - The JSON field
 * @param {Object} schema - The whole schema
 * @param {Object} schemaQ - jsonQ representation of schema
 * @param {Object} uiSchema - the UI Schema
 * @returns {Object} The modified JSON schema.
 */
const _addDefaultToNumberField = (entry, schema, schemaQ, uiSchema) => {
    const modifiedSchema = clonedeep(schema);
    const fieldName = entry.path[entry.path.length - 2];
    const fieldPath = dropRight(entry.path, 1);
    const requiredFieldsPath = [...dropRight(fieldPath, 2), 'required'];
    const requiredFields = get(modifiedSchema, requiredFieldsPath);

    if (requiredFields && requiredFields.includes(fieldName)) {
        _setIfAbsent(modifiedSchema, fieldPath, 'default', undefined);
    }

    return modifiedSchema;
};

/**
 * Adds the `default` property to a string, number and
 * boolean type schema field. It uses the functions
 * `_addDefaultToStringField`, `_addDefaultToNumberField` and
 * `_addDefaultToBooleanField`.
 *
 * @param {Object} schema - The JSON Schema to inject the default property into.
 * @param {Object} uiSchema - The uiSchema for the JSON Schema
 * @returns {Object} transformed schema
 */
const _addDefaultPropertyToSchemaFields = (schema, uiSchema) => {
    let modifiedSchema = clonedeep(schema);
    const schemaQ = jsonQ(modifiedSchema);

    for (const entry of schemaQ.jsonQ_path.type || []) {
        const path = entry.path.join('.');
        const pathType = get(modifiedSchema, path);

        if (pathType === 'string') {
            modifiedSchema = _addDefaultToStringField(modifiedSchema, path);
        } else if (pathType === 'number') {
            modifiedSchema = _addDefaultToNumberField(
                entry,
                modifiedSchema,
                schemaQ,
                uiSchema
            );
        } else if (pathType === 'boolean') {
            modifiedSchema = _addDefaultToBooleanField(modifiedSchema, path);
        }
    }

    return modifiedSchema;
};

/**
 * Dynamically set the field order using ui:order in uiSchema for oneOf fields.
 * @param {Object} uiSchema
 * @param {Object} formData
 * @param {Object} oneOfs
 * @returns {Object} transformed uiSchema
 */
const transformUiSchemaForOneOf = (uiSchema, formData, oneOfs) => {
    if (!oneOfs || oneOfs.length === 0) {
        return uiSchema;
    }

    const transformedUiSchema = clonedeep(uiSchema);

    // iterate over oneOfs
    oneOfs.forEach((oneOf) => {
        // find key matching [oneOf.path] in formData
        const oneOfKey = oneOf.formDataPath;
        const formDataMatch = get(formData, oneOfKey);

        // if found, build key of [oneOfPath].[oneOf.name]:[formData.value]
        if (formDataMatch) {
            const uiSchemaKey = `${oneOf.formDataContainerPath}:${formDataMatch}`;

            // read key and copy value into transformedUiSchema with key [oneOfPath].[oneOf.name]
            const uiSchemaValue = get(uiSchema, uiSchemaKey);

            if (uiSchemaValue) {
                set(
                    transformedUiSchema,
                    oneOf.formDataContainerPath,
                    uiSchemaValue
                );
            }
        }
    });

    return transformedUiSchema;
};

/**
 * Find the path of the $ref reference in the definitions section of the form schema
 * @param {Object} _schemaQ jsonQ representation of the form schema
 * @param {string} _reference the reference value
 * @returns {string} the path to the referred definition, if found
 */
const _findRefPath = (_schemaQ, _reference) => {
    let refPath;
    const refIndex = _schemaQ.find('$ref').value().indexOf(_reference);

    if (refIndex !== -1) {
        _schemaQ.find('$ref').each((index, path) => {
            if (index === refIndex) {
                refPath = path;
            }
        });
    }

    return refPath;
};

/**
 * build convenience data structure for things relating to oneOf names
 * @param schemaOneOfPath
 * @returns {{field: string, selector: string, ref: string, type: string}}
 * @private
 */
const _buildOneOfNames = (schemaOneOfPath) => {
    const fieldName = _getParentElementName(schemaOneOfPath);
    const selectorName = `${fieldName}OneOf`;

    return {
        field: fieldName,
        selector: `${selectorName}`,
        ref: `${selectorName}Ref`,
        type: `_${selectorName}Type`
    };
};

/**
 * return path as array excluding 'properties'
 * @param schemaParentPath
 * @returns {*}
 * @private
 */
const _getBasicFormDataPath = (schemaParentPath, oneOfNames) => {
    const formDataParentPath = schemaParentPath
        .slice(0, -1)
        .filter((element) => element !== 'properties');
    const formDataParentPathPrefix =
        formDataParentPath.length > 0 ? `${formDataParentPath.join('.')}.` : '';
    return `${formDataParentPathPrefix}${oneOfNames.field}.${oneOfNames.selector}`;
};

/**
 * Build the formData path for the oneOf, which might be nested inside another oneOf
 * @param _schema
 * @param schemaParentPath
 * @param oneOfNames
 * @returns {string} the full formData path, with all nesting taken into account
 * @private
 */
const _buildNestedFormDataPath = (_schema, schemaParentPath, oneOfNames) => {
    const formDataPath = _getBasicFormDataPath(schemaParentPath, oneOfNames);
    let formDataPaths = formDataPath.split('.');
    const schemaQ = jsonQ(_schema);

    // is this oneOf in a reference?
    const reference = `#/definitions/${oneOfNames.field}`;
    const foundRefPath = _findRefPath(schemaQ, reference);

    // and is that in a reference?
    if (foundRefPath && head(foundRefPath) === 'definitions') {
        const ownerRef = `#/definitions/${foundRefPath[1]}`;

        // if so, find owning object
        const ownerRefPath = _findRefPath(schemaQ, ownerRef);

        // strip out 'properties', as this is not in formData
        const ownerFormDataPath = _removeProperties(ownerRefPath);

        // extract section between 'properties' and 'oneOf'
        const oneOfIndex = ownerFormDataPath.indexOf('oneOf');
        const startPath = ownerFormDataPath.slice(0, oneOfIndex);

        // remove 'definitions' from the start
        const endPath = drop(formDataPaths, 1);

        const ownerOneOfName = last(startPath);
        const ownerOneOfRefName = `${ownerOneOfName}OneOfRef`;

        // join it all together
        formDataPaths = [...startPath, ownerOneOfRefName, ...endPath];
    }

    // take the final element off, as we want the parent
    const basePath = dropRight(formDataPaths, 1).join('.');

    return {
        parent: `${basePath}`,
        oneOf: `${basePath}.${oneOfNames.selector}`,
        ref: `${basePath}.${oneOfNames.ref}`,
        type: `${basePath}.${oneOfNames.type}`
    };
};

/**
 * Return sorted list of oneOfs, with those found in properties before those in definitions
 * @param {Array} elements the unsorted list of oneOfs
 * @returns {Array} the sorted list of oneOfs
 */
const _sortByPropertiesFirst = (elements) => {
    const propertiesElements = elements.filter(
        (el) => el.path.length && el.path[0] === 'properties'
    );

    const definitionsElements = elements.filter(
        (el) => el.path.length && el.path[0] === 'definitions'
    );

    return [...propertiesElements, ...definitionsElements];
};

/**
 * Build and return the oneOf to be put into the form schema
 * @param schema
 * @param oneOfNames
 * @param enumOptions
 * @param selectedRef
 * @param containerPath
 * @param schemaParentPath
 * @returns {{type: string, required: *, properties: {}}}
 * @private
 */
const _buildSchemaOneOf = (
    schema,
    oneOfNames,
    enumOptions,
    selectedRef,
    containerPath,
    schemaParentPath
) => {
    const isRequired = _isFieldRequired(schema, containerPath);
    const requiredFieldList = isRequired ? [oneOfNames.selector] : null;
    const sectionTitle = get(schema, [...schemaParentPath, 'sectionTitle']);
    const title = get(schema, [...schemaParentPath, 'title']);
    const detailsInfo = get(schema, [...schemaParentPath, 'detailsInfo']);

    const propertiesForOneOfSelector = {
        title,
        type: 'string',
        enum: enumOptions.values,
        enumNames: enumOptions.names
    };

    if (detailsInfo) {
        propertiesForOneOfSelector.detailsInfo = detailsInfo;
    }

    const schemaOneOf = {
        type: 'object',
        properties: {
            [oneOfNames.selector]: {
                ...propertiesForOneOfSelector
            },
            [oneOfNames.ref]: selectedRef
        }
    };
    if (isRequired && requiredFieldList) {
        // required must not be undefined/null or an empty array
        schemaOneOf.required = requiredFieldList;
    }
    if (sectionTitle) {
        set(schemaOneOf, 'title', sectionTitle);
    }

    return schemaOneOf;
};

/**
 * Build the oneOf metadata object returned from the schema transformer
 * @param nestedFormPaths
 * @param oneOfNames
 * @param schemaParentPath
 * @param refs
 * @returns {{name: string, formDataPath: *, formDataContainerPath: *, containerPath: string, refPath: string, refs: *}}
 * @private
 */
const _buildOneOf = (nestedFormPaths, oneOfNames, schemaParentPath, refs) => {
    const propertyPath = schemaParentPath.slice(0, -1).join('.');
    const propertyPathPrefix = propertyPath.length ? `${propertyPath}.` : '';
    const containerPath = `${propertyPathPrefix}${oneOfNames.field}`;
    const refPath = `${containerPath}.properties.${oneOfNames.ref}`;

    return {
        name: oneOfNames.field,
        formDataPath: nestedFormPaths.oneOf,
        formDataContainerPath: nestedFormPaths.ref,
        containerPath,
        refPath,
        refs
    };
};

/**
 * Remove additionalFields properties and add defaults.
 * @param _schema
 * @param _uiSchema
 * @param _disabled
 * @returns {Object}
 * @private
 */
const _prepareSchema = (_schema, _uiSchema, _disabled) => {
    let schema = _removeAdditionalProperties(_schema);

    if (!_disabled) {
        schema = _addDefaultPropertyToSchemaFields(schema, _uiSchema);
    }
    return schema;
};

/**
 *
 * @param schemaParentPath
 * @param oneOfNames
 * @returns {string}
 * @private
 */
const _getContainerPath = (schemaParentPath, oneOfNames) => {
    const propertyPath = schemaParentPath.slice(0, -1).join('.');
    const propertyPathPrefix = propertyPath.length ? `${propertyPath}.` : '';
    return `${propertyPathPrefix}${oneOfNames.field}`;
};

/**
 * Transform the formData so that it matches the fields in the ...OneOfRef structure.
 * Also find which is the selected ...OneOfRef and return this.
 * @param _formData
 * @param nestedFormPaths
 * @param typeName
 * @param refValue
 * @param displayName
 * @returns {{formData: *, selectedRef}}
 * @private
 */
const _transformFormDataAndFindSelectedRef = (
    _formData,
    nestedFormPaths,
    typeName,
    refValue,
    displayName
) => {
    let selectedRef = {};
    const formData = clonedeep(_formData);

    const selectedFormDataValue = get(formData, nestedFormPaths.type);

    if (selectedFormDataValue === displayName) {
        selectedRef = {$ref: refValue};

        // move all data fields from e.g. partner into partner.partnerOneOfRef
        const formDataContents = get(formData, nestedFormPaths.parent);

        if (formDataContents) {
            unset(formData, nestedFormPaths.parent);
            set(formData, nestedFormPaths.ref, formDataContents);

            // rename e.g. _partnerOneOfType to partnerOneOf and move up a level
            const selectedValue = formDataContents[typeName];

            unset(formData, `${nestedFormPaths.ref}.${typeName}`);
            set(formData, nestedFormPaths.oneOf, selectedValue);
        }
    }
    return {formData, selectedRef};
};

/**
 * Safely find the enumName, if it exists. Typically used to find the webString key
 * for rendering the name of the oneOf options.
 * @param oneOfTypeSection
 * @returns {*}
 * @private
 */
const _findEnumName = (oneOfTypeSection) => {
    const enumNamesSection = oneOfTypeSection.find('enumNames');
    if (
        enumNamesSection &&
        enumNamesSection.value() &&
        enumNamesSection.value()[0] &&
        enumNamesSection.value()[0][0]
    ) {
        return enumNamesSection.value()[0][0];
    }
};

/**
 * Transform schema, uiSchema and formData with oneOf so that it works as logical conditional field.
 * react-jsonschema-form does not have good support for dynamic forms, so we transform the schemas
 * from the back-end into a 'flat' schema depending on which value is chosen for all 'oneOf' values,
 * depending on which open has been chosen by the user (i.e. in the formData).
 * @param _schema
 * @param _formData
 * @param _uiSchema
 * @param _disabled
 * @returns {*}
 */
const transformSchemas = (_schema, _formData, _uiSchema, _disabled) => {
    // remove unused fields and set some defaults:
    const schema = _prepareSchema(_schema, _uiSchema, _disabled);
    const schemaQ = jsonQ(schema);
    const oneOfElements = schemaQ.find('oneOf');

    // return early if no oneOfs to process
    if (oneOfElements.length === 0) {
        return {schema, uiSchema: _uiSchema, formData: _formData, oneOfs: []};
    }

    let formData = clonedeep(_formData || {});
    const oneOfs = [];
    const definitions = schemaQ.find('definitions');
    const sortedOneOfElements = _sortByPropertiesFirst(
        oneOfElements.jsonQ_current
    );

    sortedOneOfElements.forEach((oneOfElement) => {
        const schemaOneOfPath = oneOfElement.path;
        const schemaParentPath = _getParentElementPath(schemaOneOfPath);
        const oneOfNames = _buildOneOfNames(schemaOneOfPath);
        const nestedFormPaths = _buildNestedFormDataPath(
            _schema,
            schemaParentPath,
            oneOfNames
        );

        const enumValues = [];
        const enumNames = [];
        let selectedRef = {};
        const refs = {};

        const oneOfRefs = get(schema, schemaOneOfPath);
        for (const oneOfRef of oneOfRefs) {
            let ref;
            const refValue = oneOfRef.$ref;
            const refName = refValue.replace(/.*\//, '');
            const refProperty = definitions.find(refName);
            const oneOfTypeSection = refProperty.find(oneOfNames.type);
            const displayName = oneOfTypeSection.find('default').value()[0];

            // update to RJSF v1.5 backward compatability start
            // sometimes there is no value for the oneOf defined in the schema
            const enumNameForSelection = _findEnumName(oneOfTypeSection);
            enumValues.push(
                displayName || enumNameForSelection.split('.').pop()
            );
            enumNames.push(enumNameForSelection);
            // update to RJSF v1.5 backward compatability end

            ({
                formData,
                selectedRef: ref
            } = _transformFormDataAndFindSelectedRef(
                formData,
                nestedFormPaths,
                oneOfNames.type,
                refValue,
                displayName
            ));
            if (!isempty(ref)) {
                selectedRef = ref;
            }
            refs[displayName] = refValue;

            // delete oneOfType sections in refs, as we don't want to display these
            unset(schema, refProperty.find(oneOfNames.type).path());
        }

        const oneOf = _buildOneOf(
            nestedFormPaths,
            oneOfNames,
            schemaParentPath,
            refs
        );
        oneOfs.push(oneOf);

        const enumOptions = {
            names: enumNames,
            values: enumValues
        };
        const transformedSchemaOneOf = _buildSchemaOneOf(
            schema,
            oneOfNames,
            enumOptions,
            selectedRef,
            oneOf.containerPath,
            schemaParentPath
        );
        set(schema, oneOf.containerPath, transformedSchemaOneOf);
    });

    const uiSchema = transformUiSchemaForOneOf(_uiSchema, formData, oneOfs);

    return {schema, uiSchema, formData, oneOfs};
};

export {transformSchemas, transformUiSchemaForOneOf, _sortByPropertiesFirst};
