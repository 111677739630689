import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import UploadedFileDataRow from './UploadedFileDataRow';

/**
 * Renders the list of uploaded files, with a text title.
 * If onRemoveFile prop is null the button for delete will not be displayed in children components.
 */
class UploadedFileDataList extends React.Component {
    static propTypes = {
        filesEndpointUrlPath: PropTypes.string.isRequired,
        onRemoveFile: PropTypes.func,
        files: PropTypes.arrayOf(
            PropTypes.shape({
                fileId: PropTypes.number.isRequired,
                fileMetaData: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired
            }).isRequired
        ),
        disabled: PropTypes.bool
    };

    static defaultProps = {
        onRemoveFile: null,
        files: [],
        disabled: false
    };

    render() {
        return (
            <div className="file-upload__uploaded-list">
                <label>
                    <WebString webStringKey="cs.text.alluploadedfiles" />
                </label>
                {this.props.files.map((file, key) => (
                    <UploadedFileDataRow
                        index={key + 1}
                        key={file.fileId}
                        file={file}
                        disabled={this.props.disabled}
                        onRemoveFile={this.props.onRemoveFile}
                        filesEndpointUrlPath={this.props.filesEndpointUrlPath}
                    />
                ))}
            </div>
        );
    }
}

export default UploadedFileDataList;
