import React from 'react';
import DisabledTextBlock from './DisabledTextBlock';
import PropTypes from 'prop-types';
import WebString from '../../webString/WebString';

export const TpDisplayWebstringWidget = ({id, value}) => (
    <DisabledTextBlock id={id} value={<WebString webStringKey={value} />} />
);

TpDisplayWebstringWidget.propTypes = {
    formContext: PropTypes.shape({
        stringResources: PropTypes.object.isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
};

export default TpDisplayWebstringWidget;
