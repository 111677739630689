import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import WebString from 'components/webString/WebString';
import RiskFactorsInfoButton from './RiskFactorsInfoButton';

const columns = [
    {
        id: (entity) => `risk-factors-${entity.category.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.riskcategory" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.category} />
    },
    {
        id: (entity) => `risk-factors-${entity.title.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.riskfactor" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.title} />
    },
    {
        id: (entity) => `risk-factors-${entity.answerKey.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.value" />
        ),
        accessor: (entity) => <WebString webStringKey={entity.answerKey} />
    },
    {
        id: (entity) => `risk-factors-${entity.maxScore.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.maxscore" />
        ),
        accessor: (entity) => `${entity.maxScore}%`,
        width: 130
    },
    {
        id: (entity) => `risk-factors-${entity.score.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.score" />
        ),
        accessor: (entity) => entity.score,
        width: 75
    },
    {
        id: (entity) => `risk-factors-${entity.info.toLowerCase()}`,
        Header: (
            <WebString webStringKey="tp.riskmatrix.riskfactor.columnheader.info" />
        ),
        accessor: (entity) =>
            entity.info ? (
                <RiskFactorsInfoButton
                    popupContent={entity.info}
                    popupTitle={entity.title}
                />
            ) : null,
        width: 35
    }
];

const RiskFactors = (props) => {
    const {riskFactors} = props;

    return (
        <React.Fragment>
            <ReactTable
                className="-striped tp-risk-matrix__risk-factor-table"
                data={riskFactors}
                columns={columns}
                showPagination={false}
                defaultPageSize={riskFactors.length}
                resizable={false}
                sortable={false}
            />
            <div className="tp-risk-matrix__total-score">
                <WebString webStringKey="tp.riskmatrix.riskfactor.totalscore" />
                <span>
                    {Math.round(
                        riskFactors.reduce(
                            (acc, element) => acc + element.score,
                            0
                        ) * 100
                    ) / 100}
                </span>
            </div>
        </React.Fragment>
    );
};

RiskFactors.propTypes = {
    riskFactors: PropTypes.array.isRequired
};

export default RiskFactors;
