import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {ProposalForm} from 'components/tpForm/TpForm';
import ActionPanel from 'components/proposal/actionPanel/ActionPanel';
import NotificationPanel from 'components/notificationPanel/NotificationPanel';
import TpButton from 'components/common/buttons/TpButton';
import WebString from 'components/webString/WebString';
import ProcessLayout from 'components/common/ProcessLayout';
import FeatureTypes from 'types/FeatureTypes';
import * as processActions from 'store/actions/processActions';
import textOnlyWidgetsBundle from '../tpForm/widgets/textOnlyWidgets';
import TextOnlyFieldTemplate from '../tpForm/fields/textOnly/TextOnlyFieldTemplate';
import CircularLoader from '../loader/CircularLoader';
import StringPlaceholder from 'lib/StringPlaceholder';
import i18nUtils from 'lib/i18nUtils';
import MetaInfoBar from './metaInfoBar/MetaInfoBar';

/**
 * Display the proposal details as a text-only form.
 * Used to show imported proposals.
 */

// TODO:#34122 better error handling, it is not clear for the user what was wrong, only the default
//  error message will be shown if the user tries to access the wrong proposal
const ProposalDetails = () => {
    const {proposalId} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const stringResources = useSelector(
        (state) => state.settings.i18n.stringResources
    );
    const proposal = useSelector((state) => state.process.proposal);

    useEffect(() => {
        dispatch(processActions.getImportedProposal(proposalId));

        return () => {
            dispatch(processActions.clearProposal());
        };
    }, []);

    const homePageRedirect = () => {
        history.push('/');
    };

    if (isEmpty(proposal) || proposal.id !== proposalId) {
        return <CircularLoader overlay={true} />;
    }

    const formElement = (
        <ProposalForm
            id={proposal.formId}
            featureType={FeatureTypes.PROPOSAL}
            featureId={proposalId}
            formData={proposal.formData || {}}
            schema={proposal.formDefinition.formSchema}
            uiSchema={proposal.formDefinition.uiSchema}
            fieldData={proposal.fieldData}
            renderAsText={true}
            showSubmitButton={false}
            customWidgets={textOnlyWidgetsBundle}
            customFieldTemplate={TextOnlyFieldTemplate}
            customFormContext={{proposalId}}
        />
    );
    const approvalStatus = proposal.formData.approvalStatus.toLowerCase();
    const statusWsKey = `tp.form.proposaldetails.status.${approvalStatus}`;
    const statusText = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        statusWsKey
    );
    const statusPlaceholder = new StringPlaceholder(
        'proposalStatus',
        statusText
    );

    const actionPanel = (
        <ActionPanel
            bodyContent={
                <WebString
                    webStringKey={`tp.form.proposaldetails.action.text`}
                    placeholder={statusPlaceholder}
                />
            }
            footerContent={
                <div className="bk-cs-float-right">
                    <TpButton
                        id="buttonGo"
                        visible={true}
                        disabled={false}
                        webStringKey="tp.form.proposaldetails.action.button"
                        onClick={homePageRedirect}
                    />
                </div>
            }
        />
    );

    const errorPanel = <NotificationPanel notifications={[]} />;

    return (
        <>
            <MetaInfoBar />
            <ProcessLayout
                contentWebStringKeyBase="tp.form.proposaldetails"
                formPanel={formElement}
                errorPanel={errorPanel}
                actionPanel={actionPanel}
            />
        </>
    );
};

export default ProposalDetails;
