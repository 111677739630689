import React from 'react';
import {useHistory} from 'react-router-dom';
import TpTable from '../tables/TpTable';
import * as tpProposalActions from 'store/actions/processActions';
import {getExternalQuestionnaireTableColumns} from './utilities/tableColumns';
import TableTypes from 'types/TableTypes';
import urls from 'routes/urls';
import getTableDataFromStateAndDispatchHelper from 'components/dashboard/utilities/tablesStateWithDispatchHelper';

const ExternalQuestionnaireTable = () => {
    const history = useHistory();

    const dataFromState = getTableDataFromStateAndDispatchHelper(
        null,
        TableTypes.EXTERNAL,
        tpProposalActions.getAllExternalQuestionnaireProposals
    );

    const tableColumns = getExternalQuestionnaireTableColumns(
        dataFromState.columns,
        dataFromState.filterOptions
    );

    const onTableRowClick = (row) => {
        history.push(`${urls.EXTERNAL_QUESTIONNAIRES_URL}/${row.id}`);
    };

    const mappedStateAndProps = {
        data: dataFromState.data,
        isFetchingData: dataFromState.isFetchingData,
        searchParams: dataFromState.searchParams,
        totalPages: dataFromState.totalPages,
        locale: dataFromState.locale,
        defaultPageSize: dataFromState.defaultPageSize,
        fetchData: dataFromState.fetchData,
        tableColumns,
        onTableRowClick,
        titleWebstringKey: 'tp.table.externalquestionnaireproposals.title',
        descriptionWebstringKey: 'tp.table.externalquestionnaireproposals.text',
        defaultSorted: [
            {
                id: 'proposalIdBusinessPartner',
                desc: true
            }
        ]
    };

    return <TpTable {...mappedStateAndProps} />;
};

export default ExternalQuestionnaireTable;
