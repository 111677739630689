import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import last from 'lodash.last';
import {setTaskFromProgressBar} from 'store/actions/proposalActions';
import StatusTypes from 'types/StatusTypes';
import ProgressBarScroller from './ProgressBarScroller';

/**
 * Connected ProgressBar component.
 * builds webstring keys and click events for each task,
 * and renders with dumb ProgressBar component.
 */
class ProgressBar extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        onTaskClick: PropTypes.func.isRequired,
        currentTask: PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired
        }),
        tasks: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                status: PropTypes.string.isRequired,
                isLastTask: PropTypes.bool
            })
        ).isRequired
    };

    static defaultProps = {
        currentTask: null,
        tasks: []
    };

    static emptyFn = () => null;

    static emptyTask = {
        id: 'empty-task',
        status: 'EMPTY',
        webStringKey: '...',
        onClick: ProgressBar.emptyFn
    };

    taskClickFn = (taskId) =>
        this.props.onTaskClick(this.props.id, taskId, this.props.currentTask);

    /**
     * Append an empty task to the end if the final one is not the last task.
     * This happens when we do not yet know what all future tasks will be.
     * @param tasks
     * @returns {*}
     */
    appendFutureTasksPlaceholder(tasks) {
        if (!tasks.length) {
            return [];
        }
        if (last(tasks).isLastTask) {
            return tasks;
        } else {
            return [...tasks, ProgressBar.emptyTask];
        }
    }

    _getClickEventForTask(task) {
        if (task.status !== StatusTypes.Task.OPEN) {
            return this.taskClickFn;
        } else {
            return ProgressBar.emptyFn;
        }
    }

    /**
     * Build webstring key from task id
     * @param {string} id
     * @returns {string} webstring key
     */
    _getWebStringKey = (id) =>
        `tp.process.${id.replace(/(-|process[:0-9]*)/g, '')}.short.title`;

    _mapTask = (task) => ({
        ...task,
        onClick: this._getClickEventForTask(task),
        webStringKey: this._getWebStringKey(task.id)
    });

    render() {
        if (this.props.tasks.length === 0) {
            return null;
        }
        const tasks = this.props.tasks.map(this._mapTask);
        const tasksToDisplay = this.appendFutureTasksPlaceholder(tasks);

        const currentTaskIndex = this.props.currentTask
            ? this.props.tasks.findIndex(
                  (task) => task.id === this.props.currentTask.id
              )
            : null;

        return (
            <ProgressBarScroller
                tasks={tasksToDisplay}
                currentTaskIndex={currentTaskIndex}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onTaskClick: (proposalId, taskId, currentTask) => {
        dispatch(setTaskFromProgressBar(proposalId, taskId, currentTask));
    }
});

export default connect(null, mapDispatchToProps)(ProgressBar);
export {ProgressBar};
