import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'react-progressbar';

/**
 * Round down the number.
 * @example
 * // returns 5
 * roundDown(5.34);
 *  * @example
 * // returns 5
 * roundDown(5.74);
 *
 * @param {Number}  number - number to be rounded down
 * @returns {Number} - rounded down value for number
 */
const roundDown = (number) =>
    Math.floor(number * Math.pow(10, 0)) / Math.pow(10, 0);

/**
 * Component for displaying a progressBar as a colored (red for failing, green for succeeding) line incl. information about progress in %.
 * Contains two buttons: one to cancel and another to refresh the progress in case of failure.
 *
 * @param {Object} props
 * @returns {ReactElement} markup
 */
const PercentProgressBar = (props) => {
    const progressRounded = roundDown(props.progress);
    const percentageErrorClass = props.error
        ? ' file-upload__uploading-pct--error'
        : '';

    return (
        <div className="file-upload__progress">
            <span
                className={`file-upload__uploading-pct ${percentageErrorClass}`}>
                {progressRounded}%
            </span>
            <div className="file-upload__progressbar">
                <Progress
                    height={9}
                    color={props.error ? '#ac4329' : '#989e00'}
                    completed={progressRounded}
                />
            </div>
            {props.progress !== 100 ||
            (props.progress === 100 && props.error) ? (
                <i
                    className="file-upload__uploading-action-btns tp-percent-progress-bar__cancelicon"
                    onClick={props.onCancel}
                />
            ) : null}
            {props.error ? (
                <i
                    className="file-upload__uploading-action-btns tp-percent-progress-bar__refreshicon"
                    onClick={props.onRefresh}
                />
            ) : null}
        </div>
    );
};

PercentProgressBar.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    progress: PropTypes.number,
    error: PropTypes.bool
};

PercentProgressBar.defaultProps = {
    progress: 0,
    error: false
};

export default PercentProgressBar;
