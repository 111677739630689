import webStringUtils from 'lib/webStringUtils';

const generalProperties = {
    firstName: {
        type: 'string',
        title: 'tp.admin.createorupdateform.firstname.title',
        minLength: 1,
        maxLength: 250
    },
    lastName: {
        type: 'string',
        title: 'tp.admin.createorupdateform.lastname.title',
        minLength: 1,
        maxLength: 250
    },
    emailAddress: {
        type: 'string',
        title: 'tp.admin.createorupdateform.email.title',
        minLength: 1,
        maxLength: 254,
        pattern:
            '^([^\\s@]{1,64}@([^_+*~:;,#\'?!\\"§$%&/()=?`´²³{\\[\\]}\\s]+\\.)+([a-zA-z]+)$|)$'
    }
};
const generalPropertiesExtended = {
    id: {
        type: 'number',
        title: 'tp.admin.createorupdateform.id.title',
        minLength: 1,
        maxLength: 250
    },
    created: {
        type: 'number',
        title: 'tp.admin.createorupdateform.created.title'
    },
    updated: {
        type: 'number',
        title: 'tp.admin.createorupdateform.updated.title'
    },
    isActive: {
        title: 'tp.admin.createorupdateform.isactive.title',
        type: 'boolean',
        enumNames: [
            'tp.admin.createorupdateform.isactive.option.active',
            'tp.admin.createorupdateform.isactive.option.inactive'
        ]
    },
    autoRegistered: {
        type: 'boolean',
        title: 'tp.admin.createorupdateform.autoregistered.title',
        enumNames: ['cs.text.answer.yes', 'cs.text.answer.no']
    },
    ...generalProperties
};

const generalFields = ['firstName', 'lastName', 'emailAddress'];
const generalFieldsExtended = [
    'id',
    'created',
    'updated',
    'isActive',
    'autoRegistered',
    ...generalFields
];

const getSingleRightField = (singleRight) => ({
    [singleRight]: {
        title: `tp.text.singleright.${singleRight
            .replace(/_/g, '')
            .toLowerCase()}`,
        type: 'boolean',
        default: false,
        enumNames: ['cs.text.answer.yes', 'cs.text.answer.no']
    }
});

const getDimensionSchema = (dimensionName, dimensionValues) => ({
    type: 'array',
    title: webStringUtils.getWebStringForDimension(dimensionName),
    items: {
        type: 'string',
        enum: dimensionValues
    },
    uniqueItems: true,
    minItems: 1
});

const getSingleRightsSchema = (singleRights) => {
    const field = {};
    singleRights.forEach((sr) => {
        Object.assign(field, getSingleRightField(sr));
    });

    return {
        type: 'object',
        title: '',
        properties: {
            ...field
        }
    };
};

const getScopeForMultipleRoles = (dimensions) => {
    const dimensionObject = {};
    dimensions.map((d) => {
        dimensionObject[d.name] = getDimensionSchema(d.name, d.values);
    });

    return {
        type: 'object',
        title: '',
        required: [...dimensions.map((d) => d.name)],
        properties: {
            ...dimensionObject
        }
    };
};

const getOneOfIsRoleActive = (dimensions, singleRights) => {
    const isRoleActive = {
        properties: {
            isRoleActive: {
                enum: [true]
            },
            scope: getScopeForMultipleRoles(dimensions)
        }
    };
    if (singleRights.length > 0)
        isRoleActive.properties.singleRights = getSingleRightsSchema(
            singleRights
        );
    return {
        oneOf: [isRoleActive]
    };
};

export default {
    generalFields,
    generalFieldsExtended,
    generalProperties,
    generalPropertiesExtended,
    getOneOfIsRoleActive
};
