import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import timeUtils from 'lib/timeUtils';
import WebString from 'components/webString/WebString';
import ProposalAutoSave from 'components/proposal/ProposalAutoSave';
import AbortProposalButton from 'components/proposal/metaInfoBar/AbortProposalButton';
import SetInactiveButton from 'components/proposal/metaInfoBar/SetInactiveButton';
import RecheckProposalButton from 'components/proposal/metaInfoBar/RecheckProposalButton';
import RecheckInformation from 'components/proposal/metaInfoBar/RecheckInformation';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import UserFeatureTypes from 'types/UserFeatureTypes';
import ProposalClassificationButton from 'components/proposal/metaInfoBar/proposalClassification/ProposalClassificationButton';

//TODO:#34124 refactoring: create for each component a new file

export const CreationDate = ({creationDate}) => (
    <li className="tp-meta-info-bar__creation-date">
        <span className="tp-meta-info-bar__label tp-meta-info-bar__label-creation-date">
            <WebString
                id="tp-meta-info-bar__webstring-creation-date"
                webStringKey="cs.text.created"
            />
        </span>
        <span className="tp-meta-info-bar__list-item-creation-date">
            {creationDate}
        </span>
    </li>
);

export const ProposalID = ({proposalId}) => (
    <li className="tp-meta-info-bar__proposal-id">
        <span className="tp-meta-info-bar__list-item-proposal-id">
            #{proposalId}
        </span>
    </li>
);

export const Status = ({status}) => {
    const statusName = status.toLowerCase();
    return (
        <li className="tp-meta-info-bar__status">
            <span className="tp-meta-info-bar__label tp-meta-info-bar__label-status">
                <WebString
                    id="tp-meta-info-bar__webstring-status-label"
                    webStringKey="cs.text.status"
                />
            </span>
            <span className="tp-meta-info-bar__status-wrapper">
                <span
                    className={`tp-meta-info-bar__list-item-status tp-meta-info-bar__list-item-status--${statusName}`}
                />
                <span className="tp-meta-info-bar__list-item-status-text">
                    <WebString
                        id="tp-meta-info-bar__webstring-status-text"
                        webStringKey={`cs.text.status.${statusName.replace(
                            /[^a-zA-Z0-9]/g,
                            ''
                        )}`}
                    />
                </span>
            </span>
        </li>
    );
};

export const Owner = ({owner}) => (
    <li className="tp-meta-info-bar__owner">
        <span className="tp-meta-info-bar__label tp-meta-info-bar__label-owner">
            <WebString
                id="tp-meta-info-bar__webstring-owner"
                webStringKey="cs.text.owner"
            />
        </span>
        <span className="tp-meta-info-bar__list-item-owner">{owner}</span>
    </li>
);

export const Dimension = ({dimensionName, dimensionValue}) => {
    return (
        <li
            id={`tp-meta-info-bar__scope--${dimensionName}`}
            className="tp-meta-info-bar__scope">
            <span className="tp-meta-info-bar__label tp-meta-info-bar__label-scope">
                {dimensionName}
            </span>
            <span className="tp-meta-info-bar__list-item-scope">
                {dimensionValue}
            </span>
        </li>
    );
};

export const AutoSave = () => (
    <li className="tp-meta-info-bar__save-proposal">
        <ProposalAutoSave />
    </li>
);

export class MetaInfoBar extends React.Component {
    static propTypes = {
        proposalId: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        creationDate: PropTypes.number.isRequired,
        applicantCanOnlySeeOwnProposal: PropTypes.bool.isRequired,
        scope: PropTypes.shape({}).isRequired,
        owner: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired
    };

    getCreationDate = (creationDate) => {
        return timeUtils.getFormattedUnixTimestring(creationDate);
    };

    state = {
        dateCreated: this.getCreationDate(this.props.creationDate)
    };

    componentDidUpdate(prevProps) {
        if (this.props.locale !== prevProps.locale) {
            this.setState({
                dateCreated: this.getCreationDate(this.props.creationDate)
            });
        }
    }

    render() {
        const {scope, applicantCanOnlySeeOwnProposal} = this.props;

        return ReactDOM.createPortal(
            <div className="tp-meta-info-bar">
                <ul className="tp-meta-info-bar__list">
                    <CreationDate creationDate={this.state.dateCreated} />
                    <ProposalID proposalId={this.props.proposalId} />
                    <RecheckInformation />
                    <Status status={this.props.status} />
                    {!applicantCanOnlySeeOwnProposal && (
                        <>
                            <Owner owner={this.props.owner} />
                            {scope
                                ? Object.entries(scope).map(
                                      ([dimensionName, dimensionValue]) => {
                                          return (
                                              <Dimension
                                                  key={dimensionName}
                                                  dimensionName={dimensionName}
                                                  dimensionValue={
                                                      dimensionValue
                                                  }
                                              />
                                          );
                                      }
                                  )
                                : null}
                        </>
                    )}
                    <li className="tp-meta-info-bar__abort-set-inactive-recheck-change-proposal">
                        <AbortProposalButton className="tp-meta-info-bar__abort-proposal-button" />
                        <RecheckProposalButton className="tp-meta-info-bar__recheck-proposal-button" />
                        <SetInactiveButton className="tp-meta-info-bar__set-inactive-button" />
                        <UserFeatureToggle
                            requiredFeatureOneOf={[
                                UserFeatureTypes.ASSESSMENT,
                                UserFeatureTypes.PROPOSAL
                            ]}>
                            <ProposalClassificationButton className="tp-meta-info-bar__proposal-classification-button" />
                        </UserFeatureToggle>
                    </li>
                    <AutoSave />
                </ul>
            </div>,
            document.querySelector('#bk-cs-over-content')
        );
    }
}

const mapStateToProps = ({
    process: {proposal},
    settings,
    sessionContext: {account}
}) => {
    const {
        id: proposalId,
        status = '',
        owner,
        creationTimestamp: creationDate,
        scope
    } = proposal;
    const {
        i18n: {locale}
    } = settings;
    const {applicantCanOnlySeeOwnProposal} = account;

    return {
        applicantCanOnlySeeOwnProposal,
        proposalId,
        status,
        owner,
        creationDate,
        scope,
        locale
    };
};

export default connect(mapStateToProps)(MetaInfoBar);
