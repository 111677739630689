import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const ObjectFieldTemplate = (props) => (
    <fieldset>
        {props.title ? (
            <legend>
                <WebString
                    id={`${props.idSchema.$id}__title`}
                    webStringKey={props.title}
                />
            </legend>
        ) : null}
        {props.properties.map((schemaProp) => schemaProp.content)}
    </fieldset>
);

ObjectFieldTemplate.propTypes = {
    idSchema: PropTypes.shape({
        $id: PropTypes.string.isRequired
    }).isRequired,
    properties: PropTypes.array.isRequired,
    title: PropTypes.string
};
ObjectFieldTemplate.defaultProps = {
    title: ''
};

export default ObjectFieldTemplate;
