import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import IconButton from 'components/tpForm/fields/iconButtons/IconButton';
import TpPopup from 'components/common/TpPopup';

/**
 * IconButton which removes an uploaded file.
 * Shows a confirmation dialog before calling event callback.
 */
class IconButtonRemove extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {showPopUp: false};

        this.popupConfig = {
            headerInfo: {
                webStringKey: 'cs.text.alert.warning',
                type: 'warning',
                showCloseButton: true
            },
            buttons: [
                {
                    webStringKey: 'cs.text.answer.no',
                    id: `btn-remove-${this.props.id}-no`
                },
                {
                    webStringKey: 'cs.text.answer.yes',
                    id: `btn-remove-${this.props.id}-yes`,
                    action: this.props.onClick
                }
            ]
        };
    }

    UNSAFE_componentWillMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    openPopup = () => {
        this.setState({showPopUp: true});
    };

    closePopup = () => {
        if (this.mounted) {
            this.setState({showPopUp: false});
        }
    };

    render() {
        return (
            <div>
                <IconButton
                    id={`remove_${this.props.id}`}
                    onClick={this.openPopup}
                    iconClassName={'icon-button__icon--remove'}>
                    <WebString webStringKey="cs.button.tooltip.remove" />
                </IconButton>

                {this.state.showPopUp ? (
                    <TpPopup {...this.popupConfig} onClose={this.closePopup}>
                        <WebString webStringKey="cs.text.fileremove.confirm" />
                    </TpPopup>
                ) : null}
            </div>
        );
    }
}

export default IconButtonRemove;
