import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {AuthContext} from 'components/authorization/AuthProvider';
import LoginPageWithBoxInfo from 'components/authorization/LoginPageWithBoxInfo';

const LoginWithOrWithoutUuid = () => {
    const {customerUuid} = useParams();
    const auth = useContext(AuthContext);

    const args = {};
    if (customerUuid) {
        args.extraQueryParams = {
            customerUuid
        };
    }
    auth.signinRedirect(args);

    return <LoginPageWithBoxInfo />;
};

export default LoginWithOrWithoutUuid;
