import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {saveAs} from 'file-saver';
import HTTPClient from 'lib/HTTPClient';
import WebString from 'components/webString/WebString';
import IconButton from 'components/tpForm/fields/iconButtons/IconButton';

export class ReportDownloadButton extends React.Component {
    static propTypes = {
        onError: PropTypes.func
    };

    static defaultProps = {
        onError: () => undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fileData: null
        };
    }

    handleFileDownload = () => {
        const {reportId} = this.props.reportData;
        if (this.state.fileData) {
            this.saveFileLocally();
        } else {
            this.setState({isLoading: true, fileData: null}, () =>
                this.getFileData(reportId)
            );
        }
    };

    async getFileData(reportId) {
        try {
            const response = await HTTPClient.getBinaryWithHeaders(
                `reports/${reportId}`,
                true
            );
            let fileName;
            if (response) {
                const contentDisposition =
                    response.fileHeaders['content-disposition'];
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }
            this.setState(
                {
                    fileData: {
                        contents: response.fileContent,
                        fileName: fileName,
                        mime: response['content-type']
                    }
                },
                () => this.saveFileLocally()
            );
        } catch (error) {
            this.setState(
                {
                    isLoading: false
                },
                () => this.props.onError(error, 'DOWNLOAD_FILE_FAILED')
            );
        }
    }

    saveFileLocally = () => {
        const fileData = this.state.fileData;
        if (!fileData) {
            return false;
        }
        const blob = new Blob([fileData.contents], {type: fileData.mime});
        saveAs(blob, fileData.fileName);
        this.setState({
            isLoading: false
        });
    };

    render() {
        const {reportStatus, reportId} = this.props.reportData;
        const btnStatus = ['IN_PROGRESS', 'NOT_STARTED', 'FAILED'].includes(
            reportStatus
        )
            ? 'nodownload'
            : 'download';
        return (
            <IconButton
                id={`tp-table-reportsgeneration__downloadbutton-${reportId}`}
                onClick={this.handleFileDownload}
                iconClassName={`${
                    this.state.isLoading
                        ? 'icon-button__icon--loading-download'
                        : 'icon-button__icon--download'
                }`}
                isLoading={this.state.isLoading}
                className={`tp-table-reportsgeneration__downloadbtn tp-table-reportsgeneration__downloadbtn--${btnStatus}`}>
                <WebString webStringKey="cs.button.tooltip.download" />
            </IconButton>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onError(error, type) {
        dispatch({type, error});
    }
});

export default connect(null, mapDispatchToProps)(ReportDownloadButton);
