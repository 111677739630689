import jsonQ from 'jsonq';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import initial from 'lodash.initial';
import merge from 'lodash.merge';

/**
 * Transform a formData object shape B to match the initial object structure A sent by the
 * backend. The backend cannot perform form schema validation on structure B.
 * Empty oneOf objects (where no value has been chosen), which are nested in the
 * formData object, must be removed completely.
 *
 * transformOutgoingFormData iterates over all keys which match the RegEx pattern
 * /OneOf$/.
 * @param {Object} formData
 * @returns {Object} transformed formData
 */
const transformOutgoingFormData = (formData) => {
    const transformedFormData = clonedeep(formData);
    const formDataQ = jsonQ(formData);

    // find formData keys ending in 'OneOf', e.g. partnerOneOf
    const oneOfKeys = Object.keys(formDataQ.jsonQ_path).filter((key) =>
        /OneOf$/.test(key)
    );

    for (const oneOfKey of oneOfKeys) {
        const transformedFormDataQ = jsonQ(transformedFormData);

        // read the ...oneOf value
        const oneOfQ = transformedFormDataQ.find(oneOfKey); // e.g. partnerOneOf
        const oneOfPath = oneOfQ.path(); // e.g. partner.partnerOneOf
        const oneOfValue = oneOfQ.firstElm(); // e.g. "company"
        const containerPath = initial(oneOfPath); // e.g. partner

        if (oneOfValue) {
            // delete the key holding the oneOf selector value
            unset(transformedFormData, oneOfPath);

            // rewrite this as _{value}Type
            const oneOfTypeKey = `_${oneOfKey}Type`; // e.g. _partnerOneOfType
            const oneOfTypePath = [...containerPath, oneOfTypeKey]; // e.g. partner._partnerOneOfType

            set(transformedFormData, oneOfTypePath, oneOfValue);

            // read contents of {value}Ref
            const refPath = [...containerPath, `${oneOfKey}Ref`]; // e.g. partner.partnerOneOfRef
            const refContents = get(transformedFormData, refPath);

            // delete the {value}Ref key
            unset(transformedFormData, refPath);

            // write this into parent
            const refContentsWithPath = set({}, containerPath, refContents);

            merge(transformedFormData, refContentsWithPath);
        } else {
            unset(transformedFormData, containerPath);
        }
    }

    return transformedFormData;
};

export {transformOutgoingFormData};
