import React from 'react';
import PropTypes from 'prop-types';
import * as fieldsUtils from 'components/tpForm/fields/fieldsUtils';
import WebString from 'components/webString/WebString';

const FieldTemplate = (props) => {
    const {
        id,
        classNames,
        label,
        displayLabel,
        required,
        children,
        uiSchema
    } = props;
    const requiredFieldClass = required ? ' bk-cs-form-label--required' : '';
    const labelElement =
        !displayLabel ||
        !label ||
        fieldsUtils.isHeaderHidden(uiSchema) ? null : (
            <label
                className={`bk-cs-form-label bk-cs-field-label${requiredFieldClass}`}
                id={`form__section-header::${label}`}>
                <WebString webStringKey={label} />
            </label>
        );
    return (
        <div id={`${id}_field`} className={classNames}>
            {labelElement}
            <div className="bk-cs-form-field">{children}</div>
        </div>
    );
};
FieldTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    displayLabel: PropTypes.bool.isRequired,
    uiSchema: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    errors: PropTypes.object
};
FieldTemplate.defaultProps = {
    label: '',
    required: false,
    errors: null
};

export default FieldTemplate;
