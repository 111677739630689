import pick from 'lodash/pick';
import {
    BusinessPartnerEditCompanySchema,
    BusinessPartnerEditCompanyUiSchema,
    BusinessPartnerEditPersonSchema,
    BusinessPartnerEditPersonUiSchema
} from './BusinessPartnerEditCoreDataSchema';
import BusinessPartnerTypes from 'types/BusinessPartnerTypes';

/**
 * @param {BusinessPartnerTypes} businessPartnerType
 * @returns {object} object containing schema and uiSchema
 */
const getBusinessPartnerSchemas = (businessPartnerType) =>
    businessPartnerType === BusinessPartnerTypes.PERSON
        ? {
              schema: BusinessPartnerEditPersonSchema,
              uiSchema: BusinessPartnerEditPersonUiSchema
          }
        : {
              schema: BusinessPartnerEditCompanySchema,
              uiSchema: BusinessPartnerEditCompanyUiSchema
          };

const pickBusinessPartnerFormData = (businessPartner) =>
    businessPartner.partnerType === BusinessPartnerTypes.PERSON
        ? pick(businessPartner, [
              'firstName',
              'name',
              'location',
              'country',
              'email',
              'firstAddressField',
              'secondAddressField',
              'postCode'
          ])
        : pick(businessPartner, [
              'name',
              'location',
              'country',
              'legalForm',
              'email',
              'firstAddressField',
              'secondAddressField',
              'postCode'
          ]);

export default {
    getBusinessPartnerSchemas,
    pickBusinessPartnerFormData
};
