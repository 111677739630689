import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {connect} from 'react-redux';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import ManagerAssessedProposalsTable from './ManagerAssessedProposalsTable';
import ManagerAssessableProposalsTable from './ManagerAssessableProposalsTable';
import ApplicantProposalsTable from './ApplicantProposalsTable';
import UserRoleTypes from 'types/UserRoleTypes';
import UserFeatureTypes from 'types/UserFeatureTypes';
import WebString from '../webString/WebString';

const possibleTabs = [
    {
        feature: UserFeatureTypes.ASSESSMENT,
        titleWebStringKey: 'tp.proposalspage.tabs.assessable',
        content: (
            <UserFeatureToggle
                requiredFeatureOneOf={[UserFeatureTypes.ASSESSMENT]}>
                <ManagerAssessableProposalsTable
                    userRole={UserRoleTypes.MANAGER}
                />
            </UserFeatureToggle>
        )
    },
    {
        feature: UserFeatureTypes.ASSESSMENT,
        titleWebStringKey: 'tp.proposalspage.tabs.assessed',
        content: (
            <UserFeatureToggle
                requiredFeatureOneOf={[UserFeatureTypes.ASSESSMENT]}>
                <ManagerAssessedProposalsTable
                    userRole={UserRoleTypes.MANAGER}
                />
            </UserFeatureToggle>
        )
    },
    {
        feature: UserFeatureTypes.PROPOSAL,
        titleWebStringKey: 'tp.proposalspage.tabs.proposals',
        content: (
            <UserFeatureToggle
                requiredFeatureOneOf={[UserFeatureTypes.PROPOSAL]}>
                <ApplicantProposalsTable userRole={UserRoleTypes.APPLICANT} />
            </UserFeatureToggle>
        )
    },
    {
        feature: UserFeatureTypes.AUDIT,
        titleWebStringKey: 'tp.proposalspage.tabs.readonly',
        content: (
            <UserFeatureToggle requiredFeatureOneOf={[UserFeatureTypes.AUDIT]}>
                <ManagerAssessedProposalsTable
                    userRole={UserRoleTypes.AUDITOR}
                />
            </UserFeatureToggle>
        )
    }
];

/**
 * Renders the relevant table(s) for the user, based on user feature.
 */
const renderSingleFeature = (matchingContent) => {
    const [table] = matchingContent;
    return table.content;
};

const renderMultipleFeatures = (matchingContent) => {
    return (
        <Tabs>
            <TabList>
                {matchingContent.map((tab) => (
                    <Tab key={tab.titleWebStringKey}>
                        <WebString webStringKey={tab.titleWebStringKey} />
                    </Tab>
                ))}
            </TabList>
            {matchingContent.map(({content}, index) => (
                <TabPanel key={index}>{content}</TabPanel>
            ))}
        </Tabs>
    );
};

export const ProposalsPage = ({userFeatures}) => {
    const matchingContent = possibleTabs.filter((tab) =>
        userFeatures.includes(tab.feature)
    );

    return matchingContent.length === 1
        ? renderSingleFeature(matchingContent)
        : renderMultipleFeatures(matchingContent);
};

const mapStateToProps = (state) => ({
    userFeatures: state.sessionContext.user.features
});

export default connect(mapStateToProps)(ProposalsPage);
