import {country} from './BusinessPartnerCountryObject';

const firstName = {
    type: 'string',
    title: 'tp.businesspartnerdetails.firstname',
    minLength: 1,
    maxLength: 250
};
const lastName = {
    type: 'string',
    title: 'tp.businesspartnerdetails.surname',
    maxLength: 150,
    minLength: 2
};
const location = {
    type: 'string',
    title: 'tp.businesspartnerdetails.location',
    maxLength: 150,
    minLength: 2
};

const legalForm = {
    type: 'string',
    title: 'tp.businesspartnerdetails.legalform',
    maxLength: 150
};

const companyName = {
    type: 'string',
    title: 'tp.businesspartnerdetails.companyname',
    maxLength: 150,
    minLength: 2
};

const email = {
    type: ['null', 'string'],
    title: 'tp.businesspartnerdetails.email',
    maxLength: 254,
    default: '',
    pattern:
        '^([^\\s@]{1,64}@([^_+*~:;,#\'?!\\"§$%&/()=?`´²³{\\[\\]}\\s]+\\.)+([a-zA-z]+)$|)$'
};
const firstAddressField = {
    type: 'string',
    title: 'tp.businesspartnerdetails.firstaddressfield',
    maxLength: 150,
    minLength: 2
};

const secondAddressField = {
    type: 'string',
    title: 'tp.businesspartnerdetails.secondaddressfield',
    default: '',
    maxLength: 150
};

const postCode = {
    type: 'string',
    title: 'tp.businesspartnerdetails.postcode',
    maxLength: 15,
    minLength: 1
};
const uiSchemaPropertyWithPlaceholder = {
    'ui:placeholder': 'cs.form.please.select'
};

const BusinessPartnerEditCompanySchema = {
    type: 'object',
    required: ['name', 'location', 'country', 'firstAddressField', 'postCode'],
    properties: {
        name: companyName,
        location,
        country,
        legalForm,
        email,
        firstAddressField,
        secondAddressField,
        postCode
    }
};

const BusinessPartnerEditCompanyUiSchema = {
    'ui:order': [
        'name',
        'location',
        'country',
        'legalForm',
        'email',
        'firstAddressField',
        'secondAddressField',
        'postCode'
    ],
    country: {
        ...uiSchemaPropertyWithPlaceholder,
        'ui:options': {
            sorted: true
        }
    },
    hideHeader: true
};

const BusinessPartnerEditPersonSchema = {
    type: 'object',
    required: [
        'firstName',
        'name',
        'location',
        'country',
        'firstAddressField',
        'postCode'
    ],
    properties: {
        firstName,
        name: lastName,
        location,
        country,
        email,
        firstAddressField,
        secondAddressField,
        postCode
    }
};

const BusinessPartnerEditPersonUiSchema = {
    'ui:order': [
        'firstName',
        'name',
        'location',
        'country',
        'email',
        'firstAddressField',
        'secondAddressField',
        'postCode'
    ],
    country: {
        ...uiSchemaPropertyWithPlaceholder,
        'ui:options': {
            sorted: true
        }
    },
    hideHeader: true
};

export {
    BusinessPartnerEditCompanySchema,
    BusinessPartnerEditCompanyUiSchema,
    BusinessPartnerEditPersonSchema,
    BusinessPartnerEditPersonUiSchema
};
