import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const TpTooltip = ({id, children}) => (
    <ReactTooltip
        id={id}
        place="top"
        type="dark"
        delayHide={500}
        className="tp-tooltip"
        effect="solid"
        delayShow={500}
        wrapper="span">
        {children}
    </ReactTooltip>
);

TpTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

export default TpTooltip;
