import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import HTTPClient from 'lib/HTTPClient';
import {RiskAlertTableArrayFieldTemplate} from './RiskAlertTableArrayFieldTemplate';
import RiskAlertsDowJonesImage from './riskAlertsFieldSegments/RiskAlertsDowJonesImage';
import RiskAlertsBPRiskCount from './riskAlertsFieldSegments/RiskAlertsBPRiskCount';
import RiskAlertsPopUp from './riskAlertsFieldSegments/RiskAlertsPopUp';
import RiskAlertsBPInfo from './riskAlertsFieldSegments/RiskAlertsBPInfo';
import RiskAlertsTable from './riskAlertsFieldSegments/RiskAlertsTable';

/**
 * Field displaying the Dow Jones risk alert table, and general information above.
 * Displays DJ logo and partner name and address, then number of risk alert, then
 * renders the risk alert table.
 */
const RiskAlertsField = (props) => {
    const dispatch = useDispatch();

    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpHeaderWebStringKey, setPopUpHeaderWebStringKey] = useState('');
    const [popUpContent, setPopUpContent] = useState(null);

    /**
     * Closes the modal window.
     */
    const handleOnClosePopUp = () => {
        setShowPopUp(false);
        setPopUpContent(null);
    };

    /**
     * Fetch the risk profile data from the BE and put into state for display.
     * @param {String} peId
     * @returns undefined
     */
    const getDowJonesProfileAsync = async (peId) => {
        try {
            const response = await HTTPClient.get(`factiva/data/${peId}`);
            setPopUpContent(response);
        } catch (error) {
            setShowPopUp(false);
            dispatch({type: 'GET_DOWJONES_PROFILE_FAILED', error});
        }
    };

    /**
     * Callback passed into RiskAlertTableEntry via RJSF's formContext object
     * Opens a risk profile in a modal window.
     * @param {String} peId
     * @param {String} name
     */
    const displayRiskProfileOnClick = (peId, name) => {
        setShowPopUp(true);
        setPopUpHeaderWebStringKey(name);
        getDowJonesProfileAsync(peId);
    };

    // extend RJSF's registry to provide custom array field template here only
    const getObjectFieldProps = () => ({
        ...props,
        registry: {
            ...props.registry,
            ArrayFieldTemplate: RiskAlertTableArrayFieldTemplate,
            formContext: {
                ...props.formContext,
                displayRiskProfileOnClick
            }
        }
    });

    const riskAlerts = props.formContext.fieldData.riskAlerts || {};
    const riskAlertMatches = riskAlerts.matches || [];

    return (
        <div className="tp-risk-alerts">
            <RiskAlertsPopUp
                show={showPopUp}
                headerWebstringKey={popUpHeaderWebStringKey}
                onClose={handleOnClosePopUp}
                content={popUpContent}
            />
            <RiskAlertsDowJonesImage />
            <RiskAlertsBPInfo businessPartner={riskAlerts.businessPartner} />
            <RiskAlertsBPRiskCount count={riskAlertMatches.length} />
            <div className="tp-risk-alerts__table-wrapper">
                {props.textOnly ? (
                    <RiskAlertsTable
                        onRiskProfileClick={displayRiskProfileOnClick}
                        alerts={props.formData.rows || []}
                    />
                ) : (
                    <ObjectField {...getObjectFieldProps()} />
                )}
            </div>
        </div>
    );
};

RiskAlertsField.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired,
    registry: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    formContext: PropTypes.shape({
        fieldData: PropTypes.shape({
            riskAlerts: PropTypes.shape({
                businessPartner: PropTypes.shape({
                    name: PropTypes.string,
                    address: PropTypes.string,
                    dunsNumber: PropTypes.string
                }),
                matches: PropTypes.arrayOf(PropTypes.object)
            })
        })
    }),
    formData: PropTypes.shape({
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                countryCode: PropTypes.string,
                status: PropTypes.string,
                critical: PropTypes.bool,
                comment: PropTypes.string,
                peId: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                riskFlags: PropTypes.string.isRequired,
                matchPct: PropTypes.string.isRequired
            })
        )
    }),
    textOnly: PropTypes.bool
};

RiskAlertsField.defaultProps = {
    formContext: {
        fieldData: {
            riskAlerts: {}
        }
    },
    formData: {
        rows: []
    },
    textOnly: false
};

export default RiskAlertsField;
