import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const RiskAlertsTableEntry = ({alert, onRiskProfileClick}) => {
    const countryKey =
        alert.countryCode === 'NULL'
            ? 'tp.form.dowjones.unknown'
            : `cs.text.country.${alert.countryCode}`;
    const statusKey = alert.status
        ? `tp.form.dowjones.status.${alert.status}`
        : 'tp.form.dowjones.unprocessed';
    const criticalKey =
        alert.critical === null
            ? 'tp.form.dowjones.unprocessed'
            : alert.critical
            ? 'cs.text.yes'
            : 'cs.text.no';
    const comment = alert.comment || '-';

    return (
        <tbody className="tp-risk-alerts__table-tbody">
            <tr>
                <td className="tp-risk-alerts__table-cell">
                    {alert.riskFlags}
                </td>
                <td className="tp-risk-alerts__table-cell">
                    <span
                        id={`risk-alert-profile-${alert.peId}`}
                        onClick={() =>
                            onRiskProfileClick(alert.peId, alert.name)
                        }
                        className="tp-risk-alerts__table-link">
                        {alert.name}
                    </span>
                </td>
                <td className="tp-risk-alerts__table-cell">
                    <WebString webStringKey={countryKey} />
                </td>
                <td className="tp-risk-alerts__table-cell">
                    {alert.matchPct}%
                </td>
                <td className="tp-risk-alerts__table-cell">
                    <WebString webStringKey={statusKey} />
                </td>
                <td className="tp-risk-alerts__table-cell">
                    <WebString webStringKey={criticalKey} />
                </td>
            </tr>
            <tr className="tp-risk-alerts__comment">
                <td colSpan="6" className="tp-risk-alerts__comment">
                    <span className="tp-risk-alerts__heading">
                        <WebString webStringKey="tp.form.dowjones.riskcomment" />
                        :{' '}
                    </span>
                    <span>{comment}</span>
                </td>
            </tr>
        </tbody>
    );
};

RiskAlertsTableEntry.propTypes = {
    alert: PropTypes.shape({
        countryCode: PropTypes.string,
        status: PropTypes.string,
        critical: PropTypes.bool,
        comment: PropTypes.string,
        peId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        riskFlags: PropTypes.string.isRequired,
        matchPct: PropTypes.string.isRequired
    }).isRequired,
    onRiskProfileClick: PropTypes.func.isRequired
};

export default RiskAlertsTableEntry;
