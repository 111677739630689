import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import i18nUtils from 'lib/i18nUtils';
import Form from 'components/tpForm/Form';
import * as snackActions from 'store/actions/snackActions';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';
import TpFieldTemplate from 'components/tpForm/fields/TpFieldTemplate';
import {
    generateReportsUiSchema,
    getReportsSchema
} from './ReportGenerationSchemas';
import {
    generateReport,
    getReports
} from 'store/actions/reportGenerationActions';
import ReportsTable from './ReportsTable';

export class ReportGeneration extends React.Component {
    static propTypes = {
        stringResources: PropTypes.object.isRequired,
        onSubmitGenerateReport: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
    }

    handleGenerateReport = ({formData}) => {
        const {reportType, dateRange} = formData;
        this.props.onSubmitGenerateReport({
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            reportType
        });
    };

    render() {
        const maximumDate = moment().subtract(1, 'days').valueOf();
        return (
            <React.Fragment>
                <Form
                    id="form-report-generation"
                    onSubmit={this.handleGenerateReport}
                    schema={getReportsSchema(maximumDate)}
                    uiSchema={generateReportsUiSchema}
                    FieldTemplate={TpFieldTemplate}
                    stringResources={this.props.stringResources}
                    showSubmitButton={true}
                    submitButtonId={'tp-generate-report-btn'}
                    submitButtonWebStringKey={'tp.button.generatereport'}
                    submitButtonClassNames={'tp-generate-report-btn'}
                />
                <ReportsTable />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    stringResources: state.settings.i18n.stringResources
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitGenerateReport: (reportParams, stringResources) =>
        dispatch(
            generateReport(reportParams, {
                onSuccess() {
                    dispatch(getReports());
                    dispatch(
                        snackActions.createSnack({
                            id: `snack-success-generate-report`,
                            snackType: TpSnackbarTypes.SUCCESS,
                            autoHideDuration: 4000,
                            message: `${i18nUtils.getTranslatedWebStringKey(
                                stringResources,
                                'tp.generatereport.notification.message.success'
                            )}`
                        })
                    );
                },
                onFailure(error) {
                    dispatch(
                        snackActions.createSnack({
                            id: `snack-error-generate-report`,
                            snackType: TpSnackbarTypes.ERROR,
                            autoHideDuration: 4000,
                            message: `${i18nUtils.getTranslatedWebStringKey(
                                stringResources,
                                'tp.errormsg.report.generate'
                            )}`
                        })
                    );
                }
            })
        )
});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onSubmitGenerateReport(reportParams) {
        dispatchProps.onSubmitGenerateReport(
            reportParams,
            stateProps.stringResources
        );
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ReportGeneration);
