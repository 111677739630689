import React from 'react';
import PropTypes from 'prop-types';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import InputAdornment from '@material-ui/core/InputAdornment';

/*
 * @example:
 *       @schema:
 *       "currency": {
 *         "type": "string",
 *         "minLength": 1   // if required
 *       }
 *       @uiSchema:
 *       "currency": {
 *          "ui:widget": "cs-currency",
 *          "options":{
 *               currencySymbol: "any"
 *       }
 */

const TpCurrency = (props) => (
    <CurrencyTextField
        id={props.id}
        disabled={props.disabled}
        readonly={props.readonly}
        variant="outlined"
        value={props.value}
        className={`form-control tp-currency-field${
            props.disabled || props.readonly ? ' disabled' : ''
        }${props.rawErrors ? ' has-error' : ''}`}
        currencySymbol=""
        minimumValue="0"
        decimalCharacter={','}
        digitGroupSeparator={''}
        outputFormat={'string'}
        placeholder={'123,45'}
        size={'small'}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    {props.options.currencySymbol}
                </InputAdornment>
            )
        }}
        onChange={(event, value) => props.onChange(value)}
    />
);

TpCurrency.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    options: PropTypes.shape({
        currencySymbol: PropTypes.string
    }),
    rawErrors: PropTypes.array
};

TpCurrency.defaultProps = {
    value: '',
    disabled: false,
    readonly: false,
    options: {
        currencySymbol: '€'
    },
    rawErrors: null
};

export default TpCurrency;
