import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';

const RiskAlertsTableHeader = ({headers}) => (
    <thead>
        <tr className="bk-cs-underlined">
            {headers.map((item, index) => (
                <th className="tp-risk-alerts__column-header" key={index}>
                    <WebString
                        webStringKey={`tp.form.dowjones.${item}.header`}
                    />
                </th>
            ))}
        </tr>
    </thead>
);

RiskAlertsTableHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RiskAlertsTableHeader;
