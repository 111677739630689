import moment from 'moment';
// import any locales we support here (and here only)
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/pt-br';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/hr';
import 'moment/locale/sr';
import 'moment/locale/id';
import 'moment/locale/lt';
import 'moment/locale/ja';
import 'moment/locale/ru';
import 'moment/locale/th';
import 'moment/locale/sl';
import 'moment/locale/zh-cn';

// somehow the import order seems to affect the tests. Please leave en-gb on the
// bottom
import 'moment/locale/en-gb';

import numeral from 'numeral';
// import any locales we support here (and here only)
import 'numeral/locales/en-gb';
import 'numeral/locales/es';
import 'numeral/locales/de';
import 'numeral/locales/fr';
import 'numeral/locales/pl';
import 'numeral/locales/pt-br';
import 'numeral/locales/it';
import 'numeral/locales/nl-nl';
import 'numeral/locales/sl';
import 'numeral/locales/ja';
import 'numeral/locales/ru';
import 'numeral/locales/th';

import HTTPClient from 'lib/HTTPClient';
import {ACTION_TYPES} from 'store/reducers/settingsReducer';
import FeatureTypes from 'types/FeatureTypes';
import i18nUtils from 'lib/i18nUtils';

import germanMockResponse from './selfRegistrationStrings.de';
import englishMockResponse from './selfRegistrationStrings.en';

const loadRegistrationStrings = (locale) => {
    return {
        type: ACTION_TYPES.LOAD_REGISTRATION_STRINGS,
        promise: HTTPClient.get(`strings/registration/${locale}`).catch(() =>
            Promise.resolve(
                locale === 'de' ? germanMockResponse : englishMockResponse
            )
        )
    };
};

const loadGlobalStrings = (locale, meta) => {
    i18nUtils.storeLocale(locale);
    numeral.locale(locale);
    moment.locale(locale);
    return {
        type: ACTION_TYPES.LOAD_GLOBAL_STRINGS,
        promise: HTTPClient.get(`strings/global/tp/${locale}`),
        meta
    };
};

const loadFormStrings = (params, meta) => {
    if (!Object.values(FeatureTypes).includes(params.type)) {
        throw new Error(`Form service, unexpected type: ${params.type}`);
    }

    if (params.formId === 'genericmanageroverview') {
        return {
            type: ACTION_TYPES.SET_FORM_STRINGS,
            formId: params.formId,
            formStringResources: {}
        };
    }
    const formServiceUrl =
        params.type === FeatureTypes.PROPOSAL
            ? `proposals/${params.typeId}/forms/${params.formId}/strings/${params.locale}`
            : `alerts/${params.typeId}/strings/${params.locale}`;

    return {
        type: ACTION_TYPES.LOAD_FORM_STRINGS,
        promise: HTTPClient.get(formServiceUrl),
        meta: {...meta, formId: params.formId}
    };
};

const loadPreCheckStrings = (params, meta) => {
    return {
        type: ACTION_TYPES.LOAD_PRECHECK_STRINGS,
        promise: HTTPClient.get(
            `pre-checks/${params.formId}/strings/${params.locale}`
        ),
        meta: {...meta, formId: params.formId}
    };
};

const clearFormStrings = (formId) => ({
    type: ACTION_TYPES.CLEAR_FORM_STRINGS,
    formId
});

const showWebStringsKeys = (isActive) => ({
    type: ACTION_TYPES.SHOW_WEB_STRINGS_KEYS,
    isActive
});

export {
    loadRegistrationStrings,
    loadGlobalStrings,
    loadFormStrings,
    loadPreCheckStrings,
    clearFormStrings,
    showWebStringsKeys
};
