import React from 'react';
import PropTypes from 'prop-types';
import TextareaWidget from 'react-jsonschema-form/lib/components/widgets/TextareaWidget';
import DisabledTextBlock from './DisabledTextBlock';

/**
 * Extended TextareaWidget of react-jsonschema-form.
 * Renders DisabledTextBlock if property readonly or disabled, otherwise TextareaWidget.
 *
 * @param {Object} props
 * @returns {TextareaWidget | DisabledTextBlock}
 */
const TpTextareaWidget = (props) => {
    if (props.readonly || props.disabled) {
        return <DisabledTextBlock id={props.id} value={props.value} />;
    }

    return <TextareaWidget {...props} />;
};

TpTextareaWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TpTextareaWidget;
