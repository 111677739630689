const getWebStringForDimension = (dimension) =>
    `tp.text.dimension.${dimension.toLowerCase()}`;

const isHTML = (text) => {
    var doc = new DOMParser().parseFromString(text, 'text/html'); //The parseFromString() method of the DOMParser interface parses a string containing either HTML or XML, returning an HTMLDocument or an XMLDocument.
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1); // Node.ELEMENT_NODE (for HTML elements)
};

export default {
    getWebStringForDimension,
    isHTML
};
