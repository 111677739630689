import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import WebString from 'components/webString/WebString';
import {convertSelectValue} from 'lib/proposalUtils';
import i18nUtils from 'lib/i18nUtils';

const ScopeElements = (props) => {
    const {scopes, onScopeChange, stringResources, selectedScope} = props;

    const getScopeValues = (scope) => {
        let sortedScopeValues = scope.values.sort((valueA, valueB) =>
            valueA.toLowerCase().localeCompare(valueB.toLowerCase())
        );
        return sortedScopeValues.map((value) => convertSelectValue(value));
    };

    const handleScopeChange = (selectedOption, dimension) => {
        onScopeChange({
            ...selectedScope,
            [dimension]: selectedOption.value
        });
    };

    return scopes.map((scope) => {
        return (
            <React.Fragment key={`fragment-${scope.dimension}`}>
                <label
                    className={`tp-proposal-classification__label-${scope.dimension}`}
                    key={`label-${scope.dimension}`}>
                    <WebString
                        webStringKey={`tp.text.dimension.${scope.dimension}`}
                    />
                </label>
                <Select
                    className={`tp-proposal-classification__select-${scope.dimension} tp-proposal-classification__select`}
                    id={`select-scope-${scope.dimension}`}
                    key={scope.dimension}
                    options={getScopeValues(scope)}
                    onChange={(selectedOption) =>
                        handleScopeChange(selectedOption, scope.dimension)
                    }
                    placeholder={i18nUtils.getTranslatedWebStringKey(
                        stringResources,
                        'cs.form.please.select'
                    )}
                    isSearchable={true}
                    value={convertSelectValue(selectedScope[scope.dimension])}
                />
            </React.Fragment>
        );
    });
};

ScopeElements.propTypes = {
    scopes: PropTypes.array.isRequired,
    stringResources: PropTypes.object.isRequired,
    selectedScope: PropTypes.object.isRequired,
    onScopeChange: PropTypes.func.isRequired
};

export default ScopeElements;
