import React from 'react';
import PropTypes from 'prop-types';
import UserRoleTypes from 'types/UserRoleTypes';
import {getWebStringForRole} from '../../rolesUtils';
import WebString from 'components/webString/WebString';

export const RenderRole = ({role}) => (
    <div className="tp-all-users-table__role-name">
        <WebString
            className="tp-role-entry"
            webStringKey={getWebStringForRole(role)}
        />
    </div>
);

RenderRole.propTypes = {
    role: PropTypes.oneOf(Object.values(UserRoleTypes)).isRequired
};

export default RenderRole;
