import React, {useContext} from 'react';
import CircularLoader from 'components/loader/CircularLoader';
import {AuthContext} from 'components/authorization/AuthProvider';
/*
 * After logout the server is going to redirect the user to the post_logout_redirect_uri property
 *  of the IDENTITY_CONFIG where the logoutCallback is going to delete the cookie from the
 *  identity server and the localStorage cleaning the browser, and redirect the user to
 *  the AppContextPath defined in our settings file
 * */

const LogoutCallback = () => {
    const auth = useContext(AuthContext);
    auth.signoutRedirectCallback();
    return <CircularLoader overlay={true} />;
};

export default LogoutCallback;
