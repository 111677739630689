import React from 'react';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import TpImage from 'components/common/TpImage';

export const CsvUploadField = ({handleFileUpload, showFileName, fileName}) => {
    const hiddenFileInputRef = React.useRef(null);

    const handleChange = (event) => {
        handleFileUpload(event.target.files[0]);
    };

    const handleClick = () => {
        hiddenFileInputRef.current.click();
    };

    return (
        <>
            <input
                type="file"
                accept=".csv"
                ref={hiddenFileInputRef}
                onChange={handleChange}
                style={{display: 'none'}}
            />
            <div
                onClick={handleClick}
                className="tp-import-csv__upload-button-container">
                <TpImage
                    className="tp-import-csv__upload-button-icon"
                    src={`icons/upload-green-circle.svg`}
                    alt="upload_icon"
                />
                <div className="tp-import-csv__upload-button-text">
                    {showFileName ? (
                        <span>{fileName}</span>
                    ) : (
                        <WebString
                            className="tp-import-csv__upload-button-webstring"
                            webStringKey={`tp.button.importcsvupload`}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

CsvUploadField.propTypes = {
    handleFileUpload: PropTypes.func.isRequired,
    showFileName: PropTypes.bool.isRequired,
    fileName: PropTypes.string.isRequired
};

export default CsvUploadField;
