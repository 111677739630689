import React from 'react';
import PropTypes from 'prop-types';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import * as browserUtils from 'components/tpForm/fields/browserUtils';
import {FirefoxObjectFieldTemplate} from './FirefoxObjectFieldTemplate';

/**
 * Facade for RJSF's ObjectField, which will pass a custom ObjectFieldTemplate when Firefox is detected.
 * This is due to a 9 year old bug in Firefox, where a fieldset will be printed on one page only,
 * even if it is longer, meaning that long forms are truncated in print format.
 * https://bugzilla.mozilla.org/show_bug.cgi?id=471015
 */
const FieldsetReplacementObjectField = (props) => {
    /*
    If the form is using custom (its own) ObjectFieldTemplate it should not be replaced with
    the default FirefoxObjectFieldTemplate for print format.

    For example UserForm.js in tool admin uses custom (its own) ObjectFieldTemplate and it should be the same also for Firefox.
     */
    const hasCustomObjectFieldTemplate = () =>
        !!props.registry.ObjectFieldTemplate;

    const allProps = {...props};
    if (
        !(
            browserUtils.isFirefox(navigator.userAgent) ||
            hasCustomObjectFieldTemplate()
        )
    ) {
        allProps.registry = {
            ...props.registry,
            ObjectFieldTemplate: FirefoxObjectFieldTemplate
        };
    }

    return <ObjectField {...allProps} />;
};

FieldsetReplacementObjectField.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object,
    errorSchema: PropTypes.object,
    idSchema: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    registry: PropTypes.shape({
        widgets: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ).isRequired,
        fields: PropTypes.objectOf(PropTypes.func).isRequired,
        definitions: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired
    })
};

FieldsetReplacementObjectField.defaultProps = {
    uiSchema: {},
    errorSchema: {},
    idSchema: {},
    formData: {},
    registry: {},
    required: false,
    disabled: false,
    readonly: false
};

export default FieldsetReplacementObjectField;
