import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import TpButton from 'components/common/buttons/TpButton';
import HTTPClient from 'lib/HTTPClient';
import getErrorSnackObject from './errorSnackUtils';
import * as snackActions from 'store/actions/snackActions';

const SendExternalQuestionnaireButton = ({
    email,
    onSuccess,
    proposalId,
    stringResources
}) => {
    const dispatch = useDispatch();

    const handleSend = async () => {
        try {
            await HTTPClient.post(`externalquestionnaires/${proposalId}`, {
                email
            });
            onSuccess();
        } catch (error) {
            const snackProperties = getErrorSnackObject(
                proposalId,
                'tp.externalquestionnaireassignment.notification.message.sendquestionnaire.error',
                stringResources
            );
            dispatch(snackActions.createSnack(snackProperties));
        }
    };

    return (
        <TpButton
            id="btn-send-external-questionnaire"
            onClick={handleSend}
            disabled={!email}
            webStringKey="tp.button.sendquestionnaire"
            className="tp-externalquestionnaire-assignment__submitbutton"
        />
    );
};

SendExternalQuestionnaireButton.propTypes = {
    email: PropTypes.string.isRequired,
    proposalId: PropTypes.number.isRequired,
    stringResources: PropTypes.object.isRequired,
    onSuccess: PropTypes.func
};

SendExternalQuestionnaireButton.propTypes = {
    onSuccess: () => null
};

export default SendExternalQuestionnaireButton;
