import React from 'react';
import defaultAppValues from 'lib/defaultAppValues';
import WebString from 'components/webString/WebString';
import ReportDownloadButton from 'components/reportGeneration/ReportDownloadButton';

const getDimensionValues = (props) => {
    if (!props || !props.value || props.value.length === 0) {
        return [];
    } else {
        const {value: scopeArray} = props;
        return scopeArray.map((scopeItem) => {
            if (scopeItem.dimension === props.column.Header) {
                let valuesString = scopeItem.values.join(', ');
                if (valuesString.length !== 0) {
                    return <span key={props.index}>{valuesString}</span>;
                }
            }
        });
    }
};

const getDimensionColumns = (uniqueDimensions) => {
    if (!uniqueDimensions) {
        return [];
    } else {
        return uniqueDimensions.map((dimension) => {
            return {
                id: `${dimension}`,
                Header: dimension,
                accessor: 'scope',
                Cell: getDimensionValues,
                filterable: false,
                className: `tp-table-reportgeneration__dimension-${dimension}-column`,
                headerClassName: `tp-table-reportgeneration__dimension-${dimension}-header rt-sortable-column--disabled`,
                sortable: false,
                dimension
            };
        });
    }
};

const getReportsTableColumns = (mappedState) => {
    const tableData = mappedState.data;
    const reportsTableColumns = [
        {
            id: 'reportId',
            Header: (
                <WebString webStringKey="tp.table.reportgeneration.reportid" />
            ),
            accessor: 'reportId',
            Cell: (props) =>
                props.value || defaultAppValues.defaultTableCellPlaceHolder,
            filterable: false,
            sortable: false,
            className: 'tp-table-reportgeneration__report-id-column',
            headerClassName:
                'tp-table-reportgeneration__report-id-header rt-sortable-column--disabled'
        },
        {
            id: 'reportType',
            Header: (
                <WebString webStringKey="tp.table.reportgeneration.reportType" />
            ),
            accessor: 'reportType',
            Cell: (props) =>
                props.value || defaultAppValues.defaultTableCellPlaceHolder,
            filterable: false,
            sortable: false,
            className: 'tp-table-reportgeneration__reportType-column',
            headerClassName:
                'tp-table-reportgeneration__reportType-header rt-sortable-column--disabled'
        },
        {
            id: 'from',
            Header: (
                <WebString webStringKey="tp.form.reportgeneration.startdate.title" />
            ),
            accessor: 'from',
            filterable: false,
            sortable: false,
            Cell: (props) =>
                props.value || defaultAppValues.defaultTableCellPlaceHolder,
            className: 'tp-table-reportgeneration__startdate-column',
            headerClassName:
                'tp-table-reportgeneration__startdate-header rt-sortable-column--disabled'
        },
        {
            id: 'to',
            Header: (
                <WebString webStringKey="tp.form.reportgeneration.enddate.title" />
            ),
            accessor: 'to',
            filterable: false,
            sortable: false,
            Cell: (props) =>
                props.value || defaultAppValues.defaultTableCellPlaceHolder,
            className: 'tp-table-reportgeneration__enddate-column',
            headerClassName:
                'tp-table-reportgeneration__enddate-header rt-sortable-column--disabled'
        },
        {
            id: 'reportStatus',
            Header: (
                <WebString webStringKey="tp.form.reportgeneration.status.title" />
            ),
            accessor: 'reportStatus',
            Cell: (props) =>
                props.value || defaultAppValues.defaultTableCellPlaceHolder,
            filterable: false,
            sortable: false,
            className: 'tp-table-reportgeneration__status-column',
            headerClassName:
                'tp-table-reportgeneration__status-header rt-sortable-column--disabled'
        }
    ];

    const downloadColumn = {
        id: 'downloadReport',
        Header: '',
        Cell: (props) => <ReportDownloadButton reportData={props.original} />,
        filterable: false,
        sortable: false,
        width: 40,
        className: 'tp-table-reportgeneration__download-column',
        headerClassName: 'tp-table-reportgeneration__download-header'
    };

    const uniqueDimensions = [
        ...new Set(
            tableData
                .map((item) =>
                    item.scope.map((scopeitem) => scopeitem.dimension)
                )
                .join(',')
                .split(',')
        )
    ].filter(Boolean);

    const allReportTableColumns = [
        ...reportsTableColumns,
        ...getDimensionColumns(uniqueDimensions),
        downloadColumn
    ];
    return allReportTableColumns;
};

export {getReportsTableColumns};
