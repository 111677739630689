import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import EditInput from './EditInput';
import EditToggle from './EditToggle';
import WebString from 'components/webString/WebString';

const EditInputEmailAddress = (props) => {
    const [enteredEmailAddress, setEnteredEmailAddress] = useState(
        props.enteredEmailAddress
    );
    const [showError, setShowError] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);

    useEffect(() => {
        if (!enteredEmailAddress) {
            setEnteredEmailAddress(props.enteredEmailAddress);
        }
    }, [props.enteredEmailAddress]);

    const handleInputChange = (enteredEmailAddress) => {
        setEnteredEmailAddress(enteredEmailAddress);
    };

    const handleKeyPress = (event) => {
        if (event.which === 13) {
            // 13 is ENTER key
            event.preventDefault();
            validateEmail(event.target.value);
        }
    };

    const handleBlur = () => {
        validateEmail(enteredEmailAddress);
    };

    const validateEmail = (emailAddress) => {
        const isAValidEmailAddress = isValidEmail(emailAddress);
        setShowError(!isAValidEmailAddress);
        setIsInEditMode(!isAValidEmailAddress);
        props.onEmailChange(
            emailAddress && isAValidEmailAddress ? emailAddress : ''
        );
    };

    const isValidEmail = (emailAddress) => {
        const emailPattern = RegExp(
            '^[^\\s@]{1,64}@([^_+*~:;,#\'?!"§$%&/()=?`´²³{\\[\\]}\\s]+\\.)+([a-zA-z]+)$'
        );
        return emailPattern.test(emailAddress);
    };

    const handleToggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
    };

    return isInEditMode ? (
        <>
            <EditInput
                initialInputValue={enteredEmailAddress}
                onInputChange={handleInputChange}
                onKeyPress={handleKeyPress}
                onBlur={handleBlur}
                key="edit-email-input"
            />
            {showError ? (
                <div
                    className="tp-edit-input__errorfield"
                    key="edit-email-input-error">
                    <WebString webStringKey="tp.errormsg.validation.email" />
                </div>
            ) : null}
        </>
    ) : (
        <EditToggle
            inputValue={enteredEmailAddress}
            onToggle={handleToggleEditMode}
        />
    );
};

EditInputEmailAddress.propTypes = {
    onEmailChange: PropTypes.func,
    enteredEmailAddress: PropTypes.string
};
EditInputEmailAddress.defaultProps = {
    onEmailChange: () => undefined,
    enteredEmailAddress: ''
};

export default EditInputEmailAddress;
