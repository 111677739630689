import {getWebStringForRole} from '../users/rolesUtils';
import i18nUtils from 'lib/i18nUtils';
import timeUtils from 'lib/timeUtils';
/**
 * returns array with values, each value should be on its one place depending on the placing defined
 * in the corresponding dimension (allDefinedDimensionsWithAllDefinedValues);
 * If value is missing "-" will be added instead;
 * If value contains "," it will be replaces by "." in order to avoid the separating of data for a new row in csv file.
 *
 *
 * @example
 * // returns ['blue', 'green', '-', '-']
 * getValuesForDisplay(Map(
 *                         colors → Set([ 'blue', 'green', 'red', 'white']),
 *                         numbers → Set(['12', '34', '78', '79'])
 *                        ),
 *                    'colors',
 *                    ['green', 'blue']
 * )
 *
 *
 * @param {Map<string, Set<string>>} allDefinedDimensionsWithAllDefinedValues
 * @param {string} dimension
 * @param {Array<string>} values
 * @returns {Array<string>}
 */
const getDimensionValuesForDisplayInRows = (
    allDefinedDimensionsWithAllDefinedValues,
    dimension,
    values
) => {
    const possibleValues = allDefinedDimensionsWithAllDefinedValues.get(
        dimension
    );

    const valuesForDisplay = [];

    for (let item of possibleValues) {
        const valueToSave = values.includes(item)
            ? item.replace(/,/g, '.')
            : '-';
        valuesForDisplay.push(valueToSave);
    }
    return valuesForDisplay;
};

/**
 * find out all possible dimensions in the users array and their possible values without duplicates
 *
 * @example
 * // returns  new Map([
 *                      ['colors', new Set(['blue', 'green', 'white'])],
 *                      ['numbers', new Set(['12', '34', '78', '79'])],
 *                      ['letters', new Set(['A', 'G'])]
 *                     ])
 *
 *
 * getAllDefinedDimensionsWithAllDefinedValues([
 *         {
 *           id: 45,
 *           roles: [{
 *               roleConfiguration: {
 *                   "role": 'APPLICANT'
 *               },
 *               scope: [{
 *                   dimension: 'colors',
 *                   values: ['blue', 'green']
 *               },
 *                   {
 *                       dimension: 'numbers',
 *                       values: ['12', '34']
 *                   }
 *               ]
 *           },
 *               {
 *                   roleConfiguration: {
 *                       "role": 'ADMIN'
 *                   },
 *                   scope: [{
 *                       dimension: 'letters',
 *                       values: ['A', 'G']
 *                   }
 *                   ]
 *               }*
 *           ]
 *       },
 *       {
 *           id: 47,
 *           roles: [{
 *               roleConfiguration: {
 *                   "role": 'APPLICANT'
 *               },
 *               scope: [{
 *                   dimension: 'colors',
 *                   values: ['blue', 'green', 'white']
 *               },
 *                   {
 *                       dimension: 'numbers',
 *                       values: ['12', '34', '78', '79']
 *                   }
 *               ]
 *           }
 *           ]
 *       }
 *])
 *
 *
 * @param {Array<object>} users
 * @returns {Map<string, Set<string>>}
 */
const getAllDefinedDimensionsWithAllDefinedValues = (users) => {
    const possibleScopesWithPossibleValues = new Map();

    users.forEach(({roles}) => {
        roles.forEach(({scope}) => {
            scope.forEach(({dimension, values}) => {
                let valuesToSave = values;
                if (possibleScopesWithPossibleValues.has(dimension)) {
                    valuesToSave = [
                        ...possibleScopesWithPossibleValues.get(dimension),
                        ...values
                    ].sort();
                }
                possibleScopesWithPossibleValues.set(
                    dimension,
                    new Set(valuesToSave)
                );
            });
        });
    });

    return possibleScopesWithPossibleValues;
};

/**
 *
 * @param {Array<string>} titleFields
 * @param {Object} stringResources
 * @returns {Array<string>} - translated title fields
 */
const getTranslationsForTitleFields = (titleFields, stringResources) => {
    return titleFields.map((title) =>
        i18nUtils.getTranslatedWebStringKey(
            stringResources,
            `tp.table.usermanagement.${title}`
        )
    );
};
const titleFields = [
    'userid',
    'userstatus',
    'firstname',
    'lastname',
    'email',
    'created',
    'role',
    'dimension',
    'dimensionvalues'
];

/**
 *
 * @param {Array<object>} usersJson
 * @param {Array<object>} stringResources
 * @returns {string} long string separated by ',' for new column and '\n' for new row
 */
const usersJsonArrayToCSVForMultipleRoles = (usersJson, stringResources) => {
    const titleFieldsTranslated = getTranslationsForTitleFields(
        titleFields,
        stringResources
    );
    const allDefinedDimensionsWithAllDefinedValues = getAllDefinedDimensionsWithAllDefinedValues(
        usersJson
    );

    let csvStr = titleFieldsTranslated.join(',') + '\n';

    usersJson.forEach(
        ({id, firstName, status, lastName, email, roles, created}) => {
            const translatedStatus = i18nUtils.getTranslatedWebStringKey(
                stringResources,
                `tp.table.usermanagement.userstatus.${status}`
            );
            roles.forEach(({roleConfiguration: {role}, scope}) => {
                const translatedRole = i18nUtils.getTranslatedWebStringKey(
                    stringResources,
                    getWebStringForRole(role)
                );
                scope.forEach(
                    ({dimension, values}) =>
                        (csvStr +=
                            [
                                id,
                                translatedStatus,
                                firstName,
                                lastName,
                                email,
                                timeUtils.getFormattedUnixTimestring(
                                    created,
                                    timeUtils.momentTimeFormat.L
                                ),
                                translatedRole,
                                dimension,
                                getDimensionValuesForDisplayInRows(
                                    allDefinedDimensionsWithAllDefinedValues,
                                    dimension,
                                    values
                                )
                            ].join(',') + '\n')
                );
            });
            csvStr += '\n';
        }
    );
    return csvStr;
};

export default {
    getDimensionValuesForDisplayInRows,
    getAllDefinedDimensionsWithAllDefinedValues,
    getTranslationsForTitleFields,
    titleFields,
    usersJsonArrayToCSVForMultipleRoles
};
