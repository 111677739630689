import React from 'react';
import {connect} from 'react-redux';
import TpTable from '../tables/TpTable';
import {getReportsTableColumns} from './ReportTableColumns';
import * as reportGenerationActions from 'store/actions/reportGenerationActions';

const mapStateToProps = (state) => {
    const {
        isReportsLoading: isLoading,
        reportsList: data
    } = state.reportGeneration;

    const {
        i18n: {locale}
    } = state.settings;

    const mappedState = {
        data,
        isLoading,
        locale
    };
    mappedState.tableColumns = getReportsTableColumns(mappedState);
    return mappedState;
};

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(reportGenerationActions.getReports())
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    tableClassName: 'tp-reportgeneration-table',
    titleWebstringKey: 'tp.table.reportgeneration.title',
    descriptionWebstringKey: 'tp.table.reportgeneration.text',
    defaultPageSize: 10,
    serverSideLogicActivated: false,
    defaultSorted: [
        {
            Id: 'reportId',
            asc: true
        }
    ],
    ...ownProps
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TpTable);
