import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import UserFeatureTypes from 'types/UserFeatureTypes';
import AppTypes from 'types/AppTypes';
import AccountFeatureTypes from 'types/AccountFeatureTypes';
import DashboardElement from './DashboardElement';
import WebString from 'components/webString/WebString';
import elementKeyFeatureToggleMap from './elementKeyFeatureToggleMap';

/**
 * Dashboard page.
 *
 * Renders Dashboard elements for all user features when the
 * amount is more than one. Otherwise no tile will be
 * rendered and the user will be routed directly to the corresponding
 * application.
 */

export const Dashboard = (props) => {
    const {userFeatures, appType, accountFeatures} = props;

    const history = useHistory();

    const [
        filteredDashboardElementKeys,
        setFilteredDashboardElementKeys
    ] = useState([]);

    useEffect(() => {
        const filteredElements = Object.entries(elementKeyFeatureToggleMap)
            .filter(([key, value]) => hasRightForElement(value))
            .map(([key]) => key);
        if (filteredElements.length === 1) {
            history.push(`${filteredElements[0]}`);
        } else {
            setFilteredDashboardElementKeys(filteredElements);
        }
    }, []);

    const hasRightForElement = (elementKey) =>
        elementKey.userFeatures.some((feature) =>
            userFeatures.includes(feature)
        ) &&
        elementKey.appTypes.includes(appType) &&
        (!elementKey.accountFeature ||
            accountFeatures.includes(elementKey.accountFeature));

    return filteredDashboardElementKeys.length <= 1 ? null : (
        <div className="bk-tp-dashboard">
            <WebString
                className="bk-tp-dashboard__cards-heading"
                webStringKey="tp.dashboard.title.text"
            />
            {filteredDashboardElementKeys.map((element) => (
                <DashboardElement key={element} elementKey={element} />
            ))}
        </div>
    );
};

Dashboard.propTypes = {
    userFeatures: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(UserFeatureTypes))
    ).isRequired,
    appType: PropTypes.oneOf(Object.values(AppTypes)).isRequired,
    accountFeatures: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(AccountFeatureTypes))
    )
};

const featuresToCheck = [...Object.values(AccountFeatureTypes)];

const mapStateToProps = ({sessionContext: {user, account, appType}}) => {
    const userFeatures = user.features;
    const enabledFeatures = featuresToCheck
        .map((feat) => (account[feat] ? feat : undefined))
        .filter((feat) => !!feat);
    return {
        userFeatures,
        appType,
        accountFeatures: enabledFeatures
    };
};

export default connect(mapStateToProps)(Dashboard);
