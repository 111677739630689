import React from 'react';
import PropTypes from 'prop-types';
import NotificationPanelError from './NotificationPanelError';
import NotificationMessage from 'domain/NotificationMessage';
import WebString from 'components/webString/WebString';

/**
 * Box containing NotificationPanelErrors.
 * Rendered in top right of page content area.
 * @deprecated
 */
export default class NotificationPanel extends React.Component {
    static propTypes = {
        notifications: PropTypes.arrayOf(
            PropTypes.instanceOf(NotificationMessage)
        ).isRequired,
        title: PropTypes.string,
        availableHeight: PropTypes.number
    };

    static defaultProps = {
        title: '',
        availableHeight: 200
    };

    render() {
        if (this.props.notifications.length === 0) {
            return null;
        }
        return (
            <div
                id="notification-panel"
                className="bk-cs-box bk-cs-box--error tp-error-panel"
                ref={(node) => (this.notificationPanelRef = node)}>
                <div className="bk-cs-box__header bk-cs-box__header--error tp-error-panel__heading">
                    <WebString
                        className="bk-cs-box__header-content bk-cs-box__header-content--error bk-cs-nomargin"
                        webStringKey={this.props.title}
                    />
                </div>
                <div
                    className="bk-cs-box__content bk-cs-box__content--short tp-error-panel__body"
                    style={{maxHeight: `${this.props.availableHeight}px`}}>
                    <ul className="bk-cs-error-list bk-cs-nomargin">
                        {this.props.notifications.map((notification, index) => (
                            <NotificationPanelError
                                key={`error-${index}`}
                                message={notification}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}
