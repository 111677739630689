import i18nUtils from 'lib/i18nUtils';
import {TpSnackbarTypes} from 'components/common/TpSnackbar';

const getErrorSnackObject = (
    proposalId,
    webStringKeyForError,
    stringResources
) => ({
    id: `snack-error-externalquestionnaire-assignment-${proposalId}`,
    snackType: TpSnackbarTypes.ERROR,
    autoHideDuration: 4000,
    message: `${i18nUtils.getTranslatedWebStringKey(
        stringResources,
        webStringKeyForError
    )} `
});

export default getErrorSnackObject;
