import React from 'react';
import PropTypes from 'prop-types';
import Stickyfill from 'stickyfill';
import globalDomMeasurements from './globalDomMeasurements';
import WebString from 'components/webString/WebString';

let stickyfill = null;

/**
 * encapsulates all the layout markup and positioning logic for the main
 * display of proposals and alert manager risk alert.
 *
 * The scrolling and placement of the Notification (Error) Panel and Navigator (Action) Panel
 * is also managed by this component.
 * The height of the Notification Panel is adjusted to use the available space. This is
 * usually changed on scroll.
 * This positioning and height are managed through measuring the heights and top positions
 * of the DOM elements, and the window height. They are set on initial mount, and by
 * responding to scroll, wheel and resize events.
 *
 * These panels are set to stick to the top of the page, so they do not scroll out of
 * sight. This uses `position: sticky`, which is native in Chrome, Firefox and Safari.
 * For Internet Explorer, the `Stickyfill` polyfill is used. This is invoked in all browsers,
 * but kills itself immediately if native support is detected. in IE, it switches the position
 * to fixed when the sticky effect is needed.
 *
 */
export default class ProcessLayout extends React.Component {
    static propTypes = {
        contentWebStringKeyBase: PropTypes.string.isRequired,
        errorPanel: PropTypes.element.isRequired,
        subtaskWebStringKeyBase: PropTypes.string,
        approvalStatusWebstringKey: PropTypes.string,
        formPanel: PropTypes.element,
        actionPanel: PropTypes.element
    };

    static defaultProps = {
        subtaskWebStringKeyBase: null,
        approvalStatusWebstringKey: null,
        formPanel: null,
        actionPanel: null
    };

    constructor(props) {
        super(props);

        /* DOM element references. Needed for measuring positions and sizes */
        this.domRefs = {
            notificationPanelContainer: null,
            navigatorPanelContainer: null,
            overContent: null,
            leftHandSide: null,
            rightHandSide: null
        };

        /* magic numbers for panel placement */
        this.panelsPlacementSizes = {
            minimumHeight: 0,
            overContentAdjustment: 25,
            rhsPanelHeightAdjustment: 2
        };

        this.cachedMeasurements = null;
        this.previousNotificationPanelHeight = null;
        this.notificationPanelContent = null;
        this.notificationPanelHeader = null;

        this.state = {
            notificationPanelHeight: this.panelsPlacementSizes.minimumHeight,
            npAvailableHeight: this.panelsPlacementSizes.minimumHeight
        };
    }

    componentDidMount() {
        this._setPanelsPositionsAndSizes();

        window.addEventListener('scroll', this._setPanelHeights);
        window.addEventListener('wheel', this._setPanelHeights);
        window.addEventListener('resize', this._resetPanels);

        /* use position:sticky polyfill for IE */
        this._initStickyfill();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._setPanelHeights);
        window.removeEventListener('wheel', this._setPanelHeights);
        window.removeEventListener('resize', this._resetPanels);
        stickyfill.kill();
    }

    UNSAFE_componentWillReceiveProps() {
        // clear cached measurements
        this.previousNotificationPanelHeight = undefined;
        this.notificationPanelContent = undefined;
        this.notificationPanelHeader = undefined;

        this._setPanelHeights();
    }

    componentDidUpdate() {
        this._setPanelHeights();
    }

    /**
     * Initialise stickyfill for IE for position: sticky, so that panels do not scroll off the top.
     * This is native in good browsers.
     * @returns {undefined}
     */
    _initStickyfill = () => {
        if (stickyfill === null) {
            stickyfill = Stickyfill();
        }
        stickyfill.add(this.domRefs.navigatorPanelContainer);
        stickyfill.add(this.domRefs.notificationPanelContainer);
    };

    /**
     * Remove stickyfill instance, calculate size and placement of panels, then reinitialise stickyfill.
     * This is required on resize, as stickyfill reads and caches measurements, which will not be correct after a resize.
     * @returns {undefined}
     */
    _resetPanels = () => {
        stickyfill.kill();
        this._setPanelsPositionsAndSizes();
        this._initStickyfill();
        this._setNotificationPanelHeight();
    };

    /**
     * Returns the notification panel header height.
     * We cannot use a hard-coded value, as this may depend upon locale chosen.
     * Caches the element to save time on expensive DOM lookup each time.
     * @returns {number} panel height
     */
    _getNotificationPanelHeaderHeight = () => {
        if (!this.notificationPanelHeader) {
            this.notificationPanelHeader = this.domRefs.notificationPanelContainer.getElementsByClassName(
                'bk-cs-box__header'
            )[0];
        }
        return this.notificationPanelHeader
            ? this.notificationPanelHeader.scrollHeight
            : 0;
    };

    /**
     * Returns the notification panel content height.
     * Caches the element to save time on expensive DOM lookup each time.
     * @returns {number} panel height
     */
    _getNotificationPanelContentHeight = () => {
        if (!this.notificationPanelContent) {
            this.notificationPanelContent = this.domRefs.notificationPanelContainer.getElementsByClassName(
                'bk-cs-box__content'
            )[0];
        }
        return this.notificationPanelContent
            ? this.notificationPanelContent.scrollHeight
            : 0;
    };

    /**
     * Read initial measurements to be cached, to save looking them up each time.
     * @returns {Object}
     */
    _setCommonMeasurements = () => {
        this.cachedMeasurements = {
            windowHeight: window.innerHeight,
            overContentHeight:
                this.domRefs.overContent.offsetHeight +
                this.panelsPlacementSizes.overContentAdjustment,
            leftHandSideTop: this.domRefs.leftHandSide.getBoundingClientRect()
                .top
        };
    };

    /**
     * Set the height of the right hand panel to the available window height, or the height of the form,
     * if this scrolls off the page, whichever is greater.
     * @returns {undefined}
     */
    _setRhsContainerPanelHeight = () => {
        const formHeight =
            this.domRefs.leftHandSide.offsetHeight +
            globalDomMeasurements.footerHeight +
            globalDomMeasurements.spacerHeight -
            this.cachedMeasurements.overContentHeight;

        const availableWindowHeight =
            this.cachedMeasurements.windowHeight -
            this.cachedMeasurements.leftHandSideTop -
            this.cachedMeasurements.overContentHeight -
            this.panelsPlacementSizes.rhsPanelHeightAdjustment;

        const rightHandContainerHeight = Math.max(
            formHeight,
            availableWindowHeight
        );

        this.domRefs.rightHandSide.style.height = `${rightHandContainerHeight}px`;
    };

    /**
     * Calculate initial positions and sizes of panels.
     * This is called on initial component mount and resize.
     * @returns {undefined}
     */
    _setPanelsPositionsAndSizes = () => {
        this._setCommonMeasurements();

        // set navigator panel sticky top
        const windowHeight = this.cachedMeasurements.windowHeight;
        const navigatorPanelHeight = this.domRefs.navigatorPanelContainer.getBoundingClientRect()
            .height;
        const navPanelDistanceFromBottom =
            navigatorPanelHeight +
            globalDomMeasurements.footerHeight +
            2 * globalDomMeasurements.spacerHeight;
        this.cachedMeasurements.navigatorPanelTop =
            windowHeight - navPanelDistanceFromBottom;

        this._setRhsContainerPanelHeight();

        // update dom ref sizes. try to keep all reads and all writes together, for efficiency.
        this.domRefs.notificationPanelContainer.style.marginTop = `${this.cachedMeasurements.overContentHeight}px`;
        this.domRefs.notificationPanelContainer.style.top = `${globalDomMeasurements.headerHeight}px`;
        this.domRefs.navigatorPanelContainer.style.top = `${this.cachedMeasurements.navigatorPanelTop}px`;
    };

    /**
     * Check and adjust overall right hand side container height, and notification panel height.
     * @returns {undefined}
     */
    _setPanelHeights = () => {
        this._setRhsContainerPanelHeight();
        this._setNotificationPanelHeight();
    };

    /**
     * Calculate and set the best height for the notification panel.
     * This allows it to grow into the space below, as the form top is pushed up by scrolling.
     * @returns {undefined}
     */
    _setNotificationPanelHeight = () => {
        const notificationPanelTop = this.domRefs.notificationPanelContainer.getBoundingClientRect()
            .top;

        const npHeaderHeight = this._getNotificationPanelHeaderHeight();

        const heightAvailable =
            this.cachedMeasurements.navigatorPanelTop -
            notificationPanelTop -
            npHeaderHeight -
            globalDomMeasurements.spacerHeight;

        const notificationPanelHeight = Math.min(
            this._getNotificationPanelContentHeight(),
            heightAvailable
        );

        // only trigger a re-render if heightAvailable has changed
        if (heightAvailable !== this.previousHeightAvailable) {
            this.previousHeightAvailable = heightAvailable;
            this.setState({
                notificationPanelHeight: notificationPanelHeight,
                npAvailableHeight: heightAvailable
            });
        }
    };

    render() {
        const errorPanelWithHeight = React.cloneElement(
            this.props.errorPanel || {},
            {
                availableHeight: this.state.npAvailableHeight
            }
        );

        return (
            <div
                id="tp_proposal_task_content"
                className="row tp-process-container">
                <div
                    id="tp_proposal_current_task_content"
                    className="tp-form-container col-xs-8"
                    ref={(node) => (this.domRefs.leftHandSide = node)}>
                    <div
                        id="over-form-content"
                        className="tp-form__top-content"
                        ref={(node) => (this.domRefs.overContent = node)}>
                        <h1 className="tp-form__heading">
                            <WebString
                                id="tp_proposal_current_task_content__title"
                                webStringKey={`${this.props.contentWebStringKeyBase}.title`}
                            />
                        </h1>
                        {this.props.approvalStatusWebstringKey !== null ? (
                            <WebString
                                className="tp-form__text"
                                id="tp_proposal_current_task_content__description"
                                webStringKey={`${this.props.approvalStatusWebstringKey}.text`}
                            />
                        ) : (
                            <WebString
                                className="tp-form__text"
                                id="tp_proposal_current_task_content__description"
                                webStringKey={`${this.props.contentWebStringKeyBase}.text`}
                            />
                        )}
                        {this.props.subtaskWebStringKeyBase ? (
                            <WebString
                                id="tp_proposal_current_task_content__subtaskdescription"
                                className="tp-form__text"
                                defaultValue=""
                                webStringKey={`${this.props.subtaskWebStringKeyBase}.text`}
                            />
                        ) : null}
                    </div>
                    <div id="tp_proposal_current_sub_task_content">
                        {this.props.formPanel}
                    </div>
                </div>
                <div className="col-xs-4 stickyfill">
                    <div ref={(node) => (this.domRefs.rightHandSide = node)}>
                        <div
                            id="error-panel-container"
                            className="bk-cs-sticky"
                            ref={(node) =>
                                (this.domRefs.notificationPanelContainer = node)
                            }>
                            {errorPanelWithHeight}
                        </div>
                        <div
                            id="action-panel-container"
                            className="bk-cs-sticky"
                            ref={(node) =>
                                (this.domRefs.navigatorPanelContainer = node)
                            }>
                            {this.props.actionPanel}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
