import TextOnlySelectWidget from './textOnly/TextOnlySelectWidget';
import TextOnlyRadioWidget from './textOnly/TextOnlyRadioWidget';
import TextOnlyDateWidget from './textOnly/TextOnlyDateWidget';
import TextOnlyBaseInputWidget from './textOnly/TextOnlyBaseInputWidget';
import TextOnlyDisplayStringAsDateWidget from './textOnly/TextOnlyDisplayStringAsDateWidget';
import TextOnlyCheckboxWidget from './textOnly/TextOnlyCheckboxWidget';
import TextOnlyDisplayWebstringWidget from './textOnly/TextOnlyDisplayWebstringWidget';

/**
 * Object containing all text-only custom widgets
 */
export default {
    TextWidget: TextOnlyBaseInputWidget,
    // base input
    TextareaWidget: TextOnlyBaseInputWidget,
    SelectWidget: TextOnlySelectWidget,
    RadioWidget: TextOnlyRadioWidget,
    // checkboxwidget
    CheckboxesWidget: TextOnlySelectWidget,
    DateWidget: TextOnlyDateWidget,
    DisplayValueAsWebstringWidget: TextOnlyDisplayWebstringWidget,
    'cs-displayStringAsDate': TextOnlyDisplayStringAsDateWidget,
    'cs-date': TextOnlyDateWidget,
    'cs-checkBoxRequired': TextOnlyCheckboxWidget
};
