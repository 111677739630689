import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TpPopupButton from 'components/common/buttons/TpPopupButton';
import * as processActions from 'store/actions/processActions';
import StatusTypes from 'types/StatusTypes';
import WebString from 'components/webString/WebString';

/**
 *  Component for displaying 'cancel proposal' button in the GlobalFunctions component.
 *  On click, a popup window will be shown with warning message and 'yes' and 'no' buttons
 *  to confirm or cancel the action.
 *  After confirmation the user will be redirected to the appContextPath.
 *  After cancellation, the popup disappears and the user stays on the same page.
 *
 *  If proposal status is not 'ACTIVE', do not show the button.
 *
 */
class AbortProposalButton extends React.Component {
    static propTypes = {
        proposal: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.oneOf(Object.values(StatusTypes.Proposal))
        }).isRequired,
        cancelProposal: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    /**
     * Creates a popup config object
     * @return {object} - configuration object for TpPopUp component in TpPopupButton
     * @private
     */
    _getCancelProposalPopupConfig = () => {
        return {
            headerInfo: {
                webStringKey: 'cs.text.alert.warning',
                type: 'warning',
                showCloseButton: false
            },
            buttons: [
                {
                    webStringKey: 'cs.text.answer.no',
                    id: 'btn-cancel-proposal-no'
                },
                {
                    webStringKey: 'cs.text.answer.yes',
                    id: 'btn-cancel-proposal-yes',
                    action: () =>
                        this.props.cancelProposal(
                            this.props.proposal.id,
                            this.props.history
                        )
                }
            ]
        };
    };

    render() {
        const {
            proposal: {id, status}
        } = this.props;
        if (!(id && status && status === StatusTypes.Proposal.ACTIVE)) {
            return null;
        }

        return (
            <TpPopupButton
                asLink={true}
                className={this.props.className}
                id="cancel-proposal-btn"
                iconClassName="tp-meta-info-bar__abort-proposal-button-icon"
                webStringKey="tp.button.cancelproposal"
                webStringClassName="tp-meta-info-bar__abort-proposal-button-webstring"
                popupConf={this._getCancelProposalPopupConfig()}>
                <WebString webStringKey="tp.text.process.cancel.confirm" />
            </TpPopupButton>
        );
    }
}

const mapStateToProps = ({process: {proposal}}) => ({proposal});

const mapDispatchToProps = (dispatch) => ({
    cancelProposal(proposalId, history) {
        dispatch(
            processActions.abortProposal(proposalId, {
                onFinish() {
                    history.push('/');
                }
            })
        );
    }
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AbortProposalButton)
);
export {AbortProposalButton};
