import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import HTTPClient from 'lib/HTTPClient';
import WebString from 'components/webString/WebString';
import TpPopup from 'components/common/TpPopup';
import DisplayJsonObject from 'components/tpForm/fields/DisplayJsonObject';
import Loader from 'components/loader/CircularLoader';
import ToggleContainerState from 'components/tpForm/fields/ToggleContainerState';

/**
 * Represents a Dow Jones ad hoc check entry.
 * Renders a tbody containing a tr with the display fields
 * displays the profile on click of the link(name) in a pop up window
 */
export class AdHocCheckResultEntry extends React.Component {
    static propTypes = {
        tableRow: PropTypes.object.isRequired,
        onError: PropTypes.func
    };

    static defaultProps = {
        onError: () => undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            dowJonesProfileData: null
        };
        this.popUpConfig = {
            headerInfo: {
                showCloseButton: true,
                type: 'generic'
            },
            buttons: [
                {
                    webStringKey: 'OK',
                    id: 'btn-show-profile-ok'
                }
            ],
            size: 'full'
        };
    }

    /**
     * Closes the modal window.
     */
    handleOnClosePopup = () => {
        this.setState({showPopup: false, dowJonesProfileData: null});
    };

    /**
     * Fetch the risk profile data from the BE and put into state for display.
     * @param {String} peId
     * @param {String} name
     * @returns undefined
     */
    async getDowJonesProfileAsync(peId, name) {
        let response;
        this.setState({showPopup: true});
        try {
            response = await HTTPClient.get(`factiva/data/${peId}`);
            this.setState({dowJonesProfileData: response});
        } catch (error) {
            this.setState({showPopup: false}, () => this.props.onError(error));
        }
    }

    displayProfileOnClick = () => {
        const {peId, name} = this.props.tableRow;
        this.getDowJonesProfileAsync(peId, name);
    };

    /**
     * Displays the popUp Dialog
     * @returns undefined
     */
    getPopup() {
        const newHeaderInfo = {
            ...this.popUpConfig.headerInfo,
            webStringKey: this.props.tableRow.name
        };
        const newPopupConfig = {...this.popUpConfig, headerInfo: newHeaderInfo};
        return this.state.showPopup ? (
            <TpPopup {...newPopupConfig} onClose={this.handleOnClosePopup}>
                <ToggleContainerState scope="container" />
                {this.state.dowJonesProfileData ? (
                    <DisplayJsonObject
                        jsonObject={this.state.dowJonesProfileData}
                    />
                ) : (
                    <Loader />
                )}
            </TpPopup>
        ) : null;
    }

    render() {
        const {flags, peId, name, matchPct, countryCode} = this.props.tableRow;
        const countryWebstringKey =
            !countryCode || countryCode.toLowerCase() === 'null'
                ? 'tp.text.country.unknown'
                : `cs.text.country.${countryCode}`;
        return (
            <tbody>
                <tr className="tp-adhoccheck-table__row">
                    <td className="tp-adhoccheck__table-cell">
                        {flags.join(', ')}
                    </td>
                    <td className="tp-adhoccheck__table-name-cell">
                        <span
                            id={`adhoc-check-profile-${peId}`}
                            onClick={this.displayProfileOnClick}
                            className="tp-adhoccheck__table-link">
                            {name}
                        </span>
                        {this.getPopup()}
                    </td>
                    <td className="tp-adhoccheck__table-cell">
                        <WebString webStringKey={countryWebstringKey} />
                    </td>
                    <td className="tp-adhoccheck__table-cell">{matchPct}%</td>
                </tr>
            </tbody>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onError(error) {
        dispatch({type: 'GET_DOWJONES_PROFILE_FAILED', error});
    }
});
export default connect(null, mapDispatchToProps)(AdHocCheckResultEntry);
