export default {
    SELF_REGISTRATION_URL: '/register',

    LOGIN_URL: '/login',
    LOGIN_CALLBACK_URL: '/login/callback',

    LOGOUT_URL: '/logout',
    LOGOUT_CALLBACK_URL: '/logout/callback',

    SILENT_RENEW_URL: '/silentrenew',

    ALERTS_URL: '/alerts',

    USERS_URL: '/users',
    USER_CREATE_URL: '/users/create',

    PROPOSALS_URL: '/proposals',
    PROPOSAL_URL: '/proposals/proposal',
    PROPOSAL_INFO_URL: '/proposals/proposal/info',
    ASSESSMENT_URL: '/proposals/assessment',

    BUSINESS_PARTNERS_URL: '/businesspartners',

    EXTERNAL_QUESTIONNAIRES_URL: '/external',

    PRE_CHECK_URL: '/precheck',

    AD_HOC_CHECK_URL: '/adhoccheck',
    REPORTS_URL: '/reports'
};
