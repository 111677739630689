import schemaBlocks from './schemaBlocks';
import userSchemaNameTypes from '../userSchemaNameTypes';

export const getGeneralFormSchema = (userSchemaNameType) => ({
    type: 'object',
    required:
        userSchemaNameType === userSchemaNameTypes.UPDATE
            ? schemaBlocks.generalFieldsExtended
            : schemaBlocks.generalFields,
    properties:
        userSchemaNameType === userSchemaNameTypes.UPDATE
            ? schemaBlocks.generalPropertiesExtended
            : schemaBlocks.generalProperties
});

export const getRoleFormSchema = (
    dimensions,
    singleRights,
    isActiveDefault,
    isRoleActiveTitle
) => ({
    type: 'object',
    properties: {
        isRoleActive: {
            title: isRoleActiveTitle,
            type: 'boolean',
            enumNames: ['cs.text.answer.yes', 'cs.text.answer.no'],
            default: isActiveDefault
        }
    },
    dependencies: {
        isRoleActive: schemaBlocks.getOneOfIsRoleActive(
            dimensions,
            singleRights
        )
    }
});
