import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import timeUtils from 'lib/timeUtils';
import defaultAppValues from 'lib/defaultAppValues';

/**
 * Takes in a `value` property which is a unix epoch timestamp and renders it
 * in a beautiful format.
 *
 * @param {Number} props.value - unix timestamp
 * @param {String} props.locale - the locale locale to display the format in
 * @param {String} props.momentTimeFormat - the display format / one of momentTimeFormat (see timeUtils.js)
 * @param {String} props.className
 *
 * @returns {ReactElement}
 *
 * @example
 *    <FormattedUnixTime value={15206075150000} locale="en-US" />
 *
 *    output: <span>09.03.2018</span>
 */
export const FormattedUnixTime = (props) => {
    return (
        <span className={props.className}>
            {timeUtils.getFormattedUnixTimestring(
                props.value,
                props.momentTimeFormat
            )}
        </span>
    );
};

FormattedUnixTime.propTypes = {
    value: PropTypes.number.isRequired,
    /* is used to trigger rerendering if changes */
    locale: PropTypes.string,
    momentTimeFormat: PropTypes.oneOf(
        Object.values(timeUtils.momentTimeFormat)
    ),
    className: PropTypes.string
};

FormattedUnixTime.defaultProps = {
    locale: defaultAppValues.defaultLocale,
    className: '',
    momentTimeFormat: timeUtils.momentTimeFormat.L
};

const mapStateToProps = (state) => ({
    locale: state.settings.i18n.locale
});

export default connect(mapStateToProps)(FormattedUnixTime);
