import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {middleware as reduxPackMiddleware} from 'redux-pack';
import thunkMiddleware from 'redux-thunk';
import baseReducers from './reducers/baseReducers';
import mainErrorMiddleware from 'store/middleware/requestError';

/**
 *
 * @param {Object} appSpecificReducers - reducers that will be combined with baseReducers
 * @param {Object} initialState
 * @return {*}
 */
export const setupStore = (
    appSpecificReducers,
    initialState = {settings: {AppContextPath: '/'}}
) => {
    const createStoreWithMiddleware = compose(
        applyMiddleware(
            thunkMiddleware,
            reduxPackMiddleware,
            mainErrorMiddleware
        ),
        process.env.NODE_ENV !== 'production' &&
            process.env.BROWSER &&
            window.devToolsExtension
            ? window.devToolsExtension()
            : (f) => f
    )(createStore);

    const mergedReducer = combineReducers({
        ...appSpecificReducers,
        ...baseReducers
    });

    const store = createStoreWithMiddleware(
        mergedReducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    return store;
};
