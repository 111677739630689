import React from 'react';
import PropTypes from 'prop-types';
import timeUtils from 'lib/timeUtils';

class MessageEntry extends React.Component {
    static propTypes = {
        author: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        isAuthorCurrentUser: PropTypes.bool.isRequired
    };

    getCssClasses = () => {
        if (this.props.isAuthorCurrentUser) {
            return {
                chatFieldClass:
                    'bk-tp-chatfield__message bk-tp-chatfield__message--ours',
                authorClass: '',
                timestampClass:
                    'col-sm-12 bk-tp-chatfield__messagetimestamp bk-tp-chatfield__messagetimestamp--ours',
                messageTextClass:
                    'bk-tp-chatfield__messagetext bk-tp-chatfield__messagetext--ours'
            };
        }

        return {
            chatFieldClass:
                'bk-tp-chatfield__message bk-tp-chatfield__message--theirs',
            authorClass:
                'col-sm-8 bk-tp-chatfield__messageauthor bk-tp-chatfield__messageauthor--theirs',
            timestampClass:
                'col-sm-4 bk-tp-chatfield__messagetimestamp bk-tp-chatfield__messagetimestamp--theirs',
            messageTextClass:
                'bk-tp-chatfield__messagetext bk-tp-chatfield__messagetext--theirs'
        };
    };

    render() {
        const {
            chatFieldClass,
            authorClass,
            timestampClass,
            messageTextClass
        } = this.getCssClasses();

        return (
            <div className={chatFieldClass}>
                <div className="row">
                    {!this.props.isAuthorCurrentUser ? (
                        <div className={authorClass}>{this.props.author}</div>
                    ) : null}
                    <div className={timestampClass}>
                        {timeUtils.getDateDependentTimestring(
                            this.props.created
                        )}
                    </div>
                </div>
                <span className={messageTextClass}>{this.props.text}</span>
            </div>
        );
    }
}

export default MessageEntry;
