import i18nUtils from 'lib/i18nUtils';

export const getWebStringForRole = (role) =>
    `tp.text.role.${role.replace(/_/g, '').toLowerCase()}`;

export const sortRolesByWebStringValue = (roles, stringResources) => {
    const rolesWithKeysAndValues = roles.map((role) => {
        return {
            key: role,
            value: i18nUtils.getTranslatedWebStringKey(
                stringResources,
                getWebStringForRole(role)
            )
        };
    });
    return rolesWithKeysAndValues
        .sort(function (a, b) {
            return a.value.localeCompare(b.value);
        })
        .map((role) => role.key);
};

export const getRoleFromObject = (roleObject) =>
    roleObject.roleConfiguration.role.toLowerCase();

export const MIN_NUM_OF_ROLES_TO_SHOW = 3;

export const getShowMore = (showMoreOrLess, id) => {
    const showMoreItem = showMoreOrLess.find((item) => item.id === id);
    return showMoreItem ? showMoreItem.showMore : false;
};
