import React from 'react';
import urls from 'routes/urls';

export const isProcessPage = (currentRelativeURL) => {
    const found = [
        `${urls.PROPOSAL_URL}/`,
        `${urls.ASSESSMENT_URL}/`,
        `${urls.EXTERNAL_QUESTIONNAIRES_URL}/`
    ].find((url) => currentRelativeURL.includes(url));
    return !!found;
};

export const isInfoPage = (currentRelativeURL) =>
    currentRelativeURL.includes(urls.PROPOSAL_INFO_URL);

export const isAlertPage = (currentRelativeURL) =>
    !!currentRelativeURL.match(`${urls.ALERTS_URL}/[0-9]+$`);
