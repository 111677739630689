import React from 'react';
import defaultAppValues from 'lib/defaultAppValues';
import WebString from 'components/webString/WebString';
import SearchFilter from 'components/dashboard/filters/SearchFilter';
import {
    tableColFallback,
    isArrayNullOrEmpty
} from 'components/tables/tableColumnsUtils';

const businessPartnersTableColumns = [
    {
        id: 'bpId',
        Header: <WebString webStringKey="cs.text.bpid" />,
        accessor: (entity) => entity['businessPartnerId'],
        Cell: (props) =>
            props.value || defaultAppValues.defaultTableCellPlaceHolder,
        filterable: true,
        Filter: (filterdata) => <SearchFilter {...filterdata} />,
        className: 'tp-all-proposals-table__bp-id-column',
        headerClassName: 'tp-all-proposals-table__bp-id-header'
    },
    {
        id: 'bpName',
        Header: <WebString webStringKey="cs.text.name" />,
        accessor: (entity) => entity['businessPartnerName'],
        Cell: (props) =>
            props.value || defaultAppValues.defaultTableCellPlaceHolder,
        filterable: true,
        Filter: (filterdata) => <SearchFilter {...filterdata} />,
        className: 'tp-all-proposals-table__bp-name-column',
        headerClassName: 'tp-all-proposals-table__bp-name-header'
    },
    {
        id: 'bpCountry',
        Header: <WebString webStringKey="cs.text.country" />,
        accessor: (entity) => entity['country'],
        Cell: (props) =>
            props.value || defaultAppValues.defaultTableCellPlaceHolder,
        filterable: true,
        Filter: (filterdata) => <SearchFilter {...filterdata} />,
        className: 'tp-all-proposals-table__country-column',
        headerClassName: 'tp-all-proposals-table__country-header'
    },
    {
        id: 'bpCity',
        Header: <WebString webStringKey="cs.text.city" />,
        accessor: (entity) => entity['city'],
        Cell: (props) =>
            props.value || defaultAppValues.defaultTableCellPlaceHolder,
        filterable: true,
        Filter: (filterdata) => <SearchFilter {...filterdata} />,
        className: 'tp-all-proposals-table__city-column',
        headerClassName: 'tp-all-proposals-table__city-header'
    }
];

const getBusinessPartnersColumns = (configuredColumns) => {
    if (isArrayNullOrEmpty(configuredColumns)) {
        return [];
    }
    return configuredColumns.map(
        (confCol) =>
            businessPartnersTableColumns.find(
                (tableCol) => confCol === tableCol.id
            ) || tableColFallback(confCol)
    );
};

export {getBusinessPartnersColumns};
