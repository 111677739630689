import React, {useContext} from 'react';
import ProcessingLoginBox from './ProcessingLoginBox';
import {AuthContext} from 'components/authorization/AuthProvider';

/* This component is going to be used after login when the user is going to be
 *  redirected to the redirect_uri set on the IDENTITY_CONFIG in authConst.js.
 *  The render method will only be displayed very briefly unless there is an error.
 */
const LoginCallback = () => {
    const auth = useContext(AuthContext);
    auth.signinRedirectCallback();
    return <ProcessingLoginBox />;
};

export default LoginCallback;
