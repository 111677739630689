import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import UserFeatureTypes from 'types/UserFeatureTypes';
import UserFeatureToggle from 'components/userFeatureToggle/UserFeatureToggle';
import ExternalQuestionnaireProcess from 'components/proposal/ExternalQuestionnaireProcess';
import NotFound from 'components/common/errorHandling/NotFound';
import ExternalQuestionnaireTable from 'components/dashboard/ExternalQuestionnaireTable';
import AppTypeToggle from 'components/appTypeToggle/AppTypeToggle';
import AppTypes from 'types/AppTypes';

const ExternalQuestionnaireRoute = () => {
    const {path} = useRouteMatch();
    return (
        <AppTypeToggle
            requiredAppTypes={[AppTypes.TP]}
            defaultChildren={<NotFound />}>
            <UserFeatureToggle
                requiredFeatureOneOf={[UserFeatureTypes.EXTERNAL]}
                defaultChildren={<NotFound />}>
                <Switch>
                    <Route
                        exact={true}
                        path={`${path}/:proposalId`}
                        component={ExternalQuestionnaireProcess}
                    />
                    <Route path={path} component={ExternalQuestionnaireTable} />
                </Switch>
            </UserFeatureToggle>
        </AppTypeToggle>
    );
};

export default ExternalQuestionnaireRoute;
