import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import timeUtils from 'lib/timeUtils';
import DisabledTextBlock from './DisabledTextBlock';
import {useSelector} from 'react-redux';
import i18nUtils from 'lib/i18nUtils';
import 'lib/reactDatePickerLocale';

const earliestAcceptableDate = new Date('1900-01-01');

/**
 * CsDate is a simple date picker based on react-datepicker.
 * React-datepicker uses locale from date-fns library, that should
 * bee imported and registered. Please add a new locale if necessary in lib/reactDatePickerLocale.js.
 * Only dates after the 01.01.1900 can be selected.
 * Selected value is converted to midnight UTC before being sent to the parent component
 * which sets the value in FormData.
 * When a date is fetched from the props it will be converted to midnight locale time
 * (rounded down) before being displayed.
 * The purpose of these conversions is to maintain the chosen date no matter which
 * time zone it's displayed in.
 *
 *
 * @example:
 *       @schema:
 *          date: {
 *                   "title": "date.title",
 *                   "type": "number",
 *                   "maximum": 987654321,      // optional
 *                   "minimum": 123456789       // optional
 *                  }
 *       @uiSchema:
 *          date: {
 *                  "ui:widget": "cs-date",
 *                  "ui:options": {"defaultToday": true }   // optional
 *                 }
 */
export const CsDate = (props) => {
    /* registered locale from date-fns library (see in lib/reactDatePickerLocale.js) */
    const {locale, stringResources} = useSelector(
        (state) => state.settings.i18n
    );

    useEffect(() => {
        if (!props.value && props.options.defaultToday) {
            props.onChange(
                timeUtils.convertToUtcMidnight(Date.now().valueOf())
            );
        }
    });

    const handleChange = (dateObj) => {
        const dateForFormData = dateObj
            ? dateObj
            : props.options.defaultToday
            ? Date.now()
            : undefined;
        const utcMidnightTimeValueOrUndefined = dateForFormData
            ? timeUtils.convertToUtcMidnight(dateForFormData.valueOf())
            : undefined;
        props.onChange(utcMidnightTimeValueOrUndefined);
    };

    const midnightLocalTimeValue = props.value
        ? timeUtils.convertToLocalMidnight(props.value)
        : null;

    if (props.readonly || props.disabled) {
        const disabledValue = midnightLocalTimeValue
            ? timeUtils.getFormattedUnixTimestring(midnightLocalTimeValue, 'L')
            : '';
        return <DisabledTextBlock id={props.id} value={disabledValue} />;
    }

    const placeholderText = i18nUtils.getTranslatedWebStringKey(
        stringResources,
        props.placeholder
    );

    return (
        <div className="bk-cs-form-field">
            <DatePicker
                id={props.id}
                minDate={props.schema.minimum || earliestAcceptableDate}
                selected={midnightLocalTimeValue}
                onChange={handleChange}
                onBlur={props.onBlur}
                locale={locale}
                className="form-control react-datepicker__input"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                dateFormat={timeUtils.dateFnsFormat.P}
                placeholderText={placeholderText}
                maxDate={props.schema.maximum || undefined}
            />
        </div>
    );
};

CsDate.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    options: PropTypes.shape({
        defaultToday: PropTypes.bool
    }),
    schema: PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.oneOf(['number']).isRequired,
        minimum: PropTypes.number,
        maximum: PropTypes.number
    }).isRequired,
    value: PropTypes.number,
    placeholder: PropTypes.string
};

CsDate.defaultProps = {
    value: undefined,
    options: {},
    placeholder: ''
};

export default CsDate;
