import React from 'react';
import {useRouteMatch, Route} from 'react-router-dom';
import SelfRegistrationPage from 'components/selfRegistration/SelfRegistrationPage';

const SelfRegistrationRoute = () => {
    const {path} = useRouteMatch();
    return (
        <Route
            exact={true}
            path={`${path}/:encryptedId`}
            component={SelfRegistrationPage}
        />
    );
};

export default SelfRegistrationRoute;
