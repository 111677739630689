import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {removeMainError} from 'store/actions/errorActions';
import {MainError} from './MainError';
import WebString from 'components/webString/WebString';
import StringPlaceholder from 'lib/StringPlaceholder';

/**
 * Container class for all MainErrors.
 */
export class MainErrorBar extends React.PureComponent {
    static propTypes = {
        /* removeMainError is called when an error is closed */
        hideError: PropTypes.func.isRequired,
        /**
         * It is an array of objects which are formed by the following properties
         * @property {string} type - it is responsible for visibility of the error: two types: 'SHOW_ERROR' and 'HIDE_ERROR'
         * @property {string} title - title of the error
         * @property {string} message - webStringKey of the error
         * @property {string} messageDefault - default text for the error
         * @property {string} placeholders - placeholders for webStringKey of the error (not required)
         */
        mainErrors: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                message: PropTypes.string,
                messageDefault: PropTypes.string,
                placeholders: PropTypes.arrayOf(
                    PropTypes.instanceOf(StringPlaceholder)
                )
            }).isRequired
        )
    };

    render() {
        if (!this.props.mainErrors || this.props.mainErrors.length === 0) {
            return null;
        }

        return (
            <>
                {this.props.mainErrors.map((err, index) =>
                    err.message || err.messageDefault ? (
                        <MainError
                            key={index}
                            errKey={index}
                            title={err.title}
                            onClose={this.props.hideError}
                            webStringComponent={
                                <WebString
                                    webStringKey={err.message}
                                    defaultValue={err.messageDefault}
                                    placeholder={err.placeholders}
                                />
                            }
                        />
                    ) : null
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    mainErrors: state.mainErrors
});

const mapDispatchToProps = (dispatch) => ({
    hideError: (errKey) => {
        dispatch(removeMainError(errKey));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainErrorBar);
