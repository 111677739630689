import AppTypes from 'types/AppTypes';

export const baProductName = 'Approvals';
export const tpProductName = 'Third Party';
export const approvalAccounts = [1013, 1223, 1093, 1268, 5001, 5005, 5007];

/**
 * Returns the correct application name based on account ID.
 * Currently all BA accounts are determined based on a fixed list of account IDs.
 *
 * @param {number} accountId
 * @returns {'Approvals'|'Third Party'}
 */
export const getAccountProductName = (accountId) => {
    const appType = getCorrectedAppType(accountId);
    return appType == AppTypes.BA ? baProductName : tpProductName;
};

/**
 * Returns the correct appType (TP/BA) for the account ID.
 *
 * @param {number} accountId
 * @returns {AppTypes.BA|AppTypes.TP}
 */
export const getCorrectedAppType = (accountId) =>
    approvalAccounts.includes(accountId) ? AppTypes.BA : AppTypes.TP;
