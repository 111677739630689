import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TpTable from 'components/tables/TpTable';
import * as userManagementActions from 'store/actions/userManagementActions';
import {getUserTableColumns} from './tableColumns';
import CreateUserButton from '../createOrUpdateUser/CreateUserButton';
import ImportUsersFromCsvButton from './importUsersFromCsv/ImportUsersFromCsvButton';
import ExportUsersButton from 'components/exportUsers/ExportUsersButton';
import {wrapInDispatch} from 'store/actions/actionUtils';
import {convertToApiSearchParams} from 'components/dashboard/utilities/searchParamConverter';
import urls from 'routes/urls';

const mapStateToProps = (state) => {
    const {locale} = state.settings.i18n;
    const {
        searchParams,
        allUsersLoading,
        users,
        totalPages
    } = state.userManagement.table;
    const mappedState = {
        data: users,
        searchParams,
        totalPages,
        isFetchingData: allUsersLoading,
        locale
    };
    const availableDimensions = state.sessionContext.account.possibleDimensions;
    const availableRoles = state.sessionContext.account.availableRoles;

    const csvImportToBeEnabled = () => {
        const {applicantCanSelectScope} = state.sessionContext.account;
        if (applicantCanSelectScope) {
            return true;
        } else {
            return !availableDimensions.some(
                (dimension) => dimension.values.length > 1
            );
        }
    };

    mappedState.csvImportVisible = csvImportToBeEnabled();
    mappedState.tableColumns = getUserTableColumns(
        availableDimensions,
        availableRoles
    );

    return mappedState;
};

const mapDispatchToProps = (dispatch) => ({
    fetchData: wrapInDispatch((reactTableSearchParams, locale) => {
        const apiSearchParams = convertToApiSearchParams(
            reactTableSearchParams,
            locale
        );
        return userManagementActions.getAllUsers(
            apiSearchParams,
            reactTableSearchParams
        );
    }, dispatch)
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // UserManagement table props
    tableClassName: 'tp-all-users-table',
    titleWebstringKey: 'tp.table.usermanagement.title',
    descriptionWebstringKey: 'tp.table.usermanagement.text',
    defaultPageSize: 10,
    defaultSorted: [
        {
            id: 'userId',
            desc: true
        }
    ],
    customButton: <CreateUserButton />,
    children: (
        <div className="tp-all-users-table-children__container">
            <ExportUsersButton users={stateProps.data} />
            {stateProps.csvImportVisible ? <ImportUsersFromCsvButton /> : null}
        </div>
    ),
    onTableRowClick(row) {
        ownProps.history.push(`${urls.USERS_URL}/${row.id}`);
    }
});

/**
 * User management table wraps the data table and maps all needed
 * values to the TpTable props, e.g. the correct webstring
 * keys.
 *
 * The table for the user admin has different columns than e.g. the
 * applicant table.
 */
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)(TpTable)
);
