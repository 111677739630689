import clonedeep from 'lodash.clonedeep';
import HTTPClient from 'lib/HTTPClient';
import * as proposalUtils from 'lib/proposalUtils';
import {ACTION_TYPES as PROCESS_ACTION_TYPES} from 'store/reducers/processReducer';
import {ACTION_TYPES as ALL_PROPOSALS_ACTION_TYPES} from 'store/reducers/dashboardReducer';
import TableTypes from 'types/TableTypes';
import StatusTypes from 'types/StatusTypes';
import ViewTypes from 'types/ViewTypes';

/* HELPER */
const checkIfNaN = (name, toCheck) => {
    /* support for IE11*/
    //The following works because NaN is the only value in JavaScript which is not equal to itself.
    Number.isNaN =
        Number.isNaN ||
        function (value) {
            return typeof value === 'number' && isNaN(value);
        };
    if (isNaN(toCheck)) {
        throw new Error(`${name} is not a number`);
    }
};

const checkIfNotAString = (name, toCheck) => {
    if (!toCheck || typeof toCheck !== 'string') {
        throw new Error(`${name} is not a string`);
    }
};

const checkIfNotDefined = (name, toCheck) => {
    if (!toCheck) {
        throw new Error(`${name} is not defined`);
    }
};
/* END.HELPER */

const saveTaskAsync = (
    proposalActionType,
    proposalId,
    taskToSave,
    view,
    meta
) => {
    checkIfNaN('proposalId', proposalId);
    checkIfNotDefined('taskToSave', taskToSave);
    checkIfNotAString('view', view);

    const preparedTaskForBE = proposalUtils.prepareTaskForTaskAPI(taskToSave);

    return {
        type: proposalActionType,
        promise: HTTPClient.put(
            `proposals/${proposalId}/view/${view}/tasks/${preparedTaskForBE.id}`,
            preparedTaskForBE
        ),
        meta
    };
};

/**
 * Creates a new proposal by POSTing to the proposals
 * endpoint.
 *
 * @param selectedScopePoint - selected scope point chosen by applicant
 * @param meta redux-pack meta object to register callbacks.
 * @returns redux-pack action of type "CREATE_PROPOSAL".
 */
const createProposal = (selectedScopePoint, meta) => ({
    type: PROCESS_ACTION_TYPES.CREATE_PROPOSAL,
    promise: HTTPClient.post(`proposals`, selectedScopePoint).then((data) => ({
        data,
        view: 'applicant'
    })),
    meta
});

const metaAndTypeForGettingAllProposals = (tableType, searchParams) => ({
    type: ALL_PROPOSALS_ACTION_TYPES.LOAD_ALL_PROPOSALS,
    meta: {
        tableType,
        searchParams,
        locationForErrorDisplay: window.location.pathname
    }
});

const getAllProposals = (apiSearchParams, reactTableSearchParams) => ({
    ...metaAndTypeForGettingAllProposals(
        TableTypes.PROPOSAL,
        reactTableSearchParams
    ),
    promise: HTTPClient.get('proposals', {
        ...apiSearchParams,
        view: ViewTypes.APPLICANT
    })
});

const getAllAssessedManagerProposals = (
    apiSearchParams,
    reactTableSearchParams,
    userRole
) => ({
    ...metaAndTypeForGettingAllProposals(
        TableTypes.ASSESSED,
        reactTableSearchParams
    ),
    promise: HTTPClient.get('proposals', {
        ...apiSearchParams,
        view: ViewTypes[userRole]
    })
});

const getAllAssessableManagerProposals = (
    apiSearchParams,
    reactTableSearchParams
) => ({
    ...metaAndTypeForGettingAllProposals(
        TableTypes.ASSESSMENT,
        reactTableSearchParams
    ),
    promise: HTTPClient.get('assessable-proposals', apiSearchParams)
});

const getAllExternalQuestionnaireProposals = (
    apiSearchParams,
    reactTableSearchParams
) => ({
    ...metaAndTypeForGettingAllProposals(
        TableTypes.EXTERNAL,
        reactTableSearchParams
    ),
    promise: HTTPClient.get('proposals', {
        ...apiSearchParams,
        view: ViewTypes.BUSINESS_PARTNER
    })
});

const getProposalAsView = (proposalId, view, meta) => {
    checkIfNaN('proposalId', proposalId);
    checkIfNotAString('view', view);

    return {
        type: PROCESS_ACTION_TYPES.LOAD_PROPOSAL,
        promise: HTTPClient.get(
            `proposals/${proposalId}/view/${view}`
        ).then((data) => ({data, view})),
        meta
    };
};

const getProposalTasks = (proposalId, view, meta) => {
    checkIfNaN('proposalId', proposalId);
    checkIfNotAString('view', view);

    return {
        type: PROCESS_ACTION_TYPES.LOAD_PROPOSAL_TASKS,
        promise: HTTPClient.get(`proposals/${proposalId}/view/${view}/tasks`),
        meta
    };
};

const getProposalTaskById = (proposalId, taskId, view, meta) => {
    checkIfNaN('proposalId', proposalId);
    checkIfNotAString('taskId', taskId);
    checkIfNotAString('view', view);

    return {
        type: PROCESS_ACTION_TYPES.LOAD_PROPOSAL_TASK_BY_ID,
        promise: HTTPClient.get(
            `proposals/${proposalId}/view/${view}/tasks/${taskId}`
        ),
        meta
    };
};

const updateCurrentTask = (updatedCurrentTask) => {
    checkIfNotDefined('updatedCurrentTask', updatedCurrentTask);

    return {
        type: PROCESS_ACTION_TYPES.UPDATE_CURRENT_TASK,
        payload: updatedCurrentTask
    };
};

const autosaveCurrentTask = (proposalId, taskToSave, view, meta) => {
    return saveTaskAsync(
        PROCESS_ACTION_TYPES.AUTOSAVE_CURRENT_TASK,
        proposalId,
        taskToSave,
        view,
        meta
    );
};

const completeCurrentTask = (proposalId, taskToSave, view, meta) => {
    const newTaskToSave = clonedeep(taskToSave);
    newTaskToSave.status = StatusTypes.Task.COMPLETED;

    return saveTaskAsync(
        PROCESS_ACTION_TYPES.SAVE_CURRENT_TASK,
        proposalId,
        newTaskToSave,
        view,
        meta
    );
};

const switchTaskAutosave = (proposalId, taskToSave, view, meta) =>
    saveTaskAsync(
        PROCESS_ACTION_TYPES.SWITCH_PROPOSAL_TASK_AUTOSAVE,
        proposalId,
        taskToSave,
        view,
        meta
    );

const clearProposal = (meta) => ({
    type: PROCESS_ACTION_TYPES.CLEAR_PROPOSAL,
    promise: Promise.resolve(),
    meta
});

/**
 * When the user cancels the current proposal, the status must be updated to 'CANCELLED'
 * and sent to the BE.
 * The user is then redirected to the dashboard.
 * @param {number} proposalId - The id of the proposal to abort
 * @param {object} meta - The meta object accepted by redux-pack
 * @returns {Function}
 */
const abortProposal = (proposalId, meta) => {
    checkIfNaN('proposalId', proposalId);

    return {
        type: PROCESS_ACTION_TYPES.ABORT_PROPOSAL,
        promise: HTTPClient.post(
            `proposals/${proposalId}/commands/abort-process`
        ),
        meta
    };
};

/**
 * When the user set the current proposal to 'INACTIVE', the status must be updated
 * to 'INACTIVE' and sent to the BE.
 * @param proposalId
 * @param meta
 * @returns {{meta: *, promise: Promise, type: string}}
 */
const setProposalInactive = (proposalId, meta) => {
    checkIfNaN('proposalId', proposalId);

    return {
        type: PROCESS_ACTION_TYPES.SET_PROPOSAL_INACTIVE,
        promise: HTTPClient.post(
            `proposals/${proposalId}/commands/set-inactive`
        ),
        meta
    };
};

/**
 * When the applicant rechecks the current proposal, the rechecked proposal is closed
 * and a new proposal is created.
 * The proposal status is updated as closed.
 * @param {number} proposalId - The id of the proposal to recheck
 * @param {object} meta - The meta object accepted by redux-pack
 * @returns {Function}
 */
const recheckProposal = (proposalId, meta) => {
    checkIfNaN('proposalId', proposalId);
    return {
        type: PROCESS_ACTION_TYPES.RECHECK_PROPOSAL,
        promise: HTTPClient.post(`proposals/${proposalId}/commands/recheck`),
        meta
    };
};

/**
 *
 * @returns {{type: string}}
 */
const clearTaskErrors = () => ({
    type: PROCESS_ACTION_TYPES.HIDE_ERRORS
});

/**
 *
 * @param {object} errors
 * @param {string} validationErrorType one of types in ValidationErrorTypes
 * @returns {object} The action object to add task errors.
 */
const showTaskErrors = (errors, validationErrorType = '') => ({
    type: PROCESS_ACTION_TYPES.SHOW_ERRORS,
    errorsType: validationErrorType,
    errors
});

const getImportedProposal = (proposalId) => {
    return {
        type: PROCESS_ACTION_TYPES.GET_IMPORTED_PROPOSAL,
        promise: HTTPClient.get(`proposals/${proposalId}/details`),
        meta: {proposalId}
    };
};

export {
    createProposal,
    getAllProposals,
    getAllAssessedManagerProposals,
    getAllAssessableManagerProposals,
    getAllExternalQuestionnaireProposals,
    getProposalAsView,
    getProposalTasks,
    getProposalTaskById,
    getImportedProposal,
    updateCurrentTask,
    autosaveCurrentTask,
    completeCurrentTask,
    switchTaskAutosave,
    abortProposal,
    recheckProposal,
    setProposalInactive,
    clearTaskErrors,
    clearProposal,
    showTaskErrors
};
