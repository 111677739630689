import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#8da6d6'
    },
    barColorPrimary: {
        backgroundColor: '#2f659b'
    }
})(LinearProgress);

function LinearLoader(props) {
    const className = props.visible
        ? 'tp-linear-loader__visible'
        : 'tp-linear-loader__hidden';

    return <ColorLinearProgress className={className} />;
}

LinearLoader.propTypes = {
    visible: PropTypes.bool.isRequired
};

export default LinearLoader;
