import React from 'react';
import PropTypes from 'prop-types';
import {saveAs} from 'file-saver';
import WebString from 'components/webString/WebString';
import IconButton from 'components/tpForm/fields/iconButtons/IconButton';

/**
 * Button which triggers file download. Saves file locally when download complete.
 * If file aleady downloaded, download from BE is not called again.
 * Passes loading prop for spinner animation. Animation is stopped on error.
 * Based on
 * https://github.com/notablemind/downloadbutton
 */
export default class IconButtonDownload extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        generateFile: PropTypes.func.isRequired
    };

    static defaultProps = {
        id: null,
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fileData: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading === false && prevState.isLoading === true) {
            this.saveLocal();
        }
    }

    downloadFile = (event) => {
        event.preventDefault();
        if (this.state.fileData) {
            this.saveLocal();
        } else {
            this.setState({isLoading: true, fileData: null});
            this.props.generateFile(this.downloadComplete, this.resetLoading);
        }
    };

    resetLoading = () => {
        this.setState({
            isLoading: false
        });
    };

    downloadComplete = (fileData) => {
        this.setState({
            isLoading: false,
            fileData: fileData
        });
    };

    saveLocal = () => {
        const fileData = this.state.fileData;
        if (!fileData) {
            return false;
        }
        const blob = new Blob([fileData.contents], {type: fileData.mime});
        saveAs(blob, fileData.filename);
    };

    render() {
        const id = this.props.id || '' + Math.random();
        const className = this.props.className;
        return (
            <IconButton
                id={id}
                onClick={this.downloadFile}
                iconClassName={`${
                    this.state.isLoading
                        ? 'icon-button__icon--loading-download'
                        : 'icon-button__icon--download'
                }`}
                isLoading={this.state.isLoading}
                className={`${className ? className : 'icon-button__divider'}`}>
                <WebString webStringKey="cs.button.tooltip.download" />
            </IconButton>
        );
    }
}
