const country = {
    type: 'string',
    title: 'tp.form.adhoccheck.country.title',
    enum: [
        'AD',
        'AE',
        'AF',
        'AG',
        'AI',
        'AL',
        'AM',
        'AN',
        'AO',
        'AQ',
        'AR',
        'AS',
        'AT',
        'AU',
        'AW',
        'AX',
        'AZ',
        'BA',
        'BB',
        'BD',
        'BE',
        'BF',
        'BG',
        'BH',
        'BI',
        'BJ',
        'BL',
        'BM',
        'BN',
        'BO',
        'BQ',
        'BR',
        'BS',
        'BT',
        'BV',
        'BW',
        'BY',
        'BZ',
        'CA',
        'CC',
        'CD',
        'CF',
        'CG',
        'CH',
        'CI',
        'CK',
        'CL',
        'CM',
        'CN',
        'CO',
        'CR',
        'CU',
        'CV',
        'CW',
        'CX',
        'CY',
        'CZ',
        'DE',
        'DJ',
        'DK',
        'DM',
        'DO',
        'DZ',
        'EC',
        'EE',
        'EG',
        'EH',
        'ER',
        'ES',
        'ET',
        'FI',
        'FJ',
        'FK',
        'FM',
        'FO',
        'FR',
        'GA',
        'GB',
        'GD',
        'GE',
        'GF',
        'GG',
        'GH',
        'GI',
        'GL',
        'GM',
        'GN',
        'GP',
        'GQ',
        'GR',
        'GS',
        'GT',
        'GU',
        'GW',
        'GY',
        'HK',
        'HM',
        'HN',
        'HR',
        'HT',
        'HU',
        'ID',
        'IE',
        'IL',
        'IM',
        'IN',
        'IO',
        'IQ',
        'IR',
        'IS',
        'IT',
        'JE',
        'JM',
        'JO',
        'JP',
        'KE',
        'KG',
        'KH',
        'KI',
        'KM',
        'KN',
        'KP',
        'KR',
        'KW',
        'KY',
        'KZ',
        'LA',
        'LB',
        'LC',
        'LI',
        'LK',
        'LR',
        'LS',
        'LT',
        'LU',
        'LV',
        'LY',
        'MA',
        'MC',
        'MD',
        'ME',
        'MF',
        'MG',
        'MH',
        'MK',
        'ML',
        'MM',
        'MN',
        'MO',
        'MP',
        'MQ',
        'MR',
        'MS',
        'MT',
        'MU',
        'MV',
        'MW',
        'MX',
        'MY',
        'MZ',
        'NA',
        'NC',
        'NE',
        'NF',
        'NG',
        'NI',
        'NL',
        'NO',
        'NP',
        'NR',
        'NU',
        'NZ',
        'OM',
        'PA',
        'PE',
        'PF',
        'PG',
        'PH',
        'PK',
        'PL',
        'PM',
        'PN',
        'PR',
        'PS',
        'PT',
        'PW',
        'PY',
        'QA',
        'RE',
        'RO',
        'RS',
        'RU',
        'RW',
        'SA',
        'SB',
        'SC',
        'SD',
        'SE',
        'SG',
        'SH',
        'SI',
        'SJ',
        'SK',
        'SL',
        'SM',
        'SN',
        'SO',
        'SR',
        'SS',
        'ST',
        'SV',
        'SX',
        'SY',
        'SZ',
        'TC',
        'TD',
        'TF',
        'TG',
        'TH',
        'TJ',
        'TK',
        'TL',
        'TM',
        'TN',
        'TO',
        'TR',
        'TT',
        'TV',
        'TW',
        'TZ',
        'UA',
        'UG',
        'UM',
        'US',
        'UY',
        'UZ',
        'VA',
        'VC',
        'VE',
        'VG',
        'VI',
        'VN',
        'VU',
        'WF',
        'WS',
        'YE',
        'YT',
        'ZA',
        'ZM',
        'ZW'
    ],
    enumNames: [
        'cs.text.country.AD',
        'cs.text.country.AE',
        'cs.text.country.AF',
        'cs.text.country.AG',
        'cs.text.country.AI',
        'cs.text.country.AL',
        'cs.text.country.AM',
        'cs.text.country.AN',
        'cs.text.country.AO',
        'cs.text.country.AQ',
        'cs.text.country.AR',
        'cs.text.country.AS',
        'cs.text.country.AT',
        'cs.text.country.AU',
        'cs.text.country.AW',
        'cs.text.country.AX',
        'cs.text.country.AZ',
        'cs.text.country.BA',
        'cs.text.country.BB',
        'cs.text.country.BD',
        'cs.text.country.BE',
        'cs.text.country.BF',
        'cs.text.country.BG',
        'cs.text.country.BH',
        'cs.text.country.BI',
        'cs.text.country.BJ',
        'cs.text.country.BL',
        'cs.text.country.BM',
        'cs.text.country.BN',
        'cs.text.country.BO',
        'cs.text.country.BQ',
        'cs.text.country.BR',
        'cs.text.country.BS',
        'cs.text.country.BT',
        'cs.text.country.BV',
        'cs.text.country.BW',
        'cs.text.country.BY',
        'cs.text.country.BZ',
        'cs.text.country.CA',
        'cs.text.country.CC',
        'cs.text.country.CD',
        'cs.text.country.CF',
        'cs.text.country.CG',
        'cs.text.country.CH',
        'cs.text.country.CI',
        'cs.text.country.CK',
        'cs.text.country.CL',
        'cs.text.country.CM',
        'cs.text.country.CN',
        'cs.text.country.CO',
        'cs.text.country.CR',
        'cs.text.country.CU',
        'cs.text.country.CV',
        'cs.text.country.CW',
        'cs.text.country.CX',
        'cs.text.country.CY',
        'cs.text.country.CZ',
        'cs.text.country.DE',
        'cs.text.country.DJ',
        'cs.text.country.DK',
        'cs.text.country.DM',
        'cs.text.country.DO',
        'cs.text.country.DZ',
        'cs.text.country.EC',
        'cs.text.country.EE',
        'cs.text.country.EG',
        'cs.text.country.EH',
        'cs.text.country.ER',
        'cs.text.country.ES',
        'cs.text.country.ET',
        'cs.text.country.FI',
        'cs.text.country.FJ',
        'cs.text.country.FK',
        'cs.text.country.FM',
        'cs.text.country.FO',
        'cs.text.country.FR',
        'cs.text.country.GA',
        'cs.text.country.GB',
        'cs.text.country.GD',
        'cs.text.country.GE',
        'cs.text.country.GF',
        'cs.text.country.GG',
        'cs.text.country.GH',
        'cs.text.country.GI',
        'cs.text.country.GL',
        'cs.text.country.GM',
        'cs.text.country.GN',
        'cs.text.country.GP',
        'cs.text.country.GQ',
        'cs.text.country.GR',
        'cs.text.country.GS',
        'cs.text.country.GT',
        'cs.text.country.GU',
        'cs.text.country.GW',
        'cs.text.country.GY',
        'cs.text.country.HK',
        'cs.text.country.HM',
        'cs.text.country.HN',
        'cs.text.country.HR',
        'cs.text.country.HT',
        'cs.text.country.HU',
        'cs.text.country.ID',
        'cs.text.country.IE',
        'cs.text.country.IL',
        'cs.text.country.IM',
        'cs.text.country.IN',
        'cs.text.country.IO',
        'cs.text.country.IQ',
        'cs.text.country.IR',
        'cs.text.country.IS',
        'cs.text.country.IT',
        'cs.text.country.JE',
        'cs.text.country.JM',
        'cs.text.country.JO',
        'cs.text.country.JP',
        'cs.text.country.KE',
        'cs.text.country.KG',
        'cs.text.country.KH',
        'cs.text.country.KI',
        'cs.text.country.KM',
        'cs.text.country.KN',
        'cs.text.country.KP',
        'cs.text.country.KR',
        'cs.text.country.KW',
        'cs.text.country.KY',
        'cs.text.country.KZ',
        'cs.text.country.LA',
        'cs.text.country.LB',
        'cs.text.country.LC',
        'cs.text.country.LI',
        'cs.text.country.LK',
        'cs.text.country.LR',
        'cs.text.country.LS',
        'cs.text.country.LT',
        'cs.text.country.LU',
        'cs.text.country.LV',
        'cs.text.country.LY',
        'cs.text.country.MA',
        'cs.text.country.MC',
        'cs.text.country.MD',
        'cs.text.country.ME',
        'cs.text.country.MF',
        'cs.text.country.MG',
        'cs.text.country.MH',
        'cs.text.country.MK',
        'cs.text.country.ML',
        'cs.text.country.MM',
        'cs.text.country.MN',
        'cs.text.country.MO',
        'cs.text.country.MP',
        'cs.text.country.MQ',
        'cs.text.country.MR',
        'cs.text.country.MS',
        'cs.text.country.MT',
        'cs.text.country.MU',
        'cs.text.country.MV',
        'cs.text.country.MW',
        'cs.text.country.MX',
        'cs.text.country.MY',
        'cs.text.country.MZ',
        'cs.text.country.NA',
        'cs.text.country.NC',
        'cs.text.country.NE',
        'cs.text.country.NF',
        'cs.text.country.NG',
        'cs.text.country.NI',
        'cs.text.country.NL',
        'cs.text.country.NO',
        'cs.text.country.NP',
        'cs.text.country.NR',
        'cs.text.country.NU',
        'cs.text.country.NZ',
        'cs.text.country.OM',
        'cs.text.country.PA',
        'cs.text.country.PE',
        'cs.text.country.PF',
        'cs.text.country.PG',
        'cs.text.country.PH',
        'cs.text.country.PK',
        'cs.text.country.PL',
        'cs.text.country.PM',
        'cs.text.country.PN',
        'cs.text.country.PR',
        'cs.text.country.PS',
        'cs.text.country.PT',
        'cs.text.country.PW',
        'cs.text.country.PY',
        'cs.text.country.QA',
        'cs.text.country.RE',
        'cs.text.country.RO',
        'cs.text.country.RS',
        'cs.text.country.RU',
        'cs.text.country.RW',
        'cs.text.country.SA',
        'cs.text.country.SB',
        'cs.text.country.SC',
        'cs.text.country.SD',
        'cs.text.country.SE',
        'cs.text.country.SG',
        'cs.text.country.SH',
        'cs.text.country.SI',
        'cs.text.country.SJ',
        'cs.text.country.SK',
        'cs.text.country.SL',
        'cs.text.country.SM',
        'cs.text.country.SN',
        'cs.text.country.SO',
        'cs.text.country.SR',
        'cs.text.country.SS',
        'cs.text.country.ST',
        'cs.text.country.SV',
        'cs.text.country.SX',
        'cs.text.country.SY',
        'cs.text.country.SZ',
        'cs.text.country.TC',
        'cs.text.country.TD',
        'cs.text.country.TF',
        'cs.text.country.TG',
        'cs.text.country.TH',
        'cs.text.country.TJ',
        'cs.text.country.TK',
        'cs.text.country.TL',
        'cs.text.country.TM',
        'cs.text.country.TN',
        'cs.text.country.TO',
        'cs.text.country.TR',
        'cs.text.country.TT',
        'cs.text.country.TV',
        'cs.text.country.TW',
        'cs.text.country.TZ',
        'cs.text.country.UA',
        'cs.text.country.UG',
        'cs.text.country.UM',
        'cs.text.country.US',
        'cs.text.country.UY',
        'cs.text.country.UZ',
        'cs.text.country.VA',
        'cs.text.country.VC',
        'cs.text.country.VE',
        'cs.text.country.VG',
        'cs.text.country.VI',
        'cs.text.country.VN',
        'cs.text.country.VU',
        'cs.text.country.WF',
        'cs.text.country.WS',
        'cs.text.country.YE',
        'cs.text.country.YT',
        'cs.text.country.ZA',
        'cs.text.country.ZM',
        'cs.text.country.ZW'
    ]
};

export {country};
