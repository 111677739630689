import UserRoleTypes from 'types/UserRoleTypes';
const allManagerLevels = [
    UserRoleTypes.MANAGER_L1,
    UserRoleTypes.MANAGER_L2,
    UserRoleTypes.MANAGER_L3
];

/**
 * Returns status options based on user role.
 * If a manager is using the table, the specific level/ levels will be determined and the corresponding
 * status options will be merged, filtered for doubles and returned.
 * @returns {*}
 */
const getUserStatusOptions = (tableStatuses, userRole, tableType) => {
    if (userRole === UserRoleTypes.MANAGER) {
        const availableStates = [];
        allManagerLevels
            .map((role) => (tableStatuses[role] ? role : undefined))
            .filter((role) => !!role)
            .map((role) =>
                availableStates.push(...tableStatuses[role][tableType])
            );
        return availableStates.filter(
            (state, index) => availableStates.indexOf(state) === index
        );
    }
    return tableStatuses[userRole][tableType];
};

export default getUserStatusOptions;
