import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import WebString from 'components/webString/WebString';
import TpImage from '../common/TpImage';

const DashboardElement = ({elementKey}) => (
    <Link
        id={`tp-dashboard__link-${elementKey}`}
        className="bk-tp-dashboard__link"
        to={elementKey}>
        <div className="bk-tp-dashboard__card">
            <TpImage
                className="bk-tp-dashboard__icon"
                src={`icons/${elementKey}.svg`}
                alt="card_icon"
            />
            <div className="bk-tp-dashboard__text">
                <WebString
                    className="bk-tp-dashboard__webstring"
                    webStringKey={`tp.dashboard.elements.${elementKey}`}
                />
            </div>
            <TpImage
                className="bk-tp-dashboard__chevron-right"
                src={'icons/ic_chevron_right.svg'}
                alt="chevron-right"
            />
        </div>
    </Link>
);

DashboardElement.propTypes = {
    elementKey: PropTypes.string.isRequired
};

export default DashboardElement;
