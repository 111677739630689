import React, {useEffect, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {AuthContext} from './AuthProvider';
import {ACTION_TYPES} from 'store/reducers/oidcReducer';
import ProcessingLoginBox from './ProcessingLoginBox';
import TenantSessionLoader from './TenantSessionLoader';

/*  Validates the user access, if the user is not authenticated the user will
 *  be redirected to the login screen and after will come back to the requested route.
 *  If the user is authenticated he will be saved in the store.
 */
const RequireAuth = ({children}) => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserInfo = async () => {
            const userDetails = await auth.getLoggedInUser();

            if (!userDetails) {
                auth.signinRedirect();
            } else {
                setIsLoading(false);
                dispatch({
                    type: ACTION_TYPES.ADD_USER,
                    user: userDetails
                });
            }
        };
        getUserInfo();
    }, []);

    return isLoading ? (
        <ProcessingLoginBox />
    ) : (
        <TenantSessionLoader>{children}</TenantSessionLoader>
    );
};

RequireAuth.propTypes = {
    children: PropTypes.any.isRequired
};
export default RequireAuth;
